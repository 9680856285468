<app-diagnostic-repeat-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeOverlay($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="deadlineStep">
    <div class="training-task_select_container">
      <form [formGroup]="deadlineStep" #formDirective="ngForm">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--diagnostic-pre">{{
            translatedText.diagnostic_repeat_title
          }}</span>
          <h3 class="h3 h3--bold">{{ translatedText.diagnostic_repeat_repeat }}</h3>
          <p class="paragraph paragraph--xl">{{ translatedText.diagnostic_repeat_text }}</p>
        </div>
        <div class="book_preview_warm_box">
          <mat-icon class="book_preview_warm_icon" svgIcon="streamline_outlined:info-empty"></mat-icon>
          <p class="paragraph" *ngIf="task?.repeated == 0">
            {{ translatedText.diagnostic_repeat_only_once }}
          </p>
          <p class="paragraph" *ngIf="task?.repeated == 1">
            {{ translatedText.diagnostic_repeat_repeated }}
          </p>
        </div>

        <div class="diagnostic-repeat-select-wrap">
          <mat-selection-list #selectedDiagnostic formControlName="diagnostic" class="diagnostic-repeat-selection-list">
            <mat-list-option
              checkboxPosition="before"
              class="round-checkbox no-checkmark diagnostic-repeat-list-option"
              [selected]="true"
              [ngClass]="{
                'selected-diagnostic': checkForItemSelection(
                  selectedDiagnostic.selectedOptions.selected,
                  diagnostic?.value
                ),
                'selected-diagnostic-complete':
                  checkForItemSelection(selectedDiagnostic.selectedOptions.selected, diagnostic?.value) ==
                    diagnostic.value || deadlineStep.get('diagnosticDeadline')?.value !== '',
                expiredDiagnostic_date: getDateFromString(diagnostic.endDate) < todayDate,
              }"
              [value]="diagnostic?.value"
            >
              <div class="mdc-list-item_flex">
                <div>
                  <p class="paragraph paragraph--bold paragraph--xl">{{ diagnostic.name }}</p>
                  <p class="paragraph paragraph--l diagnostic_time">
                    {{ diagnostic.startDate | date: 'd MMM y' }} - {{ diagnostic.endDate | date: 'd MMM y' }}
                  </p>
                </div>
                <div class="deadline_wrap">
                  <p class="paragraph paragraph--s">{{ translatedText.diagnostic_repeat_select_date }}</p>
                  <mat-form-field (click)="$event.stopPropagation()">
                    <mat-label>{{ translatedText.date_format }}</mat-label>
                    <input
                      matInput
                      [min]="diagnosticDateChanger.checked ? dateToday : diagnostic.startDate"
                      [max]="diagnosticDateChanger.checked ? null : diagnostic.endDate"
                      onkeypress="return false"
                      formControlName="diagnosticDeadline"
                      type="text"
                      (click)="deadlinepicker.open()"
                      [matDatepicker]="deadlinepicker"
                    />
                    <mat-datepicker-toggle matSuffix [for]="deadlinepicker"></mat-datepicker-toggle>
                    <mat-datepicker #deadlinepicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>
        <div class="diagnostic-repeat-date-changer">
          <mat-checkbox #diagnosticDateChanger [checked]="diagnosticSelected" class="example-margin">{{
            translatedText.diagnostic_repeat_time_text
          }}</mat-checkbox>
          <mat-icon matTooltip="{{ translatedText.diagnostic_repeat_time }}" svgIcon="streamline_outlined:hint">
          </mat-icon>
        </div>
      </form>
    </div>
    <div class="diagnostic-repeat_select_button" *ngIf="diagnosticValue !== 2">
      <button alt="Zurück" mat-flat-button color="secondary" (click)="closeOverlay(true)">
        {{ translatedText.diagnostic_repeat_back }}
      </button>
      <button
        alt="Test anlegen"
        mat-flat-button
        color="primary"
        [disabled]="deadlineStep.invalid"
        (click)="submitTask(formDirective)"
      >
        {{ translatedText.diagnostic_repeat_create }}
      </button>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep">
    <!-- <button
      mat-mini-fab
      type="button"
      class="close-button close-button--left"
      [disabled]="isLoading"
      (click)="closeOverlay(true)"
      matSuffix
    >
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button> -->
    <div class="last_step_container edit">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.diagnostic_repeat_create_success }}</h3>
      <button alt="Ok" mat-flat-button color="primary" class="mat-flat-button--full" (click)="closeOverlay(true)">
        {{ translatedText.diagnostic_repeat_ok }}
      </button>
    </div>
  </cdk-step>
</app-diagnostic-repeat-stepper>
