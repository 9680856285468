<button
  alt="Schießen"
  mat-mini-fab
  type="button"
  class="close-button close-button--right"
  [mat-dialog-close]="'close'"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<div class="language-alert-container">
  <h3 class="h3 h3--bold">{{ translatedText.language_change_alert_title }}</h3>
  <p class="paragraph paragraph--l">
    {{ translatedText.language_change_alert_desc }}
  </p>
  <button alt="Ok" mat-flat-button color="warn" (click)="closeOverlay()">OK</button>
</div>
