<div class="book-reader-settings-wrap">
  <p class="paragraph paragraph--l">Schriftgröße</p>
  <mat-button-toggle-group
    class="font-size-settings"
    (change)="updateParagraphClass($event)"
    name="fontSize"
    aria-label="Font Size"
    [value]="paragraphClass"
  >
    <mat-button-toggle class="small-font" value="book-reader-viewer--paragraph paragraph--s">Aa</mat-button-toggle>
    <mat-button-toggle class="medium-font" value="book-reader-viewer--paragraph">Aa</mat-button-toggle>
    <mat-button-toggle class="big-font" value="book-reader-viewer--paragraph paragraph--l">Aa</mat-button-toggle>
    <mat-button-toggle class="extra-big-font" value="book-reader-viewer--paragraph paragraph--l-extra"
      >Aa
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-divider></mat-divider>
  <!-- <p class="paragraph paragraph--l">Zeichenanzahl: {{maxCharcount}}</p>
      <mat-slider min="500" max="3000" (input)="updateMaxCharcount($event)" step="500" value="1000" [value]="maxCharcount"></mat-slider>
      <mat-divider></mat-divider> -->
  <button alt="Einstellung übernehmen" mat-flat-button color="primary" type="button" (click)="updateSetting()">
    Einstellung übernehmen
  </button>
</div>
