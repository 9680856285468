<div class="container-fluid container-fluid--nopadding">
  <div class="authenticator-setup-container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h3 class="heading heading--l">
          {{ translatedText?.autenticator_setup_title }}
        </h3>
        <p class="paragraph paragraph--l">
          {{ translatedText?.autenticator_setup_instruction_1 }}
        </p>
        <div class="qrcode_wrapper" *ngIf="qrCode">
          <img [src]="qrCode" alt="" />
        </div>
        <ng-container *ngIf="qrCode">
          <p class="paragraph paragraph--l">
            {{ translatedText?.autenticator_setup_instruction_2 }}
          </p>
          <form (submit)="verifyQrCode(qrForm)" #qrForm="ngForm">
            <mat-form-field floatLabel="auto">
              <!-- <mat-label>{{ translatedText?.login_otp }}</mat-label> -->
              <input
                matInput
                name="otpToken"
                ngModel
                #otpTokenInput
                aria-label="OTP Token"
                placeholder="{{ translatedText?.login_otp }}"
                required
              />
              <mat-error *ngIf="loginStatus === 'otpError'"> Please enter a valid OTP token. </mat-error>
            </mat-form-field>
            <button
              alt="MFA Aktivieren"
              [disabled]="qrForm.invalid"
              mat-flat-button
              color="primary"
              type="submit"
              class="mat-flat-button--full"
              aria-label="Activate"
            >
              {{ translatedText?.autenticator_setup_submit }}
            </button>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
