import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';
import { LicenseService } from 'src/app/providers/license.service';
import { lineChart } from 'src/app/class/class-detail/chartData';
import { environment } from 'src/environments/environment';

export interface DialogData {
  source: string;
}

export interface DialogData {}
@Component({
  selector: 'app-license-activation-banner',
  templateUrl: './license-activation-banner.component.html',
  styleUrls: ['./license-activation-banner.component.scss'],
})
export class LicenseActivationBannerComponent implements OnInit, OnDestroy {
  environment = environment;
  IS_APP = environment.isApp;
  translatedText: any;
  licenseAvailable: boolean = false;
  isClosed: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private translationService: TranslationService,
    private licenseService: LicenseService,
  ) {}

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.isClosed = this.isBoxClosed();
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licenseAvailable = licenses.some(
          (license) => license.status === 'pending',
        );
      });
  }

  handleClose(): void {
    this.isClosed = true;
    this.closeBox();
  }

  isBoxClosed(): boolean {
    return localStorage.getItem('hint-state-available-licenses') === 'closed';
  }

  closeBox(): void {
    localStorage.setItem('hint-state-available-licenses', 'closed');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
