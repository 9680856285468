import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let requestUrl = req.url;
    const authToken = this.authService.getToken();
    let authRequest = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });
    // readalizerAPI
    if (requestUrl.indexOf(environment.CLOUDAPI_SERVER) !== -1) {
      authRequest = req.clone({});
    }
    // bookReaderAPI
    if (requestUrl.indexOf(environment.BOOKREADERAPI_SERVER) !== -1) {
      authRequest = req.clone({});
    }
    // CDN assets
    if (requestUrl.indexOf(environment.externalAssets) !== -1) {
      authRequest = req.clone({});
    }
    // User Frontends
    const userFrontends = [
      'https://www.laletu.de',
      'https://www.evolution.sandbox.laletu.de',
      'https://www.evolution.staging.laletu.de',
      'https://www.evolution.dev.laletu.de',
    ];
    if (userFrontends.some((url) => requestUrl.includes(url))) {
      authRequest = req.clone({});
    }
    return next.handle(authRequest);
  }
}
