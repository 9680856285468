<app-managing-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeTeacherOverlay($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="firstStep">
    <div class="admin-management-headline">
      <h2 class="h2 h2--bold">{{ translatedText.admin_managing_teacher_selection }}</h2>
      <p class="paragraph paragraph--l">{{ translatedText.admin_managing_teacher_selection_text }}</p>
    </div>
    <div class="training-task_select_container with-table">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              (change)="$event ? toggleAllRows() : null"
              [checked]="selection?.hasValue() && isAllSelected()"
              [indeterminate]="selection?.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection?.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_managing_full_name }}</th>
          <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_managing_email }}</th>
          <td mat-cell *matCellDef="let element">{{ element?._user?.email }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>{{ translatedText.admin_managing_status }}</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.status === 'active'">
              <span class="teacher-status teacher-status--active">{{ translatedText.admin_managing_active }}</span>
            </ng-container>
            <ng-container *ngIf="element.status !== 'active'">
              <span class="teacher-status teacher-status--inactive">{{ translatedText.admin_managing_inactive }}</span>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
      </table>
    </div>
    <div class="training-task_select_button">
      <button
        alt="Schließen"
        mat-flat-button
        color="secondary"
        class="mat-flat-button--full"
        (click)="closeTeacherOverlay($event)"
        [disabled]="isLoading"
      >
        {{ translatedText.admin_managing_cancel }}
      </button>
      <button
        alt="Administrator hinzufügen"
        [disabled]="isLoading"
        mat-flat-button
        color="primary"
        class="mat-flat-button--full"
        (click)="submitSelection()"
      >
        <span *ngIf="!isLoading">{{ translatedText.admin_managing_add_admin }}</span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep">
    <div class="last_step_container teacher">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.admin_managing_add_admin_success }}</h3>
      <p class="paragraph paragraph--xl">{{ translatedText.admin_managing_teacher_has_admin_rights }}</p>
      <!-- <div class="email-wrap">
            <h3 class="h3 h3--bold">{{ EmailArray.join(', ') }} </h3>
          </div>
          <p class="paragraph paragraph--xl">Diese Email enthält einen Bestätigungslink.</p> -->
      <div class="button_wrap-last-step">
        <button
          alt="Fertig"
          mat-flat-button
          color="secondary"
          class="mat-flat-button--full"
          (click)="closeTeacherOverlay($event)"
        >
          {{ translatedText.admin_managing_finish }}
        </button>
        <button
          alt="Weitere Administratoren hinzufügen"
          mat-flat-button
          color="primary"
          class="mat-flat-button--full"
          type="button"
          (click)="resetForm()"
        >
          {{ translatedText.admin_managing_add_more }}
        </button>
      </div>
    </div>
  </cdk-step>
</app-managing-stepper>
