<section class="container-fluid preview_overlay">
  <div class="row">
    <div class="col-md-12">
      <span class="paragraph task_chip task_chip--text">{{ translatedText.book_preview_training }}</span>
      <div class="book_preview_warm_box" *ngIf="!isSpecific_book">
        <mat-icon class="book_preview_warm_icon" svgIcon="streamline_outlined:info-empty"></mat-icon>
        <p class="paragraph">{{ translatedText.book_preview_time }}</p>
      </div>
    </div>

    <div class="col-md-8">
      <div class="book_preview_title">
        <h2 class="h2 h2--bold" [innerHtml]="bookTitle"></h2>
        <p class="paragraph paragraph">{{ translatedText.book_preview_from }} {{ bookAuthor }}</p>
      </div>
      <p class="paragraph description" [innerHtml]="bookDescription"></p>
    </div>

    <div class="col-md-4">
      <img
        src="{{ getBookCoverByIsbn(bookIsbn, 'normal') }}"
        alt="{{ bookTitle }}"
        loading="lazy"
        (error)="handleImageError($event)"
      />
    </div>
  </div>

  <div class="bookview_footer flex" [ngClass]="{ 'button-up': iPadResolution }">
    <button alt="Zurück" *ngIf="!isTask" mat-flat-button color="secondary" (click)="navigateBackToLibrary()">
      {{ translatedText.book_preview_back }}
    </button>
    <button
      alt="Textpassage wählen"
      *ngIf="!isTask"
      mat-flat-button
      color="primary"
      type="button"
      (click)="openBookView()"
    >
      {{ getNextStepButtonText() }}
    </button>
    <button
      alt="Lesen"
      *ngIf="isTask"
      mat-flat-button
      color="primary-student"
      type="button"
      (click)="openBookPartSelection()"
    >
      {{ translatedText.book_preview_read }}
    </button>
  </div>
</section>
