<div class="notification_controller">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div *ngIf="!isSuccessful" class="notification_controller_wrap">
    <div class="inline_text" *ngIf="notificationType != 'teacher'">
      <img class="avatar_img" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" alt="" />
      <h3 class="h3 h3--bold username">
        {{ student?.username }}
      </h3>
      <ng-container *ngIf="groupName != undefined">
        <h3 class="h3 student_group_name">({{ groupName }})</h3>
      </ng-container>
      <ng-container *ngIf="groupName == undefined">
        <h3 class="h3 student_group_name">( {{ getGroupName(student?._group)?.name }} )</h3>
      </ng-container>
    </div>
    <h3 class="h3 h3--bold">{{ headline }}</h3>
    <form [formGroup]="notificationForm" #formDirective="ngForm">
      <mat-form-field>
        <textarea
          matInput
          formControlName="message"
          placeholder="{{ placeholder }}"
          class="notification-message-container"
        ></textarea>
      </mat-form-field>
      <div class="notification_button_wrap">
        <button alt="Abbrechen" mat-flat-button color="secondary" [mat-dialog-close]="true">
          {{ translatedText.notification_overlay_cancel }}
        </button>
        <button
          alt="Verschicken"
          mat-flat-button
          color="primary"
          class="teacher_button_primary"
          [disabled]="notificationForm.invalid"
          (click)="submitForm(formDirective)"
        >
          {{ buttonText }}
        </button>
      </div>
    </form>
  </div>

  <div class="last_step_container" *ngIf="isSuccessful">
    <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
    <h3 class="h3 h3--bold">{{ translatedText.notification_overlay_success }}</h3>
    <button alt="ok" mat-flat-button color="primary" (click)="closeOverlay()">
      {{ translatedText.notification_overlay_ok }}
    </button>
  </div>
</div>
