<section>
  <div class="teacher_sidebar" [ngClass]="{ 'collapsed-width': collapsedSidebar, 'margin-bottom': !isApp }">
    <div>
      <button
        alt="Aufgabe erstellen"
        class="task_creation_btn teacher_sidebar_unset top-button"
        mat-flat-button
        color="primary"
        type="button"
        [matMenuTriggerFor]="sidebar_menu"
        #menuTrigger="matMenuTrigger"
        (click)="openSidebarMenu()"
      >
        <span class="create-task-inside-span">
          <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
          <span class="task-create-btn-text" *ngIf="!collapsedSidebar">{{
            translatedText.teacher_sidebar_create_task
          }}</span>
        </span>
        <!-- <mat-icon [ngClass]="{ 'rotate-icon': openedMenu }" class="create-task-expand-icon"> expand_more </mat-icon> -->
      </button>
    </div>
    <mat-menu #sidebar_menu="matMenu" class="sidebar_menu" (closed)="closeMenu()">
      <button alt="Diagnose" mat-menu-item (click)="createDiagnoseDialog()" *ngIf="diagnosticLicenseStatusActive">
        <mat-icon svgIcon="streamline_outlined:target"></mat-icon>{{ translatedText.teacher_sidebar_diagnostic }}
      </button>
      <button alt="Förderung" mat-menu-item (click)="createTaskDialog()" *ngIf="trainingLicenseStatusActive">
        <mat-icon svgIcon="streamline_outlined:lightning"></mat-icon>{{ translatedText.teacher_sidebar_training }}
      </button>
      <!-- <span class="paragraph paragraph--s info-text"> 
            <strong>Achtung:</strong><br>
            <strong>Bevor Sie Aufgaben erstellen </strong>stellen Sie bitte sicher, dass <br>
            <ul>
                <li>zuerst Ihre Klasse mit Lernenden angelegt wurde,<br></li>
                <li>Sie genügend Diagnoselizenzen oder Förderminuten haben.<br></li>
            </ul>
            Wenden Sie sich bitte an Ihre Schuladministration, wenn noch keine Klasse angelegt wurde oder fragen Sie neue Lizenzen an.
         </span>   -->
    </mat-menu>

    <!-- <div class="trial_box">
        <h2 class="h2 h2--bold">Zwei Wochen Probetraining! </h2>
        <p class="paragraph">Trainieren Sie Ihre Schüler mehrmals pro Woche mit flexiblen Aufgaben.</p>
        <button class="trial_box_button" mat-flat-button color="unset" type="button" (click)="createTaskDialog()">Trainingsaufgabe erstellen</button>
    </div> -->
    <div class="teacher_sidebar--flex-column">
      <div>
        <div class="teacher_sidebar_school">
          <!-- <button
            *ngIf="userType == 'teacher'"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/dashboard-license']"
            routerLinkActive="active-sidebar_button"
          >
            <mat-icon svgIcon="streamline_outlined:privacy-policy"> </mat-icon>
            <span [hidden]="collapsedSidebar" class="btn-text">{{ translatedText.teacher_sidebar_licenses }}</span>
          </button> -->
        </div>

        <div>
          <!-- class="teacher_sidebar_admin" -->
          <!-- *ngIf="userType == 'admin' || userType == 'school_admin'" -->
          <!--  <p
            [ngClass]="{ 'align-left': !collapsedSidebar }"
            class="paragraph paragraph--s">
            <span [hidden]="collapsedSidebar">{{
              translatedText.teacher_sidebar_admins
            }}</span>
            <span class="visible-tablet-or-smaller">{{
              translatedText.teacher_sidebar_admin_short
            }}</span>
          </p> -->
          <button
            alt="Dashboard"
            *ngIf="userType == 'school_admin' || !environment.whitelabel.show_overview"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/admin-dashboard']"
            routerLinkActive="active-sidebar_button"
          >
            <mat-icon svgIcon="streamline_outlined:reports"></mat-icon>
            <span class="btn-text" [hidden]="collapsedSidebar"> {{ translatedText.teacher_sidebar_dashboard }}</span>
          </button>
          <button
            alt="Übersicht"
            *ngIf="environment.whitelabel.show_overview"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/dashboard-teacher']"
            routerLinkActive="active-sidebar_button"
          >
            <ng-container *ngIf="userType == 'school_admin'">
              <mat-icon svgIcon="streamline_outlined:chart-column-grow"></mat-icon
              ><span class="btn-text" [hidden]="collapsedSidebar">{{ translatedText.teacher_sidebar_overview }}</span>
            </ng-container>
            <ng-container *ngIf="userType == 'teacher'">
              <mat-icon svgIcon="streamline_outlined:reports"></mat-icon>
              <span class="btn-text" [hidden]="collapsedSidebar"> {{ translatedText.teacher_sidebar_dashboard }}</span>
            </ng-container>
          </button>
          <button
            alt="Aufgaben"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/dashboard-tasks']"
            routerLinkActive="active-sidebar_button"
            [ngClass]="{
              'active-sidebar_button': currentUrlSlug == 'task-detail',
            }"
          >
            <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
            <span [hidden]="collapsedSidebar" class="btn-text">{{ translatedText.teacher_sidebar_tasks }}</span>
          </button>
          <button
            alt="Klassen"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/admin-group-list']"
            routerLinkActive="active-sidebar_button"
          >
            <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
            <span class="btn-text" [hidden]="collapsedSidebar">{{ translatedText.teacher_sidebar_groups }}</span>
          </button>

          <button
            alt="Lehrkräfte"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/admin-teacher-list']"
            routerLinkActive="active-sidebar_button"
          >
            <mat-icon svgIcon="streamline_outlined:user-star"></mat-icon>
            <span class="btn-text" [hidden]="collapsedSidebar">{{ translatedText.teacher_sidebar_teachers }}</span>
          </button>
          <button
            alt="Hilfe"
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            (click)="openExternalUrl(support_url)"
            routerLinkActive="active-sidebar_button"
          >
            <!-- [matMenuTriggerFor]="help" -->
            <mat-icon svgIcon="streamline_outlined:information-chat-right"></mat-icon>
            <span class="btn-text" [hidden]="collapsedSidebar"> {{ translatedText.teacher_sidebar_help }}</span>
          </button>

          <!-- <button
            class="teacher_sidebar_unset top-button"
            mat-flat-button
            type="button"
            [routerLink]="['/admin-list']"
            routerLinkActive="active-sidebar_button"
          >
            <mat-icon svgIcon="streamline_outlined:user-crown"></mat-icon>
            <span class="btn-text" [hidden]="collapsedSidebar">
              {{ translatedText.teacher_sidebar_admin }}</span
            >
          </button> -->
        </div>

        <app-hint-box
          *ngIf="router.url === '/admin-dashboard' && environment.whitelabel.sidebar_hint"
          id="teacher-sidebar-admin-dashboard"
          [title]="translatedText.teacher_sidebar_hint_admin_dashboard_title"
          [text]="translatedText.teacher_sidebar_hint_admin_dashboard_text"
        ></app-hint-box>

        <app-hint-box
          *ngIf="router.url === '/admin-group-list' && environment.whitelabel.sidebar_hint"
          id="teacher-sidebar-admin-group-list"
          [title]="translatedText.teacher_sidebar_hint_admin_group_list_title"
          [text]="translatedText.teacher_sidebar_hint_admin_group_list_text"
        ></app-hint-box>
      </div>
      <ng-container>
        <!-- <mat-menu #help="matMenu" yPosition="above">
          <button mat-menu-item [matMenuTriggerFor]="schooladmin" *ngIf="userType == 'school_admin'">
            {{ translatedText.teacher_sidebar_school_admin }}
          </button>
          <button mat-menu-item [matMenuTriggerFor]="teacher">
            {{ translatedText.teacher_sidebar_teacher }}
          </button>
          <button mat-menu-item [matMenuTriggerFor]="student">
            {{ translatedText.teacher_sidebar_students }}
          </button>
          <button
            mat-menu-item
            (click)="openExternalUrl('https://support.laletu.de/hc/de')"
          >
            {{ translatedText.teacher_sidebar_helpcenter }}
          </button>
        </mat-menu> -->

        <!-- <mat-menu #schooladmin="matMenu">
          <button mat-menu-item (click)="showVideo('3_Admin-Dashboard.mp4', translatedText.teacher_sidebar_dashboard)">
            {{ translatedText.teacher_sidebar_dashboard }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '6_Admin-Lehrkraefte.mp4',
                translatedText.teacher_sidebar_teachers_management
              )
            "
          >
            {{ translatedText.teacher_sidebar_teachers_management }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '4_Admin-Klassen.mp4',
                translatedText.teacher_sidebar_groups_management
              )
            "
          >
            {{ translatedText.teacher_sidebar_groups_management }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '5_Admin-Lernende.mp4',
                translatedText.teacher_sidebar_students_management
              )
            "
          >
            {{ translatedText.teacher_sidebar_students_management }}
          </button>
        </mat-menu>

        <mat-menu #teacher="matMenu">
          <button
            mat-menu-item
            (click)="
              showVideo(
                '2_Lehrkraft-Uebersicht.mp4',
                translatedText.teacher_sidebar_overview
              )
            "
          >
            {{ translatedText.teacher_sidebar_overview }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '3_Lehrkraft-Klassendaten.mp4',
                translatedText.teacher_sidebar_group_data
              )
            "
          >
            {{ translatedText.teacher_sidebar_group_data }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '4_Lehrkraft-Klassenuebersicht.mp4',
                translatedText.teacher_sidebar_group_overview
              )
            "
          >
            {{ translatedText.teacher_sidebar_group_overview }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '5_Lehrkraft-Lernende-Ansicht.mp4',
                translatedText.teacher_sidebar_student_overview
              )
            "
          >
            {{ translatedText.teacher_sidebar_student_overview }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '6_Lehrkraft-Aufgabenergebnisse.mp4',
                translatedText.teacher_sidebar_task_results
              )
            "
          >
            {{ translatedText.teacher_sidebar_task_results }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '7_Lehrkraft-Aufgabenuebersicht.mp4',
                translatedText.teacher_sidebar_task_overview
              )
            "
          >
            {{ translatedText.teacher_sidebar_task_overview }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '9_Lehrkraft-Diagnose.mp4',
                translatedText.teacher_sidebar_diagnostic_task
              )
            "
          >
            {{ translatedText.teacher_sidebar_diagnostic_task }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '10_Lehrkraft-Manuell-vs-auto.mp4',
                translatedText.teacher_sidebar_tasksmode
              )
            "
          >
            {{ translatedText.teacher_sidebar_tasksmode }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '11_Lehrkraft-Spezifischer-Text.mp4',
                translatedText.teacher_sidebar_specific_task
              )
            "
          >
            {{ translatedText.teacher_sidebar_specific_task }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '12_Lehrkraft-Spezifisches-Buch.mp4',
                translatedText.teacher_sidebar_single_book
              )
            "
          >
            {{ translatedText.teacher_sidebar_single_book }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '13_Lehrkraft-Beliebiges-Buch.mp4',
                translatedText.teacher_sidebar_multiple
              )
            "
          >
            {{ translatedText.teacher_sidebar_multiple }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '14_Lehrkraft-Automode.mp4',
                translatedText.teacher_sidebar_auto
              )
            "
          >
            {{ translatedText.teacher_sidebar_auto }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '8_Lehrkraft-Aufgabe-bearbeiten.mp4',
                translatedText.teacher_sidebar_edit
              )
            "
          >
            {{ translatedText.teacher_sidebar_edit }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '15_Lehrkraft-Lizenzen.mp4',
                translatedText.teacher_sidebar_licenses
              )
            "
          >
            {{ translatedText.teacher_sidebar_licenses }}
          </button>
        </mat-menu>

        <mat-menu #student="matMenu">
          <button
            mat-menu-item
            (click)="
              showVideo(
                '2_Lernende-Dashboard.mp4',
                translatedText.teacher_sidebar_dashboard
              )
            "
          >
            {{ translatedText.teacher_sidebar_dashboard }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '3_Lernende-Menuepunkte.mp4',
                translatedText.teacher_sidebar_menu
              )
            "
          >
            {{ translatedText.teacher_sidebar_menu }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '4_Lernende-Aufgabenarten.mp4',
                translatedText.teacher_sidebar_tasks_type
              )
            "
          >
            {{ translatedText.teacher_sidebar_tasks_type }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '5_Lernende-Leseaufgabe-durchfuehren.mp4',
                translatedText.teacher_sidebar_action
              )
            "
          >
            {{ translatedText.teacher_sidebar_action }}
          </button>
          <button
            mat-menu-item
            (click)="
              showVideo(
                '6_Lernende-waehrend-lesen.mp4',
                translatedText.teacher_sidebar_read
              )
            "
          >
            {{ translatedText.teacher_sidebar_read }}
          </button>
        </mat-menu> -->
      </ng-container>
    </div>
    <div class="teacher_sidebar_unset">
      <!-- [ngClass]="{ 'teacher_sidebar_unset--flex': !collapsedSidebar }" 
      [ngClass]="{
          'wrap-bottom-flex': !collapsedSidebar,
          'wrap-bottom-block': collapsedSidebar
        }"-->

      <div class="visible-tablet-or-smaller">
        <button
          alt="Anzeigen"
          class="handle-sidebar-button"
          mat-flat-button
          color="unset"
          type="button"
          (click)="handleSidebar()"
        >
          <mat-icon [ngClass]="{ 'inverted-arrow': !collapsedSidebar }" svgIcon="streamline_outlined:nav-arrow-right">
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</section>
