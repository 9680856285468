import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { SchoolService } from 'src/app/providers/school.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-oauth-user-update',
  templateUrl: './oauth-user-update.component.html',
  styleUrls: ['./oauth-user-update.component.scss'],
})
export class OauthUserUpdateUpdateComponent implements OnInit, OnDestroy {
  oauthForm: FormGroup;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = false;
  isSubmited: boolean = false;
  isError: boolean = false;
  schoolId;
  newSalutationItem: FormControl;
  salutation: string[] = ['Herr', 'Frau', 'Divers'];
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  filteredItems: Observable<any[]>;
  showAddButton: boolean = false;
  teacherEmail: string;
  isEmailDisabled: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<OauthUserUpdateUpdateComponent>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslationService,
    private schoolService: SchoolService,
  ) {
    if (injectedData) {
      if (typeof injectedData['teacherEmail'] !== 'undefined') {
        this.teacherEmail = this.injectedData['teacherEmail'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.oauthForm = this.formBuilder.group({
      email: [
        this.teacherEmail,
        [Validators.required, this.customEmailValidator],
      ],
      salutation: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
    });
    if (this.teacherEmail !== undefined && this.teacherEmail !== '') {
      this.oauthForm.get('email').disable();
      this.isEmailDisabled = true;
    }
    this.schoolId = this.authService.getSchoolId();
    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  addOption() {
    let option = this.removePromptFromOption(this.newSalutationItem.value);
    if (!this.salutation.some((entry) => entry === option)) {
      const index = this.salutation.push(option) - 1;
      this.newSalutationItem.setValue(this.salutation[index]);
    }
  }
  removePromptFromOption(option) {
    if (option.startsWith(this.prompt)) {
      option = option.substring(this.prompt.length, option.length - 1);
    }
    return option;
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  submitForm(form: FormGroupDirective) {
    if (this.oauthForm.invalid) {
      return;
    }
    this.isLoading = true;
    const { email, firstname, lastname, salutation } = this.oauthForm.value;

    const updatedUser = {
      firstname,
      lastname,
      salutation,
      ...(this.isEmailDisabled ? {} : { email }),
    };

    this.authService.updateOauthUser(updatedUser).subscribe({
      next: (res) => {
        this.authService.updateUser('email', this.oauthForm.value.email);
        this.isSubmited = true;
      },
      error: (error: any) => {
        console.log(error);
        this.isLoading = false;
        if (error.error.message == 'E-Mail bereits vergeben') {
          this.oauthForm.get('email').setErrors({ emailTaken: true });
        } else {
          this.isError = true;
        }
      },
    });
  }

  closeOverlay() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
