<button
  alt="Schließen"
  mat-mini-fab
  type="button"
  class="close-button close-button--right"
  [mat-dialog-close]="'close'"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<div class="delete_container">
  <mat-icon class="warn-icon" svgIcon="streamline_outlined:warning-circle-2"> </mat-icon>

  <h2 class="h2 h2--bold">
    {{ translatedText.account_deletion_title }}
  </h2>
  <p class="paragraph paragraph--xl">{{ translatedText.account_deletion_text }}</p>
  <div class="button_wrap">
    <button alt="Abbrechen" mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
      {{ translatedText.student_delete_cancel }}
    </button>
    <button
      alt="Konto löschen"
      mat-flat-button
      color="warning"
      type="button"
      class="student-delete_button"
      (click)="deleteTeacher()"
    >
      {{ translatedText.account_deletion_confirm_button }}
    </button>
  </div>
</div>
