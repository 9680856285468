<app-diagnostic-create-stepper
  #stepper
  linear
  [bookSelected]="bookSelected"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeAll($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="fourthFormStep">
    <form [formGroup]="fourthFormStep">
      <div class="training-task_select_container diagnostic">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--diagnostic-pre">{{
            translatedText.diagnostic_create_diagnostic
          }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.diagnostic_create_create_test }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.diagnostic_create_select_diagnostic }}
          </p>
        </div>

        <app-trial-banner
          *ngIf="trialStatus.isActive"
          [trialHeadline]="translatedText.license_books_trial_title"
          [trialText]="translatedText.license_task_trial_diagnostic_description"
          [trialStatus]="trialStatus"
          [showExpirationDate]="false"
          [showButtons]="false"
          [id]="'diagnostic-create-component'"
        ></app-trial-banner>

        <div class="diagnostic-select-wrap">
          <mat-selection-list #selectedDiagnostic formControlName="diagnostic" class="diagnostic-selection-list">
            <mat-list-option
              (click)="setValidationForDeadline(selectedDiagnostic)"
              checkboxPosition="before"
              class="diagnostic-list-option round-checkbox no-checkmark"
              [ngClass]="{
                'selected-diagnostic': checkForItemSelection(
                  selectedDiagnostic.selectedOptions.selected,
                  diagnostic?.value
                ),
                'selected-diagnostic-complete':
                  checkForItemSelection(selectedDiagnostic.selectedOptions.selected, diagnostic?.value) ==
                    diagnostic.value || fourthFormStep.get('deadline' + diagnostic.deadline)?.value !== '',
                expiredDiagnostic_date: getDateFromString(diagnostic.endDate) < todayDate,
              }"
              *ngFor="let diagnostic of diagnostics; let i = index"
              [value]="diagnostic?.value"
              [disabled]="isDiagnosticDisabled(diagnostic.endDate) && !diagnosticDateChanger.checked"
            >
              <div class="inline_text">
                <div>
                  <p class="paragraph paragraph--bold paragraph--xl">
                    {{ diagnostic.name }}
                  </p>
                  <p class="paragraph paragraph--l diagnostic_time">
                    {{ diagnostic.startDate | date: 'd. MMM y' }} -
                    {{ diagnostic.endDate | date: 'd. MMM y' }}
                  </p>
                </div>
                <div
                  class="deadline_wrap"
                  *ngIf="checkForItemSelection(selectedDiagnostic.selectedOptions.selected, diagnostic?.value)"
                >
                  <p class="paragraph paragraph--s">
                    {{ translatedText.diagnostic_create_select_date }}
                  </p>
                  <mat-form-field (click)="$event.stopPropagation()">
                    <input
                      matInput
                      placeholder="{{ translatedText.date_format }}"
                      [min]="
                        diagnosticDateChanger.checked || diagnostic.startDate < dateTodayIso
                          ? dateToday
                          : diagnostic.startDate
                      "
                      [max]="diagnosticDateChanger.checked ? null : diagnostic.endDate"
                      onkeypress="return false"
                      [formControlName]="'deadline' + diagnostic.deadline"
                      type="text"
                      (click)="deadlinepicker.open()"
                      [matDatepicker]="deadlinepicker"
                    />
                    <mat-datepicker-toggle matSuffix [for]="deadlinepicker"></mat-datepicker-toggle>
                    <mat-datepicker #deadlinepicker></mat-datepicker>
                    <!-- <mat-error *ngIf="fourthFormStep.get('deadline').hasError('required')">
                      Bitte setzen Sie eine Frist
                    </mat-error>  -->
                  </mat-form-field>
                </div>
              </div>
              <!-- {{selectedDiagnostic.selectedOptions.selected[i]?.value}} -->
            </mat-list-option>
          </mat-selection-list>

          <!-- <mat-error *ngIf="fourthFormStep.get('diagnostic').hasError('required')">
            Bitte setzen Sie eine Frist
          </mat-error>  -->
        </div>

        <div class="diagnostic-date-changer">
          <mat-checkbox
            #diagnosticDateChanger
            [disabled]="trialStatus.isActive"
            (change)="customDateChanged($event)"
            class="example-margin"
            >{{ translatedText.diagnostic_create_time }}</mat-checkbox
          >
          <mat-icon matTooltip="{{ translatedText.diagnostic_create_time_text }}" svgIcon="streamline_outlined:hint">
          </mat-icon>
        </div>
      </div>

      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Abbrechen" mat-flat-button color="secondary" (click)="closeAll(true)">
          {{ translatedText.diagnostic_create_cancel }}
        </button>
        <button
          alt="Weiter"
          mat-flat-button
          color="primary"
          [disabled]="
            fourthFormStep.get('diagnostic')?.value == '' ||
            fourthFormStep.get('deadlineDiagnosticPre').hasError('required') ||
            fourthFormStep.get('deadlineDiagnosticMid').hasError('required') ||
            fourthFormStep.get('deadlineDiagnosticPost').hasError('required')
          "
          cdkStepperNext
        >
          {{ translatedText.diagnostic_create_next }}
        </button>
      </div>
    </form>
  </cdk-step>

  <!-- <cdk-step [stepControl]="previewStep">
    
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <h2 class="h2 h2--bold">Diagnose 1</h2>
      </div>

      <div class="diagnostic_hint_box">
        <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
        <p class="paragraph paragraph--bold">Lies 2 Minuten den angezeigten Text laut.</p>
      </div>

      <div class="preview_diagnostic_box">
        <img src="../../../assets/nico_3.jpg" alt="">
        <h2 class="h2 h2--bold">Diagnose 1</h2>
        <button mat-flat-button color="secondary"  cdkStepperNext>Vorschau auf die Aufgabe</button>
      </div>
      <div class="training-task_select_button">
        <button mat-flat-button color="unset" class="mat-flat-button--full" cdkStepperPrevious>{{translatedText.diagnostic_create_back}}</button>
        <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="navigateToStep()">{{translatedText.diagnostic_create_next}}</button>
      </div>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="viewStep"> 
    <div class="training-task_select_container">
      <div class="training-task_create_headline diagnostic_view_header">
        <button mat-fab type="button" class="backButton close_book" cdkStepperPrevious matSuffix>
          <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
        </button>
        <h3 class="h3 h3--bold">Diagnose 1</h3>
      </div>

      <div class="diagnostic_hint_box">
        <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
        <p class="paragraph paragraph--bold">Lies 2 Minuten den angezeigten Text laut.</p>
      </div>

      <div class="view_diagnostic_box">
        <p class="paragraph paragraph--xl">
        Im Land der Dell gibt es ein Mädchen, bei deren Anblick ihres feurigen Haaren jeder in Verzückung gerät. Fire, so der Name des Mädchens, kann aber noch mehr. Sie kann in das Bewusstsein jedes Lebewesens eindringen. Wegen dieser Fähigkeit wird sie an den Königshof befohlen und findet sich in einem Netz aus Spionage und Verschwörungen wieder. Besonders Prinz Brigan scheint etwas gegen sie zu haben. Gerade in seine Gedanken kann Fire nicht eindringen. Was für Pläne hat der Prinz? <br>

        Das zweite Buch der Reihe «Die sieben Königreiche» spielt ausserhalb diesen und fühlt sich etwas so an. Fire ist ein sehr starker weiblicher Charakter, was mir sehr gefiel. Auch fand ich es toll, dass die Selbstverständlichkeit zum Beispiel einer Periode in die Geschichte einfliesst und sich einfügt. <br>
        Schade fand ich etwas, dass die Erzählung zum Band 1 nur wenig Verbindendes hat. Ich hätte gerne noch eine oder zwei Charaktere wiedergetroffen. Aber so lässt sich das Buch für sich lesen. <br>
        Mein Fazit: Die Geschichte wirkte auf mich wie eine ergänzende Erzählung anstelle einer Fortsetzung. <br>
        Im Land der Dell gibt es ein Mädchen, bei deren Anblick ihres feurigen Haaren jeder in Verzückung gerät. Fire, so der Name des Mädchens, kann aber noch mehr. Sie kann in das Bewusstsein jedes Lebewesens eindringen. Wegen dieser Fähigkeit wird sie an den Königshof befohlen und findet sich in einem Netz aus Spionage und Verschwörungen wieder. Besonders Prinz Brigan scheint etwas gegen sie zu haben. Gerade in seine Gedanken kann Fire nicht eindringen. Was für Pläne hat der Prinz? <br>
        
        Das zweite Buch der Reihe «Die sieben Königreiche» spielt ausserhalb diesen und fühlt sich etwas so an. Fire ist ein sehr starker weiblicher Charakter, was mir sehr gefiel. Auch fand ich es toll, dass die Selbstverständlichkeit zum Beispiel einer Periode in die Geschichte einfliesst und sich einfügt. <br>
        Schade fand ich etwas, dass die Erzählung zum Band 1 nur wenig Verbindendes hat. Ich hätte gerne noch eine oder zwei Charaktere wiedergetroffen. Aber so lässt sich das Buch für sich lesen. <br>
        Mein Fazit: Die Geschichte wirkte auf mich wie eine ergänzende Erzählung anstelle einer Fortsetzung. <br>
        </p>
        
      </div>
      <div class="training-task_select_button">
        <button mat-flat-button color="unset" class="mat-flat-button--full" cdkStepperPrevious>{{translatedText.diagnostic_create_back}}</button>
        <button mat-flat-button color="primary" class="mat-flat-button--full" (click)="navigateToStep()">{{translatedText.diagnostic_create_create_diagnostic}}</button>
      </div>
    </div>
  </cdk-step> -->

  <cdk-step [stepControl]="thirdFormStep">
    <form [formGroup]="thirdFormStep" #formDirective="ngForm" class="third_step_accorddion">
      <div class="training-task_select_container">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--diagnostic-pre">{{
            translatedText.diagnostic_create_diagnostic
          }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.diagnostic_create_assign_diagnostic }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.diagnostic_create_assign_diagnostic_text }}
          </p>
        </div>
        <ng-container *ngIf="isGroupLoading">
          <app-loading-spinner></app-loading-spinner>
        </ng-container>
        <ng-container *ngIf="!isGroupLoading">
          <div
            *ngIf="groups?.length === 0 || (groups.length > 0 && filterGroupsWithoutPersonalData(groups)?.length === 0)"
            class="empty_tasks_container"
          >
            <img src="../../../assets/empty.svg" alt="" />
            <p class="paragraph">
              <span *ngIf="userType == 'school_admin'">{{ translatedText.diagnostic_create_no_group }}</span>
              <span *ngIf="userType == 'teacher'">{{ translatedText.diagnostic_create_check_information }}</span>
            </p>
          </div>
          <div class="accordion_wrap">
            <mat-accordion multi>
              <mat-expansion-panel
                class="round-checkbox small"
                [togglePosition]="'before'"
                *ngFor="let groups of filterGroupsWithoutPersonalData(groups)"
                [disabled]="
                  (schoolYearTransition && nextYear != groups.group.school_year) ||
                  (!schoolYearTransition && currentYear != groups.group.school_year)
                "
              >
                <mat-expansion-panel-header class="expasion-header">
                  <mat-panel-title class="round-checkbox">
                    <mat-checkbox
                      (change)="toggleSelectAll(students, $event.checked)"
                      [checked]="students.selectedOptions.selected.length > 0"
                      (click)="$event.stopPropagation()"
                      [disabled]="
                        disableSelectAll(groups.group._students) ||
                        (schoolYearTransition && nextYear != groups.group.school_year) ||
                        (!schoolYearTransition && currentYear != groups.group.school_year)
                      "
                    ></mat-checkbox>
                    <p class="paragraph paragraph--bold paragraph--xl">
                      {{ groups.group.name }} -
                      {{ groups.group?.school_year | slice: 2 }}
                    </p>
                  </mat-panel-title>

                  <mat-panel-description>
                    <div class="license-amount"></div>
                  </mat-panel-description>
                  <div
                    class="tag-not-updated"
                    *ngIf="
                      (schoolYearTransition && nextYear != groups.group.school_year) ||
                      (!schoolYearTransition && currentYear != groups.group.school_year)
                    "
                  >
                    {{ translatedText.create_task_not_updated }}
                  </div>
                </mat-expansion-panel-header>
                <mat-selection-list
                  #students
                  (selectionChange)="handleSelection($event, thirdFormStep.value)"
                  [name]="groups.group._students"
                  formControlName="students"
                >
                  <mat-list-option
                    [disabled]="
                      isOptionDisabled(groups.group, students.selectedOptions.selected, student) ||
                      hasTooManyDiagnosticTasks(groups.group, student) ||
                      student?.status == 'inactive' ||
                      groups.isArchived === true
                    "
                    checkboxPosition="before"
                    *ngFor="let student of groups.group._students; index as i"
                    [value]="student"
                    [selected]="checkForSelection(student, i)"
                  >
                    <div class="accordion_list_text list-item-element">
                      <div class="inline_text">
                        <img
                          class="avatar_img--small"
                          *ngIf="student?.avatarUrl"
                          src="{{ student?.avatarUrl }}"
                          alt=""
                        />
                        <p class="paragraph username-with-avatar">
                          {{ student?.username }}
                        </p>
                        <p class="paragraph paragraph--s inactive-status-text" *ngIf="student?.status == 'inactive'">
                          (inaktiv)
                        </p>
                      </div>
                      <p
                        *ngIf="isOptionDisabled(groups.group, students.selectedOptions.selected, student)"
                        class="paragraph license-time"
                      >
                        {{ translatedText.diagnostic_create_no_assigned_license }}
                      </p>
                      <p *ngIf="hasTooManyDiagnosticTasks(groups.group, student)" class="paragraph license-time">
                        {{ translatedText.task_create_maximum_reached }}
                      </p>
                      <div class="diagnostic_test_overview">
                        <ng-container>
                          <mat-icon
                            color="gray"
                            svgIcon="streamline_outlined:check-mark-circle-2"
                            *ngIf="
                              checkForDiagnosticExistence(student?.diagnostic, 'pre') &&
                              !checkForDiagnosticCompletion(student?.diagnostic, 'pre')
                            "
                          ></mat-icon>
                          <mat-icon
                            color="green"
                            svgIcon="streamline_outlined:check-mark-circle-2"
                            *ngIf="
                              checkForDiagnosticExistence(student?.diagnostic, 'pre') &&
                              checkForDiagnosticCompletion(student?.diagnostic, 'pre')
                            "
                          ></mat-icon>
                          <mat-icon
                            color="gray"
                            svgIcon="streamline_outlined:delete-circle"
                            *ngIf="
                              !checkForDiagnosticExistence(student?.diagnostic, 'pre') &&
                              !checkForDiagnosticCompletion(student?.diagnostic, 'pre')
                            "
                          ></mat-icon>
                        </ng-container>
                        <ng-container>
                          <mat-icon
                            color="gray"
                            svgIcon="streamline_outlined:check-mark-circle-2"
                            *ngIf="
                              checkForDiagnosticExistence(student?.diagnostic, 'mid') &&
                              !checkForDiagnosticCompletion(student?.diagnostic, 'mid')
                            "
                          ></mat-icon>
                          <mat-icon
                            color="green"
                            svgIcon="streamline_outlined:check-mark-circle-2"
                            *ngIf="
                              checkForDiagnosticExistence(student?.diagnostic, 'mid') &&
                              checkForDiagnosticCompletion(student?.diagnostic, 'mid')
                            "
                          ></mat-icon>
                          <mat-icon
                            color="gray"
                            svgIcon="streamline_outlined:delete-circle"
                            *ngIf="
                              !checkForDiagnosticExistence(student?.diagnostic, 'mid') &&
                              !checkForDiagnosticCompletion(student?.diagnostic, 'mid')
                            "
                          ></mat-icon>
                        </ng-container>
                        <ng-container>
                          <mat-icon
                            color="gray"
                            svgIcon="streamline_outlined:check-mark-circle-2"
                            *ngIf="
                              checkForDiagnosticExistence(student?.diagnostic, 'post') &&
                              !checkForDiagnosticCompletion(student?.diagnostic, 'post')
                            "
                          ></mat-icon>
                          <mat-icon
                            color="green"
                            svgIcon="streamline_outlined:check-mark-circle-2"
                            *ngIf="
                              checkForDiagnosticExistence(student?.diagnostic, 'post') &&
                              checkForDiagnosticCompletion(student?.diagnostic, 'post')
                            "
                          ></mat-icon>
                          <mat-icon
                            color="gray"
                            svgIcon="streamline_outlined:delete-circle"
                            *ngIf="
                              !checkForDiagnosticExistence(student?.diagnostic, 'post') &&
                              !checkForDiagnosticCompletion(student?.diagnostic, 'post')
                            "
                          ></mat-icon>
                        </ng-container>
                      </div>
                      <div class="reference_value_wrap" *ngIf="diagnosticType == 'mid'">
                        <p class="paragraph">
                          Referenzwert:
                          <ng-container *ngIf="!student?.isSelectedReferenceValue">{{
                            student?.referenceValue
                          }}</ng-container>
                        </p>
                        <mat-icon
                          (click)="$event.stopPropagation()"
                          (click)="selectReferenceValue(student)"
                          *ngIf="!student?.isSelectedReferenceValue"
                          svgIcon="streamline_filled:edit"
                        ></mat-icon>
                        <div class="reference_value_input" *ngIf="student?.isSelectedReferenceValue">
                          <input
                            matInput
                            formControlName="referenceValue"
                            type="text"
                            [value]="student?.referenceValue"
                          />
                          <div class="reference_icons">
                            <mat-icon
                              (click)="$event.stopPropagation()"
                              (click)="setReferenceValue(student, thirdFormStep.get('referenceValue').value)"
                              svgIcon="streamline_filled:reference-check"
                            ></mat-icon>
                            <mat-icon
                              (click)="$event.stopPropagation()"
                              (click)="closeReferenceValue(student)"
                              svgIcon="streamline_filled:reference-close"
                            ></mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </ng-container>
      </div>
      <!-- {{students}} -->
      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Zurück" mat-flat-button color="secondary" (click)="navigateBack()" [disabled]="isLoading">
          {{ translatedText.diagnostic_create_back }}
        </button>
        <button
          alt="Weiter"
          mat-flat-button
          color="primary"
          (click)="submitForm(formDirective)"
          [disabled]="isLoading || studentsArray.length == 0"
        >
          <span *ngIf="!isLoading">{{ translatedText.diagnostic_create_next }}</span>
          <span>
            <app-loading-spinner class="loading-spinner" *ngIf="isLoading"></app-loading-spinner>
          </span>
        </button>
      </div>
    </form>
  </cdk-step>

  <!-- <cdk-step [stepControl]="lastFormStep">
    
    <form [formGroup]="lastFormStep">
      <div class="timeStep">
        <h2 class="h2 h2--bold">{{diagnosticTitle}} Übersicht</h2> 
        <p class="paragraph paragraph--l">Hier können Sie die erstellten Diagnosen noch einmal kontrollieren. Bei Bedarf können Sie zurückspringen und die Lernenden anpassen.</p>
      </div>

      <div class="task_summary_wrap">
        <div class="row" *ngFor="let group of groupOverview">
          <div class="col-md-12">
            <div class="group-overview-header">
              <p class="paragraph paragraph--bold paragraph--l">{{group.name}}</p>
              <mat-divider></mat-divider>
            </div>
            <div class="row">
              <div class="col-md-6" *ngFor="let task of getStundentsByGroup(group.id)">
                <div class="task_summary">
                  <div class="set_inline task_summary_header">
                      <div class="inline_text">
                        <img class="avatar_img--small" *ngIf="task?.avatarUrl" src="{{task?.avatarUrl}}" />
                        <p class="paragraph username-with-avatar">
                            {{task?.username}}
                        </p>
                      </div>
                  </div>
        
                  <div>
                    <div *ngIf="isReadingTime">
                      <div class="inline_text">
                          <p class="paragraph paragraph--s">Dauer: <span class="paragraph paragraph--bold paragraph--s">1 min</span></p>
                      </div>
                    </div>
                    <div *ngIf="!isReadingTime">
                      <div class="inline_text">
                        <p class="paragraph paragraph--s task_summary-book">Werk: <span class="paragraph paragraph--bold paragraph--s task_summary-bookTitle">{{bookTitle}}</span></p>
                      </div>
                      <div class="inline_text">
                          <p class="paragraph paragraph--s">Wörter: <span class="paragraph paragraph--bold paragraph--s">{{wordcount}}</span></p>
                      </div>
                    </div>
                    <div class="inline_text">
                        <p class="paragraph paragraph--s">Frist: <span class="paragraph paragraph--bold paragraph--s">{{fourthFormStep.get('deadline')?.value | date: 'dd.MM.yyyy'}}</span></p>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="accordion_footer">
        <button mat-flat-button color="primary" class="mat-flat-button--full right_botton" (click)="submitForm(formDirective)" [disabled]="isLoading">
          <span *ngIf="!isLoading">{{translatedText.diagnostic_create_create_task}}</span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </form>
  </cdk-step> -->

  <cdk-step [stepControl]="lastFormStep">
    <div class="last_step_container">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <h1 class="h3 h3--bold">{{ lastStepText }}</h1>
    </div>
  </cdk-step>
</app-diagnostic-create-stepper>
