<div class="invitation-delete-wrap" *ngIf="!isSubmited">
  <mat-icon svgIcon="streamline_outlined:warning-circle-2" class="warn-icon"> </mat-icon>
  <h2 class="h2">{{ translatedText.invitation_delete_headline }}</h2>
  <p class="paragraph paragraph--xl">
    {{
      translatedText.t('invitation_delete_text', {
        firstname: firstname,
        lastname: lastname,
      })
    }}
  </p>
  <div class="button_wrap">
    <button
      alt="Abbrechen"
      mat-flat-button
      color="secondary"
      [mat-dialog-close]="'close'"
      [disabled]="isLoading"
      type="button"
      class="mat-flat-button--full"
      aria-label="cancel"
    >
      {{ translatedText.invitation_delete_cancel }}
    </button>

    <button
      alt="Löschen"
      mat-flat-button
      color="warn"
      [disabled]="isLoading"
      (click)="deleteInvatation()"
      type="button"
      class="mat-flat-button--full"
      aria-label="Connect"
    >
      {{ translatedText.invitation_delete_button }}
    </button>
  </div>
</div>

<div class="invitation-delete-wrap" *ngIf="isSubmited">
  <mat-icon svgIcon="streamline_outlined:check_circle"></mat-icon>
  <h2 class="h2">{{ translatedText.invitation_delete_success_headline }}</h2>
  <p class="paragraph paragraph--xl">
    {{
      translatedText.t('invitation_delete_success_text', {
        firstname: firstname,
        lastname: lastname,
      })
    }}
  </p>
  <div class="button_wrap">
    <button
      alt="Ok"
      mat-flat-button
      color="primary"
      [mat-dialog-close]="'close'"
      [disabled]="isLoading"
      type="button"
      class="mat-flat-button--full"
      aria-label="cancel"
    >
      {{ translatedText.invitation_delete_success_close }}
    </button>
  </div>
</div>
