<div class="not-loaded-container">
  <img class="not-loaded-img" src="../../../assets/warning-triangle.svg" alt="triangle warning image" />
  <h4>Oops! Something went wrong, and this page couldn't load.</h4>

  <div class="not-loaded-btn-wrapper">
    <button
      alt="Versuchen Sie es erneut"
      mat-flat-button
      color="primary"
      class="mat-flat-button--not-full"
      (click)="refreshPage()"
    >
      <mat-icon class="material-icon">replay</mat-icon>
      Try again
    </button>
  </div>
</div>
