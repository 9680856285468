import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// import { IonicModule } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BookReaderModule } from 'src/app/book-reader/book-reader.module';
import { LoadingSpinnerModule } from 'src/app/helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from 'src/app/material.module';
import { PipeModule } from 'src/app/pipes/pipe.module';
import { RecordOverlayComponent } from './record-overlay/record-overlay.component';
import { RecordResultComponent } from './record-result/record-result.component';
import { RecordRTCComponent } from './record-rtc.component';
import { RecordFinishedOverlayComponent } from './record-finished-overlay/record-finished-overlay.component';

@NgModule({
  imports: [
    CommonModule,
    // IonicModule,
    MaterialModule,
    PipeModule,
    HttpClientModule,
    LoadingSpinnerModule,
    AppRoutingModule,
    BookReaderModule,
  ],
  declarations: [
    RecordRTCComponent,
    RecordOverlayComponent,
    RecordResultComponent,
    RecordFinishedOverlayComponent,
  ],
  exports: [RecordRTCComponent, RecordOverlayComponent, RecordResultComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RecordRTCModule {}
