<div class="container-fluid login-container">
  <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="" />
  <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt="" />
  <div class="login row">
    <div class="col-md-12">
      <div class="login_wrap" *ngIf="isEduplaces && !isEduplacesStudent">
        <img class="logo-img" src="../../../assets/new_laletu-logo.svg" alt="" />
        <h2 class="h2">{{ translatedText?.oauth_error_title }}</h2>
        <img class="content-img" src="../../../assets/eduplaces.png" alt="" />
      </div>
      <div class="login_wrap" *ngIf="isEduplaces && isEduplacesStudent">
        <img class="logo-img" src="../../../assets/new_laletu-logo.svg" alt="" />
        <h2 class="h2">{{ translatedText?.oauth_error_student }}</h2>
      </div>
    </div>
  </div>
</div>
