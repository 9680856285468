import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '../../app-routing.module';
import { AuthInterceptor } from '../../auth/auth-interceptor';
import { DirectivesModule } from '../../directives/directives.module';
import { ErrorInterceptor } from '../../error/error-interceptor';
import { MaterialModule } from '../../material.module';
import { StudentTaskListModule } from '../../student/student-task-list/student-task-list.module';
import { TaskDiagnosticModule } from '../../task/task-diagnostic/task-diagnostic.module';
import { DashboardStudentComponent } from './dashboard-student.component';

@NgModule({
  declarations: [DashboardStudentComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    StudentTaskListModule,
    DirectivesModule,
    TaskDiagnosticModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [DashboardStudentComponent],
})
export class DashboardStudentModule {}
