<div class="container-fluid container-fluid--bg-gray container-fluid--full-height task-preview-container">
  <div class="row justify-content-center">
    <div class="col-md-4 col-sm-12 book-preview_student-info-col">
      <button
        alt="Zurück zur Bibliothek"
        mat-fab
        color="unset"
        type="button"
        class="backButton_text"
        (click)="navigateToBookList()"
      >
        <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
        {{ translatedText.book_preview_student_back_to_library }}
      </button>
      <div class="task-preview_info">
        <div class="task-preview_book-info">
          <div>
            <h2 class="h2 h2--bold">{{ book?.title }}</h2>
            <p class="paragraph" *ngIf="book?.author">
              {{ translatedText.book_preview_student_from }} {{ book?.author }}
            </p>
          </div>
          <div
            class="task-preview_chip-wrap"
            *ngIf="book?.lower_grade_barrier || book?.upper_grade_barrier || book?.lower_age_barrier"
          >
            <div class="task-preview_chip">
              <p class="paragraph">
                <!-- <span *ngIf="book?.lower_grade_barrier">{{book?.lower_grade_barrier}}.</span><span *ngIf="book?.lower_grade_barrier && book?.upper_grade_barrier">-</span><span *ngIf="book?.upper_grade_barrier">{{book?.upper_grade_barrier}}.</span> Klasse -->
                <span *ngIf="book?.upper_grade_barrier"
                  >{{ translatedText.book_preview_student_to }}
                  {{ book?.lower_age_barrier }}
                </span>
                {{ translatedText.book_preview_student_years }}
              </p>
            </div>
          </div>
          <div class="task-preview_book-description">
            <p class="paragraph">
              {{ book?.book_description }}
            </p>
          </div>
        </div>
        <div class="task-preview-reading-info">
          <div class="book-preview-buttons">
            <button
              alt="Lesen"
              mat-flat-button
              color="primary-student"
              class="mat-flat-button--full"
              (click)="updateTask()"
            >
              {{ translatedText.book_preview_student_read }}
            </button>
            <button
              alt="Buchvorschau"
              mat-flat-button
              color="secondary-student"
              class="mat-flat-button--full"
              (click)="viewBook()"
            >
              {{ translatedText.book_preview_student_preview }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 col-sm-12 task-preview_book_col">
      <div class="task-preview_book">
        <img src="{{ book?.imageUrl }}" alt="{{ book?.title }}" />
      </div>
    </div>
  </div>
</div>
