<div class="group-delete_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="group-delete_wrap">
    <h3 class="h3 h3--bold">
      {{ translatedText.class_delete_question }} "{{ groupName }}"
      {{ translatedText.class_delete_question_text }}
    </h3>
    <div class="button_wrap">
      <button alt="Abbrechen" mat-flat-button color="secondary" type="button" [mat-dialog-close]="'close'">
        {{ translatedText.teacher_delete_cancel }}
      </button>
      <button alt="Klasse löschen" mat-flat-button color="warning" type="button" (click)="deleteGroup()">
        {{ translatedText.class_delete_delete }}
      </button>
    </div>
    <!-- <img
      class="footer_group-delete_img"
      [src]="'/assets/' + environment.whitelabel.generic_logo"
      alt=""
    /> -->
  </div>
</div>
