<div class="teacher-content-container">
  <div class="admin-teacher-header">
    <div class="title_with_count">
      <h3 class="h3 h3--bold">
        {{ translatedText.admin_profile_my_account }}
      </h3>
      <span class="class_count"> {{ licenseDataSource?.meta?.totalItems }} </span>
    </div>

    <div class="admin-class-list-licenses-count" *ngIf="!licensesToSchoolLoading">
      <p class="admin-class-list-licenses-wrap license-expired" *ngIf="hasExpiringLicenses">
        <mat-icon
          class="pink-icon"
          svgIcon="streamline_outlined:license_warning"
          [matTooltip]="translatedText.profile_management_license_expiring"
        ></mat-icon>
      </p>
      <p class="admin-class-list-licenses-wrap diagnosis">
        <mat-icon class="pink-icon" svgIcon="streamline_outlined:target"></mat-icon>
        <span class="licenses-list-name">{{ translatedText.admin_group_list_table_header_diagnostic }}</span>
        <span [countUp]="totalCountLeftDiagnostic" *ngIf="!isUnlimitedDiagnosticLicense">{{
          totalCountLeftDiagnostic
        }}</span>
        <span *ngIf="isUnlimitedDiagnosticLicense"> ∞ </span>
        <ng-container *ngIf="totalCountLeftDiagnosticExp > 0">
          |
          <span [countUp]="totalCountLeftDiagnosticExp">{{ totalCountLeftDiagnosticExp }}</span>
        </ng-container>
        <mat-icon
          *ngIf="hasExpiringLicenses"
          [matTooltip]="translatedText.admin_group_list_license_expired_info"
          class="green-icon"
          svgIcon="streamline_outlined:warning-triangle"
        ></mat-icon>
      </p>
      <p class="admin-class-list-licenses-wrap training">
        <mat-icon class="green-icon" svgIcon="streamline_outlined:lightning"></mat-icon>
        <span class="licenses-list-name">{{ translatedText.admin_group_list_table_header_training }}</span>
        <span [countUp]="totalCountLeftTraining">{{ totalCountLeftTraining }}</span>
        <ng-container *ngIf="totalCountLeftTrainingExp > 0">
          |
          <span [countUp]="totalCountLeftTrainingExp">{{ totalCountLeftTrainingExp }}</span>
        </ng-container>
        <mat-icon
          *ngIf="hasExpiringLicenses"
          [matTooltip]="translatedText.admin_group_list_license_expired_info"
          class="green-icon"
          svgIcon="streamline_outlined:warning-triangle"
        ></mat-icon>
      </p>
      <mat-icon
        class="green-icon"
        svgIcon="streamline_filled:info_empty"
        [matTooltip]="translatedText.profile_management_available_license_info"
      ></mat-icon>
    </div>
    <button
      alt="Lizenzcode eingeben"
      mat-flat-button
      color="primary"
      class="mat-flat-button--full w-25"
      (click)="openAddLicense()"
      aria-label="add License"
    >
      <mat-icon svgIcon="streamline_outlined:license-plus"></mat-icon>
      {{ translatedText.admin_dashboard_enter_the_license_code }}
    </button>
  </div>
  <div class="toggle-tabs">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms">
      <mat-tab label="{{ translatedText.admin_profile_licenses }}">
        <ng-container>
          <app-admin-profile-license
            [payload]="dataSource"
            [licensesToSchool]="licensesToSchool"
            (refreshEvent)="refreshLicense($event)"
          ></app-admin-profile-license>
          <div
            class="purchase-new-licenses-section"
            *ngIf="purchaseLicenseNotification && !environment.whitelabel.hide_klett_shop && !IS_APP"
          >
            <div class="close-btn" (click)="closePurchase()">
              <mat-icon class="white-icon" svgIcon="streamline_outlined:close"></mat-icon>
            </div>

            <div class="row m-0 p-0">
              <div class="col-md-8">
                <h3 class="h3 h3--bold">{{ translatedText.licenses_purchase_title }}</h3>
                <p class="paragraph paragraph--l">{{ translatedText.licenses_purchase_desc }}</p>
                <button
                  alt="Zum Klett Online Shop"
                  mat-raised-button
                  (click)="openUrl('https://www.klett.de/inhalt/272087')"
                >
                  {{ translatedText.licenses_purchase_action }}
                </button>
              </div>
              <div class="col-md-4">
                <img class="img-mb" src="./assets/trial_check_illus.svg" alt="" />
              </div>
            </div>
          </div>
        </ng-container>
      </mat-tab>
      <mat-tab label="{{ translatedText.admin_profile_data }}" *ngIf="!isOauth">
        <ng-container>
          <app-admin-profile-data></app-admin-profile-data>
        </ng-container>
      </mat-tab>

      <mat-tab label="{{ translatedText.admin_profile_change_password }}" *ngIf="!isOauth">
        <ng-container>
          <app-admin-change-password></app-admin-change-password>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
