<div class="report-error_overlay_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    (click)="closeOverlay()"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="report-error_wrap">
    <h3 class="h3 h3--bold">{{ translatedText.report_error_title }}</h3>
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <form [formGroup]="rForm" #formDirective="ngForm">
        <ng-container matColumnDef="number">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
        </ng-container>

        <ng-container matColumnDef="word">
          <th mat-header-cell *matHeaderCellDef>
            <p class="caption">{{ translatedText.report_repeat_error }}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ element?.word }}</td>
        </ng-container>

        <ng-container matColumnDef="operation">
          <th mat-header-cell *matHeaderCellDef>
            <p class="caption">{{ translatedText.report_repeat_error_type }}</p>
          </th>
          <td mat-cell *matCellDef="let element">{{ getTextToOperation(element?.operation) }}</td>
        </ng-container>

        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef>
            <p class="caption">{{ translatedText.report_error_comment }}</p>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <mat-form-field *ngIf="rowNumber == i">
              <input
                formControlName="comment"
                matInput
                [value]="element.comment"
                type="text"
                placeholder="{{ translatedText.report_error_comment }}"
              />
            </mat-form-field>
            <div *ngIf="rowNumber != i">
              {{ element.comment }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef>
            <p class="caption">{{ translatedText.report_error_action }}</p>
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <button
              alt="Ist kein Fehler"
              *ngIf="isButton || rowNumber != i"
              (click)="showComment(element, i)"
              mat-flat-button
              color="unset"
              type="button"
            >
              <span *ngIf="element.comment == '' || element.comment == undefined">{{
                translatedText.report_error_no_error
              }}</span>
              <span *ngIf="element.comment != '' && element.comment != undefined">{{
                translatedText.report_error_edit
              }}</span>
            </button>

            <div class="report-error-buttons" *ngIf="!isButton && rowNumber == i">
              <button alt="Fertig" mat-flat-button color="unset" type="button" (click)="addComment(element, i)">
                <mat-icon class="report_done" svgIcon="streamline_outlined:done"></mat-icon>
              </button>
              <button alt="Schließen" mat-flat-button color="unset" type="button" (click)="closeComment()">
                <mat-icon class="report_close" svgIcon="streamline_outlined:close"></mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          [ngClass]="{ 'highlight-row': row.comment != '' && row.comment != undefined }"
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </form>
    </table>

    <div class="table_footer">
      <button alt="Fehler senden" mat-flat-button color="primary" (click)="submitForm(formDirective)">
        {{ translatedText.report_error_send_error }}
      </button>
    </div>
  </div>
</div>
