<div class="task-creation-book-view-container">
  <div class="task-creation-book-view-header">
    <!--   <div class="inline_text bookview_header-bookinfo">
          <p class="paragraph paragraph--bold paragraph--l" [innerHtml]="bookTitle"></p>
          <p class="paragraph paragraph--bold paragraph--s">
            {{ bookAuthor }}
          </p>
        </div> -->
    <div class="header" *ngIf="taskType === 'text'">
      <p class="paragraph paragraph--l selected-words-chip">
        {{ translatedText.book_view_selected }}
        <span class="paragraph paragraph--bold paragraph--l">{{ wordcount }} {{ translatedText.book_view_words }}</span>
      </p>
      <button
        alt="Inhaltsverzeichnis"
        mat-flat-button
        color="secondary"
        type="button"
        class="toc_button"
        (click)="openBookReaderToc()"
        matSuffix
      >
        <mat-icon aria-hidden="false" fontIcon="toc"></mat-icon>
        {{ translatedText.book_view_toc }}
      </button>
    </div>
    <button
      alt="Rückgänging machen"
      mat-flat-button
      color="unset"
      class="task-creation-book-view-reset-btn"
      (click)="resetWordcount()"
      *ngIf="wordcount > 0"
    >
      <mat-icon svgIcon="streamline_outlined:undo-circle"></mat-icon>
      {{ translatedText.training_task_create_undo_button }}
    </button>
  </div>

  <div class="reader-wrapper">
    <app-book-reader-view
      [userTaskUuid]="userTaskUuid"
      [resetSelection]="resetSelection"
      [bookIsbn]="bookIsbn"
      [dsefVersion]="dsefVersion"
      [bookReaderType]="taskType === 'time' ? 'get' : 'set'"
      [taskType]="taskType"
      [bookReaderSettings]="bookReaderSettings"
      fromComponent="bookview"
    ></app-book-reader-view>
  </div>
</div>
