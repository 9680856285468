<div class="admin-change-password_wrap">
  <div class="row m-0">
    <div class="col-md-6">
      <div class="flex-col-center">
        <img class="change-password-img" src="../../../assets/change-password.svg" alt="" />
        <h3 class="h3 h3--bold mt-3">{{ translatedText.change_password_title }}</h3>
        <p class="paragraph paragraph--xl text-center mt-2">
          {{ translatedText.change_password_description }}
        </p>
      </div>
    </div>
    <div class="col-md-6">
      <form [formGroup]="rForm" #formDirective="ngForm">
        <div class="input-with-label">
          <div class="input-label-wrap">
            <p class="paragraph paragraph--s">
              {{ translatedText.change_password_enter_password }}
            </p>
            <!-- <a class="links" (click)="forgotPassword()">Passwort vergessen?</a> -->
          </div>
          <mat-form-field>
            <input
              #passwordField
              matInput
              formControlName="password"
              type="password"
              placeholder="{{ translatedText.change_password_placeholder }}"
              required
            />
            <button
              alt="Passwort anzeigen"
              mat-fab
              class="hide-password"
              type="button"
              (click)="
                passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')
              "
              matSuffix
            >
              <mat-icon *ngIf="passwordField.type === 'password'" svgIcon="streamline_outlined:closed_eye"></mat-icon>
              <mat-icon *ngIf="passwordField.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
            </button>

            <mat-error *ngIf="rForm.get('password').hasError('required')">
              {{ translatedText.change_password_error }}
            </mat-error>
            <mat-error *ngIf="rForm.get('password').hasError('pattern')">
              {{ translatedText.change_password_strong }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input-with-label">
          <p class="paragraph paragraph--s">
            {{ translatedText.change_password_new_password }}
          </p>
          <mat-form-field>
            <input
              #newPasswordField
              matInput
              formControlName="newpassword"
              type="password"
              placeholder="{{ translatedText.change_password_placeholder }}"
              required
            />
            <button
              alt="Passwort verstecken"
              mat-fab
              class="hide-password"
              type="button"
              (click)="
                newPasswordField.type === 'password'
                  ? (newPasswordField.type = 'text')
                  : (newPasswordField.type = 'password')
              "
              matSuffix
            >
              <mat-icon *ngIf="newPasswordField.type === 'password'" svgIcon="streamline_outlined:closed_eye">
              </mat-icon>
              <mat-icon *ngIf="newPasswordField.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
            </button>
            <mat-error *ngIf="rForm.get('newpassword').hasError('required')">
              <!-- Please enter your new password -->
              {{ translatedText.change_password_error }}
            </mat-error>

            <mat-error *ngIf="rForm.get('newpassword').hasError('pattern')">
              <!-- Password not strong enough -->
              {{ translatedText.change_password_strong }}
            </mat-error>

            <mat-error *ngIf="rForm.get('newpassword').hasError('notNew')">
              <!-- New password needed -->
              {{ translatedText.change_password_new_password_error }}
            </mat-error>
          </mat-form-field>
          <ul class="password-description">
            <li>{{ translatedText.change_password_length }}</li>
            <li>{{ translatedText.change_password_upper_lower }}</li>
            <li>{{ translatedText.change_password_numbers }}</li>
            <li>{{ translatedText.change_password_special }}</li>
          </ul>
        </div>

        <div class="input-with-label">
          <p class="paragraph paragraph--s">
            {{ translatedText.change_password_repeat }}
          </p>
          <mat-form-field>
            <input
              #passwordFieldRepeat
              matInput
              formControlName="confirmPassword"
              type="password"
              placeholder="{{ translatedText.change_password_repeat_placeholder }}"
              [errorStateMatcher]="matcher"
              required
            />
            <button
              alt="Passwort anzeigen"
              mat-fab
              class="hide-password"
              (click)="
                passwordFieldRepeat.type === 'password'
                  ? (passwordFieldRepeat.type = 'text')
                  : (passwordFieldRepeat.type = 'password')
              "
              matSuffix
            >
              <mat-icon *ngIf="passwordFieldRepeat.type === 'password'" svgIcon="streamline_outlined:closed_eye">
              </mat-icon>
              <mat-icon *ngIf="passwordFieldRepeat.type === 'text'" svgIcon="streamline_outlined:opened_eye"></mat-icon>
            </button>
            <mat-error *ngIf="rForm.errors?.notSame && rForm.get('confirmPassword').dirty">
              <!-- Passwords do not match -->
              {{ translatedText.change_password_repeat_error }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="instructionVideo_wrap">
          <button alt="Abbrechen" mat-flat-button color="secondary" type="button" [mat-dialog-close]="true">
            {{ translatedText.change_password_cancel }}
          </button>
          <button
            alt="Ihr Passwort zurücksetzen"
            mat-flat-button
            class="mat-flat-button--full"
            color="primary"
            (click)="submitForm(formDirective)"
            type="submit"
            aria-label="LOG IN"
            [disabled]="
              rForm.get('confirmPassword').hasError('notSame') ||
              rForm.get('newpassword').hasError('pattern') ||
              rForm.get('newpassword').hasError('required') ||
              rForm.get('password').hasError('required') ||
              rForm.invalid
            "
          >
            {{ translatedText.change_password_save }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
