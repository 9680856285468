import { formatDate } from '@angular/common';
import { HttpClient, HttpEventType } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { DSEFRange } from 'digi-sapiens-reader';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
} from '@angular/material/bottom-sheet';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import ysFixWebmDuration from 'fix-webm-duration';
import moment from 'moment';
import * as RecordRTC from 'recordrtc';
import { Subject, Subscription, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BookReaderService } from 'src/app/book-reader/book-reader-service';
import { BookReaderSettingsComponent } from 'src/app/book-reader/book-reader-settings/book-reader-settings.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { TaskCompletedOverlayComponent } from 'src/app/helpers/task-completed-overlay/task-completed-overlay.component';
import { TaskErrorOverlayComponent } from 'src/app/helpers/task-error-overlay/task-error-overlay.component';
import { BookDataService } from 'src/app/providers/book-data.service';
import { BookService } from 'src/app/providers/book.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';
import { LicenseService } from 'src/app/providers/license.service';
import { ReadingJsonService } from 'src/app/providers/readingJson.service';
import { StudentService } from 'src/app/providers/student.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { RecordOverlayComponent } from './record-overlay/record-overlay.component';
import { RecordResultComponent } from './record-result/record-result.component';
import { TimemodeOverlayComponent } from '../helpers/timemode-overlay/timemode-overlay.component';
import { RecordFinishedOverlayComponent } from './record-finished-overlay/record-finished-overlay.component';

export interface DialogData {}
var n = <any>navigator;
//    n.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || n.webkitGetUserMedia || n.mozGetUserMedia;

export class Session {
  _id: string;
  file?: File;
  filename?: string;
  sessionReadTime?: string;
  pathToFile?: string;
  chunkHash?: string;
}

@Component({
  selector: 'app-record-rtc',
  templateUrl: './record-rtc.component.html',
  styleUrls: ['./record-rtc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RecordRTCComponent implements OnInit, OnChanges, OnDestroy {
  @Input('task') task: any;
  @Input('startWord') startWord: DSEFRange | undefined;
  @Input('startFrom') startFrom: any;
  @Input('bookReaderSettings') bookReaderSettings: any;
  dsefVersion = 1.0;
  resultContent: string;
  sendChunksToServer: boolean = false;
  private stream: MediaStream;
  private config: object;
  private recorder: any;
  public debug: boolean = false;
  public recordingStarted: boolean = false;
  public recordingPaused: boolean = true;
  public recordingFinished: boolean = false;
  public recordingUploaded: boolean = false;
  public blobIsReady: boolean = false;
  public isLoading: boolean = false;
  public showResults: boolean = false;
  public selectLastWord: boolean = false;
  private shouldCountdown = true;
  public readMode = true;
  isOverlay: boolean = false;
  resetTask: boolean = false;
  clickLastWord: boolean = false;
  bookReaderType: string = 'get';
  counter = 6;
  // public isIOSAppBugHide: boolean = false;
  session: any;
  uploadprogress: number = 0;
  // showprogress: boolean = false;
  color = 'primary';
  mode = 'determinate';
  recordedChunks: any = [];
  blobs: any = [];
  chunkCount: number = 0;
  chunkHash;
  readingPoints;
  isCountdown: boolean = false;
  isDiagnosticTest: boolean = false;
  time: number = 0;
  display;
  interval;
  localSessionKey;
  private restServerUrl;
  recordButtonText = 'Start';
  countDown: Subscription;
  isCover: boolean = true;
  // @Input('showRecordNavbar') showRecordNavbar: boolean;
  // @Input('readingModeActive') readingModeActive: boolean;
  result;
  loadingStatusText = '';
  headline;
  userUuid;
  userTaskUuid;
  book;
  readingTime;
  taskStartFrom;
  isReadingTimeTask: boolean = false;
  readingTitle;
  bookReaderNav: boolean = false;
  taskRange: object = {};
  taskId;
  tocToggle: boolean = false;
  translatedText: any;
  iPadResolution: boolean = false;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog, // TODO: private or public?
    public elRef: ElementRef, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    public resDialog: MatDialogRef<RecordRTCComponent>, // TODO: unused? private or public?
    private _bottomSheet: MatBottomSheet,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private bookService: BookService,
    private bookReaderService: BookReaderService,
    private bookDataService: BookDataService,
    private capacitorService: CapacitorService,
    private licenseService: LicenseService, // TODO: unused?
    private readingJsonService: ReadingJsonService,
    private sanitizer: DomSanitizer, // TODO: unused?
    private studentService: StudentService,
    private taskService: TaskService,
    private titleService: Title, // TODO: unused?
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    if (this.task?.type === 'time' || this.task?.type === 'time-choice')
      this.bookReaderType = 'set';

    this.taskId = this.task?._id;
    this.userTaskUuid = this.task?.uuid;
    this.dsefVersion = this.task?.dsef_version ? this.task?.dsef_version : 1.0;
    if (this.task?.mode === 'multiple') {
      let timeUsed = 0;
      if (this.task?._split_task && this.task?._split_task.length > 0) {
        timeUsed = this.task._split_task.reduce((sum, splitTask) => {
          return sum + splitTask.time_used;
        }, 0);
      }
      this.readingTime = Math.ceil((this.task?.time - timeUsed) * 60);
    } else {
      this.readingTime = this.task?.time * 60;
    }
    this.bookDataService.setShowTableOfContentsUpdateListener(false);

    // Set to start in the selected word
    this.startFrom = this.startWord;

    //this.taskStartFrom = this.task?.taskStartFrom;
    if (
      this.startFrom &&
      'pageId' in this.startFrom &&
      this.startFrom.pageId === ''
    ) {
      this.taskStartFrom = '';
    } else {
      this.taskStartFrom = this.startFrom;
    }
    if (
      this.task?.type === 'diagnostic-pre' ||
      this.task?.type === 'diagnostic-mid' ||
      this.task?.type === 'diagnostic-post' ||
      this.task?.type === 'autonomous-placement'
    ) {
      this.isDiagnosticTest = true;
    }
    // Get uuuid of user
    this.userUuid = this.authService.getUserUuid();
    // Get book
    this.bookService.getBookById(this.task?.book_isbn);
    this.bookService
      .getBookUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.book = data;
      });

    if (this.task?.type === 'time' || this.task?.type === 'time-choice') {
      this.isReadingTimeTask = true;
    }
    this.startReading();
    this.readingJsonService.currentnLoadingStatus
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((status) => {
        this.loadingStatusText = status.text;
        this.uploadprogress = status.progress;
      });
    // Reset read pages & range till now
    //this.bookDataService.resetTextContent();
    //this.bookDataService.setSkipStartObj(false);
    //this.bookDataService.resetRangeForTask();
    //this.bookDataService.resetBookmark();
    this.bookDataService.setTableOfContents([]);
    this.bookDataService.resetLoadedDSEF();

    this.bookDataService.setBookmarkStartObj(this.startWord);

    window.onbeforeunload = function (event) {
      event.returnValue =
        "Your session is still being processed. Please don't close or refresh this window.";
    };
    this.checkIfiPad();
  }

  resetTimer() {
    this.display = 0;
    this.time = 0;
  }

  startReading() {
    this.countDown = timer(0, 1000).subscribe(() => {
      /* REVERT BACK TO 1000 */
      this.counter--;
      this.isCountdown = true;
      if (this.counter === 0) {
        this.countDown.unsubscribe();
        this.startRecording();
        // Update book with syllabification
        this.bookDataService.setHyphenationModeUpdateListener(
          this.task.reader_settings.hyphenation_mode,
        );
      }
    });
  }

  hasSelectedRange(): boolean {
    const range = this.bookDataService.getRangeForTask();
    return range.end.pageId !== '' && range.end.selectionContainerId !== '';
  }

  clickedStartWord(e: CustomEvent): void {}

  processAudio(audioWebMURL) {
    //var recordedBlob = this.recorder.getBlob();
    this.recorder.getDataURL(function (dataURL) {});
  }

  saveTask() {
    this.taskRange = this.bookDataService.getRangeForTask();
    this.stopRecording('clicked');
  }

  openRecordOverlay(status): void {
    this.isCover = true;
    this.isOverlay = true;
    this.toggleRecording('pause');
    this.recordingPaused = true;
    this.clickLastWord = true;
    this.taskRange = this.bookDataService.getRangeForTask();
    let dialogRef = this.dialog.open(RecordOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'record-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'record-overlay-panel-backdrop',
      data: {
        status: status,
        taskType: this.task?.type,
      },
    });
    dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.isOverlay = false;
        // continue reading
        console.log(res);
        if (res === 'continue') {
          this.isCover = false;
          this.selectLastWord = false;
          this.readMode = true;
          this.recordingPaused = false;
          this.toggleRecording('resume');
        }
        //reding finished stop record show result
        if (res === 'finished') {
          if (this.task?.type !== 'text') {
            this.selectLastWord = true;
            this.readMode = false;
            this.toggleRecording('pause');

            if (localStorage.getItem('dialogEnd') !== 'dontShow') {
              let dialogRef = this.dialog.open(TimemodeOverlayComponent, {
                width: '100%',
                autoFocus: false,
                panelClass: 'timemode-overlay',
                //hasBackdrop: false,
                disableClose: true,
                backdropClass: 'license-add-backdrop',
                data: {
                  timeOver: true,
                },
              });
              dialogRef.afterClosed().subscribe((res) => {});
            }
          } else {
            this.stopRecording('clicked');
          }
        }
        //reding canceld stop record
        if (res === 'cancel') {
          this.cancelRecording();
          this.router.navigate(['/dashboard-student']);
          this.dialog.closeAll();
        }
        // restart task
        if (res === 'restart') {
          this.restartRecording();
          // this.cancelRecording();
          // this.dialog.closeAll();
        }
      });
  }

  openRecordResult(result?): void {
    // console.log(result);
    this.isOverlay = true;
    this.toggleRecording('pause');
    this.recordingPaused = true;
    let dialogRef = this.dialog.open(RecordResultComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'record-rtc-result-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'record-rtc-result-overlay-panel-backdrop',
      data: {
        result: result,
        task: this.task,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  openErrorOverlay(): void {
    this.dialog.open(TaskErrorOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-error-overlay-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-error-overlay-task-panel-backdrop',
    });
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  openBookReaderSettings() {
    let maxCharcount = this.bookReaderService.getMaxCharcount();
    let paragraphClass = this.bookReaderService.getParagraphClass();
    const config: MatBottomSheetConfig = {
      data: {
        maxCharcount: maxCharcount,
        paragraphClass: paragraphClass,
      },
    };
    this._bottomSheet.open(BookReaderSettingsComponent, config);
  }

  /**
   * recordingAction
   */
  recordingAction() {
    // Reset results first
    this.showResults = false;
    if (!this.recordingStarted && this.recordingPaused) {
      // this.startRecording();
    } else if (this.recordingStarted && !this.recordingPaused) {
      // this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'pause');
      this.recordingPaused = true;
      this.toggleRecording('pause');
    } else {
      // this.webSocketService.emitTo('record-timer', this.webSocketService.getCurrentRoom(), 'resume');
      this.recordingPaused = false;
      this.toggleRecording('resume');
    }
  }

  pauseRecording() {
    this.recordingPaused = true;
    this.toggleRecording('pause');
    this.isCover = true;
  }

  resumeRecording() {
    this.recordingPaused = false;
    this.selectLastWord = false;
    this.readMode = true;
    this.toggleRecording('resume');
    this.isCover = false;
  }

  cancelRecording() {
    clearInterval(this.interval);
    this.resetTimer();
    this.recordingFinished = false;
    this.recordingStarted = false;
    this.recordingPaused = true;
    this.recorder.reset();
  }

  restartRecording() {
    clearInterval(this.interval);
    this.resetTimer();
    this.recordingFinished = false;
    this.recordingStarted = false;
    this.recordingPaused = true;
    this.isCover = true;
    this.countDown.unsubscribe();
    this.counter = 6;
    this.recorder.destroy();
    this.recorder = null;
    //this.reset();
    this.resetTask = true;
    setTimeout(() => {
      this.resetTask = false;
      this.startReading();
    }, 500);
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (!this.recordingPaused) {
        this.time++;
        // if (this.task?.type === "textReading") {
        //   this.time++;
        //   console.log(this.time)
        // }
        if (
          this.task?.type === 'time' ||
          this.task?.type === 'time-choice' ||
          this.task?.type === 'self'
        ) {
          if (this.time === this.readingTime) {
            this.selectLastWord = true;
            this.readMode = false;
            this.toggleRecording('pause');

            if (localStorage.getItem('dialogEnd') !== 'dontShow') {
              let dialogRef = this.dialog.open(TimemodeOverlayComponent, {
                width: '100%',
                autoFocus: false,
                panelClass: 'group-delete-overlay-panel',
                //hasBackdrop: false,
                disableClose: true,
                backdropClass: 'license-add-backdrop',
                data: {
                  timeOver: true,
                },
              });
              dialogRef.afterClosed().subscribe((res) => {});
            }
            clearInterval(this.interval);
            return;
          }

          // this.time--;
          // this.displayTime = this.readingTime - this.time++;
        }

        //this.display = this.transform(this.time);
        if (
          this.task?.type === 'diagnostic-pre' ||
          this.task?.type === 'diagnostic-mid' ||
          this.task?.type === 'diagnostic-post' ||
          this.task?.type === 'autonomous-placement' ||
          this.task?.auto_stop
        ) {
          // End test after reading time is passed
          if (this.time === this.readingTime) {
            // after selected the last word this funtion should run
            this.stopRecording('clicked');
            this.taskCompletedOverlay();
          }
        }
      }
      switch (this.display) {
        case 25:
        case 50:
        case 75: {
          break;
        }
        case 100: {
          break;
        }
        default: {
          break;
        }
      }
    }, 1000);
  }

  /**
   * startRecording
   */
  async startRecording() {
    if (typeof n.mediaDevices === 'undefined' || !n.mediaDevices.getUserMedia) {
      alert('This browser does not support WebRTC getUserMedia API.');

      if (!!n.getUserMedia) {
        alert('This browser seems supporting deprecated getUserMedia API.');
      }
    }
    await this.getMedia();
  }

  /**
   * getMedia
   */
  getMedia() {
    n.mediaDevices
      .getUserMedia({
        video: false,
        audio: {
          echoCancellation: true,
        },
      })
      .then(this.successCallback.bind(this))
      .catch(this.errorCallback.bind(this));
  }

  /**
   * successCallback
   */
  successCallback(stream: MediaStream) {
    this.stream = stream;
    this.stream.getAudioTracks().forEach(function (track, index, array) {
      console.log('track ' + index);
      console.log(track);
      //track.enabled = true;
    });
    // Create hash for blob chunks to merge later
    this.chunkHash = Math.random().toString(36).substring(2, 15);
    // Change options for audio only
    this.config = {
      type: 'audio',
      //mimeType: 'audio/wav',
      mimeType: 'audio/webm;codecs=opus',
      audio: true,
      video: false,
      // FINETUNING start
      // get intervals based blobs in milliseconds
      timeSlice: 1000,
      // both for audio and video tracks
      bitsPerSecond: 128000,
      // ignored when codecs=pcm
      audioBitsPerSecond: 128000,
      // // the range 22050 to 96000.
      // sampleRate: 96000,
      // // let us force 16khz recording:
      desiredSampRate: 16000,
      // // Legal values are (256, 512, 1024, 2048, 4096, 8192, 16384).
      bufferSize: 16384,
      // FINETUNING end
      numberOfAudioChannels: 1,
      ondataavailable: this.dataAvailable.bind(this),
      recorderType: RecordRTC.MediaStreamRecorder,
    };

    console.log(this.stream);

    this.recorder = new RecordRTC.RecordRTCPromisesHandler(
      this.stream,
      this.config,
    );
    this.recorder.startRecording();

    // Emit time for metamorphose
    this.recordingStarted = true;
    this.recordingPaused = false;
    this.resetTimer();
    console.log('=====>');
    this.isCountdown = false;
    this.isCover = false;
    this.recordingFinished = false;

    // Jump to chapter
    if (
      this.task?.type === 'time' ||
      this.task?.type === 'time-choice' ||
      this.task?.type === 'self'
    ) {
      // Reset content for books before recording - Fix for double appended text
      this.bookDataService.resetTextContent();
      this.bookDataService.resetRangeForTask();
      this.bookDataService.setJumpToContentIdUpdated(this.taskStartFrom);
    }

    // if(this.task?.type === "time") {
    //   this.time = this.readingTime * 60;
    // }
    this.startTimer();
  }

  /**
   * errorCallback
   */
  errorCallback(error) {
    //handle error here
    this.dialog.closeAll();
    this.router.navigate(['/dashboard-student']);
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      panelClass: 'snack-error',
      data: 'Mikrofonrechte wurden nicht freigegeben',
      duration: 3000,
      horizontalPosition: 'left',
    });
    console.log('error record', error);
  }

  dataAvailable(blob) {
    if (this.sendChunksToServer) {
      console.log(blob);
      //this.recordedChunks.push(blob);
      this.chunkCount++;

      // Build a form
      const chunkForm = new FormData();
      chunkForm.append('file', blob);
      // chunkForm.append('name', file.name);
      // chunkForm.append('total', blockCount);
      chunkForm.append('index', this.chunkCount.toString());
      //chunkForm.append('size', blob.size);
      chunkForm.append('chunkHash', this.chunkHash);
      // console.log(chunkForm)
    }
    // this.blobs.push(blob);

    // var size = 0;
    // this.blobs.forEach(function(b) {
    //     size += b.size;
    // });

    // console.log('Total blobs: ' + this.blobs.length + ' (Total size: ' + this.bytesToSize(size) + ')');
  }

  bytesToSize(bytes: number): string {
    const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return 'n/a';
    const i: number = parseInt(
      Math.floor(Math.log(bytes) / Math.log(1024)).toString(),
    );
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`;
  }

  /**
   * toggleRecording change the status / start or pause
   */
  toggleRecording(status: any) {
    if (this.recordingStarted) {
      if (status === 'pause') {
        console.log('recording paused');
        this.recorder.pauseRecording();
      } else if (status === 'resume') {
        console.log('recording resumed');
        this.recorder.resumeRecording();
      }
    }
  }

  /**
   * stopRecording
   */
  stopRecording(interaction: string) {
    // Get current end point
    this.turnOffUserMedia();
    this.isCover = true;
    // Inform to stop metamorphose
    console.log(this.recorder);
    clearInterval(this.interval);
    this.recordingFinished = true;
    this.recordingStarted = false;
    this.recordingPaused = true;
    // this.showprogress = true;
    this.isLoading = true;
    // Stop recording
    var _this = this;
    console.log('---- record stop now ----');
    this.recorder
      .stopRecording()
      .then(function () {
        //this.recorder.stopRecording(this.stopRecordingCallback()).then(function() {
        console.log('stop then fired');
        _this.blobIsReady = true;
        _this.upload();
      })
      .catch(function (error) {
        console.error(error);
        _this.router.navigate(['/dashboard-student']);
        _this.taskErrorOverlay();
      });
  }

  stopRecordingCallback() {
    console.log('stop callback fired');
    // this.blobIsReady = true;
    // this.upload();
    var blob = new Blob(this.blobs, {
      type: 'audio/wav',
    });
    console.log('blob size is ' + this.bytesToSize(blob.size));
  }

  /**
   * upload audio
   */
  upload() {
    console.log('upload fired');
    const taskRange = this.bookDataService.getRangeForTask();

    var timestamp = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
    var fileName = '';
    // check if blob is ready
    var _this = this;
    // Generate blob and send it to server
    this.recorder.getBlob().then(function (blob) {
      console.log('blob fired');
      if (
        _this.capacitorService.getDevicePlatform() === 'android' ||
        (_this.capacitorService.getDevicePlatform() === 'web' &&
          _this.capacitorService.getBrowserName() === 'chrome')
      ) {
        console.log('seekable blob');
        RecordRTC.getSeekableBlob(blob, function (seekableBlob) {
          console.log(seekableBlob);
          let blob = new Blob([seekableBlob], {
            type: seekableBlob.type.replace('video', 'audio'),
          });
          console.log(blob);

          _this.createSession(blob, fileName, taskRange);
        });
      } else {
        console.log('fix duration in blob ' + _this.time);
        // Get duration and fix blob
        ysFixWebmDuration(blob, _this.time, function (fixedBlob) {
          _this.createSession(fixedBlob, fileName, taskRange);
        });
      }
    });
    // this.reset();
  }

  createSession(blob, fileName, taskRange) {
    const session: Session = new Session();
    session._id = 'pre_' + new Date().getTime();
    session.file = blob;
    //session.filename = fileName + '.wav';
    session.filename = fileName + '.opus';
    if (this.sendChunksToServer) {
      session.chunkHash = this.chunkHash;
    }
    this.session = session;
    console.log('TASK RANGE', taskRange);
    this.saveSession(this.session, taskRange);
  }

  getMedailleUrl() {
    let url;
    if (this.readingPoints < 50) {
      url = '../../assets/medaille_bronze.png';
    } else if (this.readingPoints > 83) {
      url = '../../assets/medaille_gold.png';
    } else {
      url = '../../assets/medaille_silber.png';
    }
    return url;
  }

  saveSession(session, range) {
    let contentLanguage = this.bookDataService.getContentLanguage();
    //console.log(content)
    //this.readingJsonService.duplicateFile(session);
    let task = {
      _id: this.taskId,
      status: 'finished',
      completed: true,
      book_isbn: this.task?.book_isbn,
      book_name: this.task?.book_name,
      dsef_version: this.dsefVersion,
      mode: this.task?.mode,
      range: this.task?.range,
      grade: this.task?._group.grade,
      subgroup_uuid: this.task?._group.uuid,
    };

    // Get task range by task type
    if (this.task?.type === 'time' || this.task?.type === 'time-choice') {
      task['range'] = range;
    }
    console.log(task);

    // Save session to local database
    this.readingJsonService
      .saveSessionToLocalDatabase(
        session,
        this.userUuid,
        this.userTaskUuid,
        contentLanguage,
        task,
        this.time,
        this.bookDataService.getImageCount(),
      )
      .then((localSessionKey: number) => {
        console.log('Session saved with key:', localSessionKey);
        // Upload session to readalizer
        this.readingJsonService
          .uploadSession(
            session,
            this.userUuid,
            this.userTaskUuid,
            contentLanguage,
            task,
            this.time,
            this.bookDataService.getImageCount(),
            localSessionKey,
          )
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(
            (data) => {
              this.uploadprogress = 100;
              this.dialog.closeAll();
              this.openFinishedRecordingOverlay();
              const studentId = this.authService.getStudentId();
              // Update auto bookmark start for student
              /*if (this.task?.type !== 'text') {
                  let bookmarkStartObj =
                    this.bookDataService.getBookmarkStartObj();
                  this.studentService
                    .updateBookmarkOnStudent({
                      _id: studentId,
                      name:
                        'Lesezeichen ' +
                        moment().format('DD.MM.YYYY HH:mm') +
                        ' Uhr',
                      book_isbn: this.task?.book_isbn,
                      start: bookmarkStartObj,
                      auto: true,
                    })
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe((res) => {
                      //this.openRecordResult(data);
                    });
                } */
              // Open record result
              //if (data['level_wcpm']) {
              /* * * * * * * * * * * * * * *  LOCAL DEVELOPMENT ONLY START  * * * * * * * * * * * * * * */
              // Mock devalue license on local environment
              if (environment.production == false) {
                this.taskService.mockDevalueLicense({
                  session_uuid: data['session_uuid'],
                  type_key:
                    this.task?.type == 'diagnostic-pre' ||
                    this.task?.type == 'diagnostic-mid' ||
                    this.task?.type == 'diagnostic-post'
                      ? 'di'
                      : 'fo',
                  amount: this.time,
                  session_data: {
                    //reading_speed_correct_words_per_minute: data['reading_speed_correct_words_per_minute']
                    level_wcpm: data['level_wcpm'],
                  },
                });
              }
              /* * * * * * * * * * * * * * *  LOCAL DEVELOPMENT ONLY END  * * * * * * * * * * * * * * */
              this.router.navigate(['/student-results']);
              //this.openRecordResult(data);
              // Update task list
              this.taskService.getTasksToStudents(studentId);
              //}
            },
            (error: any) => {
              this.uploadprogress = 100;
              this.dialog.closeAll();
              this.router.navigate(['/dashboard-student']);
              this.openErrorOverlay();
            },
          );
      })
      .catch((error: any) => {
        console.error('Error saving session:', error);
      });
  }

  // TODO: unused?
  /**
   * save session and audio file on server (API)
   */
  createSessionApi(element) {
    const formData = new FormData();
    formData.append('originalname', element.filename);
    formData.append('sessionReadTime', element.time);
    if (element.chunkHash) {
      formData.append('chunkHash', element.chunkHash);
    }
    formData.append('file', element.file, element.filename);

    // TODO: doesn't get subscribed to
    return this.http
      .post<any>(this.restServerUrl + '/api/session/create', formData, {
        reportProgress: true,
        observe: 'events',
        params: { readalizer_customer: environment.readalizer_customer },
      })
      .pipe(
        map((event) => {
          switch (event['type']) {
            case HttpEventType.UploadProgress:
              const progress = Math.round(
                (100 * event['loaded']) / event['total'],
              );
              return {
                status: 'progress',
                message: progress,
              };
            case HttpEventType.Response:
              return {
                status: 'finished',
                data: event['body'],
              };
            default:
              return {
                status: 'unhandled',
                type: event['type'],
              };
          }
        }),
      );
  }

  openBookReaderToc() {
    this.tocToggle = !this.bookDataService.getShowTableOfContents();
    this.bookDataService.setShowTableOfContentsUpdateListener(this.tocToggle);
  }

  /**
   * reset record
   */
  // reset() {
  //   this.recorder.reset(this.processAudio.bind(this));
  //   this.recordingFinished = false;
  // }

  navigateResults() {
    this.router.navigate(['/results'], {
      queryParams: { readingPoints: this.readingPoints },
    });
  }

  /**
   * ngOnChanges / set recorder pause
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.readingModeActive) {
      if (
        changes.readingModeActive.currentValue === false &&
        this.recordingStarted &&
        !this.recordingPaused
      ) {
        this.recordingPaused = true;
        this.toggleRecording('pause');
      }
    }
  }

  updateReaderSettings(event, option) {
    switch (option) {
      case 'font_family':
        this.bookDataService.setFontFamilyUpdateListener(event.value);
        break;
      case 'font_size':
        this.bookDataService.setFontSizeUpdateListener(event.value);
        break;
      case 'line_height':
        this.bookDataService.setLineHeightUpdateListener(event.value);
        break;
      case 'letter_spacing':
        this.bookDataService.setLetterSpacingUpdateListener(event.value);
        break;
      case 'hyphenation_mode':
        this.bookDataService.setHyphenationModeUpdateListener(event.value);
        break;
      default:
        break;
    }
  }

  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  taskErrorOverlay() {
    let dialogRef = this.dialog.open(TaskErrorOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-error-overlay-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-error-overlay-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  taskCompletedOverlay() {
    let dialogRef = this.dialog.open(TaskCompletedOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-completed-overlay-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'task-completed-overlay-task-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.turnOffUserMedia();
    setTimeout(() => {
      //console.log('record timer unsubscribe -------------')
      clearInterval(this.interval);
      this.countDown.unsubscribe();
      this._unsubscribeAll.next(true);
      this._unsubscribeAll.complete();
    }, 100);
  }

  turnOffUserMedia() {
    // Close Microphone on single reading
    if (this.stream != undefined) {
      // this.stream.getTracks().forEach(function (track, index, array) {
      //   track.stop();
      // });
      Promise.all(this.stream.getTracks().map((track) => track.stop()))
        .then(() => {
          this.stream = null;
        })
        .catch((error) => {
          console.error('Error stopping local stream tracks:', error);
        });
    }
  }

  openFinishedRecordingOverlay() {
    this.dialog.open(RecordFinishedOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'recording-finished-panel',
      disableClose: true,
      backdropClass: 'license-add-backdrop',
    });
  }

  checkIfiPad() {
    this.capacitorService.readDeviceInfo().then((data) => {
      let deviceInfo = data;
      let devicePlatform = deviceInfo.platform.toLowerCase();
      let deviceOS = deviceInfo.operatingSystem;

      if (devicePlatform === 'web' && deviceOS === 'ios') {
        this.iPadResolution = true;
      } else {
        this.iPadResolution = false;
      }
    });
  }
}
