<section class="for-parents-bg-color">
  <div class="for-parents-bg-wrapper">
    <img src="../../assets/for-parents-leaves-right.png" class="for-parents-bg-img-right" alt="" />
    <div class="for-parents-container">
      <div class="for-parents-header-wrap">
        <div class="title">
          <h1 [innerHTML]="translatedText.parents_landing_page_title"></h1>
          <h4 [innerHTML]="translatedText.parents_landing_page_desc"></h4>
        </div>
        <div class="for-parents-form-bg">
          <parents-form></parents-form>
        </div>
      </div>

      <div class="row col-xl-12 for-parents-middle">
        <div class="col-xl-6 text-col home_container">
          <h1 class="headline_box">
            {{ translatedText.parents_landing_page_why }}
          </h1>
          <h1 class="headline_box">
            {{ translatedText.parents_landing_page_loud }}
          </h1>
          <p>
            {{ translatedText.parents_landing_page_text_1 }}
          </p>
          <p>
            {{ translatedText.parents_landing_page_text_2 }}
          </p>
          <p>{{ translatedText.parents_landing_page_text_3 }}.</p>
          <p>
            {{ translatedText.parents_landing_page_text_4 }}
          </p>
        </div>
        <div class="col-md-6 col-sm-12">
          <img class="img-col" src="../../assets/for-parents-circle-children.png" alt="" />
        </div>
      </div>
    </div>

    <div class="for-parents-green-bg">
      <div class="bottom-form-wrapper">
        <div class="flex-column">
          <div class="title">
            <h1>{{ translatedText.parents_landing_page_footer }}</h1>
            <h3>{{ translatedText.parents_landing_page_footer_desc }}</h3>
          </div>
          <img
            src="../../../assets/smile-parents-page.png"
            alt="image of smile with headphone and other elements from {{ environment.whitelabel.name }}"
          />
        </div>
        <div class="for-parents-form-bg">
          <parents-form></parents-form>
        </div>
      </div>
    </div>

    <div class="row col-lg-12 for-parents-icons-section">
      <div class="col-md-3 icon-wrapper">
        <img class="for-parents-icon" src="../../assets/forParents_headphones.svg" alt="" />
        <p>{{ translatedText.parents_landing_page_list_1 }}</p>
      </div>
      <div class="col-md-3 icon-wrapper">
        <img class="for-parents-icon" src="../../assets/forParents_smileBar.svg" alt="" />
        <p>{{ translatedText.parents_landing_page_list_2 }}</p>
      </div>
      <div class="col-md-3 icon-wrapper">
        <img class="for-parents-icon" src="../../assets/forParents_bookIdea.svg" alt="" />
        <p>{{ translatedText.parents_landing_page_list_3 }}</p>
      </div>
    </div>
  </div>
</section>
