import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-oauth-error',
  templateUrl: './oauth-error.component.html',
  styleUrls: ['./oauth-error.component.scss'],
})
export class OauthErrorComponent implements OnInit, OnDestroy {
  translatedText: any;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  isEduplaces: boolean = false;
  isEduplacesStudent: boolean = false;
  isEdupool: boolean = false;
  isEdupoolStudent: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const origin = params['origin'] ? params['origin'].replace(/"/g, '') : '';
      const user = params['user'] ? params['user'].replace(/"/g, '') : '';

      this.isEduplaces = origin === 'eduplaces';
      this.isEduplacesStudent = user === 'student';
      this.isEdupool = origin === 'edupool';
      this.isEdupoolStudent = user === 'student';
    });

    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
