<div class="csv-import-container">
  <div class="training-task_create_headline">
    <h3 class="h2 h2--bold">{{ translatedText.admin_group_class_csv_import_heading }}</h3>
  </div>
  <div class="csv-license-count-info-container">
    <div class="row">
      <div class="col-md-12">
        <div class="admin-class-list-licenses-count">
          <p class="admin-class-list-licenses-wrap license-expired" *ngIf="totalCountPending > 0">
            <mat-icon
              svgIcon="streamline_outlined:license_warning"
              [matTooltip]="translatedText.admin_group_list_table_header_license_activation"
            ></mat-icon>
          </p>
          <p class="admin-class-list-licenses-wrap diagnosis w-full">
            <mat-icon class="pink-icon" svgIcon="streamline_outlined:target"></mat-icon>
            <span class="licenses-list-name"> {{ translatedText.admin_group_list_table_header_diagnostic }}</span>
            <span [countUp]="totalCountLeftDiagnostic" *ngIf="!hasUnlimitedDiagnosticLicense">{{
              totalCountLeftDiagnostic
            }}</span>
            <span *ngIf="hasUnlimitedDiagnosticLicense"> ∞ </span>
            <ng-container *ngIf="totalCountLeftDiagnosticExp > 0">
              |
              <span [countUp]="totalCountLeftDiagnosticExp">{{ totalCountLeftDiagnosticExp }}</span>
            </ng-container>
            <mat-icon
              *ngIf="hasExpiringLicenses"
              class="pink-icon"
              svgIcon="streamline_outlined:warning-triangle"
              [matTooltip]="translatedText.admin_group_list_license_expired_info"
            ></mat-icon>
          </p>
          <p class="admin-class-list-licenses-wrap training w-full">
            <mat-icon class="green-icon" svgIcon="streamline_outlined:lightning"></mat-icon>
            <span class="licenses-list-name"> {{ translatedText.admin_group_list_table_header_training }}</span>
            <span [countUp]="totalCountLeftTraining">{{ totalCountLeftTraining }} </span>
            <ng-container *ngIf="totalCountLeftTrainingExp > 0">
              |
              <span [countUp]="totalCountLeftTrainingExp">{{ totalCountLeftTrainingExp }}</span>
            </ng-container>
            <mat-icon
              *ngIf="hasExpiringLicenses"
              [matTooltip]="translatedText.admin_group_list_license_expired_info"
              class="green-icon"
              svgIcon="streamline_outlined:warning-triangle"
            ></mat-icon>
          </p>
          <!-- <mat-icon
            [matTooltip]="translatedText.profile_management_available_license_info"
            class="green-icon"
            svgIcon="streamline_filled:info_empty"
          ></mat-icon> -->
        </div>

        <div class="admin-class-create-licenses-information" *ngIf="enoughLicenses">
          <p class="paragraph paragraph--bold">
            <mat-icon svgIcon="streamline_outlined:info-empty"></mat-icon>
            {{ translatedText.teacher_sidebar_hint_admin_group_information_create_title }}
          </p>
          <p class="paragraph">
            {{ translatedText.teacher_sidebar_hint_admin_group_information_create_text }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="upload-csv-box">
    <mat-icon class="icon-56 mb-2" svgIcon="streamline_filled:box-upload"></mat-icon>
    <p class="paragraph paragraph--bold paragraph--xl">
      {{ translatedText.admin_group_class_csv_import_sub_heading }}
    </p>
    <p class="paragraph paragraph--m">
      {{ translatedText.admin_group_class_csv_import_desc }}
      <a (click)="openLanguagesCSVOverlay()" class="cursor-pointer">{{
        translatedText.admin_group_class_add_student_download_link
      }}</a
      >.
    </p>
    <p class="paragraph paragraph--m">
      {{ translatedText.admin_group_class_csv_import_desc_contact }}
      <a href="{{ environment.whitelabel.csv_import_support_url }}" target="_blank" class="cursor-pointer">{{
        translatedText.admin_group_class_csv_import_desc_contact_helpdesk
      }}</a
      >.
    </p>

    <div class="csv-group-btn">
      <div>
        <button
          alt="Vorlage herunterladen"
          mat-flat-button
          color="secondary"
          type="button"
          (click)="downloadCSVTemplate()"
        >
          <mat-icon class="icon-20" svgIcon="streamline_outlined:download-csv"></mat-icon>
          {{ translatedText.admin_group_class_csv_import_download_btn }}
        </button>
      </div>
      <input type="file" hidden (change)="onFileChanged($event)" #fileInput />
      <div *ngIf="csvFileName === null">
        <button alt="Datei hochladen" mat-flat-button color="primary" type="button" (click)="fileInput.click()">
          <mat-icon class="icon-20" svgIcon="streamline_outlined:upload-csv"></mat-icon>
          {{ translatedText.admin_group_class_csv_import_upload_btn }}
        </button>
      </div>
      <div class="csv-replace-btn" *ngIf="csvFileName">
        <div class="sample-csv-list">
          <mat-icon class="icon-20" svgIcon="streamline_outlined:csv-list-doc"></mat-icon>
          {{ csvFileName }}
        </div>
        <button alt="CSV-Datei ersetzen" mat-flat-button color="secondary" type="button" (click)="fileInput.click()">
          <mat-icon class="icon-20" svgIcon="streamline_outlined:replace-csv"></mat-icon>
          {{ translatedText.admin_group_class_csv_import_replace_btn }}
        </button>
      </div>
    </div>

    <div *ngIf="isCSVError" class="csv-error">
      <span>{{ csvErrorMessage }}</span>
    </div>
  </div>
</div>
