<app-training-task-stepper
  #stepper
  linear
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeAll($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="taskModeStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
        <h3 class="h3 h3--bold">
          {{ translatedText.training_task_create_title_mode }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.training_task_create_description_mode }}
        </p>
      </div>
      <div class="training-task_selector_wrap full-width">
        <button
          alt="Manueller Modus"
          class="training-task_selector"
          [ngClass]="isManuelMode ? 'active' : ''"
          (click)="setTaskData('manuel')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:manuel-mode"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_manual_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_manual_description }}
              </p>
            </div>
          </div>
        </button>

        <button
          alt="Auto Modus"
          class="training-task_selector"
          [ngClass]="isAutoMode ? 'active' : ''"
          (click)="setTaskData('auto')"
          [disabled]="isMaintenanceMode"
          [hidden]="contentType === 'klett'"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:auto-mode"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_auto_title }}
                <span>
                  ({{ translatedText.training_task_create_beta }})
                  <mat-icon svgIcon="streamline_outlined:info-empty" matTooltip="{{ betaInfoText }}"></mat-icon
                ></span>
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_auto_description }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
      <button alt="Abbrechen" mat-flat-button color="secondary" (click)="closeAll(true)">
        {{ translatedText.training_task_create_cancel }}
      </button>
      <button alt="Weiter" mat-flat-button color="primary" (click)="selectedType('mode')">
        {{ translatedText.training_task_create_next }}
      </button>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="taskAreaStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
        <h3 class="h3 h3--bold">
          {{ translatedText.training_task_create_test_area_title }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.training_task_create_test_area_description }}
        </p>
      </div>
      <div class="training-task_selector_wrap">
        <button
          alt="Leseflüssigkeit"
          class="training-task_selector"
          [ngClass]="isReading_fluency ? 'active' : ''"
          (click)="setTaskData('reading_fluency')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:reading_fluency"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_fluency_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_fluency_description }}
              </p>
            </div>
          </div>
        </button>

        <button
          alt="Textverständnis"
          class="training-task_selector"
          [ngClass]="isUnderstand_text ? 'active' : ''"
          (click)="setTaskData('understand_text')"
          [disabled]="true"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:understand_text"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_understanding_title }}
                <span class="training-task_selector_chip">{{ translatedText.training_task_create_info }}</span>
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_understanding_description }}
              </p>
            </div>
          </div>
        </button>

        <button
          alt="Hörverständnis"
          class="training-task_selector"
          [ngClass]="isListening_comprehension ? 'active' : ''"
          (click)="setTaskData('listening_comprehension')"
          [disabled]="true"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:listening_comprehension"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_listing_title }}
                <span class="training-task_selector_chip">{{ translatedText.training_task_create_info }}</span>
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_listing_description }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
      <button alt="Zurück" mat-flat-button color="secondary" cdkStepperPrevious>
        {{ translatedText.training_task_create_back }}
      </button>
      <button alt="Weiter" mat-flat-button color="primary" (click)="selectedType('area')">
        {{ translatedText.training_task_create_next }}
      </button>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="taskChoiceStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
        <h3 class="h3 h3--bold">
          {{ translatedText.training_task_create_task_type_title }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.training_task_create_task_type_description }}
        </p>
      </div>
      <div class="training-task_selector_wrap">
        <button
          alt="Lesetext auswählen"
          class="training-task_selector"
          [ngClass]="isSpecific_text ? 'active' : ''"
          (click)="setTaskData('specific_text')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:specific_text"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_specific_text_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_specific_text_description }}
              </p>
            </div>
          </div>
        </button>
        <button
          alt="Spezifisches Buch"
          class="training-task_selector"
          [ngClass]="isSpecific_book ? 'active' : ''"
          (click)="setTaskData('specific_book')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:specific_book"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_specific_book_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_specific_book_description }}
              </p>
            </div>
          </div>
        </button>
        <button
          alt="Beliebiges Buch"
          class="training-task_selector"
          [ngClass]="isEvery_book ? 'active' : ''"
          (click)="setTaskData('every_book')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:every_book"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_every_book_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_every_book_description }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
      <button alt="Zurück" mat-flat-button color="secondary" cdkStepperPrevious>
        {{ translatedText.training_task_create_back }}
      </button>
      <button alt="Weiter" mat-flat-button color="primary" (click)="navigateToStep('taskChoice')">
        {{ translatedText.training_task_create_next }}
      </button>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="bookSelectionStep">
    <app-old-book-list
      *ngIf="isBookList"
      [contentType]="contentType"
      (bookListClose)="bookListClose($event)"
      (bookListData)="bookListData($event)"
    ></app-old-book-list>
    <app-book-preview
      *ngIf="isBookPreview"
      [bookData]="bookData"
      [isSpecific_book]="isSpecific_book"
      (bookView)="openBookView($event)"
      (backToLibrary)="backToLibrary($event)"
    ></app-book-preview>
    <app-book-view
      *ngIf="isBookView"
      [bookData]="bookData"
      (backToBookPreview)="backToBookPreview($event)"
      (goToReaderSettings)="navigateToReaderSettings($event)"
    ></app-book-view>
  </cdk-step>

  <cdk-step [stepControl]="completionTaskStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
        <h3 class="h3 h3--bold">
          {{ translatedText.training_task_create_task_done }}
        </h3>
        <p class="paragraph paragraph--l">
          {{ translatedText.training_task_create_task_done_description }}
        </p>
      </div>
      <div class="training-task_selector_wrap">
        <button
          alt="Einmalige Aufgabe"
          class="training-task_selector"
          [ngClass]="isOneTimeTask ? 'active' : ''"
          (click)="setTaskData('one_time_task')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:check_mark" class="blue-icon"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_single_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_single_description }}
              </p>
            </div>
          </div>
        </button>
        <button
          alt="Aufgabe in mehreren Schritten"
          class="training-task_selector"
          [ngClass]="isSeveralSteps ? 'active' : ''"
          (click)="setTaskData('several_steps')"
        >
          <div class="training-task_button_wrap">
            <mat-icon svgIcon="streamline_outlined:hourglass" class="blue-icon"></mat-icon>
            <div class="training-task_selector_content">
              <h3 class="h3 h3--bold">
                {{ translatedText.training_task_create_multiple_title }}
              </h3>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_multiple_description }}
              </p>
            </div>
          </div>
        </button>
      </div>
    </div>
    <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
      <button alt="Zurück" mat-flat-button color="secondary" (click)="navigateBackToStep()">
        {{ translatedText.training_task_create_back }}
      </button>
      <button alt="Weiter" mat-flat-button color="primary" (click)="navigateToStep('completion')">
        {{ translatedText.training_task_create_next }}
      </button>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="completionDetailStep">
    <form [formGroup]="completionDetailStep">
      <div class="training-task_select_container">
        <div class="form_content">
          <div class="training-task_create_headline">
            <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
            <!-- TODO: i18n / proper implementation -->
            <!-- <div *ngIf="trialStatus.isActive">Reading Minutes left in Trial Mode</div> -->
            <h3 class="h3 h3--bold">
              {{ translatedText.training_task_create_details_title }}
            </h3>
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_details_description }}
            </p>
          </div>

          <div class="training-task_form_wrap toggle hidden">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_allow_read }}
            </p>
            <div class="chart_toggle">
              <input type="checkbox" (change)="optionSetting($event, 'read_more')" id="allow_read_more" /><label
                for="allow_read_more"
              ></label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <mat-form-field>
                <input
                  matInput
                  formControlName="minutes"
                  type="number"
                  [min]="1"
                  required
                  (input)="onMinutesChanged($event)"
                />
                <mat-error *ngIf="completionDetailStep.get('minutes').hasError('required')">
                  <!-- Please choose a deadline. -->
                  {{ translatedText.training_task_create_minutes }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field>
                <mat-select type="role" [(value)]="selectedCompletionDetail" disableOptionCentering [disabled]="true">
                  <mat-option value="minutes">{{ translatedText.training_task_create_minutes_word }}</mat-option>
                  <!-- <mat-option value="chapter">Kapitel</mat-option> -->
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <app-trial-banner
            *ngIf="trialStatus.isActive"
            [trialHeadline]="translatedText.license_books_trial_title"
            [trialText]="translatedText.license_task_trial_time_description"
            [trialStatus]="trialStatus"
            [showExpirationDate]="false"
            [showButtons]="false"
          ></app-trial-banner>

          <!-- <div class="row hidden_row_detail">
            <div class="col-md-6" *ngIf="isSeveralSteps">
              <p class="paragraph paragraph--s" *ngIf="selectedCompletionDetail == 'minutes'">Mindestanzahl Minuten pro Woche</p>
              <p class="paragraph paragraph--s" *ngIf="selectedCompletionDetail != 'minutes'">Mindestanzahl Kapiteln pro Woche</p>
              <mat-form-field>
                <input matInput formControlName="minimum" type="number" required>
              </mat-form-field>
            </div>
          </div> -->
        </div>
      </div>
      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Zurück" mat-flat-button color="secondary" cdkStepperPrevious>
          {{ translatedText.training_task_create_back }}
        </button>
        <button alt="Weiter" mat-flat-button color="primary" cdkStepperNext>
          {{ translatedText.training_task_create_next }}
        </button>
      </div>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="readerSetting">
    <form [formGroup]="readerSetting">
      <div class="training-task_select_container">
        <div class="form_content">
          <div class="training-task_create_headline">
            <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
            <h3 class="h3 h3--bold">
              {{ translatedText.training_task_create_text_options }}
            </h3>
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_text_options_description }}
            </p>
          </div>

          <div class="training-task_form_wrap">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_font }}
            </p>
            <mat-form-field>
              <mat-select
                type="role"
                formControlName="font_family"
                (selectionChange)="updateReaderOptions($event, 'fontFamily')"
                disableOptionCentering
              >
                <mat-option value="abeezee">ABeeZee</mat-option>
                <mat-option value="poppins">Poppins</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="training-task_form_wrap">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_font_size }}
            </p>
            <mat-button-toggle-group
              name="fontSize"
              (change)="updateReaderOptions($event, 'fontSize')"
              [value]="fontSize"
              aria-label="Font Style"
            >
              <mat-button-toggle class="fontSize fontSize__small" value="small">T</mat-button-toggle>
              <mat-button-toggle class="fontSize fontSize__normal" value="normal">T</mat-button-toggle>
              <mat-button-toggle class="fontSize fontSize__big" value="big">T</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="training-task_form_wrap">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_lines }}
            </p>
            <mat-button-toggle-group
              name="line_height"
              (change)="updateReaderOptions($event, 'line_height')"
              [value]="line_height"
              aria-label="Font Style"
            >
              <mat-button-toggle class="line_height line_height__small" value="small"
                >{{ translatedText.training_task_create_good }} <br />
                {{ translatedText.training_task_create_world }}</mat-button-toggle
              >
              <mat-button-toggle class="line_height line_height__normal" value="normal"
                >{{ translatedText.training_task_create_good }} <br />
                {{ translatedText.training_task_create_world }}</mat-button-toggle
              >
              <mat-button-toggle class="line_height line_height__big" value="big"
                >{{ translatedText.training_task_create_good }} <br />
                {{ translatedText.training_task_create_world }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>

          <div class="training-task_form_wrap">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_space }}
            </p>
            <mat-button-toggle-group
              name="letter_spacing"
              (change)="updateReaderOptions($event, 'letter_spacing')"
              [value]="letter_spacing"
              aria-label="Font Style"
            >
              <mat-button-toggle class="letter_spacing letter_spacing__small" value="small"
                >{{ translatedText.training_task_create_good }} <br />
                {{ translatedText.training_task_create_world }}</mat-button-toggle
              >
              <mat-button-toggle class="letter_spacing letter_spacing__normal" value="normal"
                >{{ translatedText.training_task_create_good }} <br />
                {{ translatedText.training_task_create_world }}</mat-button-toggle
              >
              <mat-button-toggle class="letter_spacing letter_spacing__big" value="big"
                >{{ translatedText.training_task_create_good }} <br />
                {{ translatedText.training_task_create_world }}</mat-button-toggle
              >
            </mat-button-toggle-group>
          </div>

          <div class="training-task_form_wrap">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_syllable }}
            </p>
            <mat-button-toggle-group
              name="hyphenation_mode"
              (change)="updateReaderOptions($event, 'hyphenation_mode')"
              [value]="hyphenation_mode"
              aria-label="hyphenation mode"
            >
              <mat-button-toggle class="hyphenation_mode" value="option0"
                >{{ translatedText.training_task_create_none }} <br />
                {{ translatedText.training_task_create_syllable }}</mat-button-toggle
              >
              <mat-button-toggle class="hyphenation_mode" value="option1">
                <div class="hyphenation_mode_wrap">
                  <img src="./assets/blue_circle.svg" alt="blue circle" />
                  <img src="./assets/red_circle.svg" alt="Red circle" />
                </div>
              </mat-button-toggle>
              <mat-button-toggle class="hyphenation_mode" value="option2">
                <div class="hyphenation_mode_wrap">
                  <img src="./assets/teal_circle.svg" alt="teal circle" />
                  <img src="./assets/orange_circle.svg" alt="orange circle" />
                </div>
              </mat-button-toggle>

              <mat-button-toggle class="hyphenation_mode" value="option3">
                <div class="hyphenation_mode_wrap">
                  <img src="./assets/gray_circle.svg" alt="gray circle" />
                  <img src="./assets/black_circle.svg" alt="black circle" />
                </div>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="training-task_form_wrap toggle">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_text_options_allow }}
            </p>
            <div class="chart_toggle">
              <input type="checkbox" (change)="optionSetting($event, 'reader')" id="switch" /><label
                for="switch"
              ></label>
            </div>
          </div>

          <div class="reader_setting_preview">
            <p class="paragraph paragraph--s">
              {{ translatedText.training_task_create_preview }}
            </p>
            <p
              class="paragraph {{ fontFamilyClass }} {{ fontSizeClass }} {{ lineSpacingClass }} {{
                spaceBetweenClass
              }} {{ hyphenationClass }}"
            >
              <ng-container *ngIf="getCurrentLanguage() != 'en'">
                <span class="word"><span class="hyphen1">Ein</span></span
                >&nbsp;<span class="word"
                  ><span class="hyphen2">ge</span><span class="hyphen1">streif</span
                  ><span class="hyphen2">tes</span></span
                >&nbsp;<span class="word"
                  ><span class="hyphen1">Eich</span><span class="hyphen2">hörn</span
                  ><span class="hyphen1">chen</span></span
                >&nbsp;<span class="word"><span class="hyphen2">husch</span><span class="hyphen1">te</span></span
                >&nbsp;<span class="word"><span class="hyphen2">vor</span></span
                >&nbsp;<span class="word"><span class="hyphen1">sei</span><span class="hyphen2">nen</span></span
                >&nbsp;<span class="word"><span class="hyphen1">Fü</span><span class="hyphen2">ßen</span></span
                >&nbsp;<span class="word"><span class="hyphen1">über</span></span
                >&nbsp;<span class="word"><span class="hyphen2">den</span></span
                >&nbsp;<span class="word"><span class="hyphen1">Platz</span></span
                >&nbsp;<span class="word"><span class="hyphen2">und</span></span
                >&nbsp;<span class="word"><span class="hyphen1">ver</span><span class="hyphen2">schwand</span></span
                >&nbsp;<span class="word"><span class="hyphen1">im</span></span
                >&nbsp;<span class="word"
                  ><span class="hyphen2">Kas</span><span class="hyphen1">ta</span><span class="hyphen2">ni</span
                  ><span class="hyphen1">en</span><span class="hyphen2">baum.</span></span
                >
              </ng-container>

              <ng-container *ngIf="getCurrentLanguage() == 'en'">
                <span class="word"><span class="hyphen1">A</span></span
                >&nbsp;<span class="word"><span class="hyphen2">squir</span><span class="hyphen1">rel</span></span
                >&nbsp;<span class="word"><span class="hyphen2">scur</span><span class="hyphen1">ried</span></span
                >&nbsp;<span class="word"><span class="hyphen2">across</span></span
                >&nbsp;<span class="word"><span class="hyphen1">the</span></span
                >&nbsp;<span class="word"><span class="hyphen2">yard</span></span
                >&nbsp;<span class="word"><span class="hyphen1">and</span></span
                >&nbsp;<span class="word"
                  ><span class="hyphen2">dis</span><span class="hyphen1">ap</span
                  ><span class="hyphen2">peared</span></span
                >&nbsp;<span class="word"><span class="hyphen1">in</span><span class="hyphen2">to</span></span
                >&nbsp;<span class="word"><span class="hyphen1">the</span></span
                >&nbsp;<span class="word"><span class="hyphen2">chest</span><span class="hyphen1">nut</span></span
                >&nbsp;<span class="word"><span class="hyphen1">tree.</span></span>
              </ng-container>
            </p>
          </div>
        </div>
      </div>
      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Zurück" mat-flat-button color="secondary" (click)="navigateBackToStep()">
          {{ translatedText.training_task_create_back }}
        </button>
        <button alt="Weiter" mat-flat-button color="primary" (click)="navigateToDeadline()">
          {{ translatedText.training_task_create_next }}
        </button>
      </div>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="durationForm">
    <form [formGroup]="durationForm">
      <div class="training-task_select_container">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.training_task_create_duration }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.training_task_create_duration_description }}
          </p>
        </div>

        <mat-form-field class="duration-form-field">
          <mat-select formControlName="duration" disableOptionCentering>
            <mat-option class="dropdown-select" value="1">1 {{ translatedText.training_task_create_month }}</mat-option>
            <!-- <mat-option value="2">2 Monate</mat-option>
            <mat-option value="3">3 Monate</mat-option>
            <mat-option value="4">4 Monate</mat-option>
            <mat-option value="5">5 Monate</mat-option>
            <mat-option value="6">6 Monate</mat-option> -->
          </mat-select>
        </mat-form-field>

        <div class="training-task_create_spacing">
          <p class="paragraph paragraph--l">
            {{ translatedText.training_task_create_weeks_description }}
          </p>
        </div>

        <div class="automode-day-selection-wrap">
          <mat-chip-set aria-label="category selection">
            <mat-chip
              (click)="toggleChipSelection('1')"
              [selected]="weekdaysSelected.includes('1')"
              [ngClass]="{
                active: weekdaysSelected.includes('1'),
                disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('1'),
              }"
            >
              <p class="paragraph paragraph--s paragraph--bold">Mo</p>
            </mat-chip>
            <mat-chip
              (click)="toggleChipSelection('2')"
              [selected]="weekdaysSelected.includes('2')"
              [ngClass]="{
                active: weekdaysSelected.includes('2'),
                disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('2'),
              }"
            >
              <p class="paragraph paragraph--s paragraph--bold">Di</p>
            </mat-chip>
            <mat-chip
              (click)="toggleChipSelection('3')"
              [selected]="weekdaysSelected.includes('3')"
              [ngClass]="{
                active: weekdaysSelected.includes('3'),
                disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('3'),
              }"
            >
              <p class="paragraph paragraph--s paragraph--bold">Mi</p>
            </mat-chip>
            <mat-chip
              (click)="toggleChipSelection('4')"
              [selected]="weekdaysSelected.includes('4')"
              [ngClass]="{
                active: weekdaysSelected.includes('4'),
                disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('4'),
              }"
            >
              <p class="paragraph paragraph--s paragraph--bold">Do</p>
            </mat-chip>
            <mat-chip
              (click)="toggleChipSelection('5')"
              [selected]="weekdaysSelected.includes('5')"
              [ngClass]="{
                active: weekdaysSelected.includes('5'),
                disabled: weekdaysSelected.length == 3 && !weekdaysSelected.includes('5'),
              }"
            >
              <p class="paragraph paragraph--s paragraph--bold">Fr</p>
            </mat-chip>
          </mat-chip-set>
        </div>

        <!-- <mat-form-field>
          <mat-select formControlName="weekdays"  disableOptionCentering multiple (selectionChange)="getSelectedWeekdays($event.value)">
            <mat-option value="1" [disabled]="durationForm.get('weekdays').value.length >= 3 && !durationForm.get('weekdays').value.includes('1')">{{translatedText.training_task_create_monday}}</mat-option>
            <mat-option value="2" [disabled]="durationForm.get('weekdays').value.length >= 3 && !durationForm.get('weekdays').value.includes('2')">{{translatedText.training_task_create_tuesday}}</mat-option>
            <mat-option value="3" [disabled]="durationForm.get('weekdays').value.length >= 3 && !durationForm.get('weekdays').value.includes('3')">{{translatedText.training_task_create_wednesday}}</mat-option>
            <mat-option value="4" [disabled]="durationForm.get('weekdays').value.length >= 3 && !durationForm.get('weekdays').value.includes('4')">{{translatedText.training_task_create_thursday}}</mat-option>
            <mat-option value="5" [disabled]="durationForm.get('weekdays').value.length >= 3 && !durationForm.get('weekdays').value.includes('5')">{{translatedText.training_task_create_friday}}</mat-option>
          </mat-select>
        </mat-form-field> -->

        <div class="diagnostic-date-changer">
          <mat-checkbox #disableInHolidays>{{ translatedText.training_task_create_holidays }}</mat-checkbox>
          <mat-icon
            matTooltip="{{ translatedText.training_task_create_holidays_description }}"
            svgIcon="streamline_outlined:hint"
          ></mat-icon>
        </div>
      </div>
      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Zurück" mat-flat-button color="secondary" (click)="navigateBackToStep()">
          {{ translatedText.training_task_create_back }}
        </button>
        <button
          alt="Weiter"
          [disabled]="weekdaysSelected.length !== 3"
          mat-flat-button
          color="primary"
          (click)="navigateToStep()"
        >
          {{ translatedText.training_task_create_next }}
        </button>
      </div>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="deadlineStep" [ngClass]="{ 'button-up': iPadResolution }">
    <form [formGroup]="deadlineStep">
      <div class="training-task_select_container">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.training_task_create_deadline }}
          </h3>
          <div class="deadline_tooltip-wrap">
            <p class="paragraph paragraph--xl">
              {{ translatedText.training_task_create_deadline_description }}
            </p>
            <!--  <mat-icon matTooltip="{{ betaInfoText }}" svgIcon="streamline_outlined:hint"></mat-icon> -->
          </div>
        </div>
        <div class="timeStepForm">
          <mat-form-field class="not-full-width">
            <input
              matInput
              [min]="todayDate"
              [max]="trialStatus.isActive ? trialStatus.expiryDate : null"
              onkeypress="return false"
              formControlName="deadline"
              type="text"
              (click)="deadlinepicker.open()"
              [matDatepicker]="deadlinepicker"
              (dateChange)="onChangeDeadline($event)"
            />
            <mat-label>{{ translatedText.date_format }}</mat-label>
            <mat-datepicker-toggle matSuffix [for]="deadlinepicker"></mat-datepicker-toggle>
            <mat-datepicker #deadlinepicker></mat-datepicker>
            <mat-error *ngIf="deadlineStep.get('deadline').hasError('required')">
              <!-- Please choose a deadline. -->
              {{ translatedText.training_task_create_deadline_error }}
            </mat-error>
          </mat-form-field>
        </div>

        <app-trial-banner
          *ngIf="trialStatus.isActive"
          [trialHeadline]="translatedText.license_books_trial_title"
          [trialText]="translatedText.license_task_trial_deadline_description"
          [trialStatus]="trialStatus"
          [showExpirationDate]="false"
          [showButtons]="false"
        ></app-trial-banner>
      </div>
      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Zurück" mat-flat-button color="secondary" (click)="navigateBackToStep()">
          {{ translatedText.training_task_create_back }}
        </button>
        <button alt="Weiter" mat-flat-button color="primary" cdkStepperNext>
          {{ translatedText.training_task_create_next }}
        </button>
      </div>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="groupSelectStep">
    <form [formGroup]="groupSelectStep" #formDirective="ngForm" class="third_step_accorddion">
      <div class="training-task_select_container">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.training_task_create_who }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.training_task_create_who_description }}
          </p>
        </div>

        <app-trial-banner
          *ngIf="trialStatus.isActive"
          [trialHeadline]="translatedText.license_books_trial_title"
          [trialText]="translatedText.license_task_trial_student_selection_description"
          [trialStatus]="trialStatus"
          [showExpirationDate]="false"
          [showButtons]="false"
        ></app-trial-banner>

        <ng-container *ngIf="isGroupLoading">
          <app-loading-spinner></app-loading-spinner>
        </ng-container>
        <ng-container *ngIf="!isGroupLoading">
          <div
            *ngIf="groups?.length == 0 || (groups.length > 0 && filterGroupsWithoutPersonalData(groups)?.length == 0)"
            class="empty_tasks_container"
          >
            <img src="../../../assets/empty.svg" alt="" />
            <p class="paragraph">
              <span *ngIf="userType == 'school_admin'">{{ translatedText.training_task_create_no_group }}</span>
              <span *ngIf="userType == 'teacher'">{{ translatedText.training_task_create_check_information }}</span>
            </p>
          </div>
          <div class="accordion_wrap">
            <mat-accordion multi>
              <mat-expansion-panel
                [togglePosition]="'before'"
                *ngFor="let groups of filterGroupsWithoutPersonalData(groups)"
                [disabled]="
                  (schoolYearTransition && nextYear != groups.group.school_year) ||
                  (!schoolYearTransition && currentYear != groups.group.school_year)
                "
              >
                <mat-expansion-panel-header>
                  <mat-panel-title class="round-checkbox">
                    <mat-checkbox
                      (change)="toggleSelectAll(students, $event.checked)"
                      [checked]="students.selectedOptions.selected.length > 0"
                      class="example-margin"
                      (click)="$event.stopPropagation()"
                      [disabled]="
                        disableSelectAll(groups.group._students) ||
                        (schoolYearTransition && nextYear != groups.group.school_year) ||
                        (!schoolYearTransition && currentYear != groups.group.school_year)
                      "
                    ></mat-checkbox>
                    <div class="group-select">
                      <p class="paragraph paragraph--bold paragraph--xl">
                        {{ groups.group.name }} -
                        {{ groups.group?.school_year | slice: 2 }}
                      </p>
                      <p class="paragraph">
                        {{ translatedText.training_task_create_selected }}
                        <span class="paragraph paragraph--bold"
                          >{{ students.selectedOptions.selected.length }}/{{ groups?.group?._students?.length }}
                        </span>
                      </p>
                    </div>
                  </mat-panel-title>
                  <mat-panel-description>
                    <div class="license-amount"></div>
                  </mat-panel-description>
                  <div
                    class="tag-not-updated"
                    *ngIf="
                      (schoolYearTransition && nextYear != groups.group.school_year) ||
                      (!schoolYearTransition && currentYear != groups.group.school_year)
                    "
                  >
                    {{ translatedText.create_task_not_updated }}
                  </div>
                </mat-expansion-panel-header>
                <mat-selection-list
                  class="round-checkbox small"
                  #students
                  (selectionChange)="handleSelection($event, groupSelectStep.value)"
                  [name]="groups.group._students"
                  formControlName="students"
                >
                  <mat-list-option
                    checkboxPosition="before"
                    *ngFor="let student of groups.group._students; index as i"
                    [value]="student"
                    [selected]="checkForSelection(student, i)"
                    [disabled]="
                      isOptionDisabled(groups.group, students.selectedOptions.selected, student) ||
                      hasTooManyTrialTasks(groups.group, student) ||
                      student?.status == 'inactive'
                    "
                  >
                    <div class="list-item-element">
                      <div class="inline_text">
                        <img
                          class="avatar_img--small"
                          *ngIf="student?.avatarUrl"
                          src="{{ student?.avatarUrl }}"
                          alt=""
                        />
                        <p class="paragraph username-with-avatar">
                          {{ student?.username }}
                        </p>
                        <p class="paragraph paragraph--s inactive-status-text" *ngIf="student?.status == 'inactive'">
                          ({{ translatedText.training_task_create_inactive }})
                        </p>
                      </div>
                      <p
                        *ngIf="isOptionDisabled(groups?.group, students.selectedOptions.selected, student)"
                        class="paragraph license-time"
                      >
                        {{ translatedText.training_task_create_no_assigned_license }}
                      </p>
                      <p *ngIf="hasTooManyTrialTasks(groups.group, student)" class="paragraph license-time">
                        {{ translatedText.task_create_maximum_reached }}
                      </p>
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </ng-container>
        <!-- {{students}} -->
      </div>
      <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
        <button alt="Zurück" mat-flat-button color="secondary" (click)="navigateBackToStep()">
          {{ translatedText.training_task_create_back }}
        </button>
        <button
          alt="Weiter"
          mat-flat-button
          color="primary"
          (click)="setTaskDataForOverview()"
          [disabled]="studentsArray.length == 0"
        >
          {{ translatedText.training_task_create_next }}
        </button>
      </div>
    </form>
  </cdk-step>

  <cdk-step [stepControl]="taskOverviewStep">
    <div class="training-task_select_container">
      <div class="training-task_create_headline">
        <span class="paragraph task_chip task_chip--text">{{ translatedText.training_task_create_chip }}</span>
        <h3 class="h3 h3--bold">
          {{ translatedText.training_task_create_overview }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.training_task_create_overview_description }}
        </p>
      </div>

      <div class="row">
        <div class="col-md-12">
          <p class="task_summary-headline">
            {{ translatedText.training_task_create_task }}
          </p>
          <div class="task_summary">
            <div class="set_inline task_summary_header">
              <div class="task_chips_wrap">
                <span class="paragraph task_chip task_chip--text">{{
                  translatedText.training_task_create_training
                }}</span>
                <span class="paragraph task_chip task_chip--reading-loud">{{
                  translatedText.training_task_create_fluency_title
                }}</span>
              </div>
            </div>
            <div>
              <div class="inline_text">
                <p class="paragraph paragraph--s task_summary-book">{{ translatedText.training_task_create_book }}:</p>
                <p
                  *ngIf="taskChoice != 'every_book'"
                  class="paragraph paragraph--bold paragraph--s task_summary-bookTitle"
                >
                  {{ replaceHtmlContent(bookData?.title) }}
                </p>
                <p
                  *ngIf="taskChoice == 'every_book'"
                  class="paragraph paragraph--bold paragraph--s task_summary-bookTitle"
                >
                  {{ translatedText.training_task_create_self }}
                </p>
                <!-- <p *ngIf="taskMode == 'auto'" class="paragraph paragraph--bold paragraph--s task_summary-bookTitle">Dynamisch</p> -->
              </div>
              <div class="inline_text">
                <p *ngIf="taskMode != 'auto'" class="paragraph paragraph--s">
                  {{ translatedText.training_task_create_task }}:
                </p>
                <p class="paragraph paragraph--bold paragraph--s">
                  {{ replaceHtmlContent(description) }}
                </p>
              </div>
              <div class="inline_text">
                <p *ngIf="taskMode != 'auto'" class="paragraph paragraph--s">
                  {{ translatedText.training_task_create_deadline_word }}:
                </p>
                <p class="paragraph paragraph--bold paragraph--s">
                  {{ deadlineStep.get('deadline')?.value | date: 'dd.MM.yyyy' }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="task_summary_wrap">
        <div class="row">
          <div class="col-md-12">
            <p class="task_summary-headline">
              {{ translatedText.training_task_create_students }} ({{ studentsArray?.length }})
            </p>
            <div class="task_overview-chip-wrap">
              <div *ngFor="let student of studentsArray; let i = index" class="task_overview-chip">
                <div class="user-img">
                  <img *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" alt="" />
                  <p class="paragraph paragraph--s paragraph--bold">
                    {{ student?.username }}
                    <span>({{ student?.groupName }} - {{ student?.groupSchoolYear | slice: 2 }})</span>
                  </p>
                </div>
                <mat-icon
                  svgIcon="streamline_outlined:close"
                  (click)="removeFromStudentsArray(i, student?._id)"
                ></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="training-task_select_button" [ngClass]="{ 'button-up': iPadResolution }">
      <button
        alt="Zurück"
        mat-flat-button
        color="secondary"
        (click)="navigateBackToStudentSelection()"
        [disabled]="isLoading"
      >
        {{ translatedText.training_task_create_back }}
      </button>
      <button
        alt="Aufgabe erstellen"
        mat-flat-button
        color="primary"
        (click)="submitTask(formDirective)"
        [disabled]="studentsArray.length == 0 || isLoading"
      >
        <span *ngIf="!isLoading">{{ translatedText.training_task_create_create }}</span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
    </div>
  </cdk-step>

  <cdk-step [stepControl]="lastStep">
    <div class="last_step_container" *ngIf="isLoading">
      <app-loading-spinner></app-loading-spinner>
      <h3 class="h3 h3--bold" *ngIf="taskMode != 'auto'">
        {{ translatedText.training_task_create_create_description }}
      </h3>
      <h3 class="h3 h3--bold" *ngIf="taskMode == 'auto'">
        {{ translatedText.training_task_create_create_description_more }}
      </h3>
    </div>

    <div class="last_step_container" *ngIf="!isLoading">
      <mat-icon svgIcon="streamline_outlined:check_mark" class="blue-icon"></mat-icon>
      <h3 class="h3 h3--bold">
        {{ translatedText.training_task_create_success }}
      </h3>
    </div>
  </cdk-step>
</app-training-task-stepper>
