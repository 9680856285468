import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { LicenseTrialExtensionComponent } from 'src/app/license/license-trial-extension/license-trial-extension.component';
import { CapacitorService } from 'src/app/providers/capacitor.service';

@Component({
  selector: 'app-trial-banner',
  templateUrl: './trial-banner.component.html',
  styleUrls: ['./trial-banner.component.scss'],
})
export class TrialBannerComponent implements OnInit, OnDestroy {
  @Input() trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  @Input() trialHeadline: string;
  @Input() expiredHeadline: string;
  @Input() trialText: string;
  @Input() expiredText: string;
  @Input() showButtons: boolean = false;
  @Input() showExpirationDate: boolean = false;
  @Input() id: string;
  environment = environment;
  IS_APP = environment.isApp;
  translatedText: any;
  closeBanner: boolean = false;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private capacitorService: CapacitorService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // Trial functions
  isLastTrialWeek() {
    const oneWeekInMillis = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
    const currentTime = Date.now();
    const difference = this.trialStatus.expiryDate.getTime() - currentTime;
    return difference <= oneWeekInMillis && difference > 0;
  }

  isExpiredTrial() {
    return this.trialStatus.expiryDate.getTime() - Date.now() < 0;
  }

  openLicensetrialExtension() {
    let dialogRef = this.dialog.open(LicenseTrialExtensionComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-transfer-backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((trialExtended) => {
      if (trialExtended) {
        const newExpiry = new Date();
        newExpiry.setDate(newExpiry.getDate() + 28);
        this.trialStatus.expiryDate = newExpiry;
      } else {
        return;
      }
    });
  }

  openUrl(url: string) {
    this.capacitorService.openUrl(url);
  }
}
