<div class="task-diagnostic_container" *ngIf="showPreDiagnostic">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="task-diagnostic_inner task-diagnostic_inner--pre">
        <div class="task-diagnostic_header">
          <div class="task-diagnostic_header_left">
            <div class="diagnostic_chip diagnostic_chip__type">
              <p class="paragraph">{{ translatedText.task_diagnostic_pre }}</p>
            </div>
            <div class="diagnostic_chip diagnostic_chip__deadline">
              <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
              <p class="paragraph">
                {{ calculateDaysLeft(preDiagnostic?.deadline) }}
              </p>
            </div>
          </div>
          <div class="task-diagnostic_header_right">
            <div class="diagnostic_chip diagnostic_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of preDiagnostic?._teacher; let i = index">
                  <span [hidden]="i == 0"> / </span>
                  {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="task-diagnostic_footer">
          <div class="diagnostic_footer_content">
            <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
            <p class="paragraph paragraph--l">
              {{ translatedText.task_diagnostic_read }}
              <strong>3 {{ translatedText.task_diagnostic_minutes }}</strong>
              {{ translatedText.task_diagnostic_shown_text }}
              <strong>{{ translatedText.task_diagnostic_text }}</strong
              >.
            </p>
          </div>
          <div class="view_button">
            <button
              alt="Aufgabe starten"
              mat-flat-button
              color="primary-student"
              class="diagnostic_button"
              (click)="openTaskPreview(preDiagnostic?._id)"
            >
              {{ translatedText.task_diagnostic_start }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="task-diagnostic_container" *ngIf="showMidDiagnostic">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="task-diagnostic_inner task-diagnostic_inner--mid">
        <div class="task-diagnostic_header">
          <div class="task-diagnostic_header_left">
            <div class="diagnostic_chip diagnostic_chip__type">
              <p class="paragraph">{{ translatedText.task_diagnostic_mid }}</p>
            </div>
            <div class="diagnostic_chip diagnostic_chip__deadline">
              <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
              <p class="paragraph">
                {{ calculateDaysLeft(midDiagnostic?.deadline) }}
              </p>
            </div>
          </div>
          <div class="task-diagnostic_header_right">
            <div class="diagnostic_chip diagnostic_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of midDiagnostic?._teacher; let i = index">
                  <span [hidden]="i == 0"> / </span>
                  {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="task-diagnostic_footer">
          <div class="diagnostic_footer_content">
            <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
            <p class="paragraph paragraph--l">
              {{ translatedText.task_diagnostic_read }}
              <strong>3 {{ translatedText.task_diagnostic_minutes }}</strong>
              {{ translatedText.task_diagnostic_shown_text }}
              <strong>{{ translatedText.task_diagnostic_text }}</strong
              >.
            </p>
          </div>
          <div class="view_button">
            <button
              alt="Aufgabe starten"
              mat-flat-button
              color="primary-student"
              class="diagnostic_button"
              (click)="openTaskPreview(midDiagnostic?._id)"
            >
              {{ translatedText.task_diagnostic_start }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="task-diagnostic_container" *ngIf="showPostDiagnostic">
  <div class="row">
    <div class="col-md-12 col-sm-12">
      <div class="task-diagnostic_inner task-diagnostic_inner--post">
        <div class="task-diagnostic_header">
          <div class="task-diagnostic_header_left">
            <div class="diagnostic_chip diagnostic_chip__type">
              <p class="paragraph">{{ translatedText.task_diagnostic_post }}</p>
            </div>
            <div class="diagnostic_chip diagnostic_chip__deadline">
              <mat-icon svgIcon="streamline_filled:clock"></mat-icon>
              <p class="paragraph">
                {{ calculateDaysLeft(postDiagnostic?.deadline) }}
              </p>
            </div>
          </div>
          <div class="task-diagnostic_header_right">
            <div class="diagnostic_chip diagnostic_chip__teacher">
              <mat-icon svgIcon="streamline_filled:teacher"></mat-icon>
              <p class="paragraph">
                <span *ngFor="let teacher of postDiagnostic?._teacher; let i = index">
                  <span [hidden]="i == 0"> / </span>
                  {{ getSalutation(teacher) }} {{ teacher?.lastname }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="task-diagnostic_footer">
          <div class="diagnostic_footer_content">
            <mat-icon class="orange_target" svgIcon="streamline_filled:target"></mat-icon>
            <p class="paragraph paragraph--l">
              {{ translatedText.task_diagnostic_read }}
              <strong>3 {{ translatedText.task_diagnostic_minutes }}</strong>
              {{ translatedText.task_diagnostic_shown_text }}
              <strong>{{ translatedText.task_diagnostic_text }}</strong
              >.
            </p>
          </div>
          <div class="view_button">
            <button
              alt="Aufgabe starten"
              mat-flat-button
              color="primary-student"
              class="diagnostic_button"
              (click)="openTaskPreview(postDiagnostic?._id)"
            >
              {{ translatedText.task_diagnostic_start }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
