<div class="teacher-mobile-menu" (click)="(!mobileOverlayClicked)">
  <div class="create-task-btn-wrapper">
    <div class="round-button-wrapper">
      <p [hidden]="!mobileOverlayClicked">{{ translatedText.teacher_sidebar_create_task }}</p>
      <button
        alt="Schließen"
        mat-fab
        (click)="mobileOverlayHandler()"
        *ngIf="diagnosticLicenseStatusActive || trainingLicenseStatusActive"
        type="button"
      >
        <mat-icon [hidden]="mobileOverlayClicked" svgIcon="streamline_outlined:target"></mat-icon>
        <mat-icon [hidden]="!mobileOverlayClicked">close</mat-icon>
      </button>
    </div>
    <div class="round-buttons" [hidden]="!mobileOverlayClicked">
      <div class="round-button-wrapper" (click)="createDiagnoseDialog()">
        <p class="animate-diagnose">{{ translatedText.teacher_sidebar_diagnostic }}</p>
        <button alt="Ziel" class="animate-diagnose" mat-fab color="secondary" *ngIf="diagnosticLicenseStatusActive">
          <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
        </button>
      </div>
      <div class="round-button-wrapper" (click)="createTaskDialog()">
        <p class="animate-task">{{ translatedText.teacher_sidebar_training }}</p>
        <button alt="Blitz" class="animate-task" mat-fab color="secondary" *ngIf="trainingLicenseStatusActive">
          <mat-icon svgIcon="streamline_outlined:lightning"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="teacher-mobile-menu-container">
    <div>
      <button
        alt="Übersicht"
        mat-flat-button
        color="unset"
        type="button"
        [routerLink]="['/admin-dashboard']"
        routerLinkActive="active-sidebar_button"
      >
        <mat-icon svgIcon="streamline_outlined:chart-column-grow"></mat-icon>
        <span>{{ translatedText.teacher_sidebar_overview }}</span>
      </button>
    </div>

    <div>
      <button
        alt="Aufgaben"
        mat-flat-button
        color="unset"
        type="button"
        [routerLink]="['/dashboard-tasks']"
        routerLinkActive="active-sidebar_button"
        [ngClass]="{ 'active-sidebar_button': currentUrlSlug === 'task-detail' }"
      >
        <mat-icon svgIcon="streamline_outlined:target"></mat-icon>
        {{ translatedText.teacher_sidebar_tasks }}
      </button>
    </div>

    <div [hidden]="userType !== 'teacher'">
      <button
        alt="Lizenzen"
        mat-flat-button
        color="unset"
        type="button"
        [routerLink]="['/dashboard-license']"
        routerLinkActive="active-sidebar_button"
      >
        <mat-icon svgIcon="streamline_outlined:privacy-policy"> </mat-icon>
        <span>{{ translatedText.teacher_sidebar_licenses }}</span>
      </button>
    </div>

    <div *ngIf="userType === 'admin' || userType === 'school_admin'">
      <button
        alt="Mehr"
        class=" "
        mat-flat-button
        color="unset"
        type="button"
        routerLinkActive="active-sidebar_button"
        [matMenuTriggerFor]="more"
      >
        <mat-icon svgIcon="streamline_outlined:more"></mat-icon>
        <span>{{ translatedText.teacher_mobile_bar_more }}</span>
      </button>
    </div>

    <div class="">
      <button
        alt="Hilfe"
        mat-flat-button
        color="unset"
        type="button"
        routerLinkActive="active-sidebar_button"
        (click)="openUrl(support_url)"
      >
        <mat-icon svgIcon="streamline_outlined:information-chat-right"></mat-icon>
        <span>{{ translatedText.teacher_sidebar_help }}</span>
      </button>
    </div>
    <!-- [matMenuTriggerFor]="help" -->

    <!-- <ng-container>
      <mat-menu #help="matMenu" xPosition="before" yPosition="above">
        <button mat-menu-item [matMenuTriggerFor]="schooladmin" *ngIf="userType === 'school_admin'">Schuladmin</button>
        <button mat-menu-item [matMenuTriggerFor]="teacher">{{ translatedText.teacher_sidebar_school_admin }}</button>
        <button mat-menu-item [matMenuTriggerFor]="student">{{ translatedText.teacher_sidebar_students }}</button>
        <button mat-menu-item (click)="openExternalUrl(support_url)">{{ translatedText.teacher_sidebar_helpcenter }}</button>
      </mat-menu>

      <mat-menu #schooladmin="matMenu">
        <button mat-menu-item (click)="showVideo('3_Admin-Dashboard.mp4', 'Dashboard')">{{ translatedText.teacher_sidebar_dashboard }}</button>
        <button mat-menu-item (click)="showVideo('6_Admin-Lehrkraefte.mp4', 'Lehrkräfteverwaltung')">
          {{ translatedText.teacher_sidebar_teachers_management }}
        </button>
        <button mat-menu-item (click)="showVideo('4_Admin-Klassen.mp4', 'Klassenverwaltung')">{{ translatedText.teacher_sidebar_groups_management }}</button>
        <button mat-menu-item (click)="showVideo('5_Admin-Lernende.mp4', 'Lernendenverwaltung')">
          {{ translatedText.teacher_sidebar_students_management }}
        </button>
      </mat-menu>

      <mat-menu #teacher="matMenu">
        <button mat-menu-item (click)="showVideo('2_Lehrkraft-Uebersicht.mp4', 'Übersicht')">{{ translatedText.teacher_sidebar_overview }}</button>
        <button mat-menu-item (click)="showVideo('3_Lehrkraft-Klassendaten.mp4', 'Klassendaten')">{{ translatedText.teacher_sidebar_group_data }}</button>
        <button mat-menu-item (click)="showVideo('4_Lehrkraft-Klassenuebersicht.mp4', 'Klassenübersicht')">
          {{ translatedText.teacher_sidebar_group_overview }}
        </button>
        <button mat-menu-item (click)="showVideo('5_Lehrkraft-Lernende-Ansicht.mp4', 'Lernendenübersicht')">
          {{ translatedText.teacher_sidebar_student_overview }}
        </button>
        <button mat-menu-item (click)="showVideo('6_Lehrkraft-Aufgabenergebnisse.mp4', 'Aufgabenergebnisse')">
          {{ translatedText.teacher_sidebar_task_results}}
        </button>
        <button mat-menu-item (click)="showVideo('7_Lehrkraft-Aufgabenuebersicht.mp4', 'Aufgabenübersicht')">
          {{ translatedText.teacher_sidebar_task_overview }}
        </button>
        <button mat-menu-item (click)="showVideo('9_Lehrkraft-Diagnose.mp4', 'Diagnoseaufgabe')">
          {{ translatedText.teacher_sidebar_diagnostic_task }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('10_Lehrkraft-Manuell-vs-auto.mp4', 'Förderaufgabe - manueller Modus vs. Auto-Modus')"
        >
          {{ translatedText.teacher_sidebar_tasksmode }}
        </button>
        <button mat-menu-item (click)="showVideo('11_Lehrkraft-Spezifischer-Text.mp4', 'Spezifische Textaufgabe')">
          {{ translatedText.teacher_sidebar_specific_task }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('12_Lehrkraft-Spezifisches-Buch.mp4', 'Aufgabe mit spezifischem Buch - einmalig')"
        >
          {{ translatedText.teacher_sidebar_single_book }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('13_Lehrkraft-Beliebiges-Buch.mp4', 'Aufgabe mit beliebigem Buch - mehrstufig')"
        >
          {{ translatedText.teacher_sidebar_multiple }}
        </button>
        <button mat-menu-item (click)="showVideo('14_Lehrkraft-Automode.mp4', 'Aufgabe im Auto-Modus')">
          {{ translatedText.teacher_sidebar_auto }}
        </button>
        <button mat-menu-item (click)="showVideo('8_Lehrkraft-Aufgabe-bearbeiten.mp4', 'Aufgabe bearbeiten')">
          {{ translatedText.teacher_sidebar_edit }}
        </button>
        <button mat-menu-item (click)="showVideo('15_Lehrkraft-Lizenzen.mp4', 'Lizenzen')">{{ translatedText.teacher_sidebar_licenses }}</button>
      </mat-menu>

      <mat-menu #student="matMenu">
        <button mat-menu-item (click)="showVideo('2_Lernende-Dashboard.mp4', 'Dashboard')">{{ translatedText.teacher_sidebar_dashboard }}</button>
        <button mat-menu-item (click)="showVideo('3_Lernende-Menuepunkte.mp4', 'Menüpunkte')">{{ translatedText.teacher_sidebar_menu }}</button>
        <button mat-menu-item (click)="showVideo('4_Lernende-Aufgabenarten.mp4', 'Aufgabenarten')">
          {{ translatedText.teacher_sidebar_tasks_type }}
        </button>
        <button
          mat-menu-item
          (click)="showVideo('5_Lernende-Leseaufgabe-durchfuehren.mp4', 'Eine Leseaufgabe durchführen')"
        >
          {{ translatedText.teacher_sidebar_action }}
        </button>
        <button mat-menu-item (click)="showVideo('6_Lernende-waehrend-lesen.mp4', 'Während des Lesens')">
          {{ translatedText.teacher_sidebar_read }}
        </button>
      </mat-menu>
    </ng-container> -->
  </div>

  <mat-menu class="dropdown-menu" #more="matMenu" yPosition="above">
    <button
      alt="Dashboard"
      class=""
      mat-flat-button
      color="unset"
      type="button"
      [routerLink]="['/admin-dashboard']"
      routerLinkActive="active-sidebar_button"
    >
      <mat-icon svgIcon="streamline_outlined:reports"></mat-icon>
      {{ translatedText.admin_dashboard_headline }}
    </button>

    <button
      alt="Klassen"
      class=""
      mat-flat-button
      color="unset"
      type="button"
      [routerLink]="['/admin-group-list']"
      routerLinkActive="active-sidebar_button"
    >
      <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
      {{ translatedText.admin_dashboard_groups }}
    </button>

    <button
      alt="Lehrkräfte"
      class=""
      mat-flat-button
      color="unset"
      type="button"
      [routerLink]="['/admin-teacher-list']"
      routerLinkActive="active-sidebar_button"
    >
      <mat-icon svgIcon="streamline_outlined:user-star"></mat-icon>
      {{ translatedText.admin_dashboard_teachers }}
    </button>

    <button
      alt="Administratoren"
      class=""
      mat-flat-button
      color="unset"
      type="button"
      [routerLink]="['/admin-list']"
      routerLinkActive="active-sidebar_button"
    >
      <mat-icon svgIcon="streamline_outlined:user-crown"></mat-icon>
      {{ translatedText.admin_list_headline }}
    </button>
  </mat-menu>
</div>
