import { Component, OnInit, Input, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TaskService } from '../../providers/task.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService } from 'src/app/auth/auth.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-task-delete',
  templateUrl: './task-delete.component.html',
  styleUrls: ['./task-delete.component.scss'],
})
export class TaskDeleteComponent implements OnInit {
  environment = environment;
  masterTaskId;
  subTaskId;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  translatedText;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  source: string = 'detail';
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private taskService: TaskService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
    private authService: AuthService,
  ) {
    if (injectedData) {
      if (typeof injectedData['masterTaskId'] !== 'undefined') {
        this.masterTaskId = this.injectedData['masterTaskId'];
      }
      if (typeof injectedData['subTaskId'] !== 'undefined') {
        this.subTaskId = this.injectedData['subTaskId'];
      }
      if (typeof injectedData['source'] !== 'undefined') {
        this.source = this.injectedData['source'];
      }
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  //used when delete task is accessed from teacher task list component
  deleteSingleTask() {
    this.trialStatus = this.authService.getUserTrialStatus();
    this.taskService
      .deleteSingleTask(this.subTaskId, this.trialStatus.isActive)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log('here', res);
          this.router.navigate(['/dashboard-tasks']);
          this.dialog.closeAll();
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: this.translatedText?.task_delete_success,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        (error) => {
          console.log('error', error.error.message);
          let message = this.translatedText?.cannot_delete_task;
          if (
            error.error.message ===
            'Unauthorized: This student is not assigned to you.'
          ) {
            message = this.translatedText.task_detail_delete_not_possible;
          }

          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
  }

  // used when delete task is accessed from task detail component
  deleteTask() {
    this.trialStatus = this.authService.getUserTrialStatus();
    this.taskService
      .deleteTask(this.masterTaskId, this.trialStatus.isActive)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          console.log(res);
          this.router.navigate(['/dashboard-tasks']);
          this.dialog.closeAll();
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: this.translatedText?.task_delete_success,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        (error) => {
          let message = this.translatedText?.cannot_delete_task;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
    setTimeout(() => {
      this.dialog.closeAll();
    }, 2000);
  }
}
