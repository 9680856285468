<!-- <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--right" matSuffix>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button> -->

<div class="school_state_update_wrap">
  <form [formGroup]="schoolForm" #formDirective="ngForm">
    <div class="school_state_update_title">
      <h2 class="h2" *ngIf="!isSubmited">{{ translatedText.school_state_update_title }}</h2>
      <h2 class="h2" *ngIf="isSubmited">{{ translatedText.school_state_update_success }}</h2>
      <h2 class="h2" *ngIf="isError">{{ translatedText.technical_error }}</h2>
    </div>
    <div class="row" *ngIf="!isSubmited && !isError">
      <div class="col-md-12">
        <mat-label>{{ translatedText.school_create_school_state }}</mat-label>
        <mat-form-field>
          <mat-select formControlName="state" type="role" disableOptionCentering panelClass="state-select">
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{ state.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="schoolForm.get('state').hasError('required')">
            {{ translatedText.school_create_school_state_error }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="school_state_update_buttons" *ngIf="!isError">
      <button
        alt="Speichern"
        mat-flat-button
        color="primary"
        *ngIf="!isSubmited"
        [disabled]="isLoading || schoolForm.invalid"
        mat-flat-button
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.school_edit_save }}
      </button>
      <button
        alt="Schließen"
        mat-flat-button
        color="primary"
        *ngIf="isSubmited"
        mat-flat-button
        (click)="closeOverlay()"
      >
        {{ translatedText.admin_group_close_btn }}
      </button>
    </div>
  </form>
</div>
