import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { GroupService } from 'src/app/providers/group.service';
import {
  AdminService,
  TeacherData,
  SchoolYears,
} from 'src/app/providers/admin.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-oauth-groups-update',
  templateUrl: './oauth-groups-update.component.html',
  styleUrls: ['./oauth-groups-update.component.scss'],
})
export class OauthGroupsUpdateUpdateComponent implements OnInit, OnDestroy {
  oauthFormArray: FormArray;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = false;
  isSubmited: boolean = false;
  isError: boolean = false;
  schoolId;
  filteredItems: Observable<any[]>;
  newStreamItem: FormControl;
  stream: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  prompt =
    'Drücken Sie die <enter> Taste um einen neuen Klassenzug hinzuzufügen.';
  showAddButton: boolean = false;
  schoolYears: SchoolYears[] = [];
  currentYearName: string = '';
  $currentYearUpdate = null;
  groups;
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<OauthGroupsUpdateUpdateComponent>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslationService,
    private adminService: AdminService,
    private _formBuilder: FormBuilder,
    private groupService: GroupService,
  ) {
    if (injectedData) {
      if (typeof injectedData['groups'] !== 'undefined') {
        this.groups = this.injectedData['groups'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    console.log(this.groups);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.schoolId = this.authService.getSchoolId();
    this.oauthFormArray = this._formBuilder.array(
      this.groups.map((group) =>
        this._formBuilder.group({
          grade: [group.grade || '', Validators.required],
          stream: [
            group.stream,
            [Validators.required, this.onlyLettersValidator()],
          ],
          school_year: [group.school_year || '', Validators.required],
        }),
      ),
    );

    this.newStreamItem = new FormControl();
    this.filteredItems = this.newStreamItem.valueChanges.pipe(
      startWith(''),
      map((item) => (item ? this.filterItems(item) : this.stream?.slice())),
    );
    this.getSchoolYearUpdateInfo();
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      if (data) {
        this.currentYearName = data.year;
        if (data.yearChange) {
          this.schoolYears.push({
            name: data.nextYear.slice(2),
            value: data.nextYear,
          });
        } else {
          this.schoolYears.push({
            name: this.currentYearName.slice(2),
            value: this.currentYearName,
          });
        }

        // this.$currentYearUpdate = setTimeout(() => {
        //   if (data.yearChange)
        //     this.oauthForm.patchValue({ school_year: data.nextYear });
        //   else this.oauthForm.patchValue({ school_year: data.year });
        // }, 500);
      }
    });
  }

  filterItems(name: string) {
    let results = this.stream.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  onlyLettersValidator() {
    return (control) => {
      const value = control.value;
      if (!/^[a-zA-ZäöüÄÖÜ]+$/.test(value)) {
        return { onlyLetters: true };
      }
      return null;
    };
  }

  getGroupForm(index: number): FormGroup {
    return this.oauthFormArray.at(index) as FormGroup;
  }

  submitForm() {
    if (this.oauthFormArray.invalid) {
      return;
    }
    this.isLoading = true;

    // Alle Formulardaten aus dem FormArray auslesen und `_id` hinzufügen
    const updatedGroups = this.groups.map((group, index) => ({
      _id: group._id,
      grade: this.oauthFormArray.at(index).value.grade,
      stream: this.oauthFormArray.at(index).value.stream,
      school_year: this.oauthFormArray.at(index).value.school_year,
    }));

    // Sende die Daten an den Service
    this.groupService.updateOauthGroups(updatedGroups).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.closeOverlay();
        // this.isSubmitted = true;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.isError = true;
      },
    });
  }

  closeOverlay() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
