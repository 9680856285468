import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { OauthUserUpdateUpdateComponent } from './oauth-user-update.component';

@NgModule({
  declarations: [OauthUserUpdateUpdateComponent],
  imports: [BrowserModule, MaterialModule, FormsModule, ReactiveFormsModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [OauthUserUpdateUpdateComponent],
})
export class OauthUserUpdateUpdateModule {}
