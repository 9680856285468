import { Component, OnInit, ViewChild, Inject, Optional } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AdminService, GroupData } from '../../../providers/admin.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import {
  UntypedFormBuilder,
  ValidatorFn,
  FormControl,
  FormGroup,
  Validators,
  FormGroupDirective,
  AbstractControl,
} from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkStepper } from '@angular/cdk/stepper';
import { TeacherService } from 'src/app/providers/teacher.service';
import { SuccessSnackbarComponent } from '../../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';
export interface DialogData {}

/**
 * Teacher edit form
 */
@Component({
  selector: 'app-admin-teacher-edit',
  templateUrl: './admin-teacher-edit.component.html',
  styleUrls: ['./admin-teacher-edit.component.scss'],
})
export class AdminTeacherEditComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  rForm: FormGroup;
  userTypes: string[];
  filteredItems: Observable<any[]>;
  newSalutationItem: FormControl;
  salutation: string[] = ['Herr', 'Frau', 'Divers'];
  showAddButton: boolean = false;
  selectedIndex: number;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  infoEmailText: string =
    'Dieser Dienst darf nur von Lehrkräften im Rahmen Ihrer Lehrtätigkeit und zur Evaluation des Dienstes genutzt werden. \n\n Bitte nutzen Sie für die Registrierung Ihre dienstliche E-Mail-Adresse. Sollten Sie über keine entsprechende E-Mail-Adresse verfügen und eine Webmail-Adresse (@gmail, @aol, @web, etc.) nutzen wollen, werden wir vor Gewährung eines Zugangs ggf. Ihre Identität überprüfen. Geben Sie hierzu bitte auch Ihre Telefonnummer an.';
  isLoading: boolean = false;
  groups;
  selectedRole: string = 'teacher';
  isEditd: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  teacherId;
  schoolId;
  roleText: string = '';
  roleTextMultiple: string = '';
  teacherEmail;
  translatedText: any;
  openedMenu: string = null;
  teacherRole: string;
  teacherPosition;

  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private adminService: AdminService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['teacherId'] !== 'undefined') {
        this.teacherId = this.injectedData['teacherId'];
      }
      if (typeof injectedData['teacherEmail'] !== 'undefined') {
        this.teacherEmail = this.injectedData['teacherEmail'];
      }
      if (typeof injectedData['teacherPosition'] !== 'undefined') {
        this.teacherPosition = this.injectedData['teacherPosition'];
      }
    }
  }

  checkTeacherPosition(position: string): string {
    if (
      (position != 'school_admin' && position != 'teacher') ||
      position == undefined
    ) {
      return 'teacher';
    } else {
      return position;
    }
  }

  ngOnInit(): void {
    this.userTypes = this.authService.getHierachicalRoles();
    // get teacher to get school id
    this.teacherService.readSingleTeacher(this.teacherId).subscribe((res) => {
      //console.log(res);
      this.rForm.get('salutation').setValue(res['salutation']);
      this.rForm.get('firstname').setValue(res['firstname']);
      this.rForm.get('lastname').setValue(res['lastname']);
      this.rForm.get('email').setValue(this.teacherEmail);
      this.rForm
        .get('position')
        .setValue(this.checkTeacherPosition(res['position']));
      this.rForm.get('groups').setValue(this.rForm.get('groups')?.value);
    });

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.salutation = [
          this.translatedText.salutation_male,
          this.translatedText.salutation_female,
          this.translatedText.salutation_divers,
        ];
      });

    this.getGroups();

    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);

    this.rForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator]],
      groups: [this.groups],
      position: [this.teacherPosition, [Validators.required]],
    });
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }

    return results;
  }

  getSelectedText(): string[] {
    const selectedTexts = this.groups
      ?.filter((group) => this.rForm.get('groups').value?.includes(group._id))
      ?.map((group) => `${group.name} - ${group.school_year.slice(2)}`);
    return selectedTexts;
  }

  removeSelectedGroup(index: number, event): void {
    event.stopPropagation();
    this.rForm.get('groups').value?.splice(index, 1);
    this.rForm.get('groups').setValue(this.rForm.get('groups')?.value);
  }

  getGroups() {
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .pipe(
        map((groupData: GroupData) => {
          console.log(groupData);
          this.groups = groupData.data;
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    let formData = {
      teacherId: this.teacherId,
      salutation: this.rForm.value.salutation,
      firstname: this.rForm.value.firstname,
      lastname: this.rForm.value.lastname,
      email: this.rForm.value.email,
      position: this.rForm.value.position,
      groups: this.rForm.value.groups,
    };
    console.log('form data', formData);

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText.t('admin_teacher_edit_text', {
            firstname: this.rForm.value.firstname,
            lastname: this.rForm.value.lastname,
          }),
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  clickedOptions(element): void {
    this.openedMenu = element;
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
