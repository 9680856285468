import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() id!: string;
  public className;

  constructor() {}

  ngOnInit() {
    this.className = 'loading-spinner--' + this.id;
  }
}
