<section class="container-fluid overlay_container">
  <!-- <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="">
    <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt=""> -->
  <div class="step_container">
    <div class="row">
      <div class="col-md-12 training-task-stepper-col">
        <div class="training-task_container">
          <button
            alt="Zurück"
            *ngIf="environment.whitelabel.landingpage != 'registrierung' || selectedIndex !== 0"
            mat-flat-button
            type="button"
            color="tertiary"
            [hidden]="isBackButton"
            (click)="moveBack()"
            class="stepper_backButton"
            matSuffix
          >
            <mat-icon svgIcon="streamline_outlined:back"></mat-icon>
            {{ translatedText.school_admin_create_back }}
          </button>
          <div class="steps_container school-admin">
            <div class="step-wrap" [ngClass]="selectedIndex === 0 ? 'active' : selectedIndex > 0 ? 'finished' : ''">
              <div class="step_item">
                <p class="paragraph paragraph--bold paragraph--m number">1</p>
              </div>
              <p class="paragraph paragraph--bold paragraph--xs">
                {{ translatedText.school_admin_create_personal_information_stepper }}
              </p>
            </div>
            <div class="step-divider"></div>
            <div class="step-wrap" [ngClass]="selectedIndex == 1 ? 'active' : selectedIndex > 1 ? 'finished' : ''">
              <div class="step_item">
                <p class="paragraph paragraph--bold paragraph--m number">2</p>
              </div>
              <p class="paragraph paragraph--bold paragraph--xs">
                {{ translatedText.school_admin_create_finish }}
              </p>
            </div>
          </div>
          <div class="step_content">
            <div class="training-task_wrap teacher create-user" [style.display]="selected ? 'block' : 'none'">
              <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
