<section class="container-fluid overlay_container">
  <div class="header-wrapper">
    <button
      alt="Schließen"
      mat-flat-button
      class="close-button-with-text"
      type="button"
      [mat-dialog-close]="true"
      (click)="closeTeacherOverlay()"
      matSuffix
    >
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
      {{ translatedText.admin_group_create_cancel }}
    </button>
    <div class="chip_wrapper">
      <div class="chip_outline">
        <div class="chip_content add_teacher_chip">{{ translatedText.admin_teacher_create_add_teacher }}</div>
      </div>
    </div>
    <div class="top-button-wrapper">
      <button
        alt="Lehrkraft anlegen"
        mat-flat-button
        color="primary"
        class="submit-button"
        (click)="submitForm()"
        [disabled]="teachersFormControl.invalid || isLoading"
      >
        <span *ngIf="!isLoading">
          <span *ngIf="!createAdmin"> {{ translatedText.admin_teacher_create_add_teacher }}</span>
          <span *ngIf="createAdmin">{{ translatedText?.admin_teacher_create_add }}</span>
        </span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
    </div>
  </div>
  <ng-container *ngIf="!createAdmin">
    <div class="training-task_create_headline">
      <h2 class="h2 h2--bold">{{ translatedText.admin_teacher_create_add_teacher_title }}</h2>
      <p class="paragraph paragraph--l">
        {{ translatedText?.admin_teacher_create_add_teacher_text }}
      </p>
    </div>
  </ng-container>
  <ng-container *ngIf="createAdmin">
    <div class="training-task_create_headline">
      <h2 class="h2 h2--bold">
        {{ translatedText?.admin_teacher_create_create_admin }}
      </h2>
      <p class="paragraph paragraph--l">
        {{ translatedText?.admin_teacher_create_create_admin_text }}
      </p>
    </div>
  </ng-container>
  <div class="form-wrapper">
    <div *ngIf="!trialStatus.isActive" class="add-teacher-button-wrapper">
      <button
        alt="Weitere Lehrkraft anlegen"
        mat-flat-button
        color="unset"
        type="button"
        class="add-another-teacher"
        (click)="addTeachers(totalTeachers)"
      >
        <mat-icon class="add-icon">add</mat-icon>{{ translatedText.admin_teacher_create_add_more_teachers }}
      </button>
    </div>
    <form
      [formGroup]="rForm"
      #formDirective="ngForm"
      *ngFor="let teacherForm of teachersFormControl.controls; let i = index"
      [ngClass]="{
        active: teacherForm.status === 'VALID',
        'email-verification-check': teacherForm.status === 'INVALID' && teacherForm.get('email').hasError('invalid'),
      }"
    >
      <ng-container formArrayName="teachers">
        <ng-container [formGroupName]="i">
          <div class="animate-pop">
            <p class="blue-circle">
              {{ i + 1 }}
            </p>
            <div class="row">
              <div class="col-md-3">
                <mat-label>{{ translatedText?.admin_teacher_create_salutation }}</mat-label>
                <mat-form-field>
                  <mat-icon matSuffix>expand_more</mat-icon>
                  <input
                    matInput
                    placeholder="{{ translatedText?.admin_teacher_create_salutation }}"
                    aria-label="Anrede"
                    [matAutocomplete]="auto"
                    formControlName="salutation"
                    required
                  />
                  <mat-icon
                    class="register-icon"
                    matTooltip="{{ translatedText?.admin_teacher_create_salutation_tooltip }}"
                    svgIcon="streamline_outlined:hint"
                  ></mat-icon>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                      <span>{{ item }}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="teacherForm.get('salutation').hasError('required')">
                    {{ translatedText?.admin_teacher_create_salutation_error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-label>{{ translatedText?.admin_teacher_create_firstname }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="firstname"
                    type="text"
                    placeholder="{{ translatedText?.admin_teacher_create_firstname }}"
                    required
                    class="paragraph paragraph--bold paragraph--s"
                  />
                  <mat-error *ngIf="teacherForm.get('firstname').hasError('required')">
                    {{ translatedText?.admin_teacher_create_firstname_error }}
                  </mat-error>
                  <!-- <mat-error *ngIf="teacherForm.get('firstname').hasError('email')">
                    {{ translatedText?.admin_teacher_create_email_error }}
                  </mat-error> -->
                  <mat-error *ngIf="teacherForm.get('firstname').hasError('hasDigits')">
                    {{ translatedText?.admin_teacher_create_hasDigits_error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-5">
                <mat-label>{{ translatedText?.admin_teacher_create_lastname }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="lastname"
                    type="text"
                    placeholder="{{ translatedText?.admin_teacher_create_lastname }}"
                    required
                    class="paragraph paragraph--s"
                  />
                  <mat-error *ngIf="teacherForm.get('lastname').hasError('required')">
                    {{ translatedText?.admin_teacher_create_lastname_error }}
                  </mat-error>
                  <mat-error *ngIf="teacherForm.get('lastname').hasError('hasDigits')">
                    {{ translatedText?.admin_teacher_create_hasDigits_error }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-label>{{ translatedText?.admin_teacher_create_email }}</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    formControlName="email"
                    type="text"
                    placeholder="{{ translatedText?.admin_teacher_create_email }}"
                    required
                    class="paragraph paragraph--s"
                    (blur)="checkEmailExists(i)"
                  />
                  <mat-error *ngIf="teacherForm.get('email').hasError('required')">
                    {{ translatedText?.admin_teacher_create_email_error }}
                  </mat-error>
                  <mat-error *ngIf="teacherForm.get('email').hasError('email')">
                    {{ translatedText?.admin_teacher_create_email_error_valid }}
                  </mat-error>
                  <mat-error *ngIf="teacherForm.get('email').hasError('invalidEmail')">
                    {{ translatedText?.admin_teacher_create_email_error_invalid }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-3" *ngIf="!createAdmin">
                <mat-label>{{ translatedText?.admin_teacher_create_position }}</mat-label>
                <mat-form-field>
                  <mat-icon matSuffix>expand_more</mat-icon>
                  <mat-select formControlName="position">
                    <mat-option value="school_admin">{{
                      translatedText?.admin_teacher_create_position_school_management
                    }}</mat-option>
                    <mat-option value="teacher">{{
                      translatedText?.admin_teacher_create_position_teacher_form
                    }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="teacherForm.get('position').hasError('required')">
                    {{ translatedText?.admin_teacher_create_position_error }}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-3" *ngIf="!createAdmin">
                <mat-label>{{ translatedText?.admin_teacher_create_group }}</mat-label>
                <mat-form-field class="group-select">
                  <mat-icon matSuffix>expand_more</mat-icon>
                  <mat-select formControlName="groups" multiple panelClass="admin-group-select" disableOptionCentering>
                    <mat-select-trigger> {{ formatSelectedGroups(i) }}</mat-select-trigger>
                    <mat-option
                      *ngFor="let group of groups"
                      [value]="group._id"
                      [disabled]="group?._teacher?.length === 3"
                    >
                      {{ group.name }} - {{ group.school_year | slice: 2 }}
                      <ng-container *ngIf="group?._teacher.length > 0">
                        <div class="group-select_teachers">
                          <ng-container *ngFor="let teacher of group?._teacher">
                            <div class="teacher_with_icon teacher_with_icon--gray option">
                              <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                              <p class="paragraph paragraph--s">{{ teacher.firstname }} {{ teacher.lastname }}</p>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="group?._teacher.length < 1">
                        <div>-</div>
                      </ng-container>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <button
              alt="Löschen"
              class="delete-button"
              mat-fab
              (click)="removeTeachers(i)"
              [disabled]="totalTeachers === 1"
            >
              <mat-icon svgIcon="streamline_outlined:delete-outline"></mat-icon>
            </button>
          </div>
        </ng-container>
        <!--  <ng-container *ngIf="isPhone">
        <mat-form-field>
          <input
            matInput
            formControlName="phone"
            type="tel"
            placeholder="{{ translatedText?.admin_teacher_create_tel }}"
          />
          <mat-icon svgIcon="streamline_outlined:hint"></mat-icon>
          <mat-error *ngIf="rForm.get('phone').hasError('required')">
            {{ translatedText?.admin_teacher_create_tel_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('phone').hasError('pattern')">
            {{ translatedText?.admin_teacher_create_tel_error_valid }}
          </mat-error>
        </mat-form-field>
      </ng-container> -->

        <div class="email-verification-section" *ngIf="sameSchoolWarnings[i]">
          <div class="flex-box">
            <mat-icon svgIcon="streamline_outlined:warning-triagle"></mat-icon>
            <p class="paragraph paragraph--bold paragraph--m">
              {{ translatedText?.admin_teacher_create_email_exists_err_title }}
            </p>
          </div>
          <p class="paragraph paragraph--s">
            {{ translatedText?.admin_teacher_create_email_exists_err_message }}
          </p>
        </div>

        <div class="email-verification-section" *ngIf="emailExistsWarnings[i]">
          <ng-container *ngIf="!environment.whitelabel?.disable_school_merge">
            <div class="flex-box">
              <mat-icon svgIcon="streamline_outlined:warning-triagle"></mat-icon>
              <p class="paragraph paragraph--bold paragraph--m">
                {{ translatedText?.admin_teacher_create_email_exists_headline }}
              </p>
            </div>
            <p class="paragraph paragraph--s">
              {{ translatedText?.admin_teacher_create_email_exists_text }}
            </p>
          </ng-container>

          <ng-container *ngIf="environment.whitelabel?.disable_school_merge">
            <div class="flex-box">
              <mat-icon svgIcon="streamline_outlined:warning-triagle"></mat-icon>
              <p class="paragraph paragraph--bold paragraph--m">
                {{ translatedText?.admin_teacher_create_email_exists_err_title }}
              </p>
            </div>
            <p class="paragraph paragraph--s">
              {{ translatedText?.admin_teacher_create_email_exists_err_message }}
            </p>
          </ng-container>
        </div>

        <div class="email-verification-section" *ngIf="invitationExistsWarnings[i]">
          <div class="flex-box">
            <mat-icon svgIcon="streamline_outlined:warning-triagle"></mat-icon>
            <p class="paragraph paragraph--bold paragraph--m">
              {{ translatedText?.admin_teacher_create_email_invitation_headline }}
            </p>
          </div>
          <p class="paragraph paragraph--s">
            {{ translatedText?.admin_teacher_create_email_invitation_text }}
          </p>
        </div>

        <div class="email-verification-section" *ngIf="isNotTrialWarnings[i]">
          <div class="flex-box">
            <mat-icon svgIcon="streamline_outlined:warning-triagle"></mat-icon>
            <p class="paragraph paragraph--bold paragraph--m">
              {{ translatedText?.admin_teacher_not_trial_user_headline }}
            </p>
          </div>
          <p class="paragraph paragraph--s">
            {{ translatedText?.admin_teacher_not_trial_user_text }}
          </p>
        </div>
      </ng-container>
    </form>
  </div>
</section>
