<div class="classes-year-update">
  <div class="notification-box">
    <div class="row align-items-center">
      <div class="col-md-9">
        <div class="classes-info-section">
          <mat-icon svgIcon="streamline_outlined:trial_progress"></mat-icon>

          <div class="heading">
            <span>{{ classesUpdateHeading }}</span>
          </div>
        </div>
        <p class="paragraph paragraph--s mt-1">
          <span>{{ classesUpdateDescription }}</span>
        </p>
      </div>
      <div class="col-md-3">
        <button
          alt="Update"
          mat-flat-button
          color="primary"
          type="button"
          class="w-90"
          (click)="newSchoolYearOverlay()"
        >
          {{ updateButton }}
        </button>
      </div>
    </div>
  </div>
</div>
