<div class="task-error-overlay_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <!-- <img src="../../../assets/sad-smiley.png" alt=""> -->
  <!-- <h3 class="h3 h3--bold">Es ist ein Fehler aufgetreten</h3> -->
  <h3 class="h3 h3--bold">{{ translatedText.task_error_overlay_title }}</h3>
  <p class="paragraph paragraph--xl">
    {{ translatedText.task_error_overlay_desc }}
  </p>
  <div class="view_button">
    <button alt="ok" mat-flat-button color="primary-student" type="button" [mat-dialog-close]="'close'">
      {{ translatedText.task_error_overlay_close }}
    </button>
  </div>
</div>
