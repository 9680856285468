<div class="container-fluid static_container">
  <div class="row">
    <div class="col-md-12">
      <div class="static_wrap">
        <div class="static_content">
          <h1 class="h1 static_header">{{ translatedText.privacy_policy_main_title }}</h1>
          <p class="italic">
            {{ translatedText.privacy_policy_main__paragraph_1 }}
          </p>
          <br />
          <p>
            {{ translatedText.privacy_policy_main__paragraph_2 }}
          </p>
          <h2>{{ translatedText.privacy_policy_section_1_title }}</h2>
          <p>
            {{ translatedText.privacy_policy_section_1_paragraph }}
          </p>

          <h2>{{ translatedText.privacy_policy_section_2_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_2_subtitle }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_2_paragraph }}
            <br /><br />
            Digi Sapiens – Digital Learning GmbH<br />
            Opernplatz 14<br />
            60313 Frankfurt am Main
            <br /><br />
          </p>

          <h3>{{ translatedText.privacy_policy_section_2_subtitle_2 }}</h3>
          <p>{{ translatedText.privacy_policy_section_2_email }}<br /><br /></p>

          <h2>{{ translatedText.privacy_policy_section_3_title }}</h2>
          <p>
            {{ translatedText.privacy_policy_section_3_paragraph_1 }}
          </p>
          <br />
          <p>
            {{ translatedText.privacy_policy_section_3_paragraph_2 }}
          </p>

          <h2>{{ translatedText.privacy_policy_section_4_title }}</h2>
          <p>
            {{ translatedText.privacy_policy_section_4_paragraph_1 }}
            <br /><br />
            {{ translatedText.privacy_policy_section_4_list_title }}
          </p>
          <ul>
            <li>{{ translatedText.privacy_policy_section_4_list_1 }}</li>
            <li>{{ translatedText.privacy_policy_section_4_list_2 }}</li>
            <li>{{ translatedText.privacy_policy_section_4_list_3 }}</li>
            <li>{{ translatedText.privacy_policy_section_4_list_4 }}</li>
          </ul>
          <p>
            {{ translatedText.privacy_policy_section_4_paragraph_2 }}
            <br /><br />
          </p>
          <p [innerHTML]="translatedText.privacy_policy_section_4_paragraph_3"></p>

          <h2>{{ translatedText.privacy_policy_section_5_title }}</h2>
          <p>
            {{ translatedText.privacy_policy_section_5_paragraph_1 }}
            <br /><br />
            {{ translatedText.privacy_policy_section_5_paragraph_2 }}
          </p>

          <h2>{{ translatedText.privacy_policy_section_6_title }}</h2>
          <p>
            {{ translatedText.privacy_policy_section_6_list_title }}
          </p>
          <ul>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_1_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_1 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_2_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_2 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_3_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_3 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_4_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_4 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_5_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_5 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_6_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_6 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_7_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_7 }}
            </li>
            <li>
              <strong> {{ translatedText.privacy_policy_section_6_list_8_strong }}</strong>
              {{ translatedText.privacy_policy_section_6_list_8 }}
            </li>
          </ul>

          <h2>{{ translatedText.privacy_policy_section_7_title }}</h2>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_1 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_7_subtitle_1 }}</h3>
          <h3>{{ translatedText.privacy_policy_section_7_subtitle_2 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_7_list_title }}
          </p>
          <ul>
            <li>{{ translatedText.privacy_policy_section_7_list_1 }}</li>
            <li>{{ translatedText.privacy_policy_section_7_list_2 }}</li>
            <li>{{ translatedText.privacy_policy_section_7_list_3 }}</li>
            <li>{{ translatedText.privacy_policy_section_7_list_4 }}</li>
            <li>{{ translatedText.privacy_policy_section_7_list_5 }}</li>
          </ul>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_2 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_7_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_3 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_7_subtitle_4 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_4 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_7_subtitle_5 }}</h3>
          <h3>{{ translatedText.privacy_policy_section_7_subtitle_6 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_6 }}
            <br /><br />
            {{ translatedText.privacy_policy_section_7_paragraph_6_1 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_7_subtitle_7 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_7 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_7_subtitle_8 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_7_paragraph_8 }}
            <br /><br />
            {{ translatedText.privacy_policy_section_7_paragraph_8_1 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_8_title }}</h3>
          <h3>{{ translatedText.privacy_policy_section_8_subtitle_1 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_1 }}
          </p>
          <ul>
            <li>{{ translatedText.privacy_policy_section_8_list_1 }}</li>
            <li>{{ translatedText.privacy_policy_section_8_list_2 }}</li>
            <li>{{ translatedText.privacy_policy_section_8_list_3 }}</li>
          </ul>

          <h3>{{ translatedText.privacy_policy_section_8_subtitle_2 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_2 }}
            <br /><br />
            {{ translatedText.privacy_policy_section_8_paragraph_2_1 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_8_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_3 }}
            <br /><br />
            {{ translatedText.privacy_policy_section_8_paragraph_3_1 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_8_subtitle_4 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_4 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_8_subtitle_5 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_5 }} <br /><br />
            {{ translatedText.privacy_policy_section_8_paragraph_5_1 }}
          </p>

          <h3>{{ translatedText.privacy_policy_section_8_subtitle_6 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_6 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_6_1 }} <br />
          </p>
          <h3>{{ translatedText.privacy_policy_section_8_subtitle_7 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_7_1 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_7_2 }}<br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_7_3 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_7_4 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_7_5 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_8_subtitle_8 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_8_1 }}
            <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_8_2 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_8_subtitle_9 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_8_paragraph_9_1 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_2 }} <br />

            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_3 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_4 }}
            <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" target="_blank"
              >https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.</a
            >
            <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_5 }}
            <a href="https://about.linkedin.com" target="_blank">https://about.linkedin.com.</a> <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_6 }}
            <a href="https://www.linkedin.com/legal/privacy-policy" target="_blank"
              >https://www.linkedin.com/legal/privacy-policy.</a
            >
            <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_7
            }}<a
              href="https://de.linkedin.com/legal/cookie-policy?trk=homepage-basic_footer-cookie-policy"
              target="_blank"
            >
              https://de.linkedin.com/legal/cookie-policy?trk=homepage-basic_footer-cookie-policy.</a
            >
            <br />
            <br />
            {{ translatedText.privacy_policy_section_8_paragraph_9_8 }}
            <a href="https://www.linkedin.com/legal/l/dpa" target="_blank">https://www.linkedin.com/legal/l/dpa</a>
            {{ translatedText.privacy_policy_and }}
            <a href="https://www.linkedin.com/legal/l/eu-sccs" target="_blank"
              >https://www.linkedin.com/legal/l/eu-sccs.</a
            >
          </p>
          <h2>{{ translatedText.privacy_policy_section_9_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_9_subtitle }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_9_paragraph_1 }} "http://" ein "https://"
            {{ translatedText.privacy_policy_section_9_paragraph_2 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_9_paragraph_3 }}
          </p>
          <h2>{{ translatedText.privacy_policy_section_10_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_10_subtitle_1 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_10_paragraph_1 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_10_subtitle_2 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_10_paragraph_2 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_10_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_10_paragraph_3 }}
            <a href="https://www.cookiebot.com/de/privacy-policy/" target="_blank"
              >https://www.cookiebot.com/de/privacy-policy/.</a
            >
          </p>
          <h2>{{ translatedText.privacy_policy_section_11_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_11_subtitle_1 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_11_paragraph_1 }}<br />
            <br />
            {{ translatedText.privacy_policy_section_11_paragraph_1_2 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_11_subtitle_2 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_11_paragraph_2 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_11_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_11_paragraph_3 }}
            <a href="https://www.cookiebot.com/de/privacy-policy/" target="_blank">
              https://www.cookiebot.com/de/privacy-policy/.</a
            >
          </p>

          <h2>{{ translatedText.privacy_policy_section_12_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_12_subtitle_1 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_12_paragraph_1 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_12_paragraph_1_2 }}<br />
            <br />
            {{ translatedText.privacy_policy_section_12_paragraph_1_3 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_12_subtitle_2 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_12_paragraph_2 }}
            <br />
            <br />
            {{ translatedText.privacy_policy_section_12_paragraph_2_1 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_12_paragraph_2_2 }}
            <a href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE" target="_blank">
              https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32021D0914&from=DE
            </a>
            <br />
            <br />
            {{ translatedText.privacy_policy_section_12_paragraph_2_3 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_12_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_12_paragraph_3 }}
            <a href="https://policies.google.com/privacy" target="_blank"> https://policies.google.com/privacy.</a>
          </p>
          <h2>{{ translatedText.privacy_policy_section_13_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_13_subtitle_1 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_13_paragraph_1 }} <br />
            <br />
            {{ translatedText.privacy_policy_section_13_paragraph_1_1 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_13_subtitle_2 }}</h3>
          <p>{{ translatedText.privacy_policy_section_13_paragraph_2 }}<br /></p>
          <h3>{{ translatedText.privacy_policy_section_13_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_13_paragraph_3 }}
            <a href="https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/" target="_blank">
              https://marketingplatform.google.com/about/analytics/tag-manager/use-policy/</a
            >
          </p>

          <h2>{{ translatedText.privacy_policy_section_14_title }}</h2>
          <h3>{{ translatedText.privacy_policy_section_14_subtitle_1 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_14_paragraph_1 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_14_subtitle_2 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_14_paragraph_2 }}
          </p>
          <h3>{{ translatedText.privacy_policy_section_14_subtitle_3 }}</h3>
          <p>
            {{ translatedText.privacy_policy_section_14_paragraph_3 }}
            <a href="https://sentry.io/security/" target="_blank"> https://sentry.io/security/.</a>
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>{{ translatedText.privacy_policy_updated_date }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
