<div class="passwords-warn_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <div class="passwords-warn_wrap">
    <img src="../../../assets/warning-circle.svg" alt="" />
    <h3 class="h3 h3--bold">{{ translatedText.passwords_warn_title }}</h3>
    <div class="button-wrap">
      <button alt="Abbrechen" mat-flat-button color="secondary" [mat-dialog-close]="'close'" type="button">
        {{ translatedText.passwords_warn_close }}
      </button>
      <button alt="Fortfahren" mat-flat-button color="primary" [mat-dialog-close]="'accept'" type="button">
        {{ translatedText.passwords_warn_next }}
      </button>
    </div>
  </div>
</div>
