<form [formGroup]="classForm" class="group-create-form">
  <div class="paragraph paragraph--bold paragraph--l">
    <div class="selected-group-name-details">
      <div>{{ translatedText.admin_group_name_of_class }}</div>

      <div class="selected-group-name">
        <span *ngIf="classForm.get('grade')?.value != ''">{{ classForm.get('grade')?.value }}</span>
        <span class="disable-color" *ngIf="classForm.get('grade')?.value == ''">0</span>
        <span *ngIf="classForm.get('stream')?.value != ''">{{ classForm.get('stream')?.value }}</span>
        <span class="disable-color" *ngIf="classForm.get('stream')?.value == ''">A</span>
        - {{ classForm.get('school_year')?.value | slice: 2 }}
      </div>
    </div>
  </div>
  <div class="row first_step">
    <div class="col-4">
      <mat-label>{{ translatedText.admin_group_create_group_grade }}</mat-label>
      <mat-form-field>
        <mat-select
          formControlName="grade"
          (selectionChange)="gradeSelection($event)"
          type="role"
          disableOptionCentering
          panelClass="grade-select"
          placeholder="{{ translatedText.admin_group_create_select_paceholder }}"
        >
          <mat-option
            [value]="gd"
            *ngFor="let gd of grades"
            [disabled]="isCurrentYearValidationRequired && actualGrade === gd"
            >{{ gd }}</mat-option
          >
        </mat-select>
        <mat-error *ngIf="classForm.get('grade').hasError('required')">
          <!-- Please choose a class. -->
          {{ translatedText.admin_group_create_group_grade_error }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-label>{{ translatedText.admin_group_create_group_stream }}</mat-label>
      <mat-form-field class="example-full-width">
        <input
          matInput
          placeholder="{{ translatedText.admin_group_create_group_stream }}"
          aria-label="Klassenzug"
          [matAutocomplete]="auto"
          formControlName="stream"
          required
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let item of filteredItems | async" [value]="item">
            <span>{{ item }}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="classForm.get('stream').hasError('required')">
          <!-- Please enter a stream.  -->
          {{ translatedText.admin_group_create_group_stream_error }}
        </mat-error>
        <mat-error *ngIf="classForm.get('stream').hasError('onlyLetters')">
          <!-- Please enter a stream.  -->
          {{ translatedText.admin_group_create_noDigits_error }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-label>{{ translatedText.admin_group_create_group_school_year }}</mat-label>
      <mat-form-field>
        <mat-select formControlName="school_year" type="role" disableOptionCentering panelClass="grade-select">
          <mat-option [value]="item.value" *ngFor="let item of schoolYears">{{ item.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="classForm.get('school_year').hasError('required')">
          <!-- Please choose a class. -->
          {{ translatedText.admin_group_create_group_school_year_error }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-12">
      <div class="paragraph paragraph--bold paragraph--l">
        <div class="selected-group-name-details">
          <div>{{ translatedText.admin_dashboard_teachers }}</div>
        </div>
        <div>
          <div class="label-wrap">
            <mat-label>{{ translatedText.admin_manage_teacher_selection }}</mat-label>
            <p class="paragraph paragraph--s">{{ translatedText.admin_manage_maximum_teacher }}</p>
          </div>
          <mat-form-field class="group-select">
            <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
            <mat-select
              formControlName="teachers"
              (valueChange)="selectedTeacherValue($event)"
              [max]="3"
              panelClass="admin-group-select teacher-managing-group-select"
              disableOptionCentering
              [multiple]="isMultipleSelection"
              [placeholder]="translatedText.home_text_61"
            >
              <mat-select-trigger>
                <div class="selected-group-container">
                  <ng-container *ngFor="let teacher of getSelectedText(); let i = index">
                    <div class="selected-group teacher_with_icon teacher_with_icon--gray">
                      <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                      <p class="paragraph paragraph--bold paragraph--xs">
                        {{ teacher?.firstname }} {{ teacher?.lastname }}
                      </p>
                      <mat-icon
                        [hidden]="checkForCurrentTeacher(teacher)"
                        svgIcon="streamline_filled:teacher_close_icon"
                        (click)="removeSelectedTeacher(teacher, $event)"
                      ></mat-icon>
                    </div>
                  </ng-container>
                </div>
              </mat-select-trigger>
              <mat-option
                class="teacher-selection"
                *ngFor="let teacher of teachers"
                [value]="teacher?._id"
                [disabled]="
                  checkTeacherArray(teacher?._id, groupTeachers) ||
                  checkGrouplength(groupTeachers) ||
                  (teacher?._id == teacherId && teacher?._user?.role == 'teacher')
                "
              >
                <div class="teacher_options-wrap">
                  <div class="teacher_with_icon teacher_with_icon--gray option">
                    <div class="d-inline-flex">
                      <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                      <p class="paragraph paragraph--s ml-3">
                        {{ teacher?.firstname }} {{ teacher?.lastname }}
                        <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                      </p>
                    </div>
                  </div>
                  <div class="teachers-group">
                    <p class="paragraph paragraph--s" *ngFor="let group of teacher._group">
                      {{ group?.name }} - {{ group?.school_year }}<span>,</span>
                    </p>
                  </div>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-12" *ngIf="isEnableAvatar">
      <div class="avatar-selection">
        <p class="paragraph paragraph--bold paragraph--l">
          {{ translatedText.admin_group_create_avatar_headline }}
        </p>
        <mat-radio-group formControlName="avatar" aria-label="Select an option">
          <mat-radio-button value="animal" [checked]="recommendedAvatar === 'animal'">
            <div class="avatar_desc">
              <p class="paragraph paragraph--bold">
                {{ translatedText.admin_group_create_avatar_animal }}
                <span *ngIf="recommendedAvatar == 'animal'" class="avatar_recommended">{{
                  translatedText.admin_group_create_avatar_recomended
                }}</span>
              </p>
              <p class="paragraph paragraph paragraph--s">
                {{ translatedText.admin_group_create_animal_grades }}
              </p>
            </div>
            <img src="assets/animal_package.svg" alt="" />
          </mat-radio-button>
          <mat-radio-button value="planet" [checked]="recommendedAvatar === 'planet'">
            <div class="avatar_desc">
              <p class="paragraph paragraph--bold">
                {{ translatedText.admin_group_create_avatar_planet }}
                <span *ngIf="recommendedAvatar == 'planet'" class="avatar_recommended">{{
                  translatedText.admin_group_create_avatar_recomended
                }}</span>
              </p>
              <p class="paragraph paragraph paragraph--s">
                {{ translatedText.admin_group_create_planat_grades }}
              </p>
            </div>
            <img src="assets/planet_package.svg" alt="" />
          </mat-radio-button>
        </mat-radio-group>
        <div class="avatar-hint-box">
          <app-hint-box
            [title]="translatedText.admin_group_class_hint_avatar_title"
            [text]="translatedText.admin_group_class_hint_avatar_text"
          ></app-hint-box>
        </div>
      </div>
    </div>
  </div>
</form>
