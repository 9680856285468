<div class="task-completed-overlay_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="task-completed-overlay_container">
    <img src="../../../assets/pink-circle-with-check-mark.svg" alt="" />
    <h3 class="h3 h3--bold">
      {{ translatedText?.task_completed_overlay_title }}
    </h3>
    <p class="paragraph paragraph--xl">
      {{ translatedText?.task_completed_overlay_desc }}
    </p>
    <div class="view_button">
      <button alt="ok" mat-flat-button color="primary-student" type="button" [mat-dialog-close]="'close'">
        {{ translatedText?.task_completed_overlay_close }}
      </button>
    </div>
  </div>
</div>
