import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Optional,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { GroupService } from '../../providers/group.service';
import { StudentService } from '../../providers/student.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { CdkStepper } from '@angular/cdk/stepper';
import { Observable } from 'rxjs';
import { AdminService } from 'src/app/providers/admin.service';
import { DatePipe } from '@angular/common';
import { CsvImportStudentComponent } from 'src/app/csv-import/csv-import-students/csv-import-student.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { MatTable } from '@angular/material/table';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { CancelAddStudentComponent } from 'src/app/helpers/cancel-add-student/cancel-add-student.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface DialogData {
  currentStudentAmount: number;
}
export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Languages {
  name: string;
  code: string;
}

enum STUDENT_IMPORT_MODE {
  NORMAL,
  CSV,
}

@Component({
  selector: 'app-students-add',
  templateUrl: './students-add.component.html',
  styleUrls: [
    './students-add.component.scss',
    '../../admin/admin-group/admin-group-create/admin-group-create.component.scss',
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class StudentsAddComponent implements OnInit {
  IS_APP = environment.isApp;
  isLoading: boolean = false;
  selectedIndex: number;
  studentAmountForm: FormGroup;
  addStudentDataForm: FormGroup;
  howToAddStudents: FormGroup;
  pdfGeneration: FormGroup;
  languages: any = [];
  filteredOptions: Observable<Languages[]>[] = new Array();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  STUDENT_LIMIT: number = 40;
  femaleCount: number = 0;
  maleCount: number = 0;
  diversCount: number = 0;
  groupGrade;
  groupName = [];
  selectGroupType;
  isTeacherFill: boolean = false;
  isAdminFill: boolean = false;
  isClosedDialog: boolean = false;
  checked: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  generatePDF;
  studentsArray = [];
  stud;
  maxBirthday;
  licenseStep;
  lastStep;
  blobUrl = null;
  groupId;
  teachers;
  teacherIds;
  studentsAmount: number;
  groupYear;
  translatedText;
  recommendedAvatar = 'animal';
  licensesToSchool: any = [];
  licensesToSchoolLoading: boolean = true;
  hasUnlimitedDiagnosticLicense: boolean = false;
  totalCountDiagnostic: number = 0;
  totalCountTraining: number = 0;
  totalCountLeftDiagnostic: number = 0;
  totalCountLeftTraining: number = 0;
  totalCountLeftDiagnosticExp: number = 0;
  totalCountLeftTrainingExp: number = 0;
  totalStudents: any = 0;
  displayedColumns: string[] = ['fullname', 'diLicense', 'foLicense'];
  dataSource = [];
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChildren('licenseCheckbox') licensecheckbox: Array<MatCheckbox>;
  fullname;
  selectedAll: any;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  diLicenseLeft;
  foLicenseLeft;
  isFormInvalid: boolean = true;
  avatar: string = 'animal';
  clickedDownload: boolean = false;
  selectedAddStudentMode: string = 'new';
  Object = Object;
  studentsArrangedByGroup: Object;
  studentCreationMode: typeof STUDENT_IMPORT_MODE = STUDENT_IMPORT_MODE;
  selectedStudentCreationMode: any;
  enableCSVUpload: boolean = environment.whitelabel.enable_csv_learns_upload;
  templateDownloadMessage;
  currentSystemLanguage: string = 'en';
  csvLanguageCode: string = 'en';
  csvRecords: any[] = [];
  csvHeader: boolean = true;
  isCSVError: boolean = false;
  csvErrorMessage: string | null = null;
  csvFileName: string | null = null;
  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef;
  currentYear: string = '';
  currentStep: number = 1;
  environment = environment;

  constructor(
    private datePipe: DatePipe,
    private _formBuilder: FormBuilder,
    private authService: AuthService,
    private groupService: GroupService,
    private studentService: StudentService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private adminService: AdminService,
    private capacitorService: CapacitorService,
    private translationService: TranslationService,
    private sanitizer: DomSanitizer,
    private ngxCsvParser: NgxCsvParser,

    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    if (injectedData) {
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
      }
      if (typeof injectedData['groupYear'] !== 'undefined') {
        this.groupYear = this.injectedData['groupYear'];
      }
      if (typeof injectedData['teachers'] !== 'undefined') {
        this.teachers = this.injectedData['teachers'];
      }
      if (
        typeof injectedData['hasUnlimitedDiagnosticLicense'] !== 'undefined'
      ) {
        this.hasUnlimitedDiagnosticLicense =
          this.injectedData['hasUnlimitedDiagnosticLicense'];
      }
      if (typeof injectedData['totalCountDiagnostic'] !== 'undefined') {
        this.totalCountDiagnostic = this.injectedData['totalCountDiagnostic'];
      }
      if (typeof injectedData['totalCountLeftDiagnostic'] !== 'undefined') {
        this.totalCountLeftDiagnostic =
          this.injectedData['totalCountLeftDiagnostic'];
      }
      if (typeof injectedData['totalCountTraining'] !== 'undefined') {
        this.totalCountLeftTraining = this.injectedData['totalCountTraining'];
      }
      if (typeof injectedData['totalCountLeftTraining'] !== 'undefined') {
        this.totalCountLeftTraining =
          this.injectedData['totalCountLeftTraining'];
      }
      if (typeof injectedData['totalCountLeftDiagnosticExp'] !== 'undefined') {
        this.totalCountLeftDiagnosticExp =
          this.injectedData['totalCountLeftDiagnosticExp'];
      }
      if (typeof injectedData['totalCountLeftTrainingExp'] !== 'undefined') {
        this.totalCountLeftTrainingExp =
          this.injectedData['totalCountLeftTrainingExp'];
      }
      if (typeof injectedData['currentStudentAmount'] !== 'undefined') {
        this.studentsAmount = this.injectedData['currentStudentAmount'];
      }
      if (typeof injectedData['currentYear'] !== 'undefined') {
        this.currentYear = this.injectedData['currentYear'];
      }
      if (typeof injectedData['currentYear'] !== 'undefined') {
        this.currentYear = this.injectedData['currentYear'];
      }
    }
    this.initForms();
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        const templateFileName =
          this.currentSystemLanguage.trim() === 'en'
            ? 'assets/file/students_template.csv'
            : 'assets/file/profildaten_lernende.csv';
        this.templateDownloadMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.translatedText.t('admin_group_class_add_student_download_desc', {
            csvtemplate: `<a href="${templateFileName}" target="_blank">${this.translatedText.admin_group_class_add_student_download_link}</a>`,
          }),
        );
      });
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]);

    this.teacherIds = this.teachers.map((item) => item._id);

    this.studentAmountForm = this._formBuilder.group({
      studentAmount: [0, Validators.required],
      avatar: ['', Validators.required],
    });

    this.studentService
      .getLanguages()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.languages = res;
        for (const la of this.languages) {
          la.translate =
            this.translatedText[
              this.languages.filter((obj) => obj.code == la.code)[0]?.code
            ];
        }
      });

    this.trialStatus = this.authService.getUserTrialStatus();
    if (this.trialStatus.isActive) {
      this.STUDENT_LIMIT = environment.whitelabel.trial_number_students;
    }
    this.adminService.$addClassFormStatus.subscribe((data: any) => {
      if (data) {
        this.isFormInvalid = data.status.trim() === 'INVALID' ? true : false;
      }
    });
    this.adminService.$addClassFormStatus.subscribe((data: any) => {
      if (data && data.form === 'studentFormStep') {
        this.addStudentDataForm = data.formData;
      }
    });

    if (this.groupGrade >= 5) {
      this.avatar = 'planet';
    }
    //if (this.trialStatus.isActive) {
    this.selectedAddStudentMode = 'new';
    this.selectHowtoAddStudents('new');
    // }
  }

  initForms() {
    this.howToAddStudents = this._formBuilder.group({
      form: [this.selectedAddStudentMode, Validators.required],
    });
    this.studentAmountForm = this._formBuilder.group({
      studentAmount: [0, Validators.required],
      avatar: ['', Validators.required],
    });

    this.addStudentDataForm = this._formBuilder.group({
      student: this._formBuilder.array([]),
    });

    this.pdfGeneration = this._formBuilder.group({
      pdfGeneration: [this.blobUrl, Validators.required],
    });
  }

  increaseValue() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    const maxStudentsDuringTrialPhase = this.STUDENT_LIMIT;
    if (
      this.trialStatus.isActive &&
      this.studentsAmount + studentAmount + 1 > maxStudentsDuringTrialPhase
    ) {
      this.openUpgradeModal();
      return;
    }
    this.studentAmountForm.get('studentAmount').setValue(studentAmount + 1);
  }

  decreaseValue() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    if (studentAmount > 0) {
      this.studentAmountForm.get('studentAmount').setValue(studentAmount - 1);
    }
  }

  onStudentAmountInput() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    const maxStudentsDuringTrialPhase = this.STUDENT_LIMIT;
    if (
      this.trialStatus.isActive &&
      this.studentsAmount + studentAmount > maxStudentsDuringTrialPhase
    ) {
      this.openUpgradeModal();
      this.studentAmountForm
        .get('studentAmount')
        .setValue(maxStudentsDuringTrialPhase - this.studentsAmount);
    }
  }

  displayEnoughLicensesNote() {
    let currentAmount = this.studentAmountForm.get('studentAmount').value;
    return currentAmount > this.totalCountLeftDiagnostic &&
      currentAmount > this.totalCountLeftTraining
      ? true
      : false;
  }

  getMaxStudentCount() {
    let currentStudentAmount = this.studentsAmount ? this.studentsAmount : 0;
    return this.STUDENT_LIMIT - currentStudentAmount;
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code == code)[0]?.code
        ]
      : '';
  }

  prepareNextStep() {
    this.totalStudents = this.studentAmountForm.get('studentAmount').value;
  }

  clearStudentsSelected() {
    this.studentAmountForm.get('studentAmount').setValue(this.totalStudents);
  }

  finishedSecondStep() {
    const studentsData = this.addStudentDataForm?.get('group').value;
    for (let i = 0; i < studentsData.length; i++) {
      this.dataSource[i] = {
        fullname: studentsData[i].name,
        diLicense: '',
        foLicense: '',
      };
      this.fullname = studentsData[i].name;
    }
    this.foLicenseLeft = this.totalCountLeftTraining;
    this.diLicenseLeft = this.totalCountLeftDiagnostic;
  }

  genderSelection() {
    setTimeout(() => {
      this.maleCount = this.addStudentDataForm
        .get('group')
        .value.filter((item) => item.gender == 'male').length;
      this.femaleCount = this.addStudentDataForm
        .get('group')
        .value.filter((item) => item.gender == 'female').length;
      this.diversCount = this.addStudentDataForm
        .get('group')
        .value.filter((item) => item.gender == 'divers').length;
    }, 0);
  }

  updateTotalTrainingLicense(event) {
    if (event.target.checked) {
      this.foLicenseLeft--;
    } else {
      this.foLicenseLeft++;
    }
  }

  updateTotalDiagnosticLicense(event) {
    if (event.target.checked) {
      this.diLicenseLeft--;
    } else {
      this.foLicenseLeft++;
    }
  }

  downloadPDF() {
    if (!this.IS_APP) {
      let pdfFilename = this.groupService.generateGroupFilenameDate(
        this.groupName,
      );
      var element = document.createElement('a');
      element.setAttribute('href', this.blobUrl);
      element.setAttribute('download', pdfFilename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    this.clickedDownload = true;
    //this.dialog.closeAll();
  }

  addExistingStudentsToClass() {
    this.studentsArray.forEach((student) => {
      let groupData = {
        groupId: this.groupId,
        studentId: student._id,
      };

      this.isLoading = true;
      this.groupService
        .addStudentToGroup(groupData)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (response: any) => {
            if (response) {
              this.isLoading = false;
              let message: string;
              if (this.studentsArray.length > 1) {
                message =
                  this.translatedText.students_add_existing_success_plural;
              } else {
                message =
                  this.translatedText.students_add_existing_success_singular;
              }

              this.snackBar.openFromComponent(SuccessSnackbarComponent, {
                panelClass: 'snack-success',
                data: message,
                duration: 3000,
                horizontalPosition: 'left',
              });
            }
          },
          (error: any) => {
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              panelClass: 'snack-error',
              data: this.translatedText?.technical_error,
              duration: 3000,
              horizontalPosition: 'left',
            });
          },
        );
    });
    this.dialog.closeAll();
  }

  addNewStudentsToClass() {
    let groupData = {
      groupId: this.groupId,
      students: this.addStudentDataForm.get('group')?.value,
      pdfPrintLayout: '1',
      genaratePDF: true,
      teacher: this.teacherIds,
      translatedText: this.translatedText,
      namesPackageType: this.avatar,
    };
    this.isLoading = true;
    this.groupService
      .addStudentsToGroupAndGeneratePDF(groupData)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (response: any) => {
          if (response) {
            //this.dialog.closeAll();
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            this.blobUrl = blobUrl;
            let pdfFilename = this.groupService.generateGroupFilenameDate(
              this.groupName,
            );
            if (this.IS_APP) {
              this.capacitorService.saveBlobToFilesystem(blob, pdfFilename);
            }
            /* this.groupService.openPdfOverlay(
              blobUrl,
              this.groupName,
              'rebuild',
            ); */
          }
          this.downloadPDF();
          this.isLoading = false;
        },
        (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  openCancelDialog() {
    let dialogRef = this.dialog.open(CancelAddStudentComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-delete-overlay-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {},
    });
    dialogRef.afterClosed().subscribe((res) => {
      //console.log(res);
    });
  }

  formatDate(inputDate: string): string {
    const parts = inputDate.split('/');
    if (parts.length !== 2) {
      return '';
    }
    const year = parts[1];
    const month = parts[0];
    const day = '01';
    const formattedDate = `${year}-${month}-${day}`;
    const parsedDate = new Date(formattedDate);

    if (isNaN(parsedDate.getTime())) {
      return '';
    }
    const formattedOutput = this.datePipe.transform(
      parsedDate,
      "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ (zzzz)",
      'en-US',
    );
    return formattedOutput || '';
  }

  openStudentsCsvImport(): void {
    let dialogRef = this.dialog.open(CsvImportStudentComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'csv-import-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'csv-import-panel-backdrop',
      data: {
        students: this.addStudentDataForm.get('group').value.length,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        //console.log(res);
        if (res?.length > 0) {
          if (
            res.length === this.addStudentDataForm.get('group').value.length
          ) {
            // Durchlaufen Sie beide Arrays und setzen Sie die Werte mit setValue
            for (let i = 0; i < res.length; i++) {
              this.addStudentDataForm
                .get('group')
                .get(i.toString())
                .setValue({
                  name: res[i].name,
                  gender: res[i].gender,
                  language: res[i].language,
                  age: moment(this.formatDate(res[i].age)),
                });
            }
            this.genderSelection();
          } else {
            //console.error("Die Längen der Arrays stimmen nicht überein.");
          }
        }
      });
  }

  selectHowtoAddStudents(mode: string) {
    this.selectedAddStudentMode = mode;
    this.howToAddStudents.get('form').setValue(this.selectedAddStudentMode);
    if (mode === 'new') {
      //preselect manual input of students
      this.selectedStudentCreationMode = this.studentCreationMode.NORMAL;
    } else {
      this.selectedStudentCreationMode = null;
    }
    this.adminService.updateClassFormStatus({
      form: 'studentCreationMode',
      status: 'VALID',
    });
  }

  getSelectedStudentsList(event: any) {
    if (event.students) {
      this.studentsArray = event.students;
    }
    this.confirmAddExistingStudent();
  }

  // creates an array to render student separated by class
  confirmAddExistingStudent() {
    this.studentsArrangedByGroup = [];
    this.studentsArray.forEach((student) => {
      student._group.forEach((group) => {
        if (
          group.school_year === this.currentYear &&
          !(student._id in this.studentsArrangedByGroup)
        ) {
          if (!(group._id in this.studentsArrangedByGroup)) {
            this.studentsArrangedByGroup[group._id] = {
              groupName:
                group.grade +
                (group?.stream !== undefined ? group?.stream : ''),
              schoolYear: student.groupSchoolYear,
              students: [student],
            };
          } else {
            this.studentsArrangedByGroup[group._id]['students'].push(student);
          }
        }
      });
    });

    this.isFormInvalid = false;
  }

  openUpgradeModal() {
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: 'maxStudentsReached' },
      },
    );
  }

  chooseStudentCreationMode(type: any) {
    this.studentAmountForm.patchValue({ studentAmount: 0 });
    this.selectedStudentCreationMode = type;
    this.adminService.updateClassFormStatus({
      form: 'studentCreationMode',
      status: 'VALID',
    });

    if (this.selectedStudentCreationMode === 0) {
      this.studentAmountForm.get('studentAmount').setValue(1);
      this.csvRecords = [];
    } else {
      this.studentAmountForm.get('studentAmount').setValue(0);
    }
  }

  getCSVStudentData(csvData) {
    if (!this.isCSVError && csvData && this.studentCreationMode.CSV) {
      this.csvRecords = csvData;
    } else {
      return;
    }
  }

  eraseStudentData() {
    this.csvRecords = [];
    this.studentAmountForm.get('studentAmount').setValue(0);
  }
}

//UNUSED AND/OR REPEATED FROM CREATE LEARNERS COMPONENT

/*  setLanguages(index) {
    this.filteredOptions[index] = (
      (this.addStudentDataForm.get('group') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
            ? this.languages?.slice()
            : [];
        }),
      );
  } */

/* setLanguageOption(value, index) {
    ((this.addStudentDataForm.get('group') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }
 */

/* setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>,
    index,
  ) {
    const element = this.addStudentDataForm.get('group');
    if (element != undefined) {
      const ctrlValue = element.value[index].age;
      ctrlValue.month(normalizedMonthAndYear.month());
      ctrlValue.year(normalizedMonthAndYear.year());
      (
        (this.addStudentDataForm.get('group') as FormArray).at(
          index,
        ) as FormGroup
      )
        .get('age')
        .patchValue(ctrlValue);
      datepicker.close();
    }
  }

  createClassArray(): FormGroup {
    let schoolClassAge = parseInt(this.groupGrade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );
    return this._formBuilder.group({
      name: ['', Validators.required],
      gender: ['', Validators.required],
      language: [
        '',
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: [moment(birthyearEstimation), Validators.required],
    });
  } */

/* Private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter((option) =>
      option.name.toLowerCase().startsWith(filterValue),
    );
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (this.languages && this.languages.find((obj) => obj.code == selection)) {
      return null;
    }
    return { incorrect: true };
  } */

/*  displayFn(code) {
    return this.getLanguageByCode(code);
  } */

/*  allFilled(i: number) {
    let arrayControl = this.addStudentDataForm.get('group') as FormArray;
    let status = arrayControl.at(i).valid;
    if (status) {
      return 'active';
    }
  } */

/* setValidation() {
    let schoolClassAge = parseInt(this.groupGrade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );

    const formArray = this.addStudentDataForm.get('group') as FormArray;
    let count = this.studentAmountForm.get('studentAmount').value;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      // Entfernen Sie die Validierungen für jeden Formularwert
      formGroup.get('gender').setValidators([Validators.required]);
      formGroup
        .get('language')
        .setValidators([
          Validators.required,
          this.validatorRequireMatch.bind(this),
        ]);
      formGroup.get('age').setValidators([Validators.required]);
      formGroup.get('age').setValue(moment(birthyearEstimation));

      // Aktualisieren Sie die Validierungszustände - TODO not used. remove?
      formGroup.get('gender').updateValueAndValidity();
      formGroup.get('language').updateValueAndValidity();
      formGroup.get('age').updateValueAndValidity();
    }
  } */

/* removeValidations() {
    const formArray = this.addStudentDataForm.get('group') as FormArray;
    let count = this.studentAmountForm.get('studentAmount').value;
    for (let index = 0; index < count; index++) {
      const formGroup = formArray.at(index) as FormGroup;
      // Entfernen Sie die Validierungen für jeden Formularwert
      if (formGroup != undefined) {
        formGroup.get('gender').clearValidators();
        formGroup.get('language').clearValidators();
        formGroup.get('age').clearValidators();

        // Aktualisieren Sie die Validierungszustände - TODO not used. remove?
        formGroup.get('gender').updateValueAndValidity();
        formGroup.get('language').updateValueAndValidity();
        formGroup.get('age').updateValueAndValidity();
      }
    }
  } */

/*   getClassFormControls(): AbstractControl[] {
    return (<FormArray>this.addStudentDataForm?.get('group'))?.controls;
  } */
