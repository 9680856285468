<div class="teacher-content-container">
  <app-not-loaded *ngIf="isOffline"></app-not-loaded>
  <app-license-activation-banner></app-license-activation-banner>
  <div style="position: relative">
    <app-classes-update-banner
      *ngIf="isSchoolYearUpdateRequired && updateRequiredGroupCount >= 1"
      [classesUpdateHeading]="translatedText.classes_year_update_heading"
      [classesUpdateDescription]="translatedText.classes_year_update_desc"
      [updateButton]="translatedText.classes_update"
    >
    </app-classes-update-banner>

    <app-trial-banner
      *ngIf="trialStatus.isActive"
      [trialHeadline]="translatedText.license_trial_title"
      [trialText]="translatedText.license_trial_description"
      [expiredHeadline]="translatedText.license_trial_title_expired"
      [expiredText]="translatedText.license_trial_description_expired"
      [trialStatus]="trialStatus"
      [showExpirationDate]="true"
      [showButtons]="true"
    ></app-trial-banner>

    <h3 class="h3 h3--bold">{{ translatedText.admin_dashboard_headline }}</h3>
    <div class="dashboard_overview_container" *ngIf="!trialStatus.isActive">
      <div class="admin-widget">
        <div class="admin-widget_header-wrap">
          <div
            class="admin-widget_header with-border"
            [ngClass]="{ 'admin-widget-loading-bg': isLoading, 'teacher-margin': userType === 'teacher' }"
          >
            <!-- skeleton loader code -->
            <div class="skeleton-widget-wrap" *ngIf="isLoadingSchoolData">
              <div class="skeleton-loader bar long"></div>
              <div class="skeleton-loader bar short"></div>
            </div>
            <div *ngIf="!isLoadingSchoolData">
              <p
                class="paragraph paragraph--s paragraph--bold"
                matTooltip="{{ schoolData['name'] }}"
                [matTooltipPosition]="'after'"
              >
                {{ schoolData['name'] }}
              </p>
              <p class="paragraph paragraph--s">
                {{ schoolData['address'] }}<br />
                {{ schoolData['postalCode'] }} {{ schoolData['city'] }} <br />
              </p>
            </div>
            <div [hidden]="isLoading" class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:school-building"></mat-icon>
            </div>
          </div>

          <div
            class="admin-widget_header"
            [ngClass]="{ 'admin-widget-loading-bg': isLoading, 'teacher-margin': userType === 'teacher' }"
          >
            <!-- skeleton loader code -->
            <div class="skeleton-widget-wrap" *ngIf="isLoading">
              <div class="skeleton-loader bar short"></div>
              <div class="skeleton-loader bar long"></div>
            </div>
            <div *ngIf="!isLoading">
              <h2 class="h2 h2--bold" [countUp]="teacherCount">
                {{ teacherCount }}
              </h2>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_dashboard_teachers }}
              </p>
            </div>
            <div [hidden]="isLoading" class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:user-star"></mat-icon>
            </div>
          </div>
        </div>
        <div class="admin-widget_link" *ngIf="userType === 'school_admin'">
          <a class="links links--bold" [routerLink]="['/admin-teacher-list']">
            {{ translatedText.admin_dashboard_show_all_teachers }}
          </a>
        </div>
      </div>
      <div class="admin-widget">
        <div class="admin-widget_header-wrap">
          <div
            class="admin-widget_header with-border"
            [ngClass]="{ 'admin-widget-loading-bg': isLoading, 'teacher-margin': userType === 'teacher' }"
          >
            <!-- skeleton loader code -->
            <div class="skeleton-widget-wrap" *ngIf="isLoading">
              <div class="skeleton-loader bar short"></div>
              <div class="skeleton-loader bar long"></div>
            </div>
            <div *ngIf="!isLoading">
              <h2 class="h2 h2--bold" [countUp]="groupCount">
                {{ groupCount }}
              </h2>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_dashboard_groups }}
              </p>
            </div>
            <div [hidden]="isLoading" class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
            </div>
          </div>
          <div
            class="admin-widget_header"
            [ngClass]="{ 'admin-widget-loading-bg': isLoading, 'teacher-margin': userType === 'teacher' }"
          >
            <!-- skeleton loader code -->
            <div class="skeleton-widget-wrap" *ngIf="isLoading">
              <div class="skeleton-loader bar short"></div>
              <div class="skeleton-loader bar long"></div>
            </div>
            <div *ngIf="!isLoading">
              <h2 class="h2 h2--bold" [countUp]="studentCount">
                {{ studentCount }}
              </h2>
              <p class="paragraph paragraph--s">
                {{ translatedText.admin_dashboard_students }}
              </p>
            </div>
            <div [hidden]="isLoading" class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:student-admin"></mat-icon>
            </div>
          </div>
        </div>

        <div class="admin-widget_link" *ngIf="userType === 'school_admin'">
          <a class="links links--bold" [routerLink]="['/admin-group-list']">
            {{ translatedText.admin_dashboard_show_all_groups }}</a
          >
        </div>
      </div>
    </div>
    <ng-container *ngIf="isSchoolState">
      <app-dashboard-teacher></app-dashboard-teacher>
    </ng-container>
  </div>
</div>
