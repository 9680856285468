import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConfirmDialogComponent } from '../helpers/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CapacitorService } from './capacitor.service';
import { TranslationService } from './translation.service';
import { VersionService } from './version.service';
import { Browser } from '@capacitor/browser';
import * as LiveUpdates from '@capacitor/live-updates';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessSnackbarComponent } from '../helpers/snackbar/success-snackbar/success-snackbar.component';
import { lastValueFrom } from 'rxjs';

// Debug
//import { UserService } from "../providers/user.service";

export interface AppMaintenance {
  maintenance?: {
    enabled: boolean;
  };
  ios?: {
    latest: string;
    minimum: string;
    enabled: boolean;
  };
  android?: {
    latest: string;
    minimum: string;
    enabled: boolean;
  };
}

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  IS_APP = environment.isApp;
  dialogConfig: any;
  deviceInfo: any;
  mandatoryConfig: any;
  isDeclined: boolean = false;
  private maintenanceUrl;
  isMandatory: boolean = false;
  updateRequired: boolean = false;

  constructor(
    private http: HttpClient,
    private capacitorService: CapacitorService,
    private versionService: VersionService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private translationService: TranslationService,
  ) {
    //this.maintenanceUrl = 'https://cdn.dev.dgs.pub/assets/maintenance.json';
    this.maintenanceUrl = environment.maintenanceUrl;
  }

  async checkForMaintenanceAndUpdates() {
    try {
      this.deviceInfo = this.capacitorService.getDeviceInfo();
      const data: AppMaintenance = await this.http
        .get<any>(`${this.maintenanceUrl}?timestamp=${new Date().getTime()}`)
        .toPromise();

      if (!data) {
        console.warn('maintenance.json not found');
        return;
      }

      // close all existing dialogs of specific instance that user is not locked
      this.dialog.openDialogs
        .filter(
          (dialog) =>
            dialog.componentInstance instanceof ConfirmDialogComponent,
        )
        .forEach((dialog) => dialog.close());

      // check maintenance mode
      if (data.maintenance.enabled) {
        this.isMandatory = true;
        this.showMaintenanceAlert(data);
        return;
      }
      // for testing
      // const currentVersion = '0.9.6';
      // this.checkIOSUpdates(data, currentVersion);
      if (!this.IS_APP) return;

      // Set live update channel for appflow
      await LiveUpdates.setConfig({
        channel: environment.liveUpdateChannel,
      });

      const currentVersion = this.deviceInfo.version;
      const platform = this.deviceInfo.platform.toLowerCase();
      // console.log('currentVersion:', currentVersion);
      // console.log('latestVersion:', data.android.latest, platform);
      // check android versions
      if (platform === 'android' && data.android.enabled) {
        this.checkAndroidUpdates(data, currentVersion);
      }
      // check ios versions
      if (platform === 'ios' && data.ios.enabled) {
        this.checkIOSUpdates(data, currentVersion);
      }
      // let currentConfig = await LiveUpdates.getConfig();
      // console.log(currentConfig);
      // {"appId":"758f5dc0","autoUpdateMethod":"none","strategy":"differential","channel":"Development","enabled":true,"maxVersions":2}
      // If current frontend is not the latest version, force appflow update
      const appUpdateRequired = await this.compareAppWithWebVersion();
      // console.log('appUpdateRequired:', appUpdateRequired);
      if (appUpdateRequired) {
        let message = this.translationService.getTranslatedText(
          'maintenance_liveupdate_available',
        );
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: message,
          duration: 3000,
          horizontalPosition: 'center',
        });
        const result = await LiveUpdates.sync();
        // console.log(result);
        // "source":"download","activeApplicationPathChanged":true,"snapshot":{"buildId":"9881708","id":"7549cca2-0397-4529-a2fd-97c0dd839aa6"},"liveUpdate":{"channel":"Development","appId":"758f5dc0"}}
        if (result.activeApplicationPathChanged) {
          message = this.translationService.getTranslatedText(
            'maintenance_liveupdate_finish',
          );
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'center',
          });
          // Reload app after 3 seconds
          setTimeout(async () => {
            await LiveUpdates.reload();
          }, 3000);
        }
      }
    } catch (error) {
      console.error('checkForMaintenanceAndUpdates:', error);
    }
  }

  private showMaintenanceAlert(data: AppMaintenance) {
    this.presentAlert(
      this.translationService.getTranslatedText(
        'maintenance_alert_maintenance_title',
      ),
      this.translationService.getTranslatedText(
        'maintenance_alert_maintenance_text',
      ),
      this.translationService.getTranslatedText(
        'maintenance_alert_maintenance_btn',
      ),
      '',
      false,
      false,
    );
  }

  private async compareAppWithWebVersion() {
    const { local, remote } = await lastValueFrom(
      this.versionService.compareVersions(),
    ); // Wait for the Observable to complete
    // console.log('local version:', local);
    // console.log('remote version:', remote);
    return this.versionService.isUpdateRequired(local, remote); // Return the result
  }

  private checkAndroidUpdates(data: AppMaintenance, currentVersion: string) {
    const { latest, minimum } = data.android;

    if (this.capacitorService.compareOSVersion(currentVersion, minimum, '<')) {
      this.isMandatory = true;
      this.presentAlert(
        this.translationService.getTranslatedText(
          'maintenance_alert_mandatory_title',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_mandatory_text',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_mandatory_btn',
        ),
        environment.androidAppUrl,
        true,
        false,
      );
    } else if (
      this.capacitorService.compareOSVersion(currentVersion, latest, '<')
    ) {
      this.presentAlert(
        this.translationService.getTranslatedText(
          'maintenance_alert_optional_title',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_optional_text',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_optional_btn',
        ),
        environment.androidAppUrl,
        true,
        true,
      );
    }
  }

  private checkIOSUpdates(data: AppMaintenance, currentVersion: string) {
    const { latest, minimum } = data.ios;

    if (this.capacitorService.compareOSVersion(currentVersion, minimum, '<')) {
      this.isMandatory = true;
      this.presentAlert(
        this.translationService.getTranslatedText(
          'maintenance_alert_mandatory_title',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_mandatory_text',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_mandatory_btn',
        ),
        environment.iosAppUrl,
        true,
        false,
      );
    } else if (
      this.capacitorService.compareOSVersion(currentVersion, latest, '<')
    ) {
      this.presentAlert(
        this.translationService.getTranslatedText(
          'maintenance_alert_optional_title',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_optional_text',
        ),
        this.translationService.getTranslatedText(
          'maintenance_alert_optional_btn',
        ),
        environment.iosAppUrl,
        true,
        true,
      );
    }
  }

  async openAppstoreEntry(url: string) {
    if (url.startsWith('itms-beta://')) {
      // Attempt to open TestFlight directly using itms-beta://
      try {
        await Browser.open({ url: 'itms-beta://' });
      } catch (error) {
        console.error(
          'TestFlight app may not be installed. Falling back to App Store...',
        );
        // Fallback to the App Store URL for TestFlight
        await Browser.open({
          url: 'https://apps.apple.com/app/testflight/id899247664',
        });
      }
    } else {
      // just window open since itms-apps:// urls don't work with Capacitor Browser
      window.open(url);
    }
  }

  async presentAlert(
    header,
    message,
    buttontext = '',
    url = '',
    showButton,
    decline,
  ) {
    if (this.isDeclined && !this.isMandatory) {
      return;
    }

    this.mandatoryConfig = new MatDialogConfig();
    this.mandatoryConfig.disableClose = true;
    this.mandatoryConfig.autoFocus = false;
    this.mandatoryConfig.data = {
      title: header,
      note: message,
      accept: showButton,
      acceptIcon: '',
      acceptText: buttontext,
      decline: decline,
      declineIcon: '',
      declineText: this.translationService.getTranslatedText(
        'admin_managing_overlay_cancel',
      ),
    };
    const hideActions = showButton ? '' : 'hide_actions';
    const basePanelClass = showButton
      ? 'update_information_dialog'
      : 'update_information_dialog_' + hideActions;

    this.mandatoryConfig.panelClass = [basePanelClass, 'highest-z-index'];
    this.mandatoryConfig.backdropClass = 'maintenance-backdrop';

    let dialogRef = this.dialog.open(
      ConfirmDialogComponent,
      this.mandatoryConfig,
    );

    dialogRef.afterOpened().subscribe(() => {
      const overlayContainer = document.querySelector(
        '.cdk-global-overlay-wrapper',
      );
      if (overlayContainer) {
        overlayContainer.classList.add('maintenance-wrapper');
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res == 'ok') {
        this.openAppstoreEntry(url);
      } else if (res == 'decline') {
        this.isDeclined = true;
      }
    });
  }

  /* App state listener */
  checkForMaintenanceAndUpdatesListener() {
    this.capacitorService.watchAppStateChange().subscribe((isActive) => {
      if (isActive) {
        this.checkForMaintenanceAndUpdates();
      }
    });
  }
}
