import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { StatusBar } from '@capacitor/status-bar';
import { Router } from '@angular/router';
import compareVersions from 'compare-versions';
import { environment } from 'src/environments/environment';
import { Filesystem, Directory } from '@capacitor/filesystem';
@Injectable({
  providedIn: 'root',
})
export class CapacitorService {
  private behaviorNetworkStatus = new BehaviorSubject<any>({
    connected: true,
    connectionType: 'none',
  });

  private behaviorAppStateChange = new BehaviorSubject<boolean>(true);
  isStatusBarLight = false;
  deviceInfo: any;
  handler = null;
  permissionCamera;
  permissionMicrophone;

  constructor(
    private __zone: NgZone,
    private router: Router,
  ) {}

  // Statusbar
  hideStatusBar() {
    StatusBar.hide();
  }

  showStatusBar() {
    StatusBar.show();
  }

  // Devie info
  async readDeviceInfo() {
    console.log('readDevice start');
    let appInfoPromise = environment.isApp
      ? App.getInfo()
      : Promise.resolve({});
    return Promise.all([appInfoPromise, Device.getInfo()]).then(
      ([appInfo, deviceInfo]) => {
        const data = {
          ...appInfo, // Spread properties from appInfo
          ...deviceInfo, // Spread properties from deviceInfo
        };
        console.log('-----------deviceinfo-----------', data);
        // Add browser info for web
        if (data['platform'] == 'web') {
          data['browser'] = this.getBrowserName();
        }
        this.setDeviceInfo(data);
        return data;
      },
    );
  }

  setDeviceInfo(data) {
    this.deviceInfo = data;
    // Remove unused data
    if (this.deviceInfo.appId != undefined) {
      delete this.deviceInfo.appId;
    }
    if (this.deviceInfo.appName != undefined) {
      delete this.deviceInfo.appName;
    }
    if (this.deviceInfo.diskFree != undefined) {
      delete this.deviceInfo.diskFree;
    }
    if (this.deviceInfo.diskTotal != undefined) {
      delete this.deviceInfo.diskTotal;
    }
    if (this.deviceInfo.isVirtual != undefined) {
      delete this.deviceInfo.isVirtual;
    }
    if (this.deviceInfo.memUsed != undefined) {
      delete this.deviceInfo.memUsed;
    }
    if (this.deviceInfo.uuid != undefined) {
      delete this.deviceInfo.uuid;
    }
    console.log(this.deviceInfo);
  }

  getDeviceInfo() {
    return this.deviceInfo;
  }

  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  getDevicePlatform() {
    this.readDeviceInfo();
    return this.deviceInfo.platform.toLowerCase();
  }

  getDeviceOSVersion() {
    return this.deviceInfo.osVersion;
  }

  compareOSVersion(oldVer, newVer, operator) {
    return compareVersions.compare(oldVer, newVer, operator);
  }

  async openUrl(url, target?) {
    const httpsRegex = /^https:\/\//;
    // Check if the URL starts with https
    if (httpsRegex.test(url)) {
      if (environment.isApp) {
        await Browser.open({ url: url });
      } else {
        await window.open(url, target ? target : '_blank');
      }
    } else {
      // If not https, navigate using the router
      this.router.navigate([url]);
    }
  }

  async openAppStoreAndExit(url) {
    await Browser.open({
      url: url,
    }).then(() => {
      App.exitApp();
    });
  }

  async saveBlobToFilesystem(blob: Blob, filename: string) {
    try {
      const base64Data = await this.blobToBase64(blob);

      // Save the Blob to the Cache directory
      const savedFile = await Filesystem.writeFile({
        path: filename,
        data: base64Data, // Base64 string
        directory: Directory.Documents, // documents directory
      });

      // Get the file URI (file:// path)
      const fileUrl = savedFile.uri; // The URI returned by Filesystem (file://)

      console.log('File saved at:', fileUrl); // Debugging: check the file URL
    } catch (error) {
      console.error('Error saving and opening file:', error);
    }
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result;
        if (typeof result === 'string') {
          resolve(result.split(',')[1]); // Remove the Base64 header
        } else {
          reject(new Error('The result is not a string.'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Convert Blob to Base64 string
    });
  }

  // Network
  startListenToNetwork() {
    this.handler = Network.addListener('networkStatusChange', (status) => {
      //console.log("Network status changed", status);
      this.__zone.run(() => {
        if (status) {
          this.behaviorNetworkStatus.next(status);
        }
      });
    });
  }

  endListenToNetwork() {
    this.handler && this.handler.remove();
  }

  getNetworkStatus() {
    Network.getStatus().then((status) => {
      console.log('Status', status);
      this.__zone.run(() => {
        if (status) {
          this.behaviorNetworkStatus.next(status);
        }
      });
    });
  }

  watchNetWorkStatus() {
    return this.behaviorNetworkStatus;
  }

  /* App url open listener */
  appUrlOpenListener() {
    App.addListener('appUrlOpen', (data: any) => {
      this.__zone.run(() => {
        let slug = data.url.split('.de').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing logic take over
      });
    });
  }

  /* App state listener */
  appStateChange() {
    App.addListener('appStateChange', ({ isActive }) => {
      // state.isActive contains the active state
      //console.log('App state changed. Is active?', isActive);
      this.__zone.run(() => {
        this.behaviorAppStateChange.next(isActive);
      });
    });
  }

  watchAppStateChange() {
    return this.behaviorAppStateChange;
  }
}
