import { Injectable } from '@angular/core';
import {
  Subject,
  BehaviorSubject,
  repeatWhen,
  retryWhen,
  of,
  EMPTY,
  throwError,
} from 'rxjs';
import {
  map,
  concatMap,
  delay,
  mergeMap,
  tap,
  takeWhile,
} from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TaskService } from './task.service';
import { TranslationService } from './translation.service';
import * as CryptoJS from 'crypto-js';

export interface Blob {
  readonly size: number;
  readonly type: string;
  slice(start?: number, end?: number, contentType?: string): Blob;
}
export interface StoredData {
  session: any;
  user_uuid: string;
  user_task_uuid: string;
  contentLanguage: string;
  task: any;
  time_read_in_seconds: number;
  num_images: number;
}

@Injectable({
  providedIn: 'root',
})
export class ReadingJsonService {
  private cloudServerUrl;
  private sessionUuid;
  private sessionTask_uuid;
  private USER_UUID: string;
  private USER_TASK_UUID: string;
  private ACCESS_TOKEN: string;
  loadingStatus = new BehaviorSubject<any>({
    text: '',
    progress: 0,
  });
  currentnLoadingStatus = this.loadingStatus.asObservable();

  constructor(
    private http: HttpClient,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    this.cloudServerUrl = environment.CLOUDAPI_SERVER;
  }

  private generateShortHash(originalString: string): string {
    // Hash the original string using SHA-256
    const hashedString = CryptoJS.SHA256(originalString).toString(
      CryptoJS.enc.Hex,
    );
    // Convert the hexadecimal hash to Base36
    const base36Hash = parseInt(hashedString, 16).toString(36).toUpperCase();
    // Take the first 6 characters
    const shortHash = base36Hash.substring(0, 6);
    return shortHash;
  }

  uploadSession(
    session,
    user_uuid,
    user_task_uuid,
    contentLanguage,
    task,
    time_read,
    num_images,
    localSessionKey,
  ) {
    this.USER_UUID = user_uuid;
    this.USER_TASK_UUID = user_task_uuid;
    const notifier = new Subject();
    // Update Status
    this.loadingStatus.next({
      text: 'Lesesitzung wird erstellt.',
      progress: 10,
    });
    // API CALL init session
    console.log(this.USER_UUID);
    const params = new FormData();
    params.append('user_uuid', this.USER_UUID);
    return this.http
      .post<any>(this.cloudServerUrl + '/auth/token', params)
      .pipe(
        concatMap((data) => {
          console.log('1. Get token by uuid:');
          this.ACCESS_TOKEN = data.access_token;
          return this.http.post<any>(
            this.cloudServerUrl + '/sessions/init/',
            {
              user_uuid: this.USER_UUID,
              user_task_uuid: this.USER_TASK_UUID,
              isbn: task.book_isbn,
              lang_book: contentLanguage,
              lang_audio: environment.language,
              text: {
                isbn: task.book_isbn,
                start: task.range.start,
                end: task.range.end,
                dsef_version: task.dsef_version,
              },
              subgroup_uuid: task.subgroup_uuid,
              num_images: num_images,
              grade: task.grade,
              customer_uuid: environment.readalizer_customer,
            },
            {
              headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('access_token', this.ACCESS_TOKEN),
              params: {
                readalizer_customer: environment.readalizer_customer,
              },
            },
          );
        }),
      )
      .pipe(
        concatMap((data) => {
          console.log('2. init session:');
          console.log(data);
          this.sessionUuid = data['session_uuid'];
          console.log('3. upload audio:');
          console.log(session.file);
          const formData = new FormData();
          formData.append('file', session.file, session.filename);
          // Update Status
          this.loadingStatus.next({
            text: this.translationService.getTranslatedText(
              'readingJson_service_reference_record_progress',
            ),
            progress: 20,
          });
          // API CALL upload audio
          return this.http.post(
            this.cloudServerUrl + '/audio/upload/' + this.sessionUuid,
            formData,
            {
              headers: new HttpHeaders().set('access_token', this.ACCESS_TOKEN),
            },
          );
        }),
      )
      .pipe(
        concatMap((data) => {
          console.log('4. wait for session content:');
          let count = 0;
          // Update Status
          this.loadingStatus.next({
            text: this.translationService.getTranslatedText(
              'readingJson_service_reference_text',
            ),
            progress: 30,
          });
          let finished = false;
          // API CALL session result
          return this.http
            .get(this.cloudServerUrl + '/sessions/' + this.sessionUuid, {
              headers: new HttpHeaders()
                .set('Content-Type', 'application/json')
                .set('access_token', this.ACCESS_TOKEN),
            })
            .pipe(
              map((data) => {
                count++;
                console.log('Count ' + count);
                console.log(data);
                let percent = count * 15 < 100 ? count * 15 : 99;
                // Update Status
                this.loadingStatus.next({
                  text:
                    this.translationService.getTranslatedText(
                      'readingJson_service_reference_text_in_progress',
                    ) +
                    ' ' +
                    percent +
                    '% ' +
                    this.translationService.getTranslatedText(
                      'readingJson_service_reference_text_finished',
                    ),
                  progress: 60,
                });
                if (data['reference_file'] === 'markers_incorrect') {
                  //throw new Error('Markers are incorrect');
                  console.log('Markers are incorrect');
                  finished = true;
                } else if (data['reference_file'] == null && count < 50) {
                  console.log('waiting for reference text');
                } else {
                  console.log('reference text received');
                  finished = true;
                }
                return data;
              }),
              mergeMap((res) => {
                return finished ? of(res) : EMPTY;
              }),
              repeatWhen((notifications) =>
                notifications.pipe(
                  delay(1000),
                  takeWhile(() => !finished),
                ),
              ),
            );
        }),
      )
      .pipe(
        concatMap((data) => {
          console.log('5. uploaded reference response:');
          console.log(data);
          let count = 0;
          let finished = false;
          // API CALL process session
          return this.http
            .get(
              this.cloudServerUrl +
                '/sessions/' +
                this.sessionUuid +
                '/full_process',
              {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
                  .set('access_token', this.ACCESS_TOKEN),
                observe: 'response',
              },
            )
            .pipe(
              map((response) => {
                count++;
                console.log('Process count ' + count);
                console.log(response.status);
                // Retry if status code is other then 200
                if (response.status == 200) {
                  finished = true;
                }
                return response.body;
              }),
              mergeMap((res) => {
                this.deleteSessionFromLocalDatabase(localSessionKey);
                return finished ? of(res) : EMPTY;
              }),
              repeatWhen((notifications) =>
                notifications.pipe(
                  delay(2000),
                  takeWhile(() => !finished),
                ),
              ),
            );
        }),
      )
      .pipe(
        concatMap((data) => {
          console.log('6. save task to database:');
          console.log(data);
          this.sessionTask_uuid = data['session_task_uuid'];

          // Save task as completed in database
          //task.session_task_uuid = this.sessionTask_uuid;
          task.session_uuid = this.sessionUuid;
          task.time_read = time_read;
          task.session_processing = true;
          task.session_hash = this.generateShortHash(this.sessionUuid);
          console.log('TASK IS', task);
          this.deleteSessionFromLocalDatabase(localSessionKey);
          return this.taskService.updateSingleTask(task);
        }),
      );
    /* .pipe(
        concatMap((data) => {
          console.log('7. process session:');
          // Delete finished session from local database
          this.deleteSessionFromLocalDatabase(localSessionKey);
          let count = 0;
          // Update Status
          this.loadingStatus.next({
            text: this.translationService.getTranslatedText(
              'readingJson_service_reference_data',
            ),
            progress: 40,
          });
          let finished = false;
          // API CALL session result
          return this.http
            .get(
              this.cloudServerUrl + '/sessions/result/' + this.sessionTask_uuid,
              {
                headers: new HttpHeaders()
                  .set('Content-Type', 'application/json')
                  .set('access_token', this.ACCESS_TOKEN),
              },
            )
            .pipe(
              map((data) => {
                count++;
                console.log('Count ' + count);
                console.log(data);
                let percent = count * 15 < 100 ? count * 15 : 99;
                // Update Status
                this.loadingStatus.next({
                  text:
                    this.translationService.getTranslatedText(
                      'readingJson_service_reference_data_text',
                    ) +
                    ' ' +
                    percent +
                    '% ' +
                    this.translationService.getTranslatedText(
                      'readingJson_service_reference_text_finished',
                    ),
                  progress: 60,
                });
                if (data['message'] == 'FAILURE') {
                  console.log('hit process failure');
                  finished = true;
                } else if (
                  data['message'] == 'PENDING' ||
                  data['message'] == 'PROGRESS' ||
                  data['message'] == undefined
                ) {
                  console.log('hit process progress');
                } else {
                  console.log('hit process else');
                  finished = true;
                  //this.getAnnotation();
                }
                return data;
              }),
              mergeMap((res) => {
                return finished ? of(res) : EMPTY;
              }),
              repeatWhen((notifications) =>
                notifications.pipe(
                  delay(2000),
                  takeWhile(() => !finished),
                ),
              ),
            );
        }),
      ) */
    /*   .pipe(
          delay(2000),
          concatMap((data) => {
            console.log('9. analyse session:');
            console.log(data);
            let count = 0;
            // Update Status
            this.loadingStatus.next({
              text: this.translationService.getTranslatedText(
                'readingJson_service_reference_text_analyse',
              ),
              progress: 70,
            });
            let finished = false;
            // API CALL session result
            return this.http
              .get(
                this.cloudServerUrl +
                  '/sessions/' +
                  this.sessionUuid +
                  '/annotation',
                {
                  headers: new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('access_token', this.ACCESS_TOKEN),
                },
              )
              .pipe(
                map((data) => {
                  count++;
                  console.log('Count ' + count);
                  console.log(data);
                  let percent = count * 15 < 100 ? count * 15 : 99;
                  // Update Status
                  this.loadingStatus.next({
                    text:
                      this.translationService.getTranslatedText(
                        'readingJson_service_reference_data_text',
                      ) +
                      ' ' +
                      percent +
                      '% ' +
                      this.translationService.getTranslatedText(
                        'readingJson_service_reference_text_analyse_text',
                      ),
                    progress: 80,
                  });
                  if (data['processing_status'] != null) {
                    console.log('hit analyse done');
                    finished = true;
                  } else {
                    console.log('hit analyse progress');
                  }
                  data['sessionId'] = this.sessionUuid;
                  data['session_task_uuid'] = this.sessionTask_uuid;
                  return data;
                }),
                mergeMap((res) => {
                  return finished ? of(res) : EMPTY;
                }),
                repeatWhen((notifications) =>
                  notifications.pipe(
                    delay(2000),
                    takeWhile(() => !finished),
                    retryWhen((errors) =>
                      errors.pipe(
                        mergeMap((error: HttpErrorResponse, index) => {
                          if (error.status === 409 && index < 3) {
                            console.log(
                              `Retry ${index + 1} times due to 409 error`,
                            );
                            return of(error.status);
                          } else if (!error.status && index < 3) {
                            console.log(
                              `Retry ${
                                index + 1
                              } times due to no error and not finished`,
                            );
                            return of(error.status);
                          }
                          return throwError(error);
                        }),
                        delay(2000),
                      ),
                    ),
                  ),
                ),
              );
          }),
        ) */
  }

  saveSessionToLocalDatabase(
    session: any,
    user_uuid: string,
    user_task_uuid: string,
    contentLanguage: string,
    task: any,
    time_read: number,
    num_images: number,
  ) {
    return new Promise((resolve, reject) => {
      // Convert Blob to ArrayBuffer first (before the transaction starts)
      session.file
        .arrayBuffer()
        .then((arrayBuffer) => {
          const dbRequest = window.indexedDB.open('sessionDatabase', 1);

          dbRequest.onupgradeneeded = (event: any) => {
            const db = event.target.result;
            if (!db.objectStoreNames.contains('sessions')) {
              const objectStore = db.createObjectStore('sessions', {
                autoIncrement: true,
              });
              objectStore.createIndex('user_uuid', 'user_uuid', {
                unique: false,
              });
              objectStore.createIndex('user_task_uuid', 'user_task_uuid', {
                unique: false,
              });
            }
          };

          dbRequest.onsuccess = (event: any) => {
            const db = event.target.result;

            // Start the transaction
            const transaction = db.transaction(['sessions'], 'readwrite');
            const objectStore = transaction.objectStore('sessions');

            // Prepare the session data with ArrayBuffer
            const cleanedSession = {
              ...session,
              file: arrayBuffer, // Use the ArrayBuffer instead of Blob
              fileType: session.file.type, // Save the Blob type
            };

            const data = {
              session: cleanedSession,
              user_uuid: user_uuid,
              user_task_uuid: user_task_uuid,
              contentLanguage: contentLanguage,
              task: task,
              time_read: time_read,
              num_images: num_images,
            };

            // Perform the put operation
            const request = objectStore.put(data);

            request.onsuccess = () => {
              console.log('Session data saved successfully');
              resolve(request.result);
            };

            request.onerror = (event: any) => {
              console.error('Error saving session data:', event.target.error);
              reject(event.target.error);
            };

            // Handle transaction completion
            transaction.oncomplete = () => {
              console.log('Transaction completed successfully');
            };

            transaction.onerror = (event: any) => {
              console.error('Transaction failed:', event.target.error);
              reject(event.target.error);
            };
          };

          dbRequest.onerror = (event: any) => {
            console.error('Error opening IndexedDB:', event.target.error);
            reject(event.target.error);
          };
        })
        .catch((error) => {
          console.error('Error converting Blob to ArrayBuffer:', error);
          reject(error);
        });
    });
  }

  getSessionFromLocalDatabase(key: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const dbRequest = window.indexedDB.open('sessionDatabase', 1);

      dbRequest.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(['sessions'], 'readonly');
        const objectStore = transaction.objectStore('sessions');
        const getRequest = objectStore.get(key);

        getRequest.onsuccess = (event: any) => {
          const dataEntry: any = event.target.result;

          if (dataEntry && dataEntry.session?.file) {
            // Reconstruct the Blob from ArrayBuffer and MIME type
            const blob = new Blob([dataEntry.session.file], {
              type: dataEntry.session.fileType, // Use the saved fileType
            });

            dataEntry.session.file = blob; // Replace ArrayBuffer with Blob
          }

          resolve(dataEntry);
        };

        getRequest.onerror = (event: any) => {
          console.error('Error getting data entry:', event.target.error);
          reject(event.target.error);
        };
      };

      dbRequest.onerror = (event: any) => {
        console.error(
          'Error opening local session database:',
          event.target.error,
        );
        reject(event.target.error);
      };
    });
  }

  getAllSessionsKeysFromLocalDatabase(user_uuid: string): Promise<number[]> {
    return new Promise<number[]>((resolve, reject) => {
      const dbRequest = window.indexedDB.open('sessionDatabase', 1);

      dbRequest.onupgradeneeded = (event: any) => {
        const db = event.target.result;
        const objectStore = db.createObjectStore('sessions', {
          autoIncrement: true,
        });
        objectStore.createIndex('user_uuid', 'user_uuid', { unique: false });
        objectStore.createIndex('user_task_uuid', 'user_task_uuid', {
          unique: false,
        });
      };

      dbRequest.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(['sessions'], 'readonly');

        transaction.onerror = (event: any) => {
          console.error('Transaction error:', event.target.error);
          reject(event.target.error);
        };

        const objectStore = transaction.objectStore('sessions');
        const index = objectStore.index('user_uuid');
        const keys: number[] = [];

        transaction.oncomplete = () => {
          resolve(keys);
        };

        const request = index.openCursor(IDBKeyRange.only(user_uuid));

        request.onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            keys.push(cursor.primaryKey);
            cursor.continue();
          }
        };

        request.onerror = (event: any) => {
          console.error('Error reading cursor:', event.target.error);
          reject(event.target.error);
        };
      };

      dbRequest.onerror = (event: any) => {
        console.error(
          'Error opening local session database:',
          event.target.error,
        );
        reject(event.target.error);
      };
    });
  }

  deleteSessionFromLocalDatabase(key: number) {
    return new Promise<void>((resolve, reject) => {
      const dbRequest = window.indexedDB.open('sessionDatabase', 1);

      dbRequest.onsuccess = (event: any) => {
        const db = event.target.result;
        const transaction = db.transaction(['sessions'], 'readwrite');
        const objectStore = transaction.objectStore('sessions');

        const deleteRequest = objectStore.delete(key);

        deleteRequest.onsuccess = () => {
          console.log('Entry deleted successfully');
          resolve();
        };

        deleteRequest.onerror = (event: any) => {
          console.error('Error deleting entry:', event.target.error);
          reject(event.target.error);
        };
      };

      dbRequest.onerror = (event: any) => {
        console.error('Error opening database:', event.target.error);
        reject(event.target.error);
      };
    });
  }

  getAudio(teacherId, sessionUuid, browser) {
    // API CALL get audio to session
    const params = new FormData();
    params.append('user_uuid', teacherId);
    return this.http
      .post<any>(this.cloudServerUrl + '/auth/token', params)
      .pipe(
        concatMap((data) => {
          console.log('1. Get token by uuid:');
          this.ACCESS_TOKEN = data.access_token;
          return this.http.get<Blob>(
            this.cloudServerUrl + '/audio/download/' + sessionUuid,
            {
              headers: new HttpHeaders()
                //.set('Content-Type', 'application/json')
                .set('access_token', this.ACCESS_TOKEN),
              responseType: 'blob' as 'json',
            },
          );
        }),
      );
  }
}
