<div class="group-delete_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="group-delete_wrap">
    <ng-container *ngIf="isDownload">
      <p class="paragraph extra-padding-top">
        <strong>{{ translatedText.student_pdf_overlay_info_warning }} </strong
        >{{ translatedText.student_pdf_overlay_info_warning_text }}
      </p>
      <button
        alt="PDF herunterladen"
        mat-flat-button
        color="primary"
        type="button"
        class="group-delete_button"
        (click)="regeneratePdfForStudent()"
        [disabled]="isLoading"
      >
        <span *ngIf="!isLoading">{{ translatedText.student_pdf_overlay_info_download }}</span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
      <img class="footer_group-delete_img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
    </ng-container>

    <ng-container *ngIf="!isDownload">
      <p class="paragraph extra-padding-top">
        <strong>{{ translatedText.student_pdf_overlay_info_warning }} </strong
        >{{ translatedText.student_pdf_overlay_info_save_info }}
      </p>
      <button
        alt="PDF herunterladen"
        mat-flat-button
        color="primary"
        type="button"
        class="group-delete_button"
        (click)="downloadPDF()"
        [disabled]="isLoading"
      >
        <span *ngIf="!isLoading">{{
          IS_APP ? translatedText.generate_pdf_ok : translatedText.student_pdf_overlay_info_download
        }}</span>
        <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
      </button>
      <img class="footer_group-delete_img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
    </ng-container>
  </div>
</div>
