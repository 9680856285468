import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/providers/group.service';
import { LicenseService } from 'src/app/providers/license.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentService } from 'src/app/providers/student.service';
import { environment } from 'src/environments/environment';

const MAX_ALLOWED_TRAINING_TASKS = 4;
const MAX_ALLOWED_DIAGNOSTIC_TASKS = 1;
export interface Group {}

interface StudentCheckbox {
  studentId: string;
  studentName: string;
  isValid: boolean;
}
interface GroupCheckboxStudents {
  data: any;
  checked: boolean;
  active: boolean;
}

@Component({
  selector: 'app-individual-learners-selection',
  templateUrl: './individual-learners-selection.component.html',
  styleUrls: ['./individual-learners-selection.component.scss'],
})
export class IndividualLearnersSelectionComponent implements OnInit {
  translatedText;
  studentsArray = [];
  groupOverview = [];
  taskOverview = [];
  groupArray = [];
  groups: any = [];
  tasks;
  groupSelectStep: FormGroup;
  description;
  bookData = {
    author: '',
    description: '',
    isbn: '',
    title: '',
    dsef_version: 1.0,
  };
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  nextYear: string;
  currentYear: string;
  isGroupLoading: boolean = true;
  userType;
  schoolYearTransition: boolean;
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  checkSelection: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  estimationByGrade: any;
  snackBar: any;
  teacherId: any;
  showBetaInfo: boolean;
  deadlineStep: any;
  licensesToSchool: any[];
  contentType: string;
  licensesToSchoolLoading: boolean;
  @Output() selectedStudents = new EventEmitter();
  @Output() totalStudentsEachClass = new EventEmitter();
  @Output() selectedGroups = new EventEmitter();
  studentsEachClass: any = {};
  @Input() selectedGroupId: string | null = null;
  @Input() source: string | null = null;
  @Input() selectedTypeDetails: string | null = null;
  @Input() autoMode: boolean;
  LICENSE_TYPE_KEY: string | null = null;
  MAXIMUM_ALLOWED_TASKS: number = 0;
  hintBoxId: string = 'students';
  public allComplete: boolean = false;
  environment = environment;
  allDisabledCount: Object = {
    count: 0,
    index: 0,
  }; // to handle the mat-checkbox for each class
  searchGroups: any = [];
  private searchInputEvent = new Subject<any>();

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private groupService: GroupService,
    private translationService: TranslationService,
    private licenseService: LicenseService,
    private schoolService: SchoolService,
    private studentService: StudentService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.groupSelectStep = this._formBuilder.group({
      students: [''],
    });
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.getLicenses();
    this.showBetaInfo = true;
    this.getTeachersGroups();

    this.trialStatus = this.authService.getUserTrialStatus();
    this.getSchoolYearChanged();
    this.checkMaxNumberOfLicenses();
  }

  getTeachersGroups() {
    this.groupService.getGroupsToTeacher(false).subscribe((res) => {
      this.isGroupLoading = false;
      let allGroups: any = res;
      let filteredGroups = allGroups.filter(
        (group) =>
          group.group.archived === false || group.group.archived === undefined,
      );

      this.groups = filteredGroups;
      this.groups.sort(function (a, b) {
        return a['group'].name.localeCompare(b['group'].name);
      });

      this.groups.forEach((element) => {
        element.group._students.forEach((el) => {
          // add current group to student selection
          el['selectedGroupId'] = element.group._id;

          // group stream is needed for adding exiting student when a student already has more than one class.
          el['_group'].forEach((g) => {
            this.groups.forEach((gi) => {
              if (gi.group._id === g._id)
                g['stream'] = gi.group.stream ? gi.group.stream : '';
            });
          });
        });

        element.group._students.sort(function (a, b) {
          return a['username'].localeCompare(b['username']);
        });
      });
      this.groups.forEach((group) => {
        //if source is add-student, removes the selected group from the rendered groups
        if (
          this.source === 'add-student' &&
          group.group._id === this.selectedGroupId
        ) {
          let elementIndex = this.groups.indexOf(group);
          this.groups.splice(elementIndex, 1);
        }

        this.studentsEachClass[group.group._id] = {
          totalStudents: group.group._students.length,
        };
        this.totalStudentsEachClass.emit(this.studentsEachClass);
      });
      this.searchGroups = structuredClone(this.groups);
    });
  }

  //check if selec students.optionsted existing student is already in class
  isStudentAlreadyInClass(student) {
    let studentAlreadyinClass = student._group.filter((group) => {
      return group._id === this.selectedGroupId;
    });
    return studentAlreadyinClass.length > 0;
  }

  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        this.contentType = this.licenseService.getContentTypeByLicense(
          this.licensesToSchool,
        );
        this.licensesToSchoolLoading = false;
      });
  }
  checkMaxNumberOfLicenses() {
    // Set License Type and Maximum allowed numbers based on the Task Type
    if (this.source && this.source !== '' && this.source === 'diagnostic') {
      this.LICENSE_TYPE_KEY = 'di';
      this.MAXIMUM_ALLOWED_TASKS = MAX_ALLOWED_DIAGNOSTIC_TASKS;
    } else if (
      this.source &&
      this.source !== '' &&
      this.source === 'training'
    ) {
      this.LICENSE_TYPE_KEY = 'fo';
      this.MAXIMUM_ALLOWED_TASKS = MAX_ALLOWED_TRAINING_TASKS;
    }
  }

  checkDiagnosticByLicenses(diagnostic, licenses) {
    let type = this.selectedTypeDetails.replace(/diagnostic-/g, '');
    let diagnosticAvailability = this.studentService.getDiagnosticByLicenses(
      diagnostic,
      licenses,
    );

    return diagnosticAvailability[type];
  }

  getSchoolYearChanged() {
    this.schoolService
      .schoolYearChanged()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.schoolYearTransition = data.yearChange;
        this.nextYear = data.nextYear;
        this.currentYear = data.year;
      });
  }

  //search input
  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 0,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        value = value !== '' ? value.toLowerCase() : '';
        if (value == '') {
          this.searchGroups = structuredClone(this.groups);
        } else {
          this.searchGroups = structuredClone(this.groups);
          this.searchGroups = this.searchGroups.map((result) => {
            result.group._students = result.group._students.filter(
              (student) => {
                return student.username.indexOf(value) !== -1;
              },
            );
            return result;
          });
        }
      });
  }

  // set data for task overview step
  setStudentDataForOverview() {
    this.taskOverview = [];
    this.groupArray = [];
    this.groupOverview = [];
    this.studentsArray.forEach((element) => {
      element.groupName = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?.name;
      element.groupSchoolYear = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?.school_year;
      element.groupLength = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?._students?.length;
      this.taskOverview.push({
        _id: element._id,
        gender: element.gender,
        username: element.username,
        avatar: element.avatar,
        avatarUrl: element.avatarUrl,
        year: this.currentYear,
        _group: element._group,
        groupLength: element.groupLength,
        groupName: this.groups.filter((group) =>
          element?._group.some((el) => el._id === group?.group?._id),
        )[0]?.group?.name,
      });
    });

    this.tasks = this.taskOverview;
    this.tasks.forEach((element) => {
      this.groupArray.push(
        this.tasks.filter((task) => element._group === task._group)[0]._group,
      );
    });

    this.groupArray = this.groupArray.filter(
      (value, index) => this.groupArray.indexOf(value) === index,
    );

    this.groupArray.forEach((element) => {
      const selectedGroup = this.tasks.filter(
        (task) => element === task._group,
      )[0];
      let group = {
        id: element,
        name: selectedGroup.groupName,
        year: this.currentYear,
        username: selectedGroup.username,
        avatarUrl: selectedGroup.avatarUrl,
      };
      this.groupOverview.push(group);
    });
    this.selectedStudents.emit({
      students: this.studentsArray,
      taskOverView: this.taskOverview,
      groupOverView: this.groupOverview,
    });
  }

  filterGroupsWithoutPersonalData() {
    let groups = this.searchGroups;
    // only list groups that have students with gender value
    return groups?.filter((element) => {
      const hasStudents =
        element.group._students && element.group._students.length > 0;
      const hasEmptyGender = element.group._students.some(
        (student) => !student.gender,
      );
      return hasStudents && !hasEmptyGender;
    });
  }

  groupsRendered: GroupCheckboxStudents[] = [
    this.groups.forEach((group) => {
      {
        data: group;
        checked: false;
        active: this.disableSelectAll(group);
      }
    }),
  ];

  // select all students student and add or remove to array
  toggleSelectAll(students, select) {
    if (select === true) {
      students.options.forEach((elt) => {
        let element = elt?._value;
        if (this.source === 'training' || this.source === 'diagnostic') {
          if (
            element?.licenses.filter(
              (l) => l.type_key == this.LICENSE_TYPE_KEY && l.active,
            ).length > 0 &&
            element?.status === 'active' &&
            !this.checkDiagnosticByLicenses(
              element.diagnostic,
              element.licenses,
            ) &&
            (!this.trialStatus.isActive ||
              (this.trialStatus.isActive &&
                (!element.trial?.[this.source] ||
                  element.trial?.[this.source] < this.MAXIMUM_ALLOWED_TASKS)))
          ) {
            elt.selected = true;
            this.studentsArray.push(element);
          }
        }
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
    this.setStudentDataForOverview();
  }

  disableSelectAll(group) {
    let countStudentsEnabled = 0;
    group._students.forEach((student) => {
      if (this.isStudentEnabled(this.source, group, student)) {
        countStudentsEnabled++;
      }
    });
    return countStudentsEnabled === 0;
  }

  isStudentEnabled(componentSource, group, student) {
    switch (componentSource) {
      case 'diagnostic':
        return (
          !this.checkDiagnosticByLicenses(
            student.diagnostic,
            student.licenses,
          ) &&
          student.status === 'active' &&
          !this.hasNoLicense(group, student) &&
          !this.isDiagnosticCompleted(student.diagnostic)
        );

      case 'training':
        return (
          !this.hasTooManyTasks(group, student) &&
          student.status === 'active' &&
          !this.hasNoLicense(group, student)
        );

      case 'add-student':
        return student.status === 'active';
    }
  }

  updateAllComplete() {
    this.allComplete =
      this.studentsArray.length > 0 &&
      this.studentsArray.every((t) => t.completed);
  }

  someComplete(): boolean {
    if (this.studentsArray.length > 0) {
      return false;
    }
    return (
      this.studentsArray.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  // select student and add or remove to array
  handleSelection(event, students) {
    if (this.isSingleGroup == false) {
      if (event.options[0].selected === true) {
        this.studentsArray.push(event.options[0].value);
      } else if (
        event.options[0].selected == false &&
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item !== event.options[0].value,
        );
      }
    } else if (this.isSingleGroup === true || this.isSingleStudent === true) {
      if (
        event.options[0].selected == false ||
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== event.options[0].value._id,
        );
      } else if (
        event.options[0].selected === true &&
        !this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray.push(event.options[0].value);
        //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
      }
    }
    this.setStudentDataForOverview();
  }

  // for training and diagnostic source - check if there are licenses
  hasNoLicense(group: any, student: any): boolean {
    const foundStudent = group._students.find(
      (item) => item._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      const license = student.licenses.find(
        (license) => license.type_key === this.LICENSE_TYPE_KEY,
      );
      return license ? !license.active : true;
    }
  }
  // for training source - check if has too many tasks
  hasTooManyTasks(group, student) {
    if (!this.trialStatus.isActive) {
      return false;
    }
    const foundStudent = group._students.find(
      (s) => s._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      if (
        foundStudent.trial?.[this.source] && // Make sure that training exists before accessing it
        foundStudent.trial[this.source] >= MAX_ALLOWED_TRAINING_TASKS
      ) {
        return true;
      }
    }
    return false;
  }

  // for diagnostic source - check if selected diagnostic is completed
  isDiagnosticCompleted(studentDiagnostics: any) {
    let type = this.selectedTypeDetails.replace(/diagnostic-/g, '');
    let diagnosticCompleted = false;
    for (let i = 0; i < studentDiagnostics.length; i++) {
      if (
        studentDiagnostics[i].kind === type &&
        studentDiagnostics[i]?._task?.status === 'finished'
      ) {
        diagnosticCompleted = true;
        return diagnosticCompleted;
      }
    }
  }

  checkForSelection(student, index) {
    if (
      this.isSingleGroup === true ||
      this.isSingleStudent === true ||
      this.checkSelection === true
    ) {
      return this.studentsArray.some((el) => {
        return el._id === student._id;
      });
    }
  }

  getLicenseAmount(licenses, tag, type) {
    let amount = 0;
    if (licenses.length > 0) {
      licenses.map((item) => {
        if (item.type_key === tag) {
          if (type === 'total') {
            amount += item.amount_total;
          } else {
            amount += item.amount_left;
          }
        }
      });
      if (tag === this.LICENSE_TYPE_KEY) {
        amount = amount > 0 ? amount : amount;
      }
    }
    return Math.floor(amount);
  }
}
