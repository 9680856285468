<div class="book-preferences-container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    (click)="closeOverlay()"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="book-preferences-wrap" *ngIf="isStartScreen">
    <img class="start-img hidden-mobile" src="../../../assets/preferences-start.svg" alt="" />
    <h3 class="h3 h3--bold">
      {{ translatedText.book_preferences_hello }}! <br />
      {{ translatedText.book_preferences_hello_text }}
    </h3>
    <button alt="Start" mat-flat-button color="primary-student" type="button" (click)="startPreferenzSelection()">
      {{ translatedText.book_preferences_start }}
    </button>
  </div>
  <div class="book-preferences-wrap" *ngIf="isPreferenceSelection">
    <img class="preference-img hidden-tablet" src="../../../assets/no-books.svg" alt="" />
    <h3 class="h3 h3--bold">{{ translatedText.book_preferences_select_text }}</h3>
    <p class="paragraph paragraph--xl">{{ translatedText.book_preferences_select }}</p>
    <div class="preferences-selection-wrap">
      <mat-chip-listbox aria-label="category selection">
        <mat-chip-option
          (click)="toggleChipSelection('Abenteuer')"
          [selected]="selectedChips.includes('Abenteuer')"
          [ngClass]="{ active: selectedChips.includes('Abenteuer') }"
        >
          <img src="../../../assets/globe.svg" alt="" />
          {{ translatedText.book_preferences_abenteuer }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Freundschaft')"
          [selected]="selectedChips.includes('Freundschaft')"
          [ngClass]="{ active: selectedChips.includes('Freundschaft') }"
        >
          <img src="../../../assets/handshake.svg" alt="" />
          {{ translatedText.book_preferences_freundschaft }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Fantasy')"
          [selected]="selectedChips.includes('Fantasy')"
          [ngClass]="{ active: selectedChips.includes('Fantasy') }"
        >
          <img src="../../../assets/Mermaid.svg" alt="" />
          {{ translatedText.book_preferences_fantasy }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Familie')"
          [selected]="selectedChips.includes('Familie')"
          [ngClass]="{ active: selectedChips.includes('Familie') }"
        >
          <img src="../../../assets/family.svg" alt="" />
          {{ translatedText.book_preferences_familie }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Magie')"
          [selected]="selectedChips.includes('Magie')"
          [ngClass]="{ active: selectedChips.includes('Magie') }"
        >
          <img src="../../../assets/magic.svg" alt="" />
          {{ translatedText.book_preferences_magie }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Lustiges')"
          [selected]="selectedChips.includes('Lustiges')"
          [ngClass]="{ active: selectedChips.includes('Lustiges') }"
        >
          <img src="../../../assets/lustiges.svg" alt="" />
          {{ translatedText.book_preferences_lustiges }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Liebe')"
          [selected]="selectedChips.includes('Liebe')"
          [ngClass]="{ active: selectedChips.includes('Liebe') }"
        >
          <img src="../../../assets/liebe.svg" alt="" />
          {{ translatedText.book_preferences_liebe }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Tiere')"
          [selected]="selectedChips.includes('Tiere')"
          [ngClass]="{ active: selectedChips.includes('Tiere') }"
        >
          <img src="../../../assets/tiere.svg" alt="" />
          {{ translatedText.book_preferences_tiere }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Detektiv')"
          [selected]="selectedChips.includes('Detektiv')"
          [ngClass]="{ active: selectedChips.includes('Detektiv') }"
        >
          <img src="../../../assets/detektiv.svg" alt="" />
          {{ translatedText.book_preferences_detektiv }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Mut')"
          [selected]="selectedChips.includes('Mut')"
          [ngClass]="{ active: selectedChips.includes('Mut') }"
        >
          <img src="../../../assets/mut.svg" alt="" />
          {{ translatedText.book_preferences_mut }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Weihnachten')"
          [selected]="selectedChips.includes('Weihnachten')"
          [ngClass]="{ active: selectedChips.includes('Weihnachten') }"
        >
          <img src="../../../assets/weihnachten.svg" alt="" />
          {{ translatedText.book_preferences_weihnachten }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Spannung')"
          [selected]="selectedChips.includes('Spannung')"
          [ngClass]="{ active: selectedChips.includes('Spannung') }"
        >
          <img src="../../../assets/spannung.svg" alt="" />
          {{ translatedText.book_preferences_spannung }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Geheimnisvolles')"
          [selected]="selectedChips.includes('Geheimnisvolles')"
          [ngClass]="{ active: selectedChips.includes('Geheimnisvolles') }"
        >
          <img src="../../../assets/mystery.svg" alt="" />
          {{ translatedText.book_preferences_geheimnisvolles }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Jugendliche')"
          [selected]="selectedChips.includes('Jugendliche')"
          [ngClass]="{ active: selectedChips.includes('Jugendliche') }"
        >
          <img src="../../../assets/jugendliche.svg" alt="" />
          {{ translatedText.book_preferences_jugendliche }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Rätsel')"
          [selected]="selectedChips.includes('Rätsel')"
          [ngClass]="{ active: selectedChips.includes('Rätsel') }"
        >
          <img src="../../../assets/raetsel.svg" alt="" />
          {{ translatedText.book_preferences_raetsel }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Selbstfindung')"
          [selected]="selectedChips.includes('Selbstfindung')"
          [ngClass]="{ active: selectedChips.includes('Selbstfindung') }"
        >
          <img src="../../../assets/selbsfindung.svg" alt="" />
          {{ translatedText.book_preferences_selbstfindung }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Märchen')"
          [selected]="selectedChips.includes('Märchen')"
          [ngClass]="{ active: selectedChips.includes('Märchen') }"
        >
          <img src="../../../assets/maerchen.svg" alt="" />
          {{ translatedText.book_preferences_maerchen }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Sport')"
          [selected]="selectedChips.includes('Sport')"
          [ngClass]="{ active: selectedChips.includes('Sport') }"
        >
          <img src="../../../assets/sport.svg" alt="" />
          {{ translatedText.book_preferences_sport }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Grusel')"
          [selected]="selectedChips.includes('Grusel')"
          [ngClass]="{ active: selectedChips.includes('Grusel') }"
        >
          <img src="../../../assets/grusel.svg" alt="" />
          {{ translatedText.book_preferences_grusel }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Geschwister')"
          [selected]="selectedChips.includes('Geschwister')"
          [ngClass]="{ active: selectedChips.includes('Geschwister') }"
        >
          <img src="../../../assets/geschwister.svg" alt="" />
          {{ translatedText.book_preferences_geschwister }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Geschichte')"
          [selected]="selectedChips.includes('Geschichte')"
          [ngClass]="{ active: selectedChips.includes('Geschichte') }"
        >
          <img src="../../../assets/geschichte.svg" alt="" />
          {{ translatedText.book_preferences_geschichte }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Natur')"
          [selected]="selectedChips.includes('Natur')"
          [ngClass]="{ active: selectedChips.includes('Natur') }"
        >
          <img src="../../../assets/natur.svg" alt="" />
          {{ translatedText.book_preferences_natur }}
        </mat-chip-option>
        <mat-chip-option
          (click)="toggleChipSelection('Übungsbücher')"
          [selected]="selectedChips.includes('Übungsbücher')"
          [ngClass]="{ active: selectedChips.includes('Übungsbücher') }"
        >
          <img src="../../../assets/uebungsbuecher.svg" alt="" />
          {{ translatedText.book_preferences_uebungsbuecher }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
    <button
      alt="Speichern"
      mat-flat-button
      color="primary-student"
      [disabled]="selectedChips.length < 5"
      type="button"
      (click)="submitSelection()"
    >
      <span *ngIf="step === 'selection'">{{ translatedText.book_preferences_save }}</span>
      <span *ngIf="step !== 'selection'">{{ translatedText.book_preferences_answer }}</span>
    </button>
  </div>
  <div class="book-preferences-wrap" *ngIf="isEndScreen">
    <img class="start-img" src="../../../assets/preferences-start.svg" alt="" />
    <h3 class="h3 h3--bold">{{ translatedText.book_preferences_thanks }}</h3>
    <p class="paragraph paragraph--xl">{{ translatedText.book_preferences_start_text }}</p>
    <button alt="Ok" mat-flat-button color="primary-student" type="button" (click)="closeOverlay()">
      {{ translatedText.book_preferences_ok }}
    </button>
  </div>
</div>
