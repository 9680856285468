<div class="container home_container">
  <section class="home_header">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <div class="home_header_content">
          <h2 class="subheadline">{{ translatedText.home_subtitle }}</h2>
          <h1 class="headline">{{ translatedText.home_title }}</h1>
          <p class="paragraph">
            {{ translatedText.home_description_1 }} <br /><br />
            {{ translatedText.home_description_2 }} <br />{{ translatedText.home_description_3 }} <br />
            {{ translatedText.home_description_4 }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <div class="home_video_wrap" [class]="environment.whitelabel.homepage_video_class">
        <div class="video-container">
          <video *ngIf="!environment.whitelabel.disable_homepage_video" controls>
            <source [src]="getVideoUrl('imagefilm_laletu.mp4')" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </section>
  <section class="trial_promo_section">
    <div class="trial_promo_text_wrapper">
      <h3 class="h3 h3--bold">{{ translatedText.register_trial_banner_title }}</h3>
      <p class="paragraph paragraph--l">
        {{ translatedText.register_trial_banner_desc }}
        <button
          alt="Jetzt registrieren"
          mat-flat-button
          class="home-primary_student"
          routerLinkActive="active-link"
          [routerLink]="['/registrierung']"
        >
          <mat-icon matIconSuffix>navigate_next</mat-icon>{{ translatedText.register_now }}
        </button>
      </p>
    </div>

    <div class="trial_promo_imgs_wrapper">
      <img class="trial_promo_screenshot_group" src="../../../assets/screenshot_laletu.png" alt="" />
      <img class="trial_promo_icons_group" src="../../../assets/register_trial_large.png" alt="" />
    </div>
  </section>
  <section class="speech_bubble_section">
    <div class="speech_bubble_bg">
      <p>{{ translatedText.home_query }}</p>
      <div class="speech_bubble_bear">
        <img class="speech_bubble_img" src="../../../assets/cat-desktop.svg" alt="" />
      </div>
      <img class="cat_mobile" src="../../../assets/catsvg.svg" alt="" />
    </div>
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <h1 class="headline_box">{{ translatedText.home_that }}</h1>
        <h1 class="headline_box">{{ translatedText.home_why }}</h1>
      </div>
      <div class="col-md-9 col-sm-12">
        <div class="speech_bubble_wrap">
          <div class="speech_bubble_content">
            <div class="speech_bubble">
              <div class="speech_bubble--container">
                {{ translatedText.home_text_1 }}
              </div>
            </div>
            <div class="speech_bubble">
              <div class="speech_bubble--container">
                {{ translatedText.home_text_2 }}
              </div>
            </div>
            <div class="speech_bubble">
              <div class="speech_bubble--container">
                {{ translatedText.home_text_3 }}
              </div>
            </div>
            <div class="speech_bubble">
              <div class="speech_bubble--container">
                {{ translatedText.home_text_4 }}
              </div>
            </div>
            <div class="speech_bubble">
              <div class="speech_bubble--container">{{ translatedText.home_text_5 }}.</div>
            </div>
            <div class="speech_bubble">
              <div class="speech_bubble--container">
                {{ translatedText.home_text_6 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section_gray_bg">
    <div class="section_gray_header">
      <h1 class="headline_box">{{ translatedText.home_title_1 }}</h1>
      <h1 class="headline_box">{{ translatedText.home_title_2 }}</h1>
      <h1 class="headline_box">{{ translatedText.home_title_3 }}</h1>
    </div>

    <div class="row reverse_row">
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_7 }}</h3>
        <p class="section_text">{{ translatedText.home_text_8 }}</p>
      </div>
      <div class="col-md-6 col-sm-12 center_content align_content">
        <img src="../../../assets/Illustration_Feedback.svg" class="illu-feedback" alt="" />
      </div>
    </div>
    <div class="section_divider"></div>
    <div class="row">
      <div class="col-md-6 col-sm-12 center_content align_content">
        <img src="../../../assets/Illustration_Strength_Weakness.svg" class="illu-strength" alt="" />
      </div>
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_9 }}</h3>
        <p class="section_text">{{ translatedText.home_text_10 }}</p>
      </div>
    </div>
    <div class="section_divider"></div>
    <div class="row reverse_row">
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_11 }}</h3>
        <p class="section_text">{{ translatedText.home_text_12 }}</p>
      </div>
      <div class="col-md-6 col-sm-12 center_content align_content">
        <img src="../../../assets/Illustration_Vailidity.svg" class="illu-vailidity" alt="" />
      </div>
    </div>
    <div class="section_divider"></div>

    <div class="row">
      <div class="col-md-6 col-sm-12 center_content align_content">
        <img src="../../../assets/Illustration_Success.svg" class="illu-success" alt="" />
      </div>
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_13 }}</h3>
        <p class="section_text">{{ translatedText.home_text_14 }}</p>
      </div>
    </div>
    <div class="section_divider"></div>
    <div class="row reverse_row">
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_15 }}</h3>
        <p class="section_text">{{ translatedText.home_text_16 }}</p>
      </div>
      <div class="col-md-6 col-sm-12 center_content align_content">
        <img src="../../../assets/Illustration_Security.svg" class="illu-security" alt="" />
      </div>
    </div>
  </section>

  <section class="section-laletu-info">
    <div class="section_gray_header">
      <h1 class="headline_box">{{ translatedText.home_text_17 }}</h1>
      <h1 class="headline_box">{{ translatedText.home_text_18 }}</h1>
    </div>

    <div class="row">
      <div class="col-xl-6 col-md-12 col-sm-12">
        <img src="../../../assets/diagnose_img.png" alt="" />
      </div>
      <div class="col-xl-6 col-md-12 col-sm-12">
        <h2 class="h2 headline">{{ translatedText.home_text_19 }}</h2>
        <h2 class="h2 subheadline">{{ translatedText.home_text_20 }}</h2>
        <p class="paragraph paragraph-xl">{{ translatedText.home_text_21 }}</p>
      </div>
    </div>
    <div class="row-divider"></div>
    <div class="row reverse_row">
      <div class="col-xl-6 col-md-12 col-sm-12">
        <h2 class="h2 headline">{{ translatedText.home_text_22 }}</h2>
        <h2 class="h2 subheadline">{{ translatedText.home_text_23 }}</h2>
        <p class="paragraph paragraph-xl">{{ translatedText.home_text_24 }}</p>
        <p class="paragraph--s">{{ translatedText.home_text_24_1 }}</p>
      </div>
      <div class="col-xl-6 col-md-12 col-sm-12">
        <img src="../../../assets/forderung_img.svg" alt="" />
      </div>
    </div>
  </section>

  <section class="section_accordion">
    <div class="section_gray_header">
      <h1 class="headline_box">{{ translatedText.home_text_25 }}</h1>
      <h1 class="headline_box">{{ translatedText.home_text_26 }}</h1>
    </div>

    <div class="row">
      <div class="col-xl-12 col-md-12 col-sm-12">
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="accordion_headline">
                  {{ translatedText.home_text_27 }}
                </div>
                <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="home-accordion-box">
              <div class="accordion-content">
                <h3 class="h3">{{ translatedText.home_text_28 }}</h3>
                <p class="paragraph paragraph--l">
                  {{ translatedText.home_text_29 }} <br />
                  <span class="paragraph--s">{{ translatedText.home_text_29_1 }}</span>
                </p>

                <div class="link-wrap">
                  <div class="link-icon" (click)="openUrl('https://www.klett.de/inhalt/272087')">
                    <a class="link">{{ translatedText.home_text_30 }}</a>
                    <mat-icon>expand_more</mat-icon>
                  </div>
                  <div class="underline"></div>
                </div>
              </div>
              <div class="accordion-img">
                <img src="../../../assets/automodus.png" alt="" />
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="accordion_headline">{{ translatedText.home_text_31 }}</div>
                <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="home-accordion-box">
              <div class="accordion-content">
                <h3 class="h3">{{ translatedText.home_text_32 }}</h3>
                <p class="paragraph paragraph--l">{{ translatedText.home_text_33 }}</p>
                <div class="link-wrap">
                  <div class="link-icon" (click)="openUrl('https://www.klett.de/inhalt/272087')">
                    <a class="link">{{ translatedText.home_text_30 }}</a>
                    <mat-icon>expand_more</mat-icon>
                  </div>
                  <div class="underline"></div>
                </div>
              </div>
              <div class="accordion-img">
                <img src="../../../assets/silbentrennung.png" alt="" />
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="accordion_headline">{{ translatedText.home_text_34 }}</div>
                <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="home-accordion-box">
              <div class="accordion-content">
                <h3 class="h3">{{ translatedText.home_text_35 }}</h3>
                <p class="paragraph paragraph--l">
                  {{ translatedText.home_text_36 }} <br />
                  <span class="paragraph--s">{{ translatedText.home_text_36_1 }}</span>
                </p>
                <div class="link-wrap">
                  <div class="link-icon" (click)="openUrl('https://www.klett.de/inhalt/272087')">
                    <a class="link">{{ translatedText.home_text_30 }}</a>
                    <mat-icon>expand_more</mat-icon>
                  </div>
                  <div class="underline"></div>
                </div>
              </div>
              <div class="accordion-img">
                <img src="../../../assets/vorlieben.png" alt="" />
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="accordion_headline">{{ translatedText.home_text_37 }}</div>
                <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="home-accordion-box without_img">
              <h3 class="h3">{{ translatedText.home_text_38 }}</h3>
              <p class="paragraph paragraph--l">{{ translatedText.home_text_39 }}</p>
              <div class="link-wrap">
                <div class="link-icon" (click)="openUrl('https://www.klett.de/inhalt/272087')">
                  <a class="link">{{ translatedText.home_text_30 }}</a>
                  <mat-icon>expand_more</mat-icon>
                </div>
                <div class="underline"></div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div *ngIf="!environment.whitelabel.hide_klett_homepage_product_link" class="accordion-info-box">
          <h2 class="h2">{{ translatedText.home_text_40 }}</h2>
          <button
            alt="Zum Klett Online Shop"
            mat-flat-button
            color="primary"
            class="home-primary_student"
            type="button"
            (click)="openUrl('https://www.klett.de/inhalt/272087')"
          >
            {{ translatedText.home_text_30 }} <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="section_blue_bg">
    <div class="section_blue_bg_wrap">
      <div class="section_blue_header">
        <h1 class="headline_box">{{ translatedText.home_text_41 }}</h1>
        <h1 class="headline_box">{{ translatedText.home_text_42 }}</h1>
        <h1 class="headline_box">{{ translatedText.home_text_43 }}</h1>
      </div>

      <img class="blue_section_img" src="../../../assets/Zebra_Tablet_comp.png" alt="" />

      <div class="number_container">
        <div class="number_wrap number_bottom">
          <img class="number-mobile" src="../../../assets/1-1.png" alt="" />
          <p>{{ translatedText.home_text_44 }}</p>
          <img class="number-desktop" src="../../../assets/1.png" alt="" />
        </div>
        <div class="number_wrap number_top">
          <img class="number-desktop" src="../../../assets/2.png" alt="" />
          <img class="number-mobile" src="../../../assets/2-1.png" alt="" />
          <p>{{ translatedText.home_text_45 }}</p>
        </div>
        <div class="number_wrap number_bottom">
          <img class="number-mobile" src="../../../assets/3-1.png" alt="" />
          <p>{{ translatedText.home_text_46 }}</p>
          <img class="number-desktop" src="../../../assets/3.png" alt="" />
        </div>
        <div class="number_wrap number_top">
          <img class="number-desktop" src="../../../assets/4.png" alt="" />
          <img class="number-mobile" src="../../../assets/4-1.png" alt="" />
          <p>{{ translatedText.home_text_47 }}</p>
        </div>
        <div class="number_wrap number_bottom">
          <img class="number-mobile" src="../../../assets/5-1.png" alt="" />
          <p>{{ translatedText.home_text_48 }}</p>
          <img class="number-desktop" src="../../../assets/5.png" alt="" />
        </div>
        <div class="number_wrap number_top">
          <img class="number-mobile" src="../../../assets/6-1.png" alt="" />
          <img class="number-desktop" src="../../../assets/6.png" alt="" />
          <p>{{ translatedText.home_text_49 }}</p>
        </div>
        <div class="number_wrap number_bottom">
          <img class="number-mobile" src="../../../assets/7-1.png" alt="" />
          <p>{{ translatedText.home_text_50 }}</p>
          <img class="number-desktop" src="../../../assets/7.png" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section class="section_gray_img">
    <div class="row">
      <div class="col-12">
        <div class="section_gray_img_headline">
          <h1 class="headline_box">{{ translatedText.home_text_51 }}</h1>
          <h1 class="headline_box">{{ translatedText.home_text_52 }}</h1>
        </div>
      </div>
    </div>
    <div class="row reverse_row">
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_53 }}</h3>
        <p class="section_text">{{ translatedText.home_text_54 }}</p>
      </div>
      <div class="col-md-6 col-sm-12 center_content">
        <img src="../../../assets/lehrkraefte.jpg" alt="" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-12center_content">
        <img src="../../../assets/schueler.jpg" alt="" />
      </div>
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_55 }}</h3>
        <p class="section_text">{{ translatedText.home_text_56 }}</p>
      </div>
    </div>

    <div class="row reverse_row">
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_57 }}</h3>
        <p class="section_text">{{ translatedText.home_text_58 }}</p>
      </div>
      <div class="col-md-6 col-sm-12 center_content">
        <img src="../../../assets/schulen.jpg" alt="" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-12 center_content">
        <img src="../../../assets/flags.jpg" alt="" />
      </div>
      <div class="col-md-6 col-sm-12 center_content">
        <h3 class="section_headline">{{ translatedText.home_text_59 }}</h3>
        <p class="section_text">{{ translatedText.home_text_60 }}</p>
      </div>
    </div>
  </section>

  <section *ngIf="!environment.whitelabel.hide_products_price_table" class="section_price">
    <div class="price-selection">
      <div class="section_gray_header">
        <h1 class="headline_box">{{ translatedText.home_text_61 }}</h1>
        <h1 class="headline_box">{{ translatedText.home_text_62 }}</h1>
      </div>
      <div class="teacher_tabs_header_buttons">
        <mat-button-toggle-group
          #activeViewValue="matButtonToggleGroup"
          [value]="priceSelection"
          (change)="onSelectionChange(activeViewValue.value)"
        >
          <mat-button-toggle value="training-single">
            {{ translatedText.home_slider_training_single_tab }}
          </mat-button-toggle>
          <mat-button-toggle value="training-school">
            {{ translatedText.home_slider_training_school_tab }}
          </mat-button-toggle>
          <mat-button-toggle value="diagnostic">
            {{ translatedText.home_slider_diagnostic_tab }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <!-- single training licenses -->
    <div class="price-tab-content training-prices" *ngIf="priceSelection == 'training-single'">
      <div class="slider-container">
        <p>{{ translatedText.home_slider_number_licenses }}</p>
        <mat-slider
          class="price-tab-content__slider"
          [min]="0"
          [max]="trainingSingleSliderValues.length - 1"
          [step]="stepSliderValue"
          showTickMarks
        >
          <input
            matSliderThumb
            (dragEnd)="sliderValueChanged($event.value, 'training-single')"
            [value]="trainingSingleSliderIndexValue"
          />
          <md-slider min="1" max="5" step="0.5" tickInterval="1" thumbLabel value="1"></md-slider>
          <div class="label-wrapper">
            <span class="text-small">1</span>
            <md-slider></md-slider>
            <span class="text-small">5</span>
            <md-slider></md-slider>
            <span class="text-small">10</span>
            <md-slider></md-slider>
            <span class="text-small">20</span>
            <md-slider></md-slider>
            <span class="text-small">30</span>
          </div>
        </mat-slider>
      </div>
      <div class="price-box-wrap">
        <div class="price-box">
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
                <span class="lesen">{{ translatedText.home_reading }}</span>
              </div>

              <h3 class="h3">{{ translatedText.home_year_license }}</h3>
              <h4 class="h4">
                {{ translatedText.home_slider_training_for }}
                {{ trainingSingleSliderValues[trainingSingleSliderIndexValue] }}
                {{
                  trainingSingleSliderIndexValue == 0
                    ? translatedText.home_slider_training_student
                    : translatedText.home_slider_training_students
                }}
              </h4>
            </div>
            <h1 class="h1 price">
              €
              {{ numberTostring(trainingSinglePlans[trainingSingleSliderIndexValue].package_a.price) }}
            </h1>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSingleSliderValues[trainingSingleSliderIndexValue] }}</span> x
                {{ translatedText.home_slider_diagnostic_3_tests }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSingleSliderValues[trainingSingleSliderIndexValue] }}</span> x
                {{ translatedText.home_slider_unlimited }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_slider_access_klett_books }}</p>
            </div>
          </div>
          <div class="price-button-wrap">
            <p class="price-note">{{ translatedText.home_slider_fair_use_policy }}</p>
            <button
              alt="Zum Produkt"
              mat-flat-button
              type="button"
              (click)="openUrl(trainingSinglePlans[trainingSingleSliderIndexValue].package_a.url)"
            >
              <span class="button-with-icon">
                {{ translatedText.home_text_69 }}<mat-icon>navigate_next</mat-icon>
              </span>
            </button>
          </div>
        </div>
        <div class="price-box best-option">
          <div class="headline-wrap">
            <span class="headline">{{ translatedText.home_text_70 }}</span>
          </div>
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
                <span class="lesen">{{ translatedText.home_reading }}</span>
                <span class="plus">Plus</span>
              </div>

              <h3 class="h3">{{ translatedText.home_slider_yearly_license }}</h3>
              <h4 class="h4">
                {{ translatedText.home_slider_training_for }}
                {{ trainingSingleSliderValues[trainingSingleSliderIndexValue] }}
                {{
                  trainingSingleSliderIndexValue == 0
                    ? translatedText.home_slider_training_student
                    : translatedText.home_slider_training_students
                }}
              </h4>
            </div>
            <h1 class="h1 price">
              €
              {{ numberTostring(trainingSinglePlans[trainingSingleSliderIndexValue].package_b.price) }}
            </h1>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSingleSliderValues[trainingSingleSliderIndexValue] }}</span> x
                {{ translatedText.home_slider_diagnostic_3_tests }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSingleSliderValues[trainingSingleSliderIndexValue] }}</span> x
                {{ translatedText.home_slider_unlimited }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_slider_access_klett_books }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check" class="orange-icon"></mat-icon>
              <p class="paragraph paragraph--xl orange-text">{{ translatedText.home_slider_automatic_adaptive }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check" class="orange-icon"></mat-icon>
              <p class="paragraph paragraph--xl orange-text">{{ translatedText.home_slider_1600_books }}</p>
            </div>
          </div>
          <div class="price-button-wrap">
            <p class="price-note">{{ translatedText.home_slider_fair_use_policy }}</p>
            <button
              alt="Zum Produk"
              mat-flat-button
              type="button"
              (click)="openUrl(trainingSinglePlans[trainingSingleSliderIndexValue].package_b.url)"
            >
              <span class="button-with-icon">
                {{ translatedText.home_text_69 }}<mat-icon>navigate_next</mat-icon>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- school training licenses -->
    <div class="price-tab-content training-prices" *ngIf="priceSelection == 'training-school'">
      <div class="slider-container">
        <p>{{ translatedText.home_slider_number_licenses }}</p>
        <mat-slider
          class="price-tab-content__slider"
          [min]="0"
          [max]="trainingSchoolSliderValues.length - 1"
          [step]="stepSliderValue"
          showTickMarks
        >
          <input
            matSliderThumb
            (dragEnd)="sliderValueChanged($event.value, 'training-school')"
            [value]="trainingSchoolSliderIndexValue"
          />
          <div class="label-wrapper">
            <span class="text-small">100</span>
            <md-slider></md-slider>
            <span class="text-small">150</span>
            <md-slider></md-slider>
            <span class="text-small">200</span>
            <md-slider></md-slider>
            <span class="text-small">250</span>
            <md-slider></md-slider>
            <span class="text-small">300</span>
            <md-slider></md-slider>
            <span class="text-small">350</span>
            <md-slider></md-slider>
            <span class="text-small">400</span>
            <md-slider></md-slider>
            <span class="text-small">450</span>
            <md-slider></md-slider>
            <span class="text-small">500</span>
          </div>
        </mat-slider>
      </div>

      <div class="price-box-wrap">
        <div class="price-box">
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
                <span class="lesen">Lesen</span>
              </div>
              <h3 class="h3">{{ translatedText.home_slider_school_license }}</h3>
              <h4 class="h4">
                {{ translatedText.home_slider_training_for }}
                {{ trainingSchoolSliderValues[trainingSchoolSliderIndexValue] }}
                {{ translatedText.home_slider_training_students }}
              </h4>
            </div>
            <h1 class="h1 price">
              € {{ numberTostring(trainingSchoolPlans[trainingSchoolSliderIndexValue].package_a.price) }}
            </h1>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSchoolSliderValues[trainingSchoolSliderIndexValue] }}</span> x
                {{ translatedText.home_slider_diagnostic_3_tests }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">Extra: </span>
                {{ translatedText.home_slider_extra }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSchoolSliderValues[trainingSchoolSliderIndexValue] }}</span> x
                {{ translatedText.home_slider_unlimited }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_slider_access_klett_books }}</p>
            </div>
          </div>
          <div class="price-button-wrap">
            <p class="price-note">
              {{ translatedText.home_slider_fair_use_policy }}
              <button
                alt="Zum Produk"
                mat-flat-button
                type="button"
                (click)="openUrl(trainingSchoolPlans[trainingSchoolSliderIndexValue].package_a.url)"
              >
                <span class="button-with-icon">
                  {{ translatedText.home_text_69 }}<mat-icon>navigate_next</mat-icon>
                </span>
              </button>
            </p>
          </div>
        </div>
        <div class="price-box best-option">
          <div class="headline-wrap">
            <span class="headline">{{ translatedText.home_text_70 }}</span>
          </div>
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
                <span class="lesen">Lesen</span>
                <span class="plus">Plus</span>
              </div>
              <h3 class="h3">{{ translatedText.home_slider_school_license }}</h3>
              <h4 class="h4">
                {{ translatedText.home_slider_training_for }}
                {{ trainingSchoolSliderValues[trainingSchoolSliderIndexValue] }}
                {{ translatedText.home_slider_training_students }}
              </h4>
            </div>
            <h1 class="h1 price">
              € {{ numberTostring(trainingSchoolPlans[trainingSchoolSliderIndexValue].package_b.price) }}
            </h1>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSchoolSliderValues[trainingSchoolSliderIndexValue] }} </span>x
                {{ translatedText.home_slider_diagnostic_3_tests }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">Extra: </span>
                {{ translatedText.home_slider_extra }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">{{ trainingSchoolSliderValues[trainingSchoolSliderIndexValue] }} </span>x
                {{ translatedText.home_slider_unlimited }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_slider_access_klett_books }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check" class="orange-icon"></mat-icon>
              <p class="paragraph paragraph--xl orange-text">{{ translatedText.home_slider_automatic_adaptive }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check" class="orange-icon"></mat-icon>
              <p class="paragraph paragraph--xl orange-text">{{ translatedText.home_slider_1600_books }}</p>
            </div>
          </div>
          <div class="price-button-wrap">
            <p class="price-note">
              {{ translatedText.home_slider_fair_use_policy }}
              <button
                alt="Zum Produk"
                mat-flat-button
                type="button"
                (click)="openUrl(trainingSchoolPlans[trainingSchoolSliderIndexValue].package_b.url)"
              >
                <span class="button-with-icon">
                  {{ translatedText.home_text_69 }}<mat-icon>navigate_next</mat-icon>
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Diagnostic licenses -->
    <div class="price-tab-content" *ngIf="priceSelection == 'diagnostic'">
      <div class="price-box-wrap">
        <div class="price-box">
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
              </div>
              <h3 class="h3">{{ translatedText.home_text_83 }}</h3>
              <h4 class="h4">1 {{ translatedText.home_text_84 }}</h4>
            </div>

            <div class="price-wrap">
              <h1 class="h1 price">€3<span *ngIf="translationService.getCurrentLanguage() === 'de'">,00</span></h1>
            </div>

            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">1 </span>{{ translatedText.home_text_85 }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_text_86 }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_text_87 }}</p>
            </div>
          </div>

          <button
            alt="Zum Produk"
            mat-flat-button
            type="button"
            (click)="openUrl('https://www.klett.de/produkt/isbn/ECN20196STA12')"
          >
            <span class="button-with-icon">{{ translatedText.home_text_69 }} <mat-icon>navigate_next</mat-icon></span>
          </button>
        </div>
        <div class="price-box">
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
              </div>
              <h3 class="h3">Diagnose M</h3>
              <h4 class="h4">5 {{ translatedText.home_text_72 }}</h4>
            </div>
            <div class="price-wrap">
              <h1 class="h1 price">€12,50</h1>
              <!--  <span class="price-save">€2,50 {{ translatedText.home_text_92 }}</span> -->
            </div>
            <div class="price-list-wrap">
              <div class="price-list">
                <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
                <p class="paragraph paragraph--xl">
                  <span class="orange-text">5 </span>{{ translatedText.home_text_98 }}
                </p>
              </div>
              <div class="price-list">
                <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
                <p class="paragraph paragraph--xl">{{ translatedText.home_text_99 }}</p>
              </div>
              <div class="price-list">
                <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
                <p class="paragraph paragraph--xl">{{ translatedText.home_text_87 }}</p>
              </div>
            </div>
          </div>

          <button
            alt="Zum Produk"
            mat-flat-button
            type="button"
            (click)="openUrl('https://www.klett.de/produkt/isbn/ECN20197STA12')"
          >
            <span class="button-with-icon">{{ translatedText.home_text_69 }} <mat-icon>navigate_next</mat-icon></span>
          </button>
        </div>
        <div class="price-box best-option">
          <div class="headline-wrap">
            <span class="headline">{{ translatedText.home_text_70 }}</span>
          </div>
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
              </div>
              <h3 class="h3">{{ translatedText.home_text_71 }}</h3>
              <h4 class="h4">100 {{ translatedText.home_text_72 }}</h4>
            </div>
            <div class="price-wrap">
              <h1 class="h1 price">€199<span *ngIf="translationService.getCurrentLanguage() === 'de'">,00</span></h1>
              <!-- <span class="price-save">€101 {{ translatedText.home_text_92 }}</span> -->
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">100 </span>{{ translatedText.home_text_88 }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_text_86 }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_text_87 }}</p>
            </div>
          </div>
          <button
            alt="Zum Produk"
            mat-flat-button
            type="button"
            (click)="openUrl('https://www.klett.de/produkt/isbn/ECN20198STA12')"
          >
            <span class="button-with-icon">{{ translatedText.home_text_69 }} <mat-icon>navigate_next</mat-icon></span>
          </button>
        </div>
        <div class="price-box">
          <div class="price-box-content">
            <div class="price-box-header">
              <div class="price-logo-section">
                <img src="../../../assets/laledu_logo_svg.svg" alt="{{ environment.whitelabel.name }} Lesen logo" />
              </div>
              <h3 class="h3">{{ translatedText.home_text_93 }}</h3>
              <h4 class="h4">500 {{ translatedText.home_text_72 }}</h4>
            </div>
            <div class="price-wrap">
              <h1 class="h1 price">€950<span *ngIf="translationService.getCurrentLanguage() === 'de'">,00</span></h1>
              <!-- <span class="price-save">€550 {{ translatedText.home_text_92 }}</span> -->
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">
                <span class="orange-text">500 </span>{{ translatedText.home_text_88 }}
              </p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_text_86 }}</p>
            </div>
            <div class="price-list">
              <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
              <p class="paragraph paragraph--xl">{{ translatedText.home_text_87 }}</p>
            </div>
          </div>
          <button
            alt="Zum Produk"
            mat-flat-button
            color="unset"
            class="home-primary_inverted--blue"
            type="button"
            (click)="openUrl('https://www.klett.de/produkt/isbn/ECN20199STA12')"
          >
            <span class="button-with-icon">{{ translatedText.home_text_69 }} <mat-icon>navigate_next</mat-icon></span>
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class="home_register_section">
    <div class="row">
      <div class="col-12">
        <div class="home_register_headline">
          <h1 class="headline_box">{{ translatedText.home_text_100 }}</h1>
          <h1 class="headline_box">{{ translatedText.home_text_101 }}</h1>
        </div>
      </div>
      <div class="col-xl-5 col-md-12 col-sm-12">
        <div class="home_box_teacher">
          <h3 class="h3 h3--bold">{{ translatedText.home_text_102 }}</h3>
          <p class="paragraph">
            <span class="home_register_text">{{ translatedText.home_text_103 }}</span
            ><br /><br />
            <span class="home_register_text"
              >{{ translatedText.home_text_104 }}
              <a href="https://www.klett.de/inhalt/272087" target="_blank">{{ translatedText.home_text_105 }}</a
              >, {{ translatedText.home_text_106 }}</span
            ><br /><br />
            <span *ngIf="!environment.whitelabel.hide_register_text" class="home_register_text">{{
              translatedText.home_text_107
            }}</span>
            <!-- <span class="home_warning">Wichtig: </span> Bis Sommer 2023 befinden wir uns in einer <span class="home_warning">kostenlosen, wissenschaftlich begleiteten Erprobungsphase</span> des {{environment.whitelabel.name}}. <span class="home_warning">Aufgrund hoher Nachfrage, sind aktuell keine weiteren Kapazitäten vorhanden.</span> Mit Ihrer Registrierung werden Sie auf eine <span class="home_warning">Liste für Interessenten</span> aufgenommen. Wir melden uns bei Ihnen sobald zusätzliche Kapazitäten geschaffen wurden. -->
          </p>
          <div class="home_buttons">
            <!-- <button mat-flat-button color="accent" class="home-primary" type="button"><mat-icon>navigate_next</mat-icon><s>Registrieren</s></button> -->
            <button
              alt="Anmelden"
              mat-flat-button
              color="primary"
              class="home-secondary teacher-login"
              type="button"
              [routerLink]="['/login/teacher']"
            >
              <mat-icon>navigate_next</mat-icon>{{ translatedText.home_text_108 }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-xl-7 col-md-12 col-sm-12">
        <div class="home_box_student">
          <img class="home_box_student_img" src="../../../assets/Piri_Anmeldung.png" alt="" />
          <div class="home_box_student_content">
            <h3 class="h3 h3--bold">{{ translatedText.home_text_109 }}</h3>
            <p class="paragraph">{{ translatedText.home_text_110 }}</p>
            <div class="home_buttons">
              <button
                alt="Jetzt anmelden"
                mat-flat-button
                color="primary"
                class="home-primary_student"
                type="button"
                [routerLink]="['/login']"
              >
                {{ translatedText.home_text_111 }}<mat-icon>navigate_next</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section-laletu-helpdesk">
    <h1 class="headline_box">{{ translatedText.home_text_112 }}</h1>
    <div class="row reverse_row">
      <div class="col-12">
        <h2 class="h2 headline">{{ translatedText.home_text_113 }}</h2>
        <h2 class="h2 subheadline">{{ translatedText.home_text_114 }}: 069 - 380299333</h2>
        <h2 class="h2 subheadline">E-Mail: {{ replaceDomainName('helpdesk@laletu.de') }}</h2>
        <h2 class="h2 subheadline">{{ translatedText.home_text_115 }}: 09:30h bis 16:30h</h2>
        <p class="paragraph paragraph-xl">{{ translatedText.home_text_116 }}</p>
      </div>
    </div>
  </section>

  <section class="sponsor_section">
    <div class="row">
      <div>
        <div class="sponsor_register_headline">
          <h1 class="headline_box">{{ translatedText.home_text_117 }}</h1>
          <h1 class="headline_box">{{ translatedText.home_text_118 }}</h1>
        </div>
      </div>
      <div>
        <p>{{ translatedText.home_text_119 }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 sponsor_row">
        <div class="sponsor_img_container">
          <div class="sponsor_images" *ngIf="showSponsorLogos">
            <img src="../../../assets/logos/logo_ars-edition.png" alt="" />
            <img src="../../../assets/logos/logo_kbv.png" alt="" />
            <img src="../../../assets/logos/logo_loewe.png" alt="" />
            <img src="../../../assets/logos/logo_oetinger.png" alt="" />
            <img src="../../../assets/logos/logo_sauerlaender.png" alt="" />
            <img src="../../../assets/logos/logo_thienemann-esslinger.png" alt="" />
            <img src="../../../assets/logos/logo_tessloff.png" alt="" />
            <img src="../../../assets/logos/logo_tulipan.png" alt="" />
          </div>
          <div class="sponsor_images" *ngIf="!showSponsorLogos">
            <h2>ars Edition</h2>
            <h2>Verlagsgruppe Oetinger</h2>
            <h2>Sauerländer</h2>
            <h2>Thienemann-Esslinger Verlag Gmbh</h2>
            <h2>Tessloff</h2>
            <h2>KBV</h2>
            <h2>Loewe</h2>
            <h2>Tulipan Verlag</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--   <section class="app-section">
    <div class="row">
      <div class="col-xl-6 col-md-12 col-sm-12">
        <div class="app-content">
          <h1 class="h1">{{ translatedText.home_text_120 }}</h1>
          <h4 class="h4">{{ translatedText.home_text_121 }}</h4>
        </div>
      </div>
      <div class="col-xl-6 col-md-12 col-sm-12">
        <div class="app-info">
          <div class="button_wrap">
            <button mat-flat-button color="unset" type="button">
              <img src="../../../assets/apple-logo.svg" alt="" />{{ translatedText.home_text_122 }}
            </button>
            <button mat-flat-button color="unset" type="button">
              <img src="../../../assets/google-play-icon.svg" alt=""
                class="play-icon" />{{ translatedText.home_text_123 }}
            </button>
          </div>
          <div class="qr-code-wrap">
            <img src="../../../assets/qr.png" alt="" />
            <img src="../../../assets/qr.png" alt="" />
          </div>
          <h4 class="h4 desktop-text">{{ translatedText.home_text_124 }}</h4>
          <p class="paragraph paragraph--xl desktop-text">{{ translatedText.home_text_125 }}</p>
        </div> -->
  <!-- <div class="parents-pdf">
                <a class="link" href="../../../assets/LaLeTu_Elterninformation_092023.pdf" target="_blank">Elterninformation</a>
            </div> -->
  <!--       </div>
    </div>
  </section> -->
</div>
