<app-task-edit-diagnostic-stepper
  #stepper
  [linear]="true"
  [stepValue]="selectedIndex"
  (selectionChange)="getActiveSelection($event)"
  (isClosedDialog)="closeOverlay($event)"
>
  <!-- IN DIALOG BOXES WITH STEPPER COMPONENTS THE CLOSE BUTTON IS IN THE STEPPER COMPONENT AND IS TRIGGERED AS AN EVENT (above)-->
  <cdk-step [stepControl]="deadlineStep">
    <div class="training-task_select_container">
      <form [formGroup]="deadlineStep">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--diagnostic-pre">{{
            translatedText.task_edit_diagnostic_title
          }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.task_edit_diagnostic_description }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.task_edit_diagnostic_deadline }}
          </p>
        </div>

        <div class="diagnostic-select-wrap" *ngIf="!selectionLoading">
          <mat-selection-list #selectedDiagnostic formControlName="diagnostic" class="diagnostic-selection-list">
            <mat-list-option
              checkboxPosition="before"
              class="diagnostic-list-option"
              [selected]="true"
              [ngClass]="{
                'selected-diagnostic': checkForItemSelection(
                  selectedDiagnostic.selectedOptions.selected,
                  diagnostic?.value
                ),
                'selected-diagnostic-complete':
                  checkForItemSelection(selectedDiagnostic.selectedOptions.selected, diagnostic?.value) ==
                    diagnostic.value || deadlineStep.get('diagnosticDeadline')?.value !== '',
                expiredDiagnostic_date: getDateFromString(diagnostic.endDate) < todayDate,
              }"
              [value]="diagnostic?.value"
            >
              <div>
                <p class="paragraph paragraph--bold paragraph--xl">
                  {{ diagnostic.name }}
                </p>
                <p class="paragraph paragraph--l diagnostic_time">
                  {{ diagnostic.startDate | date: 'd MMM y' }} -
                  {{ diagnostic.endDate | date: 'd MMM y' }}
                </p>
              </div>
              <div class="deadline_wrap">
                <p class="paragraph paragraph--s">
                  {{ translatedText.task_edit_diagnostic_deadline_info }}
                </p>
                <mat-form-field (click)="$event.stopPropagation()">
                  <mat-label>{{ translatedText.date_format }}</mat-label>
                  <input
                    matInput
                    [min]="diagnostic.startDate"
                    [max]="diagnosticDateChanger.checked ? null : diagnostic.endDate"
                    onkeypress="return false"
                    formControlName="diagnosticDeadline"
                    type="text"
                    (click)="deadlinepicker.open()"
                    [matDatepicker]="deadlinepicker"
                  />
                  <mat-datepicker-toggle matSuffix [for]="deadlinepicker"></mat-datepicker-toggle>
                  <mat-datepicker #deadlinepicker></mat-datepicker>
                </mat-form-field>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>
        <div class="diagnostic-date-changer">
          <mat-checkbox #diagnosticDateChanger [checked]="diagnosticSelected" class="example-margin">
            {{ translatedText.task_edit_diagnostic_time }}</mat-checkbox
          >
          <mat-icon
            matTooltip="Legen Sie die Diagnose außerhalb des vorgegebenen Zeitraums an."
            svgIcon="streamline_outlined:hint"
          ></mat-icon>
        </div>

        <div class="training-task_select_button">
          <button alt="Zurück" mat-flat-button color="unset" class="mat-flat-button--full" (click)="closeOverlay(true)">
            {{ translatedText.task_edit_diagnostic_back }}
          </button>
          <button alt="Weiter" mat-flat-button color="primary" class="mat-flat-button--full" cdkStepperNext>
            {{ translatedText.task_edit_diagnostic_next }}
          </button>
        </div>
      </form>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="groupSelectStep">
    <div class="training-task_select_container">
      <form [formGroup]="groupSelectStep" #formDirective="ngForm" class="third_step_accorddion">
        <div class="training-task_create_headline">
          <span class="paragraph task_chip task_chip--text task_chip--diagnostic-pre">{{
            translatedText.task_edit_diagnostic_title
          }}</span>
          <h3 class="h3 h3--bold">
            {{ translatedText.task_edit_diagnostic_edit }}
          </h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.task_edit_diagnostic_edit_info }}
          </p>
        </div>
        <div
          *ngIf="groups?.length == 0 || (groups.length > 0 && filterGroupsWithoutPersonalData(groups)?.length == 0)"
          class="empty_tasks_container"
        >
          <img src="../../../assets/empty.svg" alt="" />
          <p class="paragraph">
            <span *ngIf="userType == 'school_admin'">{{ translatedText.task_edit_diagnostic_no_group }}</span>
            <span *ngIf="userType == 'teacher'">{{ translatedText.task_edit_diagnostic_check_information }}</span>
          </p>
        </div>
        <div class="accordion_wrap">
          <mat-accordion multi>
            <mat-expansion-panel *ngFor="let groups of filterGroupsWithoutPersonalData(groups)">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-checkbox
                    (change)="toggleSelectAll(students, $event.checked)"
                    [checked]="students?.selectedOptions?.selected.length > 0"
                    class="example-margin"
                    (click)="$event.stopPropagation()"
                    [disabled]="
                      disableSelectAll(
                        getLicenseAmount([], 'di', 'left'),
                        groups?.group?._students,
                        groups?.taskOverview?.diagnose_blocked_licenses
                      )
                    "
                  ></mat-checkbox>
                  <p class="paragraph paragraph--bold paragraph--xl">
                    {{ groups.group.name }} -
                    {{ groups.group?.school_year | slice: 2 }}
                  </p>
                </mat-panel-title>
                <mat-panel-description>
                  <div class="license-amount">
                    <p class="paragraph paragraph--s">
                      {{ translatedText.task_edit_diagnostic_available_licenses }}
                      <span
                        [ngClass]="
                          disableSelectAll(
                            getLicenseAmount([], 'di', 'left'),
                            groups?.group?._students,
                            groups?.taskOverview?.diagnose_blocked_licenses
                          ) == true
                            ? 'warning'
                            : ''
                        "
                        >{{
                          calculatedLicenses(
                            getLicenseAmount([], 'di', 'left'),
                            students.selectedOptions.selected,
                            groups?.taskOverview?.diagnose_blocked_licenses
                          )
                        }}</span
                      >
                    </p>
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-selection-list
                #students
                (selectionChange)="handleSelection($event, groupSelectStep.value, groups)"
                [name]="groups.group._students"
                formControlName="students"
              >
                <mat-list-option
                  [disabled]="
                    isOptionDisabled(
                      groups.group,
                      students.selectedOptions.selected,
                      student,
                      groups?.taskOverview?.diagnose_blocked_licenses
                    ) || student?.status == 'inactive'
                  "
                  checkboxPosition="before"
                  *ngFor="let student of groups.group._students; index as i"
                  [value]="student"
                  [selected]="checkForSelection(student, i)"
                >
                  <div class="accordion_list_text list-item-element">
                    <div class="inline_text">
                      <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" alt="" />
                      <p class="paragraph username-with-avatar">
                        {{ student?.username }}
                      </p>
                      <p class="paragraph paragraph--s inactive-status-text" *ngIf="student?.status == 'inactive'">
                        ({{ translatedText.task_edit_diagnostic_status }})
                      </p>
                    </div>
                    <p
                      *ngIf="
                        isOptionDisabled(
                          groups.group,
                          students.selectedOptions.selected,
                          student,
                          groups?.taskOverview?.diagnose_blocked_licenses
                        )
                      "
                      class="paragraph license-time"
                    >
                      {{ translatedText.task_edit_diagnostic_no_licenses }}
                    </p>
                    <div class="diagnostic_test_overview">
                      <ng-container>
                        <mat-icon
                          color="gray"
                          svgIcon="streamline_outlined:check-mark-circle-2"
                          *ngIf="
                            checkForDiagnosticExistence(student?.diagnostic, 'pre') &&
                            !checkForDiagnosticCompletion(student?.diagnostic, 'pre')
                          "
                        ></mat-icon>
                        <mat-icon
                          color="green"
                          svgIcon="streamline_outlined:check-mark-circle-2"
                          *ngIf="
                            checkForDiagnosticExistence(student?.diagnostic, 'pre') &&
                            checkForDiagnosticCompletion(student?.diagnostic, 'pre')
                          "
                        ></mat-icon>
                        <mat-icon
                          color="gray"
                          svgIcon="streamline_outlined:delete-circle"
                          *ngIf="
                            !checkForDiagnosticExistence(student?.diagnostic, 'pre') &&
                            !checkForDiagnosticCompletion(student?.diagnostic, 'pre')
                          "
                        ></mat-icon>
                      </ng-container>
                      <ng-container>
                        <mat-icon
                          color="gray"
                          svgIcon="streamline_outlined:check-mark-circle-2"
                          *ngIf="
                            checkForDiagnosticExistence(student?.diagnostic, 'mid') &&
                            !checkForDiagnosticCompletion(student?.diagnostic, 'mid')
                          "
                        ></mat-icon>
                        <mat-icon
                          color="green"
                          svgIcon="streamline_outlined:check-mark-circle-2"
                          *ngIf="
                            checkForDiagnosticExistence(student?.diagnostic, 'mid') &&
                            checkForDiagnosticCompletion(student?.diagnostic, 'mid')
                          "
                        ></mat-icon>
                        <mat-icon
                          color="gray"
                          svgIcon="streamline_outlined:delete-circle"
                          *ngIf="
                            !checkForDiagnosticExistence(student?.diagnostic, 'mid') &&
                            !checkForDiagnosticCompletion(student?.diagnostic, 'mid')
                          "
                        ></mat-icon>
                      </ng-container>
                      <ng-container>
                        <mat-icon
                          color="gray"
                          svgIcon="streamline_outlined:check-mark-circle-2"
                          *ngIf="
                            checkForDiagnosticExistence(student?.diagnostic, 'post') &&
                            !checkForDiagnosticCompletion(student?.diagnostic, 'post')
                          "
                        ></mat-icon>
                        <mat-icon
                          color="green"
                          svgIcon="streamline_outlined:check-mark-circle-2"
                          *ngIf="
                            checkForDiagnosticExistence(student?.diagnostic, 'post') &&
                            checkForDiagnosticCompletion(student?.diagnostic, 'post')
                          "
                        ></mat-icon>
                        <mat-icon
                          color="gray"
                          svgIcon="streamline_outlined:delete-circle"
                          *ngIf="
                            !checkForDiagnosticExistence(student?.diagnostic, 'post') &&
                            !checkForDiagnosticCompletion(student?.diagnostic, 'post')
                          "
                        ></mat-icon>
                      </ng-container>
                    </div>
                  </div>
                </mat-list-option>
              </mat-selection-list>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- {{students}} -->
      </form>
      <div class="training-task_select_button">
        <button alt="Zurück" mat-flat-button color="unset" class="mat-flat-button--full" cdkStepperPrevious>
          {{ translatedText.task_edit_diagnostic_back }}
        </button>
        <button
          alt="Speichern"
          mat-flat-button
          color="primary"
          class="mat-flat-button--full"
          (click)="submitTask(formDirective)"
          [disabled]="studentsArray.length == 0 || isLoading"
        >
          {{ translatedText.task_edit_diagnostic_save }}
        </button>
      </div>
    </div>
  </cdk-step>
  <cdk-step [stepControl]="lastStep">
    <div class="last_step_container edit">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <h3 class="h3 h3--bold">
        {{ translatedText.task_edit_diagnostic_success }}
      </h3>
      <button alt="Ok" mat-flat-button color="primary" class="mat-flat-button--full" (click)="closeOverlay(true)">
        {{ translatedText.task_edit_diagnostic_close }}
      </button>
    </div>
  </cdk-step>
</app-task-edit-diagnostic-stepper>
