<div>
  <div class="header-wrapper">
    <div class="close_wrap"></div>
    <div class="chip_wrapper">
      <div class="chip_outline">
        <div class="chip_content add-student-chip">{{ translatedText.oauth_groups_chip }}</div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <button
        alt="Speichern"
        mat-flat-button
        color="primary"
        class="submit-button"
        (click)="submitForm()"
        [disabled]="oauthFormArray.invalid"
      >
        {{ translatedText.oauth_groups_save }}
      </button>
    </div>
  </div>

  <div class="form_content">
    <div class="content_info">
      <h2 class="h2 h2--bold">
        {{ translatedText.oauth_groups_content_title }}
      </h2>

      <div class="notification-box warn-mode">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="tn-heading">
              <mat-icon svgIcon="streamline_outlined:warning-triangle"></mat-icon>

              <div class="tn-heading">{{ translatedText.oauth_groups_warn_title }}</div>
            </div>
            <p class="paragraph paragraph--s">
              {{ translatedText.oauth_groups_warn_content }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <form [formGroup]="oauthFormArray" class="group-create-form">
      <div *ngFor="let group of groups; let i = index" [formGroup]="getGroupForm(i)" class="group-form">
        <div class="groups_chips">
          <mat-icon svgIcon="streamline_filled:class_chip"></mat-icon>
          <p class="paragraph paragraph--s">{{ group.name }}</p>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-label>{{ translatedText.admin_group_create_group_grade }}</mat-label>
            <mat-form-field>
              <mat-select
                formControlName="grade"
                placeholder="{{ translatedText.admin_group_create_select_paceholder }}"
              >
                <mat-option *ngFor="let grade of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="grade">
                  {{ grade }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="getGroupForm(i).get('grade').hasError('required')">
                {{ translatedText.admin_group_create_group_grade_error }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-label>{{ translatedText.admin_group_create_group_stream }}</mat-label>
            <mat-form-field class="example-full-width">
              <input
                matInput
                placeholder="{{ translatedText.admin_group_create_group_stream }}"
                aria-label="Klassenzug"
                [matAutocomplete]="auto"
                formControlName="stream"
                required
              />

              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                  <span>{{ item }}</span>
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="oauthFormArray.at(i).get('stream').hasError('required')">
                {{ translatedText.admin_group_create_group_stream_error }}
              </mat-error>
              <mat-error *ngIf="oauthFormArray.at(i).get('stream').hasError('onlyLetters')">
                {{ translatedText.admin_group_create_noDigits_error }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-label>{{ translatedText.admin_group_create_group_school_year }}</mat-label>
            <mat-form-field>
              <mat-select formControlName="school_year">
                <mat-option [value]="item" *ngFor="let item of ['2024/25', '2025/26', '2026/27']">
                  {{ item }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="getGroupForm(i).get('school_year').hasError('required')">
                {{ translatedText.admin_group_create_group_school_year_error }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
