import { Component, OnInit, Inject, Optional, ViewChild } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { Observable, Subscription, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
  AbstractControl,
  FormArray,
  FormControl,
} from '@angular/forms';
import { StudentService } from '../../providers/student.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDatepicker } from '@angular/material/datepicker';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { ErrorSnackbarComponent } from '../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DatePipe } from '@angular/common';
import { TranslationService } from 'src/app/providers/translation.service';
import { GroupService } from 'src/app/providers/group.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface Student {
  username?: string;
  avatarUrl?: string;
  _group?: any;
  language?: string;
  age?: string;
  reference_value?: number;
}
export interface DialogData {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Languages {
  name: string;
  code: string;
}

@Component({
  selector: 'app-students-edit',
  templateUrl: './students-edit.component.html',
  styleUrls: ['./students-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class StudentsEditComponent implements OnInit {
  IS_APP = environment.isApp;
  restServerUrl;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  rForm: FormGroup;
  id;
  student: Student;
  subscription: Subscription;
  languages: any = [];
  filteredOptions: Observable<Languages[]>[] = [];
  status: string = 'Aktiv';
  editMode: boolean = false;
  userType;
  isLoading: boolean = false;
  isClosedDialog: boolean = false;
  selectedIndex: number;
  groupName;
  students;
  femaleCount: number = 0;
  maleCount: number = 0;
  diversCount: number = 0;
  maxBirthday;
  groupGrade;
  @ViewChild('stepper') stepper: CdkStepper;
  lastStep;
  blobUrl;
  groupId;
  translatedText;
  constructor(
    private authService: AuthService,
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private studentService: StudentService,
    private groupService: GroupService,
    private capacitorService: CapacitorService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    if (injectedData) {
      if (typeof injectedData['groupName'] !== 'undefined') {
        this.groupName = this.injectedData['groupName'];
      }
      if (typeof injectedData['students'] !== 'undefined') {
        this.students = this.injectedData['students'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
      }
      if (typeof injectedData['groupId'] !== 'undefined') {
        this.groupId = this.injectedData['groupId'];
      }
    }
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]);

    this.studentService
      .getLanguages()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.languages = res;
        for (const la of this.languages) {
          la.translate =
            this.translatedText[
              this.languages.filter((obj) => obj.code == la.code)[0]?.code
            ];
        }
        for (let index = 0; index < this.students.length; index++) {
          this.setLanguages(index);
        }
      });
    this.initForm();
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter(
      (option) =>
        option.name.toLowerCase().startsWith(filterValue) ||
        option.translate.toLowerCase().startsWith(filterValue),
    );
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages != undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code == code)[0]?.code
        ]
      : '';
  }

  validadeStudentField(index: number) {
    return (this.rForm.get('students') as FormArray).at(index) as FormGroup;
  }

  initForm() {
    this.rForm = this._formBuilder.group({
      students: this._formBuilder.array([this.createStudentArray('')]),
    });
    this.addStudentsToForm();
  }

  createStudentArray(student): FormGroup {
    let schoolClassAge = parseInt(this.groupGrade) + 5;
    const birthyearEstimation = new Date();
    birthyearEstimation.setFullYear(
      birthyearEstimation.getFullYear() - schoolClassAge,
    );
    let externalName = '';
    if (this.authService.getIsOauth()) {
      externalName = student.externalName;
    }

    return this._formBuilder.group({
      id: student._id,
      groupId: this.groupId,
      teacher: student._id,
      name: externalName,
      gender: ['', Validators.required],
      language: [
        '',
        [Validators.required, this.validatorRequireMatch.bind(this)],
      ],
      age: [moment(birthyearEstimation), Validators.required],
    });
  }

  getStudentInfo(studentId) {
    return this.students.filter((student) => student._id == studentId)[0];
  }

  addStudentsToForm() {
    const formArray = new FormArray([]);

    this.students.forEach((student) => {
      formArray.push(this.createStudentArray(student));
    });

    this.rForm.setControl('students', formArray);

    for (let index = 0; index < this.students.length; index++) {
      this.setLanguages(index);
    }
  }

  getStudentsFormControls(): AbstractControl[] {
    return (<FormArray>this.rForm?.get('students'))?.controls;
  }

  setLanguages(index) {
    this.filteredOptions[index] = (
      (this.rForm.get('students') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
              ? this.languages?.slice()
              : [];
        }),
      );
  }

  setLanguageOption(value, index) {
    ((this.rForm.get('students') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (this.languages && this.languages.find((obj) => obj.code == selection)) {
      return null;
    }
    return { incorrect: true };
  }

  closeOverlay(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  genderSelection() {
    setTimeout(() => {
      this.maleCount = this.rForm
        .get('students')
        .value.filter((item) => item.gender == 'male').length;
      this.femaleCount = this.rForm
        .get('students')
        .value.filter((item) => item.gender == 'female').length;
      this.diversCount = this.rForm
        .get('students')
        .value.filter((item) => item.gender == 'divers').length;
    }, 0);
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.stepper.selectedIndex = 1;

    this.studentService
      .updateStudents(this.rForm.value.students, this.translatedText)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          if (res) {
            let dataType = res['type'];
            let binaryData = [];
            binaryData.push(res);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            this.blobUrl = blobUrl;
            let pdfFilename = this.groupService.generateGroupFilenameDate(
              this.groupName,
            );
            if (this.IS_APP) {
              this.capacitorService.saveBlobToFilesystem(blob, pdfFilename);
            }
            //this.groupService.openPdfOverlay(blobUrl, 'multiple');
            this.isLoading = false;
          }
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data:
              this.translatedText?.students_edit_success_text +
              ' ' +
              this.groupName +
              ' ' +
              this.translatedText?.students_edit_success_text_2,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        (error: any) => {
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: this.translatedText?.technical_error,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      );
  }

  downloadPDF() {
    // create download object
    if (!this.IS_APP) {
      let pdfFilename = this.groupService.generateGroupFilenameDate(
        this.groupName,
      );
      var element = document.createElement('a');
      element.setAttribute('href', this.blobUrl);
      element.setAttribute('download', pdfFilename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    this.dialog.closeAll();
  }

  editPersonalInfo() {
    this.editMode = true;
  }

  showAlert() {
    alert('Under Construction');
  }

  backToPersonalInfo() {
    this.editMode = false;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
