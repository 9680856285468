import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
  FormBuilder,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TranslationService } from 'src/app/providers/translation.service';
import { SchoolService } from 'src/app/providers/school.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-school-state-update',
  templateUrl: './school-state-update.component.html',
  styleUrls: ['./school-state-update.component.scss'],
})
export class SchoolStateUpdateComponent implements OnInit, OnDestroy {
  schoolForm: FormGroup;
  translatedText: any;
  states: any[] = [
    { value: 'BW', viewValue: 'Baden-Württemberg' },
    { value: 'BY', viewValue: 'Bayern' },
    { value: 'BE', viewValue: 'Berlin' },
    { value: 'BB', viewValue: 'Brandenburg' },
    { value: 'HB', viewValue: 'Bremen' },
    { value: 'HH', viewValue: 'Hamburg' },
    { value: 'HE', viewValue: 'Hessen' },
    { value: 'MV', viewValue: 'Mecklenburg-Vorpommern' },
    { value: 'NI', viewValue: 'Niedersachsen' },
    { value: 'NRW', viewValue: 'Nordrhein-Westfalen' },
    { value: 'RP', viewValue: 'Rheinland-Pfalz' },
    { value: 'SL', viewValue: 'Saarland' },
    { value: 'SN', viewValue: 'Sachsen' },
    { value: 'ST', viewValue: 'Sachsen-Anhalt' },
    { value: 'SH', viewValue: 'Schleswig-Holstein' },
    { value: 'TH', viewValue: 'Thüringen' },
  ];
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = false;
  isSubmited: boolean = false;
  isError: boolean = false;
  schoolId;
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<SchoolStateUpdateComponent>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translationService: TranslationService,
    private schoolService: SchoolService,
  ) {
    this.schoolForm = this.formBuilder.group({
      state: ['', Validators.required],
    });
    if (injectedData) {
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.schoolId = this.authService.getSchoolId();
  }

  submitForm(form: FormGroupDirective) {
    if (this.schoolForm.invalid) {
      return;
    }
    this.isLoading = true;
    const school = {
      schoolId: this.schoolId,
      state: this.schoolForm.value.state,
    };
    this.schoolService.updateSchool(school).subscribe({
      next: (res) => {
        this.authService.updateUser('schoolState', this.schoolForm.value.state);
        this.isSubmited = true;
      },
      error: (error: any) => {
        this.isLoading = false;
        this.isError = true;
      },
    });
  }

  closeOverlay() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
