<div class="task-detail-container">
  <div class="">
    <div class="teacher-content-container">
      <div class="task_detail_header">
        <div class="inline_text">
          <button
            alt="Zurück"
            mat-fab
            type="button"
            (click)="navigateBack()"
            color="primary"
            class="backButton"
            matSuffix
          >
            <mat-icon svgIcon="streamline_outlined:left"></mat-icon>
          </button>
          <h1 class="h3 h3--bold">
            <span *ngIf="task?.type">{{ generateTaskTitle(task?.type) }}</span>
          </h1>
        </div>
        <button
          alt="Auto Modus abschalten"
          mat-button
          *ngIf="task?.mode == 'autonomous' && masterTask.status == 'active'"
          type="button"
          (click)="openDisableAutoModeOverlay()"
          matTooltip="{{ translatedText.task_detail_disable_auto }}"
        >
          <mat-icon svgIcon="streamline_filled:auto-mode"></mat-icon>
          <span class="hidden-mobile">{{ translatedText.task_detail_disable_auto }}</span>
        </button>

        <!-- <button mat-button *ngIf="masterTask?.status == 'finished'" type="button" (click)="repeatTaskDialog(masterTask?._id)">
                    <mat-icon svgIcon="streamline_outlined:refresh"></mat-icon> Aufgabe wiederholen
                </button> -->
        <!-- <button mat-button *ngIf="masterTask?.mode !== 'autonomous'" type="button" (click)="editTask(masterTask?._id, masterTask?.type)">
                    <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon> Aufgabe bearbeiten
                </button> -->

        <button
          alt="Aufgabe löschen"
          mat-button
          color="unset"
          *ngIf="taskLoaded && taskComplete == 0 && taskSplitTask == 0 && task?.mode != 'autonomous'"
          type="button"
          class="delete_task"
          (click)="openTaskDeleteDialog()"
        >
          <!--           *ngIf="
            (taskLoaded && taskComplete == 0 && taskSplitTask == 0 && task?.mode != 'autonomous') ||
            task.status === 'expired'
          " -->
          <mat-icon aria-hidden="false" fontIcon="delete"></mat-icon>
          {{ translatedText.task_detail_delete_task }}
        </button>
      </div>

      <div class="task_detail_info-container">
        <div class="task_detail_info">
          <div class="task_detail_info_header">
            <div class="task_chips_wrap">
              <div class="task_chip task_chip__type">
                <p>
                  <span
                    class="paragraph table_chip table_chip--{{ task?.type }}"
                    [ngClass]="{
                      'table_chip--hasicon': generateTaskTypeTitle(task?.type, task?.mode).hasIcon,
                    }"
                  >
                    {{ generateTaskTypeTitle(task?.type, task?.mode).title }}
                    <mat-icon
                      *ngIf="generateTaskTypeTitle(task?.type, task?.mode).hasIcon"
                      svgIcon="{{ generateTaskTypeTitle(task?.type, task?.mode).iconName }}"
                    ></mat-icon>
                  </span>
                </p>
              </div>
            </div>
            <div>
              <app-progress-bar *ngIf="task?.finishedTasks == 0" [progress]="0"></app-progress-bar>
              <app-progress-bar
                *ngIf="task?.finishedTasks > 0"
                [progress]="(100 / task?.tasks?.length) * task?.finishedTasks"
              ></app-progress-bar>
            </div>
          </div>
          <div class="task_detail_info_content">
            <div class="content_wrap">
              <div class="inline_text">
                <p class="paragraph">{{ translatedText.task_detail_book }}</p>
                <p class="paragraph paragraph--bold">
                  <span *ngIf="task?.book_name && task?.mode != 'autonomous'">{{
                    replaceHtmlContent(task?.book_name)
                  }}</span>
                  <span *ngIf="task?.mode == 'autonomous'">{{ translatedText.task_detail_dynamic }}</span>
                  <span *ngIf="!task?.book_name">{{ translatedText.task_detail_self }}</span>
                </p>
              </div>
              <div class="inline_text">
                <p class="paragraph">{{ translatedText.task_detail_task }}</p>
                <p class="paragraph paragraph--bold">
                  <span *ngIf="task?.book_name && task?.mode != 'autonomous'">
                    {{ translatedText.task_diagnostic_read }} {{ task?.time }}
                    {{
                      task?.time > 1 ? translatedText.task_diagnostic_minutes : translatedText.task_diagnostic_minute
                    }}
                    {{ replaceHtmlContent(task?.book_name) }}.
                  </span>
                  <span *ngIf="task?.mode == 'autonomous'">-</span>
                  <span *ngIf="!task?.book_name">-</span>
                </p>
              </div>
            </div>
            <div class="content_wrap">
              <div class="inline_text" *ngIf="task?.mode == 'multiple'">
                <p class="paragraph">
                  {{ translatedText.task_detail_multiple }}
                </p>
                <div class="continuous-task-wrap">
                  <p class="paragraph paragraph--bold">{{ generatePercentForContinousTask(tasks) }}%</p>
                  <div class="progress-bar-wrap">
                    <app-progress-bar *ngIf="tasks?.length == 0" [progress]="0"></app-progress-bar>
                    <app-progress-bar *ngIf="tasks?.length > 0" [progress]="generatePercentForContinousTask(tasks)">
                    </app-progress-bar>
                  </div>
                </div>
              </div>
              <div class="inline_text">
                <p class="paragraph">{{ translatedText.task_list_deadline }}:</p>
                <p class="paragraph paragraph--bold">
                  {{ task?.deadline | date: 'dd.MM.yyyy' }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="inline_text">
                        <mat-icon svgIcon="streamline_filled:edit"></mat-icon>
                        <p class="button-text">Aufgabe bearbeiten</p>
                    </div> -->
          <!-- <button mat-flat-button color="unset" type="button">  <mat-icon svgIcon="streamline_filled:add"></mat-icon>Aufgabe bearbeiten</button> -->
        </div>

        <div class="book_cover-wrap" *ngIf="task?.book_isbn && task?.mode != 'autonomous'">
          <div class="book_cover">
            <img
              src="{{ getBookCoverByIsbn(task?.book_isbn, 'normal') }}"
              alt="{{ task?.book_name }}"
              loading="lazy"
              (error)="handleImageError($event, task?.book_isbn)"
              *ngIf="task?.book_isbn"
            />
          </div>
        </div>
      </div>

      <!-- <div class="task_detail_table">
                <div class="set_inline task_tabler_header">
                    <h3 class="h3">Klassenübersicht</h3>
                    <p class="paragraph">Erledigte Aufgaben {{taskComplete}}/{{taskTotal}}</p>
                </div>
                
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
                    <ng-container matColumnDef="username">
                        <th mat-sort-header="username" mat-header-cell *matHeaderCellDef class="mat-column-align--left">
                            <span class="caption">Schüler</span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <span class="paragraph paragraph--bold username-with-avatar">
                                <span><img *ngIf="element.avatarUrl" src="{{element.avatarUrl}}" /></span>{{element.username}}
                            </span>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="completed_at">
                        <th mat-sort-header="completed_at" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                            <span class="caption">{{translatedText.task_detail_completed}} am</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                            <span class="paragraph" *ngIf="element.complete">{{element?.complete | date: 'short'}} Uhr</span>
                            <span class="paragraph" *ngIf="!element.complete">-</span>
                        </td>
                    </ng-container>
                    
                    <ng-container matColumnDef="reading_combined_score">
                        <th mat-sort-header="reading_combined_score" mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                            <span class="caption">{{translatedText.task_detail_reading_points}}</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                            <span class="paragraph" *ngIf="element.complete" [countUp]="element?.reading_combined_score">{{element?.reading_combined_score}}</span>
                            <span class="paragraph" *ngIf="!element.complete">-</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="reading_errors_score">
                        <th mat-header-cell mat-sort-header="reading_errors_score" *matHeaderCellDef class="mat-column-align--center">
                            <span class="caption">{{translatedText.task_detail_reading_errors}}</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                            <span class="paragraph"  *ngIf="element.complete" [countUp]="element?.reading_errors_score">{{element?.reading_errors_score}}</span>
                            <span class="paragraph" *ngIf="!element.complete">-</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="reading_speed_words">
                        <th mat-header-cell mat-sort-header="reading_speed_words" *matHeaderCellDef class="mat-column-align--center">
                            <span class="caption" *ngIf="!isDiagnosticTest && taskLoaded">{{translatedText.task_detail_words_per_minute}}</span>
                            <span class="caption" *ngIf="isDiagnosticTest && taskLoaded">Lesekompetenz</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--center">
                            <span class="paragraph" *ngIf="element.complete && !isDiagnosticTest" [countUp]="element?.reading_speed_words">{{element?.reading_speed_words}}</span>
                            <span class="paragraph" *ngIf="element.complete && isDiagnosticTest">{{generateDiagnosticScore(element?.diagnostic)}}</span>
                            <span class="paragraph" *ngIf="!element.complete">-</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="tendency">
                        <th mat-header-cell *matHeaderCellDef class="mat-column-align--center">
                            <span class="caption">Tendenz zu falscher Satzbetonung</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--center"> 
                            <mat-icon *ngIf="element?.tendency" color="warn" svgIcon="streamline_filled:flag"></mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="complete">
                        <th mat-header-cell  mat-sort-header="complete" *matHeaderCellDef class="mat-column-align--center">
                            <span class="caption">{{translatedText.task_detail_completed}}</span>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--center"> 
                            <mat-icon *ngIf="element.complete" color="primary" svgIcon="streamline_outlined:checkmark"></mat-icon>
                            <mat-icon *ngIf="!element.complete" class="task_incomplete_icon" svgIcon="streamline_outlined:checkmark"></mat-icon>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--right">
                            <ng-container *ngIf="getSubTaskByStudentId(element._id)?.mode == 'multiple'">
                                <ng-container *ngFor="let subTask of getSubTaskByStudentId(element._id)?._split_task">
                                    <button mat-icon-button color="primary" mat-button aria-label="Report" [queryParams]="{studentId: element?._student?._id, sessionUuid: subTask.session_uuid}" [routerLink]="['/student-report/'  + getSubTaskId(element?._student?._id)]">
                                        <mat-icon svgIcon="streamline_filled:next"></mat-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                            
                            <ng-container *ngIf="getSubTaskByStudentId(element._id)?.mode != 'multiple'">
                                <button mat-icon-button color="primary" mat-button aria-label="Report" [disabled]="!element.complete" [queryParams]="{studentId: element?._student?._id}" [routerLink]="['/student-report/'  + getSubTaskId(element?._student?._id)]">
                                    <mat-icon svgIcon="streamline_filled:next"></mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div> -->

      <div class="table-wrapper">
        <div class="table-container">
          <table
            mat-table
            [dataSource]="dataSource?.data"
            multiTemplateDataRows
            class="mat-elevation-z8"
            matSort
            matSortDisableClear
            [matSortActive]="sortBy"
            [matSortDirection]="matSortDirection"
            (matSortChange)="onSortChange($event)"
          >
            <ng-container matColumnDef="username" sticky>
              <th mat-header-cell *matHeaderCellDef>
                <span class="caption">{{ translatedText.task_detail_students }}</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex">
                <span class="paragraph paragraph--bold username-with-avatar">
                  <span
                    ><img *ngIf="element?._student?.avatarUrl" src="{{ element?._student?.avatarUrl }}" alt="" /></span
                  >{{ element?._student?.username }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="reading_combined_score">
              <th
                mat-sort-header="reading_combined_score"
                mat-header-cell
                matTooltip="{{ translatedText.task_detail_readingPointsInfoText }}"
                *matHeaderCellDef
                class="mat-column-align--center"
              >
                <span class="caption">{{ translatedText.task_detail_reading_points }}</span>
              </th>

              <td mat-cell *matCellDef="let element; let i = dataIndex" class="mat-column-align--center">
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'single'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_combined_score')"
                  >{{ getScoreByTaskUuid(element, 'reading_combined_score') }}</span
                >
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'autonomous'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_combined_score')"
                  >{{ getScoreByTaskUuid(element, 'reading_combined_score') }}</span
                >
                <span class="paragraph" *ngIf="element?.mode == 'multiple'">
                  <span
                    *ngIf="element?._split_task.length > 0"
                    [countUp]="sumSplitTaskScore(element?._student._id, 'reading_combined_score')"
                    >{{ sumSplitTaskScore(element?._student._id, 'reading_combined_score') }}</span
                  >
                  <span *ngIf="element?._split_task.length == 0">-</span>
                </span>
                <span class="paragraph" *ngIf="!element?.completed_at && element?.mode != 'multiple'">-</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="reading_errors_score">
              <th
                mat-header-cell
                mat-sort-header="reading_errors_score"
                matTooltip="{{ translatedText.task_detail_readingErrorInfoText }}"
                *matHeaderCellDef
                class="mat-column-align--center"
              >
                <span class="caption">{{ translatedText.task_detail_reading_errors }}</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" class="mat-column-align--center">
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'single'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_errors_score')"
                  >{{ getScoreByTaskUuid(element, 'reading_errors_score') }}</span
                >
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'autonomous'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_errors_score')"
                  >{{ getScoreByTaskUuid(element, 'reading_errors_score') }}</span
                >
                <span class="paragraph" *ngIf="element?.mode == 'multiple'">
                  <span
                    *ngIf="element?._split_task.length > 0"
                    [countUp]="sumSplitTaskScore(element?._student._id, 'reading_errors_score')"
                    >{{ sumSplitTaskScore(element?._student._id, 'reading_errors_score') }}</span
                  >
                  <span *ngIf="element?._split_task.length == 0">-</span>
                </span>
                <span class="paragraph" *ngIf="!element?.completed_at && element?.mode != 'multiple'">-</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="reading_speed_words">
              <th
                mat-header-cell
                mat-sort-header="reading_speed_words"
                matTooltip="{{ translatedText.task_detail_readingSpeedInfoText }}"
                *matHeaderCellDef
                class="mat-column-align--center"
              >
                <span class="caption">{{ translatedText.task_detail_wpm }}</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" class="mat-column-align--center">
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'single'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_speed_words')"
                  >{{ getScoreByTaskUuid(element, 'reading_speed_words') }}</span
                >
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'autonomous'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_speed_words')"
                  >{{ getScoreByTaskUuid(element, 'reading_speed_words') }}</span
                >
                <!-- <span class="paragraph" *ngIf="element?.completed_at && isDiagnosticTest">{{generateDiagnosticScore(element?._student?.diagnostic, element)}}</span> -->
                <span class="paragraph" *ngIf="element?.mode == 'multiple'">
                  <span
                    *ngIf="element?._split_task.length > 0"
                    [countUp]="sumSplitTaskScore(element?._student._id, 'reading_speed_words')"
                    >{{ sumSplitTaskScore(element?._student._id, 'reading_speed_words') }}</span
                  >
                  <span *ngIf="element?._split_task.length == 0">-</span>
                </span>
                <span class="paragraph" *ngIf="!element?.completed_at && element?.mode != 'multiple'">-</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="reading_speed_correct_words_per_minute">
              <th
                mat-sort-header="reading_speed_correct_words_per_minute"
                matTooltip="{{ translatedText.task_detail_readingWordsCorrectPerMinuteInfoText }}"
                mat-header-cell
                *matHeaderCellDef
                class="mat-column-align--center"
              >
                <span class="caption">{{ translatedText.task_detail_wcpm }}</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" class="mat-column-align--center">
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'single'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_speed_correct_words_per_minute')"
                  >{{ getScoreByTaskUuid(element, 'reading_speed_correct_words_per_minute') }}</span
                >
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'autonomous'"
                  [countUp]="getScoreByTaskUuid(element, 'reading_speed_correct_words_per_minute')"
                  >{{ getScoreByTaskUuid(element, 'reading_speed_correct_words_per_minute') }}</span
                >
                <!-- <span class="paragraph" *ngIf="element?.completed_at && isDiagnosticTest">{{generateDiagnosticScore(element?._student?.diagnostic, element)}}</span> -->
                <span class="paragraph" *ngIf="element?.mode == 'multiple'">
                  <span
                    *ngIf="element?._split_task.length > 0"
                    [countUp]="sumSplitTaskScore(element?._student._id, 'reading_speed_correct_words_per_minute')"
                    >{{ sumSplitTaskScore(element?._student._id, 'reading_speed_correct_words_per_minute') }}</span
                  >
                  <span *ngIf="element?._split_task.length == 0">-</span>
                </span>
                <span class="paragraph" *ngIf="!element?.completed_at && element?.mode != 'multiple'">-</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="level">
              <th
                mat-header-cell
                mat-sort-header="level"
                matTooltip="{{ translatedText.task_detail_levelInfoText }}"
                *matHeaderCellDef
                class="mat-column-align--center"
              >
                <span class="caption" *ngIf="(isDiagnosticTest && taskLoaded) || isAutomodeTest">{{
                  translatedText.task_detail_level
                }}</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" class="mat-column-align--center">
                <span
                  class="paragraph"
                  *ngIf="element?.completed_at && element?.mode == 'single' && !isDiagnosticTest"
                  >{{ getScoreByTaskUuid(element, 'level_wcpm') }}</span
                >
                <span
                  class="paragraph"
                  *ngIf="
                    element?.completed_at && element?.mode == 'autonomous' && element.type != 'autonomous-placement'
                  "
                  >{{ getScoreByTaskUuid(element, 'level_wcpm') }}</span
                >
                <span class="paragraph" *ngIf="element?.completed_at && isDiagnosticTest">{{
                  generateDiagnosticScore(element?._student?.diagnostic, element)
                }}</span>
                <span class="paragraph" *ngIf="element?.mode == 'multiple'">
                  <span *ngIf="element?._split_task.length > 0">{{
                    sumSplitTaskScore(element?._student._id, 'level_wcpm')
                  }}</span>
                  <span *ngIf="element?._split_task.length == 0">-</span>
                </span>
                <span class="paragraph" *ngIf="!element?.completed_at && element?.mode != 'multiple'">-</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="complete">
              <th
                mat-header-cell
                mat-sort-header="complete"
                matTooltip="{{ translatedText.task_detail_completed_tooltip }}"
                *matHeaderCellDef
                class="mat-column-align--center"
              >
                <span class="caption">{{ translatedText.task_detail_completed }}</span>
              </th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" class="mat-column-align--center">
                <ng-container *ngIf="element?.mode == 'autonomous'">
                  <ng-container *ngIf="element?.status == 'active' || !element?.completed_at">-</ng-container>

                  <ng-container *ngIf="element?.status == 'finished' && !element.session_processing">
                    <mat-icon
                      [matTooltip]="element?.completed_at | date: 'short'"
                      color="green"
                      svgIcon="streamline_outlined:checkmark"
                    ></mat-icon>
                  </ng-container>

                  <ng-container
                    *ngIf="element?.status == 'finished' && element.session_processing && !getProcessingFailed(element)"
                  >
                    <mat-icon
                      [matTooltip]="translatedText.student_task_list_processing"
                      svgIcon="streamline_outlined:hourglassicon"
                    ></mat-icon>
                  </ng-container>

                  <ng-container *ngIf="getProcessingFailed(element)">
                    <mat-icon
                      [matTooltip]="translatedText.student_task_list_failed"
                      color="red"
                      svgIcon="streamline_outlined:warning-triangle"
                    >
                    </mat-icon>
                  </ng-container>

                  <ng-container *ngIf="element?.status == 'expired'">
                    <mat-icon color="red" svgIcon="streamline_outlined:delete-circle"></mat-icon>
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="element?.mode != 'autonomous'">
                  <ng-container *ngIf="element?.status == 'active'">-</ng-container>

                  <ng-container *ngIf="element?.status == 'finished' && !element.session_processing">
                    <mat-icon
                      [matTooltip]="element?.completed_at | date: 'short'"
                      color="green"
                      svgIcon="streamline_outlined:checkmark"
                    ></mat-icon>
                  </ng-container>

                  <ng-container *ngIf="element?.status == 'finished' && element.session_processing">
                    <mat-icon
                      [matTooltip]="translatedText.student_task_list_processing"
                      svgIcon="streamline_outlined:hourglassicon"
                    ></mat-icon>
                  </ng-container>
                  <ng-container *ngIf="getProcessingFailed(element)">
                    <mat-icon
                      [matTooltip]="translatedText.student_task_list_failed"
                      color="red"
                      svgIcon="streamline_outlined:warning-triangle"
                    >
                    </mat-icon>
                  </ng-container>

                  <ng-container *ngIf="element?.status == 'expired'">
                    <mat-icon color="red" svgIcon="streamline_outlined:delete-circle"></mat-icon>
                  </ng-container>
                </ng-container>
              </td>
            </ng-container>

            <!-- <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element" class="mat-column-align--right">
                            <ng-container *ngIf="getSubTaskByStudentId(element._id)?.mode == 'multiple'">
                                <ng-container *ngFor="let subTask of getSubTaskByStudentId(element._id)?._split_task">
                                    <button mat-icon-button color="primary" mat-button aria-label="Report" [queryParams]="{studentId: element?._student?._id, sessionUuid: subTask.session_uuid}" [routerLink]="['/student-report/'  + getSubTaskId(element?._student?._id)]">
                                        <mat-icon svgIcon="streamline_filled:next"></mat-icon>
                                    </button>
                                </ng-container>
                            </ng-container>
                            
                            <ng-container *ngIf="getSubTaskByStudentId(element._id)?.mode != 'multiple'">
                                <button mat-icon-button color="primary" mat-button aria-label="Report" [disabled]="!element.complete" [queryParams]="{studentId: element?._student?._id}" [routerLink]="['/student-report/'  + getSubTaskId(element?._student?._id)]">
                                    <mat-icon svgIcon="streamline_filled:next"></mat-icon>
                                </button>
                            </ng-container>
                        </td>
                    </ng-container> -->

            <ng-container matColumnDef="button" stickyEnd>
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element; let i = dataIndex">
                <div class="task-action-wrap">
                  <ng-container *ngIf="element?.mode != 'autonomous'">
                    <button
                      alt="Anschauen"
                      mat-mini-fab
                      type="button"
                      matSuffix
                      aria-label="Report"
                      [queryParams]="{ studentId: element?._student?._id }"
                      [routerLink]="['/student-report/' + getSubTaskId(element?._student?._id)]"
                      *ngIf="
                        element?._split_task.length == 0 &&
                        element?.completed_at &&
                        getSubTaskStatus(element?._student?._id) == 'finished'
                      "
                    >
                      <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                      <!-- <span>Auswertung</span> -->
                    </button>
                  </ng-container>
                  <ng-container *ngIf="element?.mode == 'autonomous'">
                    <button
                      alt="Anschauen"
                      mat-mini-fab
                      color="not-filled"
                      type="button"
                      matSuffix
                      aria-label="Report"
                      [queryParams]="{ studentId: element._student._id }"
                      [routerLink]="['/student-report/' + element?._id]"
                      *ngIf="element?.completed_at && element?.status == 'finished'"
                    >
                      <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                      <!-- <span>Auswertung</span> -->
                    </button>
                  </ng-container>
                  <button
                    alt="Nachricht"
                    mat-mini-fab
                    type="button"
                    color="filled"
                    *ngIf="element?._split_task.length > 0 && element?.completed_at"
                    (click)="
                      openNotificationOverlay(
                        element?._id,
                        element?._student,
                        element?._group?.name,
                        element?._group?.school_year,
                        element?.session_uuid
                      )
                    "
                    class="task_list_note"
                    type="button"
                  >
                    <mat-icon svgIcon="streamline_outlined:chat-3"></mat-icon>
                  </button>
                  <button
                    alt="Menu mehr"
                    mat-mini-fab
                    class="not-filled"
                    [matMenuTriggerFor]="moreTeacherMenu"
                    *ngIf="element?._split_task.length == 0 && element?.completed_at"
                    (click)="$event.stopPropagation()"
                    matTooltip="{{ translatedText.task_detail_more_options }}"
                    type="button"
                    matSuffix
                  >
                    <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
                  </button>
                  <mat-menu #moreTeacherMenu="matMenu">
                    <!-- <ng-container *ngIf="element?.mode != 'autonomous'">
                                        <button mat-menu-item color="filled" type="button" matSuffix aria-label="Report" [queryParams]="{studentId: element?._student?._id}" [routerLink]="['/student-report/'  + getSubTaskId(element?._student?._id)]" *ngIf="element?._split_task.length == 0 && element?.completed_at && getSubTaskStatus(element?._student?._id) == 'finished'">
                                            <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                                            <span>Auswertung</span>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="element?.mode == 'autonomous'">
                                        <button mat-menu-item color="filled" type="button" matSuffix aria-label="Report" [queryParams]="{studentId: element._student._id}" [routerLink]="['/student-report/'  + element?._id]" *ngIf="element?.completed_at && element?.status == 'finished'">
                                            <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                                            <span>Auswertung</span>
                                        </button>
                                    </ng-container> -->
                    <button
                      alt="Nachricht hinterlassen"
                      mat-menu-item
                      color="filled"
                      *ngIf="element?.completed_at || element?._split_task.length == 0"
                      (click)="
                        openNotificationOverlay(
                          element?._id,
                          element?._student,
                          element?._group?.name,
                          element?._group?.school_year,
                          element?.session_uuid
                        )
                      "
                      class="task_list_note"
                      type="button"
                    >
                      <mat-icon svgIcon="streamline_outlined:chat-3"></mat-icon>
                      <span>{{ translatedText.task_detail_message }}</span>
                    </button>
                    <button
                      alt="Diagnose wiederholen"
                      mat-menu-item
                      aria-label="view-detail"
                      *ngIf="
                        (element?.type == 'diagnostic-pre' ||
                          element?.type == 'diagnostic-mid' ||
                          element?.type == 'diagnostic-post') &&
                        element?.repeated == 0
                      "
                      (click)="repeatDiagnostic(element?._master_task, element?.type, element?._student._id)"
                      matTooltip="Eine Wiederholung des Diagnosetests ist in der Lizenz inkludiert. Ihr Lizenzguthaben wird durch die Wiederholung nicht reduziert."
                    >
                      <mat-icon svgIcon="streamline_outlined:refresh"></mat-icon>
                      <span>{{ translatedText.task_detail_repeat_diagnose }}</span>
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="expand">
              <th mat-header-cell *matHeaderCellDef aria-label="row actions"></th>
              <td mat-cell *matCellDef="let element; let i = dataIndex" class="expand_more_col">
                <button
                  alt="Mehr anzeigen"
                  mat-icon-button
                  aria-label="expand row"
                  (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
                  *ngIf="element?._split_task?.length > 0"
                >
                  <mat-icon *ngIf="expandedElement !== element">navigate_next</mat-icon>
                  <mat-icon *ngIf="expandedElement === element">expand_more</mat-icon>
                </button>
              </td>
              {{ taskSplitTask }}
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail" *ngIf="taskSplitTask">
              <td
                mat-cell
                *matCellDef="let element"
                class="nested_table_td"
                [attr.colspan]="displayedColumns.length + 1"
              >
                <!-- split task -->
                <table
                  mat-table
                  [dataSource]="element?._split_task"
                  class="example-element-detail nested-table"
                  [ngClass]="element == expandedElement ? 'expanded' : 'collapsed'"
                  [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                >
                  <!-- Define the columns for the nested table -->
                  <ng-container matColumnDef="placeholder">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header expand_more_col"></th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <ng-container *ngIf="nestedElement?.completed_at && !nestedElement.session_processing">
                        <mat-icon
                          [matTooltip]="nestedElement.completed_at | date: 'dd.MM.yyyy'"
                          color="green"
                          svgIcon="streamline_outlined:checkmark"
                        ></mat-icon>
                      </ng-container>

                      <ng-container *ngIf="nestedElement.session_processing">
                        <mat-icon
                          [matTooltip]="translatedText.student_task_list_processing"
                          svgIcon="streamline_outlined:hourglassicon"
                        ></mat-icon>
                      </ng-container>
                      <ng-container *ngIf="getProcessingFailed(nestedElement)">
                        <mat-icon
                          [matTooltip]="translatedText.student_task_list_failed"
                          color="red"
                          svgIcon="streamline_outlined:warning-triangle"
                        >
                        </mat-icon>
                      </ng-container>

                      <ng-container *ngIf="nestedElement.status == 'expired'">
                        <mat-icon color="red" svgIcon="streamline_outlined:delete-circle"></mat-icon>
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_students }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <p class="paragraph paragrap--bold paragraph--s">
                        <span class="paragraph paragraph--bold username-with-avatar">
                          <span
                            ><img
                              *ngIf="element?._student?.avatarUrl"
                              src="{{ element?._student?.avatarUrl }}"
                              alt="" /></span
                          >{{ element?._student?.username }}
                        </span>
                      </p>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reading_combined_score">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_reading_points }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <span
                        class="paragraph"
                        [countUp]="
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_combined_score'
                          )
                        "
                        >{{
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_combined_score'
                          )
                        }}</span
                      >
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reading_errors_score">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_reading_errors }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <span
                        class="paragraph"
                        [countUp]="
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_errors_score'
                          )
                        "
                        >{{
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_errors_score'
                          )
                        }}</span
                      >
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="reading_speed_words">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_words_per_minute }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <span
                        class="paragraph"
                        [countUp]="
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_speed_words'
                          )
                        "
                        >{{
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_speed_words'
                          )
                        }}</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="reading_speed_correct_words_per_minute">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_wcpm }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <span
                        class="paragraph"
                        [countUp]="
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_speed_correct_words_per_minute'
                          )
                        "
                        >{{
                          getMultiTaskModeScore(
                            nestedElement?.session_uuid,
                            element?._student?._id,
                            'reading_speed_correct_words_per_minute'
                          )
                        }}</span
                      >
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="complete">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_completed }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement" class="mat-column-align--center">
                      <ng-container>
                        <mat-icon
                          [matTooltip]="nestedElement.completed_at | date: 'short'"
                          color="green"
                          svgIcon="streamline_outlined:checkmark"
                        ></mat-icon>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="button">
                    <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                      {{ translatedText.task_detail_action }}
                    </th>
                    <td mat-cell *matCellDef="let nestedElement">
                      <div class="task-action-wrap">
                        <button
                          alt="Anzeigen"
                          mat-mini-fab
                          type="button"
                          color="filled"
                          matSuffix
                          aria-label="Report"
                          [queryParams]="{
                            studentId: element?._student?._id,
                            sessionUuid: nestedElement.session_uuid,
                          }"
                          [routerLink]="['/student-report/' + getSubTaskId(element?._student?._id)]"
                          matTooltip="{{ translatedText.task_detail_show_result }}"
                        >
                          <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                        </button>

                        <button
                          alt="Mehr anzeigen"
                          [matMenuTriggerFor]="moreSplitTaskMenu"
                          *ngIf="nestedElement?.completed_at"
                          (click)="$event.stopPropagation()"
                          matTooltip="{{ translatedText.task_detail_more_options }}"
                          mat-mini-fab
                          type="button"
                          class="not-filled"
                          matSuffix
                        >
                          <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
                        </button>
                        <mat-menu #moreSplitTaskMenu="matMenu">
                          <button
                            alt="Nachricht hinterlassen"
                            mat-menu-item
                            color="filled"
                            *ngIf="nestedElement?.completed_at || nestedElement?._split_task.length > 0"
                            (click)="
                              openNotificationOverlay(
                                element?._id,
                                element?._student,
                                element?._group?.name,
                                element?._group?.school_year,
                                nestedElement?.session_uuid
                              )
                            "
                            class="task_list_note"
                            type="button"
                          >
                            <mat-icon svgIcon="streamline_outlined:chat-3"></mat-icon>
                            <span>{{ translatedText.task_detail_message }}</span>
                          </button>
                        </mat-menu>
                      </div>
                    </td>
                  </ng-container>

                  <!-- Replace the below line with your desired nested table columns -->
                  <!-- Add more ng-container elements for additional columns -->

                  <!-- Define the nested table rows -->
                  <tr class="hidden_nested_tr" mat-header-row *matHeaderRowDef="nestedColumns"></tr>
                  <tr mat-row *matRowDef="let nestedElement; columns: nestedColumns"></tr>
                </table>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr
              mat-row
              *matRowDef="let element; columns: columnsToDisplayWithExpand"
              class="example-element-row"
              [class.example-expanded-row]="expandedElement === element"
              (click)="expandedElement = expandedElement === element ? null : element"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </div>
        <mat-paginator
          [hidePageSize]="true"
          [length]="dataSource?.meta?.totalItems"
          [pageSize]="dataSource?.meta?.itemsPerPage"
          [pageSizeOptions]="[10, 25, 100]"
          (page)="pageEvent = $event; onPaginateChange($event)"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
