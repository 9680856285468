<div class="container-fluid container-fluid--bg-gray container-fluid--full-height">
  <div class="row justify-content-center">
    <div class="col-md-12 col-sm-12 help-desk-header">
      <h1 class="h3 h3--bold">{{ translatedText.dashboard_help_desk_title }}</h1>
      <button
        alt="Lizenzcodes prüfen"
        *ngIf="userType == 'support'"
        mat-flat-button
        color="primary"
        (click)="openCheckLicense()"
        type="button"
      >
        <mat-icon svgIcon="streamline_outlined:google-docs"></mat-icon>
        {{ translatedText.dashboard_help_desk_license_check }}
      </button>
      <button
        alt="Schule hinzufügen"
        *ngIf="userType == 'admin'"
        mat-flat-button
        color="primary"
        (click)="openCreateSchool()"
        type="button"
      >
        <mat-icon svgIcon="streamline_outlined:add-circle"></mat-icon>
        {{ translatedText.dashboard_help_desk_add_school }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="help-desk-widget">
        <div class="help-desk_header-wrap">
          <div class="help-desk_header">
            <div>
              <h2 class="h2 h2--bold" [countUp]="schoolsValue">
                {{ schoolsValue }}
              </h2>
              <p class="paragraph paragraph--s">{{ translatedText.dashboard_help_desk_schools }}</p>
            </div>
            <div class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:city"></mat-icon>
            </div>
          </div>
          <div class="widget-border"></div>
          <div class="help-desk_header">
            <div>
              <h2 class="h2 h2--bold" [countUp]="teachers">
                {{ teachers }}
              </h2>
              <p class="paragraph paragraph--s">{{ translatedText.dashboard_help_desk_teachers }}</p>
            </div>
            <div class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:user-star"></mat-icon>
            </div>
          </div>
          <div class="widget-border"></div>
          <div class="help-desk_header">
            <div>
              <h2 class="h2 h2--bold" [countUp]="school_admins">
                {{ school_admins }}
              </h2>
              <p class="paragraph paragraph--s">{{ translatedText.dashboard_help_desk_admins }}</p>
            </div>
            <div class="icon-wrap">
              <mat-icon svgIcon="streamline_outlined:user-crown"></mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row school-table-row">
    <div class="col-md-12 table_header">
      <p class="paragraaph paragraph--bold">
        {{ translatedText.dashboard_help_desk_schools }} ({{ dataSource?.meta.totalItems }})
      </p>
      <div class="toggle-buttons-wrap">
        <mat-button-toggle-group
          #activeViewValue="matButtonToggleGroup"
          [value]="tableSelection"
          (change)="onSelectionChange(activeViewValue.value)"
        >
          <mat-button-toggle value="compact">
            <mat-icon svgIcon="streamline_outlined:table-rows-compact"></mat-icon>
            {{ translatedText.dashboard_help_desk_compact }}
          </mat-button-toggle>
          <mat-button-toggle value="wide">
            <mat-icon svgIcon="streamline_outlined:table-rows"></mat-icon>
            {{ translatedText.dashboard_help_desk_wide }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="school_filter">
      <div class="col-md-3">
        <mat-form-field class="book_list_select task_type_select">
          <div class="task-label-wrap">
            <mat-label>{{ translatedText.dashboard_help_desk_school_type }}</mat-label>
          </div>
          <mat-select
            [formControl]="filterFormGroup.get('school_type')"
            floatLabel="never"
            panelClass="dashboard-task-select"
            disableOptionCentering
            multiple
          >
            <mat-select-trigger>
              {{ generateSelectionText(school_types, filterFormGroup.get('school_type').value) }}
            </mat-select-trigger>
            <app-select-all-option
              [control]="filterFormGroup.get('school_type')"
              [values]="school_types"
              [valuetype]="'object'"
              (selectionChange)="selectSchoolType($event)"
              [translatedText]="translatedText"
            >
            </app-select-all-option>
            <mat-option *ngFor="let type of school_types" [value]="type?.value">
              {{ type.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="book_list_select task_type_select">
          <div class="task-label-wrap">
            <mat-label>{{ translatedText.dashboard_help_desk_state }}</mat-label>
          </div>
          <mat-select
            [formControl]="filterFormGroup.get('state')"
            floatLabel="never"
            panelClass="dashboard-task-select"
            disableOptionCentering
            multiple
          >
            <mat-select-trigger>
              {{ generateSelectionText(statesArray, filterFormGroup.get('state').value) }}
            </mat-select-trigger>
            <app-select-all-option
              [control]="filterFormGroup.get('state')"
              [values]="statesArray"
              [valuetype]="'object'"
              (selectionChange)="selectSchoolState($event)"
              [translatedText]="translatedText"
            >
            </app-select-all-option>
            <mat-option *ngFor="let type of statesArray" [value]="type?.value">
              {{ type.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="book_list_select task_type_select">
          <div class="task-label-wrap">
            <mat-label>{{ translatedText.dashboard_help_desk_city }}</mat-label>
          </div>
          <mat-select
            [formControl]="filterFormGroup.get('city')"
            floatLabel="never"
            panelClass="dashboard-task-select"
            disableOptionCentering
            multiple
          >
            <mat-select-trigger>
              {{ generateSelectionText(cityArray, filterFormGroup.get('city').value) }}
            </mat-select-trigger>
            <app-select-all-option
              [control]="filterFormGroup.get('city')"
              [values]="cityArray"
              [valuetype]="'object'"
              (selectionChange)="selectSchoolCity($event)"
              [translatedText]="translatedText"
            ></app-select-all-option>
            <mat-option *ngFor="let city of cityArray" [value]="city?.value">
              {{ city.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field class="full-width search-school" floatLabel="never">
          <mat-label>{{ translatedText.dashboard_help_desk_search }}</mat-label>
          <input
            matInput
            type="text"
            (input)="onSearchInputFilterChange($event)"
            placeholder="{{ translatedText.dashboard_help_desk_search }}"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="col-md-12">
      <mat-table
        [dataSource]="dataSource?.data"
        matSort
        matSortDisableClear
        [matSortActive]="sortBy"
        [matSortDirection]="matSortDirection"
        (matSortChange)="onSortChange($event)"
      >
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_school_name
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s" [ngStyle]="paragraphStyles">
              {{ element?.name }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="school_type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Schulart</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s" [ngStyle]="paragraphStyles">
              {{ element?.school_type }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_table_school_address
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s" [ngStyle]="paragraphStyles">{{ element?.address }} {{ element?.zip }}</p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_table_school_city
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s" [ngStyle]="paragraphStyles">
              {{ element?.city }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_table_school_state
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s" [ngStyle]="paragraphStyles">
              {{ getFullStateName(element?.state) }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalAmountLeftDi">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_table_diagnostic
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s">
              <ng-container *ngIf="element?.totalAmountLeftDi === 0"> - </ng-container>
              <ng-container *ngIf="element?.totalAmountLeftDi !== 0">
                {{ element?.totalAmountLeftDi }}
              </ng-container>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalAmountLeftFo">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_table_training
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s">
              <ng-container *ngIf="element?.totalAmountLeftFo === 0"> - </ng-container>
              <ng-container *ngIf="element?.totalAmountLeftFo !== 0">
                {{ element?.totalAmountLeftFo }}
              </ng-container>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="schoolAdminsCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_admins
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s">
              <ng-container *ngIf="element?.schoolAdminsCount === 0"> - </ng-container>
              <ng-container *ngIf="element?.schoolAdminsCount !== 0">
                {{ element?.schoolAdminsCount }}
              </ng-container>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="teacherCount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{
            translatedText.dashboard_help_desk_teachers
          }}</mat-header-cell>
          <mat-cell *matCellDef="let element">
            <p class="paragraph paragraph--s">
              <ng-container *ngIf="element?.teacherCount === 0"> - </ng-container>
              <ng-container *ngIf="element?.teacherCount !== 0">
                {{ element?.teacherCount }}
              </ng-container>
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button
              alt="Weiter"
              mat-icon-button
              type="button"
              matSuffix
              [routerLink]="['/school-detail/' + element?._id]"
            >
              <mat-icon svgIcon="streamline_outlined:nav-arrow-right"></mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-footer-row *matFooterRowDef="['noRecord']"></mat-footer-row>
        <ng-container matColumnDef="noRecord">
          <mat-footer-cell *matFooterCellDef colspan="2">
            <div class="no_records" [hidden]="dataSource?.data.length > 0">
              <img src="../../../assets/empty.svg" alt="" />
              <p class="paragraph paragraph--bold paragraph--xl">{{ translatedText.dashboard_help_desk_no_school }}</p>
            </div>
          </mat-footer-cell>
        </ng-container>
      </mat-table>

      <mat-paginator
        [hidePageSize]="true"
        [length]="dataSource?.meta.totalItems"
        [pageSize]="
          dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
            ? dataSource?.meta.itemsPerPage
            : dataSource?.meta.totalItems
        "
        [pageSizeOptions]="[10, 25, 100]"
        (page)="pageEvent = $event; onPaginateChange($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
