<ng-container>
  <button
    alt="Schließen"
    mat-mini-fab
    [disabled]="isLoading"
    type="button"
    [mat-dialog-close]="'close'"
    class="close-button close-button--right"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <div [hidden]="secondStep">
    <div class="teacher-selection-info">
      <form [formGroup]="rForm" #formDirective="ngForm">
        <!-- *ngIf="teachers.length > 0" -->
        <h3 class="h3 h3--bold">{{ translatedText.teacher_selection_title }}</h3>
        <p class="paragraph">{{ translatedText.teacher_selection_description }}</p>
        <mat-form-field class="group-select">
          <mat-select formControlName="teacher" panelClass="admin-group-select" disableOptionCentering>
            <mat-option class="teacher-selection" *ngFor="let teacher of teachers" [value]="teacher._id">
              <div class="teacher_with_icon teacher_with_icon--gray option">
                <button alt="Benutzer" mat-mini-fab>
                  <mat-icon svgIcon="streamline_filled:round-user"></mat-icon>
                </button>
                <p class="paragraph paragraph--s">
                  {{ teacher?.name }}
                  <span *ngIf="teacher?._user.role == 'school_admin'">(A)</span>
                </p>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
      <div class="teacher-selection-info_button">
        <button
          alt="Nicht übertragen"
          mat-flat-button
          [disabled]="isLoading"
          color="secondary"
          (click)="transferTasks = false"
          (click)="notAssignTeacher()"
        >
          Nicht übertragen
        </button>
        <button
          alt="Speichern"
          [disabled]="rForm.invalid || isLoading"
          mat-flat-button
          color="primary"
          (click)="nextStep(teacherId)"
        >
          <span *ngIf="!isLoading">Speichern</span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </div>
  </div>
  <div [hidden]="!secondStep" class="teacher-delete-confirmation">
    <mat-icon svgIcon="streamline_outlined:checkmark-2" class="check-icon"></mat-icon>
    <h3 class="h3 h3--bold">Lehrkraft erfolgreich entfernt!</h3>

    <ng-container *ngIf="!replaceTeacher">
      <p>The students can still see the tasks, but they will not be assigned to any teacher.</p>
      <button alt="In ordnung" (click)="deleteTeacher()" mat-flat-button color="primary">In ordnung</button>
    </ng-container>

    <ng-container *ngIf="replaceTeacher">
      <p>Die Aufgaben wurden an die angegebene Lehrkraft übertragen.</p>
      <div class="teachers-icons-reassign">
        <div class="icons-wrap">
          <mat-icon svgIcon="streamline_filled:user" class="user-icon"></mat-icon>
          <p class="paragraph--xl light-text">{{ teacherName }}</p>
        </div>
        <div class="icons-wrap">
          <mat-icon svgIcon="streamline_outlined:arrow-right" class="arrow-icon"></mat-icon>
        </div>
        <div class="icons-wrap">
          <mat-icon svgIcon="streamline_filled:user" class="user-icon"> </mat-icon>
          <p class="paragraph--xl">{{ selectedTeacher }}</p>
        </div>
      </div>
      <button alt="In ordnung" (click)="submitForm(formDirective)" mat-flat-button color="primary">In ordnung</button>
    </ng-container>
  </div>
</ng-container>

<!-- <div class="row">
    <div class="col-12">
      <div class="teacher-selection-info">
        <mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon>
        <h3 class="h3 h3--bold">{{ translatedText.teacher_selection_question }}</h3>
        <div class="teacher-selection-info_button">
          <button mat-flat-button [disabled]="isLoading" color="secondary" (click)="removeTeacherFromGroup()">
            {{ translatedText.teacher_selection_no }}
          </button>

          <button [disabled]="isLoading" mat-flat-button color="primary" (click)="chooseTeacherForGroup()">
            {{ translatedText.teacher_selection_yes }}
          </button>
        </div>
      </div>
    </div>
  </div> -->

<!--  <div class="teacher-selection-info" *ngIf="teachers.length == 0">
        <h3 class="h3 h3--bold">{{ translatedText.teacher_selection_only_one }}</h3>
        <p class="paragraph">{{ translatedText.teacher_selection_add_teacher }}</p>

        <div class="teacher-selection-info_button">
          <button mat-flat-button [disabled]="isLoading" color="secondary" (click)="cancelRemoval()">
            {{ translatedText.teacher_selection_cancel }}
          </button>

          <button [disabled]="isLoading" mat-flat-button color="primary" (click)="removeTeacherFromGroup()">
            <span *ngIf="!isLoading">{{ translatedText.teacher_selection_remove }}</span>
            <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
          </button>
        </div>
      </div> -->
