import { isPlatformBrowser } from '@angular/common';
import { APP_ID, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  isBrowser;
  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(APP_ID) private appId: string,
    private authService: AuthService,
    private router: Router,
  ) {
    // check if server
    this.isBrowser = isPlatformBrowser(platformId);
    const platform = isPlatformBrowser(platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean | Observable<boolean> | Promise<boolean> {
    const isAuth = this.authService.getIsAuth();
    const userRole = this.authService.getType();
    if (!isAuth) {
      if (
        userRole &&
        userRole !== '' &&
        (userRole === 'school_admin' || userRole === 'teacher')
      ) {
        this.router.navigate(['/login/teacher']);
      } else {
        this.router.navigate(['/login']);
      }
    }
    return isAuth;
  }

  isTouchDevice() {
    if (this.isPlatformBrowser()) {
      if ('ontouchstart' in document.documentElement) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isPlatformBrowser() {
    return this.isBrowser;
  }
}
