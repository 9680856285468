<section>
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    (click)="closeButton.emit(true)"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="training-task_wrap" [style.display]="selected ? 'block' : 'none'">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>
</section>
