<div class="microphone-check_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="true"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="microphone-check_start" *ngIf="isStartScreen">
    <img src="../../assets/microphone-check.svg" alt="microphone-check" />
    <h3 class="h3 h3--bold">{{ translatedText.microphone_check_title }}</h3>
    <p class="paragraph paragraph--xl">{{ translatedText.microphone_check_desc }}</p>
    <div class="view_button">
      <button alt="Test starten" mat-flat-button color="primary-student" type="button" (click)="showRecordScreen()">
        {{ translatedText.microphone_check_start }}
      </button>
    </div>
  </div>

  <div class="microphone-check_info" *ngIf="isRecordScreen">
    <img src="../../assets/smile-with-headphones.svg" alt="microphone-check" />
    <h3 class="h3 h3--bold">{{ translatedText.microphone_check_mic_text }}</h3>
    <p class="paragraph paragraph--xl">{{ translatedText.microphone_check_seconds }}</p>

    <div class="view_button">
      <div class="button-wrap">
        <div class="button-border">
          <button
            alt="Aufnahme starten"
            mat-icon-button
            type="button"
            class="mic-button"
            matSuffix
            (click)="startRecording()"
          >
            <mat-icon svgIcon="streamline_outlined:mic2"></mat-icon>
          </button>
        </div>
        <p class="paragraph paragraph--l paragraph--bold">{{ translatedText.microphone_check_start_recording }}</p>
      </div>
    </div>
  </div>

  <div class="microphone-check_info microphone-check_info--rec" *ngIf="isStartRecording">
    <img src="../../assets/smile-with-headphones.svg" alt="microphone-check" />
    <h3 class="h3 h3--bold">{{ translatedText.microphone_check_question_text }}</h3>
    <p class="paragraph paragraph--xl">{{ randomQuestion.value }}</p>
    <div class="view_button">
      <div class="button-wrap" *ngIf="!isStopRecording && isAudioAvalable">
        <div class="progress-wrapper">
          <!-- <button  mat-icon-button type="button" class="mic-button"  matSuffix>
                        <mat-icon svgIcon="streamline_outlined:mic2"></mat-icon>
                    </button> -->
          <div class="button-border">
            <div class="loader">
              <mat-icon svgIcon="streamline_outlined:mic2"></mat-icon>
            </div>
          </div>
        </div>

        <p class="paragraph paragraph--l paragraph--bold">{{ translatedText.microphone_check_continue }}</p>
      </div>
      <div class="button-wrap" *ngIf="isStopRecording">
        <div class="button-border">
          <button alt="Prima!" mat-icon-button type="button" class="stop-rec-button" matSuffix>
            <mat-icon svgIcon="streamline_outlined:check"></mat-icon>
          </button>
        </div>
        <p class="paragraph paragraph--l paragraph--bold">{{ translatedText.microphone_check_good }}</p>
      </div>
    </div>
  </div>

  <div class="microphone-check_info microphone-check_info--audio-screen" *ngIf="isAudioScreen">
    <img src="../../assets/audio-screen.svg" alt="microphone-check" />
    <h3 class="h3 h3--bold">{{ translatedText.microphone_check_listen_recording }}</h3>
    <div class="audio-container">
      <div class="audio_wrap">
        <audio [src]="audioUrl" controls controlsList="nodownload" preload="none" *ngIf="audioUrl" alt=""></audio>
      </div>
      <div class="button-border">
        <button
          alt="Wiederholen"
          mat-icon-button
          type="button"
          class="mic-button mic-button--repeat"
          matSuffix
          (click)="repeatTest()"
        >
          <mat-icon svgIcon="streamline_outlined:mic-repeat"></mat-icon>
        </button>
      </div>
    </div>
    <div class="view_button">
      <button alt="Ich höre nichts" mat-flat-button color="secondary-student" type="button" (click)="faqScreen()">
        {{ translatedText.microphone_check_cant_hear }}
      </button>
      <button
        alt="Ich höre meine Stimme"
        mat-flat-button
        color="primary-student"
        type="button"
        (click)="finishedScreen()"
      >
        {{ translatedText.microphone_check_can_hear }}
      </button>
    </div>
  </div>

  <div class="microphone-check_info microphone-check_info--finished" *ngIf="isFinished">
    <img src="../../assets/mic-test-finished.svg" alt="microphone-check" />
    <h3 class="h3 h3--bold">{{ translatedText.microphone_check_good_text }}</h3>
    <div class="view_button">
      <button alt="ok" mat-flat-button color="primary-student" type="button" [mat-dialog-close]="true">Ok</button>
    </div>
  </div>

  <div class="microphone-check_info microphone-check_info--finished" *ngIf="isFAQ">
    <h3 class="h3 h3--bold">{{ translatedText.microphone_check_nothing }}</h3>
    <p class="paragraph paragraph--xl">{{ translatedText.microphone_check_accordion_title }}</p>
    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="paragraph paragraph--l paragraph--bold accordion-headline">
              {{ translatedText.microphone_check_check_connections }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="paragraph">{{ translatedText.microphone_check_check_connections_text }}</p>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="paragraph paragraph--l paragraph--bold accordion-headline">
              {{ translatedText.microphone_check_check_sound }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="paragraph">{{ translatedText.microphone_check_check_sound_text }}</p>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="paragraph paragraph--l paragraph--bold accordion-headline">
              {{ translatedText.microphone_check_restart }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="paragraph">{{ translatedText.microphone_check_restart_text }}</p>
      </mat-expansion-panel>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <p class="paragraph paragraph--l paragraph--bold accordion-headline">
              {{ translatedText.microphone_check_help }}
            </p>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p class="paragraph">{{ translatedText.microphone_check_help_text }}</p>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="view_button">
      <button alt="Versuch es noch einmal" mat-flat-button color="primary-student" type="button" (click)="repeatTest()">
        {{ translatedText.microphone_check_retry }}
      </button>
    </div>
  </div>
</div>
