<section class="step_container">
  <!-- STEPPER HEADER -->
  <div class="header-wrapper">
    <div class="cancel-btn-wrapper">
      <button alt="Abbrechen" mat-flat-button class="close-button-with-text" type="button" matSuffix mat-dialog-close>
        <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
        {{ translatedText.admin_group_create_cancel }}
      </button>
    </div>
    <div class="middle_chip_wrapper">
      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">{{ translatedText.training_task_create_chip }}</div>
        </div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <button alt="Zurück" mat-flat-button color="unset" (click)="previousStep()" *ngIf="step != 1 && !isLoading">
        {{ translatedText.students_add_back }}
      </button>
      <button
        alt="Weiter"
        *ngIf="step < 7"
        mat-flat-button
        color="primary"
        class="submit-button"
        (click)="nextStep()"
        [disabled]="!stepValidated || (studentsArray.length === 0 && step === 2)"
        matStepperNext
      >
        {{ translatedText.students_add_next }}
      </button>
      <button
        alt="Aufgabe erstellen"
        mat-flat-button
        color="primary"
        class="submit-button"
        (click)="submitTask()"
        *ngIf="step === 7 && !isLoading"
      >
        {{ translatedText.training_task_create_create }}
      </button>
    </div>
  </div>

  <div>
    <mat-stepper linear #stepper>
      <!-- [ALL TYPES OF TASKS] step 1: mode and type of task-->
      <mat-step [stepControl]="taskAreaStep" *ngIf="step === 1">
        <div class="training-task_create_headline">
          <h1>
            {{ translatedText.training_task_create_title_step_1 }}
          </h1>
        </div>
        <app-hint-box
          [title]="translatedText.training_task_create_hint_next_button_title"
          [text]="translatedText.training_task_create_hint_next_button"
          id="task-creation-next-button-hint"
          icon="glasses"
        ></app-hint-box>

        <div class="training-task-container first-step">
          <div class="training-task_selector">
            <h3 class="h3 h3--bold font-22">{{ translatedText.training_task_create_test_area_48title }}</h3>
            <button alt="Leseflüssigkeit" class="training-task_selector active">
              <!-- permanently active until other areas are implemented -->
              <div class="training-task_button_wrap">
                <mat-icon svgIcon="streamline_outlined:reading_fluency_2"></mat-icon>
                <div class="training-task_selector_content">
                  <p class="paragraph paragraph--xl paragraph--bold">
                    {{ translatedText.training_task_create_fluency_title }}
                  </p>
                  <p class="paragraph simple-text">
                    {{ translatedText.training_task_create_fluency_description }}
                  </p>
                </div>
              </div>
            </button>
          </div>
          <h3 class="h3 h3--bold font-22">{{ translatedText.training_task_create_task_type_title }}</h3>
          <div class="training-task_selector flex-wrap">
            <button
              alt="Lesetext auswählen"
              class="training-task_selector small-btn"
              [ngClass]="{ active: taskType === 'text' }"
              (click)="setTaskTypeData('text')"
            >
              <div class="training-task_button_wrap small-btn">
                <mat-icon svgIcon="streamline_outlined:specific_text_2" class="icon-42"></mat-icon>
                <div class="training-task_selector_content">
                  <p class="paragraph paragraph--xl paragraph--bold">
                    {{ translatedText.training_task_create_specific_text_title }}
                  </p>
                  <p class="paragraph simple-text">
                    {{ translatedText.training_task_create_specific_text_description }}
                  </p>
                </div>
              </div>
            </button>
            <button
              alt="Lesezeit festlegen"
              class="training-task_selector small-btn"
              [ngClass]="{ active: taskType === 'time' }"
              (click)="setTaskTypeData('time')"
            >
              <div class="training-task_button_wrap small-btn">
                <mat-icon
                  svgIcon="streamline_outlined:specific_time"
                  class="icon-42"
                  [ngClass]="{ active: taskType === 'time' }"
                ></mat-icon>
                <div class="training-task_selector_content">
                  <p class="paragraph paragraph--xl paragraph--bold">
                    {{ translatedText.training_task_create_specific_time_title }}
                  </p>
                  <p class="paragraph simple-text">
                    {{ translatedText.training_task_create_specific_time_description }}
                  </p>
                </div>
              </div>
            </button>
            <!-- [disabled]="isMaintenanceMode || contentType != 'klett'" -->
            <button
              alt="Auto Modus"
              class="training-task_selector small-btn automode-btn"
              [ngClass]="{ active: taskType === 'auto' }"
              (click)="setTaskTypeData('auto')"
              [disabled]="contentType === 'klett' ? true : null"
            >
              <div class="training-task_button_wrap small-btn">
                <mat-icon svgIcon="streamline_outlined:automode_2" class="icon-42"></mat-icon>
                <div class="training-task_selector_content">
                  <p class="paragraph paragraph--xl paragraph--bold">
                    {{ translatedText.training_task_create_auto_title }}
                    <span *ngIf="contentType != 'full'"> (Beta)</span>
                  </p>
                  <span *ngIf="contentType != 'klett'" class="admin-chip" matTooltip="{{ betaInfoText }}">
                    {{ translatedText.training_task_create_beta }}
                  </span>
                  <span *ngIf="contentType === 'klett'" class="admin-chip gray"
                    >{{ translatedText.training_task_create_only_available }}
                  </span>
                  <p class="paragraph simple-text">
                    {{ translatedText.training_task_create_auto_description }}
                  </p>
                </div>
              </div>
            </button>
          </div>
        </div>
      </mat-step>

      <!-- [ALL TYPES OF TASKS] step 2: group/students selection - create separate component -->
      <mat-step [stepControl]="groupSelectStep" *ngIf="step === 2">
        <div class="training-task_create_headline">
          <h1>
            {{ translatedText.training_task_create_who }}
          </h1>
          <p class="paragraph paragraph--xl">
            {{ translatedText.training_task_create_who_description }}
          </p>
        </div>

        <app-individual-learners-selection
          (selectedStudents)="getSelectedStudentsList($event)"
          (totalStudentsEachClass)="(totalStudentsEachClass)"
          [source]="'training'"
          [autoMode]="taskType === 'auto' ? true : false"
          [selectedTypeDetails]="'training'"
        >
        </app-individual-learners-selection>
      </mat-step>
      <!-- [AUTOMODE] step 3: task frequency -->
      <mat-step *ngIf="step === 3 && taskType === 'auto'">
        <app-automode-task-frequency (weekdaysSelect)="getSelectedWeekdays($event)"></app-automode-task-frequency>
      </mat-step>
      <!-- [TIME BASED AND TEXT BASED TASKS] step 4: book selection -->
      <mat-step *ngIf="step === 4">
        <div class="training-task_create_headline">
          <h1>
            {{ translatedText.book_list_selection_text }}
          </h1>
        </div>
        <app-book-list
          [contentType]="contentType"
          [taskType]="taskType"
          (bookListData)="bookListData($event)"
          (isTimeChoice)="setTimeChoiceTask($event)"
        ></app-book-list>
      </mat-step>

      <!-- [TIME BASED AND TEXT BASED TASKS] step 5: text settings and text view -->
      <mat-step *ngIf="step === 5" [stepControl]="readerSetting">
        <div class="training-task_create_headline">
          <h1>
            {{ translatedText.training_task_create_text_options }}
          </h1>
          <p *ngIf="taskType === 'text'">{{ translatedText.training_task_create_select_text }}</p>
        </div>

        <div class="settings_book_view">
          <form [formGroup]="readerSetting">
            <div class="form_content">
              <div class="timer-wrapper" *ngIf="taskType === 'text'">
                <span class="inline_text paragraph--bold">
                  <mat-icon class="small_time_icon" svgIcon="streamline_outlined:specific_time"></mat-icon>
                  <span class="reading-countdown">{{
                    180 - selectionTimer | readingTimeFormat | timeformat: 'min'
                  }}</span>
                </span>
                <p class="paragraph paragraph--s">Verbleibende Zeit zur Auswahl der Textpassage</p>
              </div>

              <div class="settings_item_wrap font-selector">
                <p class="paragraph paragraph--xl">
                  {{ translatedText.training_task_create_font }}
                </p>
                <mat-form-field>
                  <mat-icon matSuffix>expand_more</mat-icon>
                  <mat-select
                    type="role"
                    formControlName="font_family"
                    (selectionChange)="updateReaderOptions($event, 'fontFamily')"
                    disableOptionCentering
                  >
                    <mat-option value="abeezee">ABeeZee</mat-option>
                    <mat-option value="poppins">Poppins</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="settings_items_container">
                <div class="settings_item_wrap">
                  <p class="paragraph paragraph--xl">
                    {{ translatedText.training_task_create_font_size }}
                  </p>
                  <mat-button-toggle-group
                    name="fontSize"
                    formControlName="font_size"
                    (change)="updateReaderOptions($event, 'fontSize')"
                    [value]="fontSize"
                    aria-label="Font size"
                    [ngModel]="fontSize"
                  >
                    <mat-button-toggle class="fontSize fontSize__small" value="small">T</mat-button-toggle>
                    <mat-button-toggle class="fontSize fontSize__normal" value="normal">T</mat-button-toggle>
                    <mat-button-toggle class="fontSize fontSize__big" value="big">T</mat-button-toggle>
                  </mat-button-toggle-group>
                </div>

                <div class="settings_item_wrap">
                  <p class="paragraph paragraph--xl">
                    {{ translatedText.training_task_create_lines }}
                  </p>
                  <mat-button-toggle-group
                    name="line_height"
                    formControlName="line_height"
                    (change)="updateReaderOptions($event, 'line_height')"
                    [value]="line_height"
                    aria-label="Line height"
                    [ngModel]="line_height"
                  >
                    <mat-button-toggle class="line_height line_height__small" value="small">
                      {{ translatedText.training_task_create_good }} <br />
                      {{ translatedText.training_task_create_world }}</mat-button-toggle
                    >
                    <mat-button-toggle class="line_height line_height__normal" value="normal">
                      {{ translatedText.training_task_create_good }} <br />
                      {{ translatedText.training_task_create_world }}</mat-button-toggle
                    >
                    <mat-button-toggle class="line_height line_height__big" value="big">
                      {{ translatedText.training_task_create_good }} <br />
                      {{ translatedText.training_task_create_world }}</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="settings_items_container">
                <div class="settings_item_wrap">
                  <p class="paragraph paragraph--xl">
                    {{ translatedText.training_task_create_space }}
                  </p>
                  <mat-button-toggle-group
                    name="letter_spacing"
                    formControlName="letter_spacing"
                    (change)="updateReaderOptions($event, 'letter_spacing')"
                    [value]="letter_spacing"
                    aria-label="Letter spacing"
                    [ngModel]="letter_spacing"
                  >
                    <mat-button-toggle class="letter_spacing letter_spacing__small" value="small">
                      {{ translatedText.training_task_create_good }} <br />
                      {{ translatedText.training_task_create_world }}</mat-button-toggle
                    >
                    <mat-button-toggle class="letter_spacing letter_spacing__normal" value="normal">
                      {{ translatedText.training_task_create_good }} <br />
                      {{ translatedText.training_task_create_world }}</mat-button-toggle
                    >
                    <mat-button-toggle class="letter_spacing letter_spacing__big" value="big">
                      {{ translatedText.training_task_create_good }} <br />
                      {{ translatedText.training_task_create_world }}</mat-button-toggle
                    >
                  </mat-button-toggle-group>
                </div>

                <div class="settings_item_wrap">
                  <p class="paragraph paragraph--xl">
                    {{ translatedText.training_task_create_syllable }}
                  </p>
                  <mat-button-toggle-group
                    name="hyphenation_mode"
                    formControlName="hyphenation_mode"
                    (change)="updateReaderOptions($event, 'hyphenation_mode')"
                    [value]="hyphenation_mode"
                    aria-label="Hyphenation mode"
                    [ngModel]="hyphenation_mode"
                  >
                    <mat-button-toggle class="hyphenation_mode" value="option0">
                      {{ translatedText.training_task_create_none }} <br />
                      {{ translatedText.training_task_create_syllable }}</mat-button-toggle
                    >
                    <mat-button-toggle class="hyphenation_mode" value="option1">
                      <div class="hyphenation_mode_wrap">
                        <img src="./assets/blue_circle.svg" alt="blue circle" />
                        <img src="./assets/red_circle.svg" alt="Red circle" />
                      </div>
                    </mat-button-toggle>
                    <mat-button-toggle class="hyphenation_mode" value="option2">
                      <div class="hyphenation_mode_wrap">
                        <img src="./assets/teal_circle.svg" alt="teal circle" />
                        <img src="./assets/orange_circle.svg" alt="orange circle" />
                      </div>
                    </mat-button-toggle>

                    <mat-button-toggle class="hyphenation_mode" value="option3">
                      <div class="hyphenation_mode_wrap">
                        <img src="./assets/gray_circle.svg" alt="gray circle" />
                        <img src="./assets/black_circle.svg" alt="black circle" />
                      </div>
                    </mat-button-toggle>
                  </mat-button-toggle-group>
                </div>
              </div>
              <div class="settings_item_wrap toggle">
                <p class="paragraph paragraph--xl">
                  {{ translatedText.training_task_create_text_options_allow }}
                </p>
                <div class="chart_toggle">
                  <input
                    type="checkbox"
                    formControlName="editable"
                    (change)="optionSetting($event, 'reader')"
                    id="switch"
                  /><label for="switch"></label>
                </div>
              </div>
            </div>
          </form>
          <app-task-creation-book-view
            *ngIf="!studentsChooseBook"
            [bookData]="bookData"
            [taskType]="taskType"
            (selectionTime)="getSelectionTime($event)"
            (validateStep)="validateStep3($event)"
            [readerSettingValue]="readerSettingValue"
            class="book-view"
            fromComponent="bookview"
          >
          </app-task-creation-book-view>

          <!-- ANY BOOK SELECTION - FAKE TEXT -->
          <div *ngIf="studentsChooseBook" class="any-book-wrapper">
            <p class="paragraph fake-text-placeholder">
              <i>{{ translatedText.training_task_create_fake_text_placeholder }}</i>
            </p>
            <p class="paragraph paragraph--xl paragraph--bold">
              {{ translatedText.training_task_create_fake_any_book_title }}
            </p>
            <div>
              <p class="paragraph font-19" #fakeContent id="fakeContent">
                <ng-container *ngIf="['de_de', 'de', 'de_ch'].includes(getCurrentLanguage())">
                  <span class="word"><span class="hyphen1 ml-6">Ali</span><span class="hyphen2">ce</span> </span>
                  <span class="word">&nbsp;<span class="hyphen1">fing</span> </span>
                  <span class="word">&nbsp;<span class="hyphen2">an</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sich</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">zu</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">lang</span><span class="hyphen2">wei</span
                    ><span class="hyphen1">en;</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">sie</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">saß</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">schon</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">lan</span><span class="hyphen2">ge</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">bei</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ih</span><span class="hyphen2">rer</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Schwes</span><span class="hyphen2">ter</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">am</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Ufer</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">und</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">hat</span><span class="hyphen2">te</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">nichts</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">zu</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">tun.</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Das</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Buch,</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">das</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ih</span><span class="hyphen1">re</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Schwes</span><span class="hyphen1">ter</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">las,</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ge</span><span class="hyphen2">fiel</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ihr</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">nicht;</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">denn</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">es</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">wa</span><span class="hyphen2">ren</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">we</span><span class="hyphen2">der</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Bil</span><span class="hyphen2">der</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">noch</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">Ge</span><span class="hyphen1">prä</span
                    ><span class="hyphen2">che</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">da</span><span class="hyphen2">rin.</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">„Und</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">was</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">nüt</span><span class="hyphen1">zen</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Bücher,“</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">dach</span><span class="hyphen2">te</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Ali</span><span class="hyphen2">ce,</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">„oh</span><span class="hyphen2">ne</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Bil</span><span class="hyphen2">der</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">und</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">Ge</span><span class="hyphen1">prä</span
                    ><span class="hyphen2">che?“</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">Sie</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">über</span><span class="hyphen1">leg</span
                    ><span class="hyphen2">te</span></span
                  >
                  <span class="word">&nbsp; <span class="hyphen1">sich</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">eben,</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">(so,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">gut</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">es</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ging</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">denn</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sie</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">war</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">schläf</span><span class="hyphen1">rig</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">und</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">dumm</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">vor</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">der</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Hil</span><span class="hyphen1">ze)</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ob</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">es</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">der</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Mühe</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">wert</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sei</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">auf</span><span class="hyphen1">zu</span
                    ><span class="hyphen2">ste</span><span class="hyphen1">hen</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">und</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">Gan</span><span class="hyphen2">se</span
                    ><span class="hyphen1">blüm</span><span class="hyphen2">chen</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">zu</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">pflü</span><span class="hyphen1">cken,</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">um</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ei</span><span class="hyphen1">ne</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Ket</span><span class="hyphen1">te</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">da</span><span class="hyphen1">mit</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">zu</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ma</span><span class="hyphen1">chen,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">als</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">plötz</span><span class="hyphen2">lich</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ein</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">wei</span><span class="hyphen1">ßes</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">Ka</span><span class="hyphen1">nin</span
                    ><span class="hyphen2">chen</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">mit</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ro</span><span class="hyphen1">ten</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Au</span><span class="hyphen1">gen</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">dicht</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">an</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ihr</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">vor</span><span class="hyphen2">bei</span
                    ><span class="hyphen1">rann</span><span class="hyphen2">te.</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">war</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">gra</span><span class="hyphen1">de</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">nicht</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sehr</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">merk</span><span class="hyphen1">wür</span
                    ><span class="hyphen2">dig;</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">Ali</span><span class="hyphen2">ce</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">fand</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">es</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">auch</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">nicht</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sehr</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">au</span><span class="hyphen1">ßer</span><span class="hyphen2">or</span
                    ><span class="hyphen1">dent</span><span class="hyphen2">lich,</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">daß</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sie</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">das</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">Ka</span><span class="hyphen1">nin</span
                    ><span class="hyphen2">chen</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">sa</span><span class="hyphen2">gen</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">hör</span><span class="hyphen2">te:</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">„O</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">weh,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">o</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">weh!</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Ich</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">wer</span><span class="hyphen2">de</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">zu</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">spät</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">kom</span><span class="hyphen2">men!“</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">(Als</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sie</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">es</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">spät</span><span class="hyphen1">er</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">wie</span><span class="hyphen1">der</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">über</span><span class="hyphen1">leg</span
                    ><span class="hyphen2">te,</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">fiel</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ihr</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ein,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">daß</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sie</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sich</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">da</span><span class="hyphen2">rü</span
                    ><span class="hyphen1">ber</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">hät</span><span class="hyphen1">te</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">wun</span><span class="hyphen1">dern</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sol</span><span class="hyphen1">len;</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">doch</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">zur</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Zeit</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">kam</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ihr</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Al</span><span class="hyphen2">les</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ganz</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">na</span><span class="hyphen1">tür</span
                    ><span class="hyphen1">lich</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">vor.).</span></span>
                </ng-container>

                <ng-container *ngIf="getCurrentLanguage() == 'en'">
                  <span class="word"><span class="hyphen1 ml-6">Al</span><span class="hyphen2">ice</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">was</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">be</span><span class="hyphen2">gin</span
                    ><span class="hyphen1">ning</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">get</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">very</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">tired</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">of</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sit</span><span class="hyphen2">ting</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">by</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">her</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">sis</span><span class="hyphen2">ter</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">on</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">bank,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">and</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">hav</span><span class="hyphen2">ing</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">noth</span><span class="hyphen2">ing</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">do:</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">once</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">or</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">twice</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">she</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">had</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">peeped</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">in</span><span class="hyphen2">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">book</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">her</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sister</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">was</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">read</span><span class="hyphen1">ing,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">but</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">it</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">had</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">no</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">pic</span><span class="hyphen2">tures</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">or</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">con</span><span class="hyphen2">ver</span
                    ><span class="hyphen1">sa</span><span class="hyphen2">tions</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">in</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">it,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">"and</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">what</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">is</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">use</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">of</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">a</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">book,"</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">thought</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Al</span><span class="hyphen2">ice</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">"with</span><span class="hyphen2">out</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">pic</span><span class="hyphen2">tures</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">or</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">con</span><span class="hyphen1">ver</span
                    ><span class="hyphen2">sa</span><span class="hyphen1">tions?"</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">So</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">she</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">was</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">con</span><span class="hyphen2">sid</span
                    ><span class="hyphen1">er</span><span class="hyphen2">ing</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">in</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">her</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">own</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">mind</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">(as</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">well</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">as</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">she</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">could,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">for</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">hot</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">day</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">made</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">her</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">feel</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">very</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">sleep</span><span class="hyphen1">y</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">and</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">stupid),</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">wheth</span><span class="hyphen1">er</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">plea</span><span class="hyphen2">sure</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">of</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">mak</span><span class="hyphen1">ing</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">a</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">dai</span><span class="hyphen2">sy</span
                    ><span class="hyphen1">chain</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">would</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">be</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">worth</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">trou</span><span class="hyphen1">ble</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">of</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">get</span><span class="hyphen2">ting</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">up</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">and</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">pick</span><span class="hyphen2">ing</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">dai</span><span class="hyphen1">sies,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">when</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">sud</span><span class="hyphen2">den</span
                    ><span class="hyphen1">ly</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">a</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">White</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Rab</span><span class="hyphen1">bit</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">with</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">pink</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">eyes</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ran</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">close</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">by</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">her.</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">There</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">was</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">noth</span><span class="hyphen2">ing</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">so</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ver</span><span class="hyphen1">y</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">re</span><span class="hyphen1">mark</span
                    ><span class="hyphen2">able</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen1">in</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">that;</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">nor</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">did</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">Al</span><span class="hyphen2">ice</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">think</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">it</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">so</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">ver</span><span class="hyphen1">y</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">much</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">out</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">of</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">way</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">hear</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">Rab</span><span class="hyphen1">bit</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">say</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">it</span><span class="hyphen1">self,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">"Oh</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">dear,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">oh</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">dear!</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">I</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">shall</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">be</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">late!"</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">(when</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">she</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">thought</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">it</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">o</span><span class="hyphen1">ver</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen1">af</span><span class="hyphen2">ter</span
                    ><span class="hyphen1">wards,</span></span
                  >
                  <span class="word">&nbsp;<span class="hyphen2">it</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">oc</span><span class="hyphen2">curred</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">her</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">that</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">she</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">ought</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">to</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">have</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">won</span><span class="hyphen1">dered</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">at</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">this,</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">but</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">at</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">the</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">time</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">it</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">all</span></span>
                  <span class="word">&nbsp;<span class="hyphen2">seemed</span></span>
                  <span class="word">&nbsp;<span class="hyphen1">quite</span></span>
                  <span class="word"
                    >&nbsp;<span class="hyphen2">na</span><span class="hyphen1">tu</span
                    ><span class="hyphen2">ral).</span></span
                  >
                </ng-container>
              </p>
            </div>
          </div>
        </div>
      </mat-step>

      <!-- [TIME BASED AND TEXT BASED TASKS] step 6: due date and mode -->
      <mat-step *ngIf="step === 6" [stepControl]="readingTimeSelector">
        <form [formGroup]="readingTimeSelector">
          <div class="training-task_create_headline">
            <h1 *ngIf="taskType === 'time'">{{ translatedText.training_task_create_mode_and_due_date }}</h1>
            <h1 *ngIf="taskType === 'text'">{{ translatedText.training_task_create_due_date }}</h1>
          </div>
          <app-hint-box
            *ngIf="taskType === 'time'"
            [title]="translatedText.training_task_create_hint_next_button_title"
            [text]="translatedText.training_task_create_hint_mode_selection"
            id="task-creation-mode-selection"
            icon="glasses"
          ></app-hint-box>
          <div class="training-task-container fifth-step">
            <div class="block-section" *ngIf="taskType === 'time'">
              <h3 class="h3 h3--bold font-22">{{ translatedText.training_task_create_task_done }}</h3>
              <form class="training-task_selector flex-wrap" formControlName="taskMode" ngDefaultControl>
                <button
                  alt="Einmalige Aufgabe"
                  class="training-task_selector small-btn"
                  [ngClass]="{ active: taskMode === 'one_time_task' }"
                  (click)="setTaskModeData('one_time_task')"
                >
                  <div class="training-task_button_wrap small-btn icon">
                    <mat-icon svgIcon="streamline_outlined:one_time_task" class="icon-42"></mat-icon>
                    <div class="training-task_selector_content">
                      <p class="paragraph paragraph--xl paragraph--bold">
                        {{ translatedText.training_task_create_single_title }}
                      </p>
                      <p class="paragraph l">
                        {{ translatedText.training_task_create_single_description }}
                      </p>
                    </div>
                  </div>
                </button>

                <button
                  alt="Aufgabe in mehreren Schritten"
                  class="training-task_selector small-btn"
                  [ngClass]="{ active: taskMode === 'several_steps' }"
                  (click)="setTaskModeData('several_steps')"
                >
                  <div class="training-task_button_wrap small-btn">
                    <mat-icon svgIcon="streamline_outlined:multistep_task" class="icon-42"></mat-icon>
                    <div class="training-task_selector_content">
                      <p class="paragraph paragraph--xl paragraph--bold">
                        {{ translatedText.training_task_create_multiple_title }}
                      </p>
                      <p class="paragraph">
                        {{ translatedText.training_task_create_multiple_description }}
                      </p>
                    </div>
                  </div>
                </button>
              </form>
            </div>
            <app-trial-banner
              *ngIf="trialStatus.isActive"
              [trialHeadline]="translatedText.license_books_trial_title"
              [trialText]="translatedText.license_task_trial_deadline_description"
              [trialStatus]="trialStatus"
              [showExpirationDate]="false"
              [showButtons]="false"
            ></app-trial-banner>

            <div *ngIf="taskType === 'time'">
              <h3 class="h3 h3--bold font-22">{{ translatedText.training_task_create_reading_duration }}</h3>
              <p class="paragraph reading-duration-desc">
                {{ translatedText.training_task_create_reading_duration_desc }}
              </p>
              <div class="minutes-select-wrap">
                <mat-form-field>
                  <input
                    matInput
                    formControlName="minutes"
                    type="number"
                    [min]="1"
                    required
                    (input)="onMinutesChanged($event, readingTime)"
                    [(ngModel)]="readingTime"
                    ngDefaultControl
                  />{{ readingTime }}
                  <div class="minutes-selector-buttons-wrap">
                    <button
                      alt="10"
                      mat-flat-button
                      color="unset"
                      class="minutes-button"
                      (click)="selectReadingTime(10)"
                    >
                      10
                    </button>
                    <button
                      alt="15"
                      mat-flat-button
                      color="unset"
                      class="minutes-button"
                      (click)="trialStatus.isActive ? openUpgradeModal() : selectReadingTime(15)"
                      [ngClass]="{ disabled: trialStatus.isActive }"
                    >
                      15
                    </button>
                    <button
                      alt="20"
                      mat-flat-button
                      color="unset"
                      class="minutes-button"
                      (click)="trialStatus.isActive ? openUpgradeModal() : selectReadingTime(20)"
                      [ngClass]="{ disabled: trialStatus.isActive }"
                    >
                      20
                    </button>
                  </div>
                  <mat-error *ngIf="readingTimeSelector.get('minutes').hasError('required')">
                    {{ translatedText.training_task_create_minutes }}
                  </mat-error>
                </mat-form-field>
                <span class="minutes-selector-text">
                  <p class="paragraph paragraph--l">{{ translatedText.training_task_create_minutes_word }}</p>
                </span>
              </div>
            </div>

            <div class="block-section--time-task">
              <h3 class="h3 h3--bold font-22">{{ translatedText.training_task_create_due_date }}</h3>
              <app-due-date-selector
                [control]="readingTimeSelector.get('selectedDate')"
                [readingTimeSelector]="readingTimeSelector"
                (taskVisibility)="getTaskVisibleFrom($event)"
                (newSelectedDeadline)="getSelectedDeadline($event)"
              >
              </app-due-date-selector>
            </div>
          </div>
        </form>
      </mat-step>

      <!-- [ALL TYPES OF TASKS] step 7: task overview and last step -->
      <mat-step *ngIf="step === 7" [stepControl]="taskOverviewStep">
        <div *ngIf="!isLoading">
          <div class="training-task_create_headline">
            <h1>{{ translatedText.training_task_create_overview }}</h1>
          </div>
          <div class="training-task-container">
            <div class="block-section">
              <div class="row">
                <div class="col-md-12">
                  <p class="task_summary-headline">
                    {{ translatedText.training_task_create_task }}
                  </p>
                  <div class="task_summary">
                    <div class="task_summary_header">
                      <!-- TIME AND TEXT BASED HEADER SECTION -->
                      <div *ngIf="taskType != 'auto'">
                        <p class="paragraph paragraph--s paragraph--bold title">
                          {{ translatedText.dashboard_teacher_overview }}
                        </p>
                        <div class="inline_text" *ngIf="taskType != 'auto'">
                          <img
                            class="book-overview-cover"
                            src="{{ getBookCoverByIsbn(bookData.isbn, 'normal') }}"
                            alt="{{ bookData.title }}"
                            loading="lazy"
                            (error)="handleImageError(bookData.isbn, $event)"
                            *ngIf="!studentsChooseBook"
                          />
                          <div class="task-specs-wrapper" [ngClass]="{ 'with-book': !studentsChooseBook }">
                            <div>
                              <p
                                class="paragraph paragraph--bold paragraph--l task_summary-bookTitle"
                                *ngIf="!studentsChooseBook"
                              >
                                {{ bookData.title }}
                              </p>
                              <p class="paragraph paragraph--s title" *ngIf="!studentsChooseBook">
                                {{ bookData.author }}
                              </p>
                            </div>
                            <div class="task_chips_wrap--blue task_chips_wrap training_task_details">
                              <div class="task_overview_chip-wrap" *ngIf="taskType === 'time'">
                                <p class="paragraph paragraph--s paragraph--bold">
                                  {{ translatedText.training_task_create_duration_time }}
                                </p>
                                <p class="paragraph task_chip task_chip--overview">
                                  {{ readingTime }}
                                  {{
                                    readingTime === 1
                                      ? translatedText.student_task_list_minute
                                      : translatedText.student_task_list_minutes
                                  }}
                                </p>
                              </div>
                              <div class="task_overview_chip-wrap" *ngIf="taskType === 'text'">
                                <p class="paragraph paragraph--s paragraph--bold">
                                  {{ translatedText.training_task_create_selected_passage }}
                                </p>
                                <p class="paragraph task_chip task_chip--overview">
                                  {{ wordcount }} {{ translatedText.app_component_words }}
                                </p>
                              </div>
                              <div class="task_overview_chip-wrap">
                                <p class="paragraph paragraph--s paragraph--bold">
                                  {{ translatedText.training_task_create_due_date }}
                                </p>
                                <p class="paragraph task_chip task_chip--overview">
                                  {{ selectedDeadline | date: 'dd.MM.yyyy' }}
                                </p>
                              </div>
                              <div class="task_overview_chip-wrap" *ngIf="taskType === 'time'">
                                <p class="paragraph paragraph--s paragraph--bold">
                                  {{ translatedText.training_task_create_task_mode }}
                                </p>
                                <p class="paragraph task_chip task_chip--overview">
                                  <span *ngIf="taskMode === 'one_time_task'">
                                    {{ translatedText.training_task_create_single_tag }}</span
                                  >
                                  <span *ngIf="taskMode === 'several_steps'">
                                    {{ translatedText.training_task_create_multiple_tag }}</span
                                  >
                                </p>
                              </div>
                              <div class="task_overview_chip-wrap">
                                <p class="paragraph paragraph--s paragraph--bold">
                                  {{ translatedText.training_task_create_visible_from }}
                                </p>
                                <p class="paragraph task_chip task_chip--overview">
                                  <span *ngIf="today >= taskVisibleFrom">
                                    {{ translatedText.training_task_create_immediately }}</span
                                  >
                                  <span *ngIf="today < taskVisibleFrom">{{
                                    taskVisibleFrom | date: 'dd.MM.yyyy'
                                  }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                      <div class="task_chips_wrap">
                        <div class="task_overview_chip-wrap">
                          <p class="paragraph paragraph--s paragraph--bold">
                            {{ translatedText.training_task_create_test_area_title }}
                          </p>
                          <span class="paragraph task_chip task_chip--overview">
                            <mat-icon svgIcon="streamline_outlined:reading_fluency_2"></mat-icon>
                            {{ translatedText.training_task_create_fluency_title }}
                          </span>
                        </div>
                        <div class="task_overview_chip-wrap">
                          <p class="paragraph paragraph--s paragraph--bold">
                            {{ translatedText.training_task_create_task_type_title }}
                          </p>
                          <span *ngIf="taskType === 'text'" class="paragraph task_chip task_chip--overview">
                            <mat-icon svgIcon="streamline_outlined:specific_text_2"></mat-icon>
                            {{ translatedText.training_task_create_specific_text_title }}
                          </span>
                          <span *ngIf="taskType === 'time'" class="paragraph task_chip task_chip--overview">
                            <mat-icon svgIcon="streamline_outlined:specific_time"></mat-icon>
                            {{ translatedText.training_task_create_specific_time_title }}
                          </span>
                          <span *ngIf="taskType === 'auto'" class="paragraph task_chip task_chip--overview">
                            <mat-icon svgIcon="streamline_outlined:automode_2"></mat-icon>
                            {{ translatedText.training_task_create_auto_title }}
                          </span>
                        </div>
                      </div>
                      <!-- AUTOMODE HEADER SECTION -->
                      <div *ngIf="taskType === 'auto'">
                        <hr />
                        <div class="task_chips_wrap">
                          <div class="task_overview_chip-wrap">
                            <p class="paragraph paragraph--s paragraph--bold">
                              {{ translatedText.training_task_create_auto_duration }}
                            </p>
                            <span class="paragraph task_chip task_chip--overview">
                              1 {{ translatedText.training_task_create_month }}</span
                            >
                          </div>
                          <div class="task_overview_chip-wrap">
                            <p class="paragraph paragraph--s paragraph--bold">Zeitplan</p>
                            <div class="weekdays-wrap">
                              <div
                                class="paragraph task_chip task_chip--overview narrow-chip"
                                *ngFor="let day of selectedWeekdays"
                              >
                                <p class="mr-0" *ngIf="day === '1'">{{ translatedText.training_task_create_monday }}</p>
                                <p class="mr-0" *ngIf="day === '2'">
                                  {{ translatedText.training_task_create_tuesday }}
                                </p>
                                <p class="mr-0" *ngIf="day === '3'">
                                  {{ translatedText.training_task_create_wednesday }}
                                </p>
                                <p class="mr-0" *ngIf="day === '4'">
                                  {{ translatedText.training_task_create_thursday }}
                                </p>
                                <p class="mr-0" *ngIf="day === '5'">{{ translatedText.training_task_create_friday }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="task_summary students-list">
                <div class="row">
                  <div class="col-md-12 task_summary_header">
                    <p class="paragraph paragraph--s paragraph--bold title">
                      {{ translatedText.training_task_create_students }}
                    </p>
                    <!-- students/group selection -->
                    <div class="accordion_wrap selected-students-chips">
                      <mat-accordion>
                        <mat-expansion-panel
                          [togglePosition]="'before'"
                          *ngFor="let groupId of Object.keys(studentsArrangedByGroup)"
                        >
                          <mat-expansion-panel-header class="group-select-header">
                            <mat-panel-title class="round-checkbox">
                              <div class="group-select">
                                <p class="paragraph paragraph--bold paragraph--xl">
                                  {{ studentsArrangedByGroup[groupId].groupName }} -
                                  {{ studentsArrangedByGroup[groupId].schoolYear | slice: 2 }}
                                </p>
                                <div class="students-total-tag-wrapper">
                                  <div class="students-total-tag-outline">
                                    <div class="students-total-tag-content">
                                      {{ studentsArrangedByGroup[groupId].students?.length }} /
                                      {{ studentsArrangedByGroup[groupId].groupLength }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-panel-title>
                          </mat-expansion-panel-header>
                          <mat-selection-list>
                            <div class="student-chip-wrap">
                              <div *ngFor="let student of studentsArrangedByGroup[groupId]['students']; let i = index">
                                <div class="student-chip">
                                  <img *ngIf="student.avatarUrl" src="{{ student.avatarUrl }}" alt="" />
                                  <p class="paragraph paragraph--s paragraph--bold">
                                    {{ student.username }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </mat-selection-list>
                        </mat-expansion-panel>
                      </mat-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="last_step_container" *ngIf="isLoading">
          <app-loading-spinner></app-loading-spinner>
          <h3 class="h3 h3--bold" *ngIf="taskMode != 'auto'">
            {{ translatedText.training_task_create_create_description }}
          </h3>
          <h3 class="h3 h3--bold" *ngIf="taskMode == 'auto'">
            {{ translatedText.training_task_create_create_description_more }}
          </h3>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</section>
