<!-- active -->
<div class="license-active-banner" *ngIf="licenseAvailable && !isClosed && !IS_APP">
  <div class="license-active-alert-box">
    <div class="row align-items-center">
      <div class="col-md-9">
        <div class="license-active-info-section">
          <mat-icon svgIcon="streamline_outlined:license_warning"></mat-icon>

          <div class="heading">
            <span> {{ translatedText.licenses_activate_alert_title }}</span>
          </div>
        </div>
        <p class="paragraph paragraph--s mt-1">
          <span> {{ translatedText.licenses_activate_alert_desc }}</span>
        </p>
      </div>
      <div class="col-md-3">
        <button
          alt="Lizenz aktivieren"
          mat-flat-button
          color="primary"
          type="button"
          class="w-80"
          routerLink="/admin-profile-management"
        >
          {{ translatedText.admin_licenses_activate }}
        </button>
      </div>
    </div>
  </div>
  <button alt="Schließen" mat-mini-fab type="button" class="close-button" (click)="handleClose()">
    <!-- have it show only once? -->
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
</div>
