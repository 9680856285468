<section class="container-fluid overlay_container">
  <div class="step_container">
    <div class="header-wrapper">
      <button
        alt="Abbrechen"
        mat-flat-button
        class="close-button-with-text"
        type="button"
        matSuffix
        (click)="openCancelDialog()"
      >
        <mat-icon svgIcon="streamline_outlined:close" *ngIf="!isLoading"></mat-icon>
        {{ translatedText.admin_group_create_cancel }}
      </button>
      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">{{ translatedText.diagnostic_create_diagnostic }}</div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button alt="Zurück" mat-flat-button color="unset" (click)="backBtn()" *ngIf="step !== 1 && !isLoading">
          {{ translatedText.students_add_back }}
        </button>
        <button
          alt="Weiter"
          mat-flat-button
          color="primary"
          class="submit-button"
          (click)="nextBtn()"
          *ngIf="step !== 3"
          [disabled]="isFormInvalid || (studentsArray.length === 0 && step === 2)"
        >
          {{ translatedText.students_add_next }}
        </button>
        <button
          alt="Aufgabe anlegen"
          mat-flat-button
          color="primary"
          (click)="submitForm()"
          *ngIf="step === 3"
          class="wider-button"
        >
          <span *ngIf="!isLoading">{{ translatedText.diagnostic_create_create_task }}</span>
          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </div>
    <div class="training-task_container">
      <div class="step_content">
        <div class="training-task_wrap">
          <div *ngIf="step === 1">
            <app-diagnostic-tasks-create (formData)="getDateFormData($event)"></app-diagnostic-tasks-create>
          </div>

          <div *ngIf="step === 2">
            <div class="training-task_create_headline">
              <h2 class="h2 h2--bold mt-5 mb-2">
                {{ translatedText.training_task_create_who }}
              </h2>
              <p class="paragraph paragraph--xl">
                {{ translatedText.training_task_create_who_description }}
              </p>
            </div>
            <app-individual-learners-selection
              (selectedStudents)="getSelectedStudentsList($event)"
              [source]="'diagnostic'"
              [selectedTypeDetails]="selectedDiagnostic.diagnostic"
            >
            </app-individual-learners-selection>
          </div>

          <div *ngIf="step === 3">
            <app-task-test-overview [selectedTypeDetails]="selectedDiagnostic" [studentList]="taskOverView">
            </app-task-test-overview>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
