import { CdkStepper, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  forwardRef,
  Renderer2,
  ElementRef,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BetaInfoComponent } from 'src/app/helpers/beta-info/beta-info.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { BookDataService } from 'src/app/providers/book-data.service';
import { BookService } from 'src/app/providers/book.service';
import { GroupService } from 'src/app/providers/group.service';
import { LicenseService } from 'src/app/providers/license.service';
import { SchoolService } from 'src/app/providers/school.service';
import { TaskService } from 'src/app/providers/task.service';
import { TranslationService } from 'src/app/providers/translation.service';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';

const MAX_ALLOWED_TRAINING_TASKS = 4;

// TODO: define interface
export interface Group {}

export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface DialogData {}

class CustomDateAdapter extends MomentDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'app-training-task-create',
  templateUrl: './training-task-create.component.html',
  styleUrls: ['./training-task-create.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TrainingTaskCreateComponent),
      multi: true,
    },
  ],
})
export class TrainingTaskCreateComponent implements OnInit, OnDestroy {
  selectedIndex: number = 0;
  isMaintenanceMode: boolean = false;
  taskTypeStep;
  taskAreaStep;
  taskChoiceStep;
  taskOverviewStep;
  lastStep;
  readerSetting: FormGroup;
  groupSelectStep: FormGroup;
  readingTimeSelector: FormGroup;
  durationForm: FormGroup;
  isManualMode: boolean = false;
  //isAutoMode: boolean = false;
  isSpecific_text: boolean = false;
  isSpecific_book: boolean = false;
  isOneTimeTask: boolean = false;
  isSeveralSteps: boolean = false;
  isEvery_book: boolean = false;
  licensesToSchool: any = [];
  licensesToSchoolLoading: boolean = true;
  contentType: 'klett' | 'full' | 'trial' = 'trial';
  taskMode: string = 'one_time_task';
  taskChoice;
  @ViewChild('stepper') stepper: CdkStepper;
  disableInHolidays: boolean = false;
  taskType: string = 'manual';
  taskArea;
  taskCompletionMode;
  fontSize: string = 'normal';
  line_height: string = 'normal';
  letter_spacing: string = 'small';
  hyphenation_mode: string = 'option0';
  fontFamilyClass: string = '';
  fontSizeClass: string = '';
  lineSpacingClass: string = '';
  spaceBetweenClass: string = '';
  hyphenationClass: string = '';
  teacherId;
  userType;
  groups: any = [];
  studentsArray = [];
  isSingleGroup: boolean = false;
  isSingleStudent: boolean = false;
  groupOverview = [];
  description;
  tasks;
  taskOverview = [];
  groupArray = [];
  estimationByGrade = [];
  wordcount;
  bookSelected;
  isBookList: boolean = false;
  isBookPreview: boolean = false;
  isBookView: boolean = false;
  isClosedDialog: boolean = false;
  bookData = {
    author: '',
    description: '',
    isbn: '',
    title: '',
    dsef_version: 1.0,
  };
  disableStep: boolean = false;
  selectedCompletionDetail = 'minutes';
  isLoading: boolean = false;
  isAllowedReadMore: boolean = false;
  deadline;
  //maxMinimumValue = 0;
  checkSelection: boolean = false;
  trainingLicenseLeft;
  @ViewChild('students') students: MatSelectionList;
  selectedDayLength: number = 0;
  betaInfoText: string =
    'Diese Funktion befindet sich im Beta-Stadium und kann daher unbegrenzt kostenfrei genutzt werden. Ihr Minutenbudget wird nicht belastet. In jedem Fall profitieren die Lernenden aber von den Inhalten und der Lautlesepraxis. Sie als Lehrkraft haben Transparenz über den Erledigungsstand von Aufgaben und können in die Aufzeichnungen reinhören. Wir arbeiten mit Hochdruck daran, diese Funktion schnellstmöglich fehlerfrei bereitzustellen.';
  isGroupLoading: boolean = true;
  showBetaInfo: boolean = false;
  translatedText;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  // schoolyear change variables
  currentYear: string;
  schoolYearTransition: boolean;
  nextYear: string;

  clickedCloseBtn: boolean = false;
  selectedDeadline: Date = undefined;
  taskVisibleFrom: Date;
  today: any | null = new Date();
  readingTime: number;
  btnReadingTime;
  studentsArrangedByGroup = {};
  totalStudentsEachClass = {};
  bookImgUrl;
  imageLoadFailed: boolean = false;
  selectedIsbn;
  anyBookSelection;
  selectedWeekdays: string[] = [];
  selectionTimer: number;
  studentsChooseBook: boolean = false;
  @ViewChild('fakeContent', { static: false }) fakeContent: ElementRef;

  $fakeSyllabiElement: any;
  isFakeContentSyllabi: boolean = false;
  readerSettingValue: any;
  viewValueTaskVisibily: any;

  step: number = 1;
  stepValidated$ = new BehaviorSubject<boolean>(false);
  private subscription: Subscription;
  stepValidated: boolean = false;
  minDate: Date;

  Object = Object;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private bookDataService: BookDataService,
    private groupService: GroupService,
    private taskService: TaskService,
    private translationService: TranslationService,
    private licenseService: LicenseService,
    private schoolService: SchoolService,
    private bookService: BookService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    this.minDate = new Date();
    this.minDate.setHours(0, 0, 0, 0);

    this.readingTimeSelector = this._formBuilder.group({
      taskMode: [this.taskMode, [Validators.required]],
      minutes: [null, [Validators.required, Validators.min(1)]],
      selectedDate: [this.selectedDeadline, [Validators.required]],
    });
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.getLicenses();
    this.showBetaInfo = true;

    this.groupService.getGroupsToTeacher(false).subscribe((res) => {
      this.isGroupLoading = false;
      let allGroups: any = res;
      let filteredGroups = allGroups.filter(
        (group) =>
          group.group.archived === false || group.group.archived === undefined,
      );
      this.groups = filteredGroups;

      this.groups.sort(function (a, b) {
        return a['group'].name.localeCompare(b['group'].name);
      });
      this.groups.forEach((element) => {
        element.group._students.sort(function (a, b) {
          return a['username'].localeCompare(b['username']);
        });
      });
    });

    this.readerSetting = this._formBuilder.group({
      font_family: ['abeezee'],
      font_size: ['small'],
      line_height: ['small'],
      letter_spacing: ['small'],
      hyphenation_mode: ['option0'],
      editable: [true],
    });

    this.durationForm = this._formBuilder.group({
      duration: ['1', Validators.required],
    });

    this.trialStatus = this.authService.getUserTrialStatus();
    this.getSchoolYearChanged();

    this.subscription = this.stepValidated$.subscribe(
      (value) => (this.stepValidated = value),
    );

    this.readingTimeSelector.get('taskMode').setValue('one_time_task');
    if (this.readingTimeSelector.get('selectedDate').value === null) {
      this.readingTimeSelector.get('selectedDate').setErrors({ valid: false });
    }
  }

  private checkStepValidity() {
    //checks if deadline selection step is valid in case of time based task

    if (this.readingTimeSelector.get('selectedDate').value === null) {
      this.readingTimeSelector.get('selectedDate').setErrors({ valid: false });
    }
    if (this.taskType === 'time') {
      const isValid =
        this.readingTimeSelector.get('taskMode')?.valid &&
        this.readingTimeSelector.get('minutes')?.valid &&
        this.readingTimeSelector.get('selectedDate')?.valid;

      this.stepValidated$.next(isValid);

      //checks if deadline is valid in case of text based task
    } else {
      this.stepValidated$.next(
        this.readingTimeSelector.get('selectedDate')?.valid,
      );
    }
  }

  getSelectedDeadline(event: Date) {
    this.readingTimeSelector.get('selectedDate').setValue(event);
    this.selectedDeadline = event;
    if (event != null) {
      this.readingTimeSelector.get('selectedDate')?.valid;
    }
    this.checkStepValidity();
  }

  getTaskVisibleFrom(event: Date) {
    this.taskVisibleFrom = event;
  }

  getCurrentLanguage() {
    return this.translationService.getCurrentLanguage();
  }

  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        this.contentType = this.licenseService.getContentTypeByLicense(
          this.licensesToSchool,
        );

        this.licensesToSchoolLoading = false;
      });
  }

  getCurrentYear() {
    this.schoolService
      .currentYear()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        //console.log(data);
        this.currentYear = data.year;
      });
  }

  getSchoolYearChanged() {
    this.schoolService
      .schoolYearChanged()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.schoolYearTransition = data.yearChange;
        this.getCurrentYear();
        this.nextYear = data.nextYear;
      });
  }

  getLicenseAmount(licenses, tag, type) {
    let amount = 0;
    if (licenses.length > 0) {
      licenses.map((item) => {
        if (item.type_key === tag) {
          if (type === 'total') {
            amount += item.amount_total;
          } else {
            amount += item.amount_left;
          }
        }
      });
      if (tag === 'fo') {
        amount = amount > 0 ? amount / 60 : amount;
      }
    }
    return Math.floor(amount);
  }

  calculatedLicenses(licenseLeft, readingMinutes, blockedLicenses) {
    const licenseCount = blockedLicenses + readingMinutes;
    // Beta mode free to use
    if (
      /*this.taskChoice === "specific_book" || this.taskChoice === "every_book" ||*/
      this.taskType === 'auto'
    ) {
      return licenseLeft;
    } else {
      return licenseLeft - licenseCount < 0 ? 0 : licenseLeft - licenseCount;
    }
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(isbn, event: any) {
    // just try once
    if (!this.imageLoadFailed) {
      this.imageLoadFailed = true;
      this.bookService.getBookimage(isbn).subscribe((response) => {
        if (response) {
          // just url
          event.target.src = response.link;
        }
      });
    }
  }

  readingMinutes(students, studentsSelected) {
    if (this.taskType === 'text') {
      let minutes = 0;
      if (students.length > 0) {
        students.map((student) => {
          const isSelected = studentsSelected.some(
            (selectedStudent: any) =>
              selectedStudent._value._id === student._id,
          );
          if (isSelected) {
            let filteredGrade = this.estimationByGrade.filter((item) =>
              student._group.some((el) => el.grade === item.grade),
            );
            minutes +=
              filteredGrade.length > 0
                ? Math.ceil(filteredGrade[0].minutes)
                : 0;
          }
        });
      }
      return minutes;
    } else if (this.taskType === 'time') {
      return (
        this.readingTimeSelector.get('minutes').value * studentsSelected.length
      );
    } else if (this.taskType === 'auto') {
      return (
        this.durationForm.get('duration').value *
        4 *
        this.selectedDayLength *
        15 *
        studentsSelected.length
      );
    }
  }

  getSelectionTime(event): void {
    if (event < 181) {
      this.selectionTimer = event;
      let goBackStep = setTimeout(() => {
        this.selectionTimer, 181000;
      });
    } else {
      this.previousStep();
    }
  }

  filterGroupsWithoutPersonalData(groups) {
    // only list groups that have students with gender value
    return groups?.filter((element) => {
      const hasStudents =
        element.group._students && element.group._students.length > 0;
      const hasEmptyGender = element.group._students.some(
        (student) => !student.gender,
      );
      return hasStudents && !hasEmptyGender;
    });
  }

  //handles ste validation when student chooses book
  setTimeChoiceTask(event) {
    if (event) {
      this.studentsChooseBook = true;
      this.stepValidated$.next(true);
    } else {
      this.studentsChooseBook = false;
    }
  }

  setTaskTypeData(value) {
    this.taskArea = 'reading_fluency'; //hardcoded until other areas are implemented
    this.taskType = value;

    if (this.taskType === 'time' || this.taskType === 'text') {
      this.isManualMode = true;
      //this.durationForm.get('weekdays').setValidators(null);
      this.durationForm.get('duration').setValidators(null);
      //this.durationForm.get('weekdays').updateValueAndValidity();
      this.durationForm.get('duration').updateValueAndValidity();
    } else if (this.taskType === 'auto') {
      //this.durationForm.get('weekdays').setValidators([Validators.required]);
      this.durationForm.get('duration').setValidators([Validators.required]);
      //this.durationForm.get('weekdays').updateValueAndValidity();
      this.durationForm.get('duration').updateValueAndValidity();
    } else {
      this.taskType = '';
    }
    if (this.taskType != '' || this.taskType != undefined) {
      this.stepValidated$.next(true);
    }
  }
  setTaskModeData(value) {
    //only for time based tasks
    this.taskMode = value;
    if (this.taskMode === 'one_time_task') {
      this.readingTimeSelector
        .get('taskMode')
        .setValidators([Validators.required]);
      this.readingTimeSelector
        .get('minutes')
        .setValidators([Validators.required, Validators.min(1)]);
      this.readingTimeSelector.get('minutes').updateValueAndValidity();
    } else if (this.taskMode === 'several_steps') {
      this.readingTimeSelector
        .get('taskMode')
        .setValidators([Validators.required]);
      this.readingTimeSelector.get('minutes').updateValueAndValidity();
    } else {
      this.taskMode = '';
      this.readingTimeSelector.get('minutes').setValidators(null);
      this.readingTimeSelector.get('minutes').updateValueAndValidity();
    }
    if (this.taskType === 'time') {
      this.checkStepValidity();
    }
  }

  // handles the buttons in reading time input
  selectReadingTime(value) {
    this.btnReadingTime = value;
    this.readingTime = this.btnReadingTime;
    this.readingTimeSelector.get('minutes').setValue(value);
    this.checkStepValidity();

    this.onMinutesChanged(event, value);
  }

  onMinutesChanged(event, readingTime) {
    const currentValue = readingTime;
    const maxMinutesDuringTrial = 10;

    if (this.trialStatus.isActive && currentValue > maxMinutesDuringTrial) {
      this.readingTimeSelector.get('minutes').patchValue(maxMinutesDuringTrial);
      this.openUpgradeModal();
    } else {
      let maxMinutesDuringFairUse = 45;
      if (this.taskMode === 'several_steps') {
        maxMinutesDuringFairUse = 120;
      }

      if (currentValue > maxMinutesDuringFairUse) {
        this.readingTimeSelector
          .get('minutes')
          .patchValue(maxMinutesDuringFairUse);
        this.readingTimeSelector
          .get('minutes')
          .setValidators([Validators.required, Validators.min(1)]);
      }
    }
    this.readingTimeSelector.get('minutes').setValue(readingTime);

    this.checkStepValidity();
  }

  //automode weekday selection
  getSelectedWeekdays(event: any) {
    this.disableInHolidays = event.isHolidayTask;
    if (event && event.weekdays) {
      this.selectedDayLength = event.weekdays.length;
      this.selectedWeekdays = event.weekdays;
    }
    if (this.selectedDayLength === 3) {
      this.stepValidated$.next(true);
    } else {
      this.stepValidated$.next(false);
    }
    this.selectedWeekdays.sort();
  }

  optionSetting(value, name) {
    if (name === 'reader') {
      if (value.currentTarget.checked === true) {
        this.readerSetting.get('editable').setValue(true);
      } else {
        this.readerSetting.get('editable').setValue(false);
      }
    } else if (name === 'read_more') {
      if (value.currentTarget.checked === true) {
        this.isAllowedReadMore = true;
      } else {
        this.isAllowedReadMore = false;
      }
    }
  }

  updateReaderOptions(event, option) {
    this.readerSettingValue = { event: event.value, option: option };
    if (!this.studentsChooseBook) return false;
    let fakeContentTag = this.fakeContent
      ? this.fakeContent.nativeElement
      : null;
    switch (option) {
      case 'fontFamily':
        this.isFakeContentSyllabi = false;
        if (event.value === 'abeezee') {
          this.fontFamilyClass = 'fontFamily__abeezee';
        } else if (event.value === 'poppins') {
          this.fontFamilyClass = 'fontFamily__poppins';
        } else {
          this.fontFamilyClass = '';
        }
        this.removeClassFromElement(fakeContentTag, [
          'fontFamily__abeezee',
          'fontFamily__poppins',
          'fontFamily',
        ]);
        this.addClassToElement(
          fakeContentTag,
          this.fontFamilyClass,
          'fontFamily',
        );
        break;
      case 'fontSize':
        this.isFakeContentSyllabi = false;
        this.fontSize = event.value;
        if (event.value === 'small') {
          this.fontSizeClass = 'fontSize__small';
        } /* if (event.value === 'normal') {
          this.fontSizeClass = 'fontSize__normal';
        } else */ else if (event.value === 'big') {
          this.fontSizeClass = 'fontSize__big';
        } else {
          this.fontSizeClass = 'fontSize__normal';
        }
        this.removeClassFromElement(fakeContentTag, [
          'fontSize__small',
          'fontSize__normal',
          'fontSize__big',
          'fontSize',
        ]);
        this.addClassToElement(fakeContentTag, this.fontSizeClass, 'fontSize');
        break;
      case 'line_height':
        this.isFakeContentSyllabi = false;
        this.line_height = event.value;
        /* if (event.value === 'small') {
          this.lineSpacingClass = 'line_height__small';
        } else */ if (event.value === 'normal') {
          this.lineSpacingClass = 'line_height__normal';
        } else if (event.value === 'big') {
          this.lineSpacingClass = 'line_height__big';
        } else {
          this.lineSpacingClass = 'line_height__small';
        }
        this.removeClassFromElement(fakeContentTag, [
          'line_height__small',
          'line_height__normal',
          'line_height__big',
          'line_height',
        ]);
        this.addClassToElement(
          fakeContentTag,
          this.lineSpacingClass,
          'line_height',
        );
        break;
      case 'letter_spacing':
        this.isFakeContentSyllabi = false;
        this.letter_spacing = event.value;
        if (event.value === 'small') {
          this.spaceBetweenClass = 'letter_spacing__small';
        } else if (event.value === 'normal') {
          this.spaceBetweenClass = 'letter_spacing__normal';
        } else if (event.value === 'big') {
          this.spaceBetweenClass = 'letter_spacing__big';
        } else {
          this.spaceBetweenClass = '';
        }
        this.removeClassFromElement(fakeContentTag, [
          'letter_spacing__small',
          'letter_spacing__normal',
          'letter_spacing__big',
          'letter_spacing',
        ]);

        this.addClassToElement(
          fakeContentTag,
          this.spaceBetweenClass,
          'letter_spacing',
        );
        break;
      case 'hyphenation_mode':
        this.isFakeContentSyllabi = false;
        this.hyphenation_mode = event.value;
        if (event.value === 'option1') {
          this.hyphenationClass = 'hyphenation_preview__option1';
        } else if (event.value === 'option2') {
          this.hyphenationClass = 'hyphenation_preview__option2';
        } else if (event.value === 'option3') {
          this.hyphenationClass = 'hyphenation_preview__option3';
        } else {
          this.hyphenationClass = '';
        }
        this.addClassToElement(
          fakeContentTag,
          this.hyphenationClass,
          'hyphenation_preview',
        );
        this.$fakeSyllabiElement = setTimeout(() => {
          //fakeContentTag = this.fakeSyllabiContent.nativeElement;
          this.removeClassFromElement(fakeContentTag, [
            'hyphenation_preview__option1',
            'hyphenation_preview__option2',
            'hyphenation_preview__option3',
            'hyphenation_preview',
          ]);
          this.removeClassFromElement(fakeContentTag, [
            'fontFamily__abeezee',
            'fontFamily__poppins',
            'fontSize__small',
            'fontSize__normal',
            'fontSize__big',
            'line_height__small',
            'line_height__normal',
            'line_height__big',
            'letter_spacing__small',
            'letter_spacing__normal',
            'letter_spacing__big',
            'fontFamily',
            'fontSize',
            'line_height',
            'line_height',
            'letter_spacing',
          ]);
          if (this.fontFamilyClass !== '') {
            this.addClassToElement(
              fakeContentTag,
              this.fontFamilyClass,
              'fontFamily',
            );
          }
          if (this.fontSizeClass !== '') {
            this.addClassToElement(
              fakeContentTag,
              this.fontSizeClass,
              'fontSize',
            );
          }
          if (this.lineSpacingClass !== '') {
            this.addClassToElement(
              fakeContentTag,
              this.lineSpacingClass,
              'line_height',
            );
          }
          if (this.spaceBetweenClass !== '') {
            this.addClassToElement(
              fakeContentTag,
              this.spaceBetweenClass,
              'letter_spacing',
            );
          }
          if (this.hyphenationClass !== '') {
            this.addClassToElement(
              fakeContentTag,
              this.hyphenationClass,
              'hyphenation_preview',
            );
          }
        }, 500);
        break;
      default:
        break;
    }
  }

  // Remove classes before adding it
  removeClassFromElement(element, classNames) {
    for (const i of classNames) {
      this.renderer.removeClass(element, i);
    }
  }

  // Add Class To Element
  addClassToElement(element, className, rootClassName) {
    this.renderer.addClass(element, rootClassName);
    if (className !== '') {
      this.renderer.addClass(element, className);
    }
  }

  // select all students and add or remove from array
  toggleSelectAll(students, select) {
    if (select === true) {
      students.options.forEach((element) => {
        if (
          element?._value?.status === 'active' &&
          (!element._value.trial?.training ||
            element._value.trial?.training < MAX_ALLOWED_TRAINING_TASKS)
        ) {
          element.selected = true;
          this.studentsArray.push(element._value);
        }
      });
    } else {
      students.deselectAll();
      students.options.forEach((element) => {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== element._value._id,
        );
      });
    }
  }

  // select student and add or remove to array
  handleSelection(event, students) {
    if (this.isSingleGroup == false) {
      if (event.options[0].selected === true) {
        this.studentsArray.push(event.options[0].value);
      } else if (
        event.options[0].selected == false &&
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item !== event.options[0].value,
        );
      }
    } else if (this.isSingleGroup === true || this.isSingleStudent === true) {
      if (
        event.options[0].selected == false ||
        this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray = this.studentsArray.filter(
          (item) => item._id !== event.options[0].value._id,
        );
      } else if (
        event.options[0].selected === true &&
        !this.studentsArray.includes(event.options[0].value)
      ) {
        this.studentsArray.push(event.options[0].value);
        //this.studentsArray =  this.studentsArray.filter(item => item !== event.options[0].value);
      }
    }
  }

  hasTooManyTrialTasks(group, student) {
    if (!this.trialStatus.isActive) {
      return false;
    }
    const foundStudent = group._students.find(
      (s) => s._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      if (
        foundStudent.trial?.training && // Make sure that training exists before accessing it
        foundStudent.trial.training >= MAX_ALLOWED_TRAINING_TASKS
      ) {
        return true;
      }
    }
    return false;
  }

  // set data for task overview step
  setTaskDataForOverview() {
    this.taskOverview = [];
    this.groupArray = [];
    this.groupOverview = [];
    this.studentsArrangedByGroup = [];

    // creates an array to render student separated by class on last step
    this.studentsArray.forEach((student) => {
      const group = (student.groupName = this.groups.filter((group) =>
        student?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group);

      if (!(group._id in this.studentsArrangedByGroup)) {
        this.studentsArrangedByGroup[group._id] = {
          groupName: group.name,
          schoolYear: student.groupSchoolYear,
          students: [student],
          groupLength: group._students.length,
        };
      } else {
        this.studentsArrangedByGroup[group._id]['students'].push(student);
      }
    });

    this.studentsArray.forEach((element) => {
      element.groupName = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?.name;

      element.groupSchoolYear = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?.school_year;

      element.groupLength = this.groups.filter((group) =>
        element?._group.some((el) => el._id === group?.group?._id),
      )[0]?.group?._students?.length;

      this.taskOverview.push({
        groupName: this.groups.filter((group) =>
          element?._group.some((el) => el._id === group?.group?._id),
        )[0]?.group?.name,
        _id: element._id,
        gender: element.gender,
        username: element.username,
        avatar: element.avatar,
        avatarUrl: element.avatarUrl,
        _group: element._group,
      });
    });
    let minutesString;
    if (this.readingTimeSelector.value.minutes === 1) {
      minutesString = 'Minute';
    } else {
      minutesString = 'Minuten';
    }

    this.taskChoice = this.taskType;

    if (this.taskChoice === 'text') {
      this.description =
        this.translationService.getTranslatedText('finished_task_read') +
        ' ' +
        this.wordcount +
        ' ' +
        this.translationService.getTranslatedText('finished_task_read_words') +
        ' ' +
        this.bookData?.title +
        '.';
    } else if (this.taskChoice === 'time') {
      this.description =
        this.translationService.getTranslatedText('finished_task_read') +
        ' ' +
        this.readingTime;
      ' ' +
        minutesString +
        ' ' +
        this.translationService.getTranslatedText('finished_task_read_in') +
        ' ' +
        this.bookData?.title +
        '.';
    } else if (this.taskChoice === 'time-choice') {
      this.description =
        this.translationService.getTranslatedText('finished_task_read') +
        ' ' +
        this.readingTime +
        ' ' +
        minutesString +
        ' ' +
        this.translationService.getTranslatedText('finished_task_read_book');
    }

    this.tasks = this.taskOverview;
    this.tasks.forEach((element) => {
      this.groupArray.push(
        this.tasks.filter((task) => element._group === task._group)[0]._group,
      );
    });

    this.wordcount = this.bookDataService.getWordCountForTask();

    this.groupArray = this.groupArray.filter(
      (value, index) => this.groupArray.indexOf(value) === index,
    );
    this.groupArray.forEach((element) => {
      let group = {
        id: element,
        name: this.tasks.filter((task) => element === task._group)[0].groupName,
        username: this.tasks.filter((task) => element === task._group)[0]
          .username,
        avatarUrl: this.tasks.filter((task) => element === task._group)[0]
          .avatarUrl,
      };
      this.groupOverview.push(group);
    });
  }

  bookListData(event) {
    this.isBookList = event.listview;
    //this.isBookPreview = event.preview;
    this.bookData = event;
    if (event != '' && this.taskType != 'auto') {
      this.stepValidated$.next(true);
    }
  }

  validateStep3(event) {
    //no validators required for step 4 if time task
    if (event === true && this.taskType != 'auto') {
      this.stepValidated$.next(true);
    }
  }

  nextStep(value?) {
    const taskStep = {
      TASK_TYPE: 1,
      TASK_STUDENTS: 2,
      TASK_AUTOMODE: 3,
      TASK_BOOK: 4,
      TASK_READER_OPTIONS: 5,
      TASK_DATE: 6,
      TASK_OVERVIEW: 7,
    };

    //automode exceptions rules
    if (this.taskType === 'auto') {
      switch (this.step) {
        case taskStep['TASK_TYPE']:
          //open warning pop up for automode
          this.openBetaInfo(false);
          this.step++;
          break;
        case taskStep['TASK_STUDENTS']:
          //on automode the book selection step is skipped
          this.step = taskStep['TASK_AUTOMODE'];
          break;
        case taskStep['TASK_AUTOMODE']:
          this.stepValidated$.next(true);
          this.studentsChooseBook = true;
          this.step = taskStep['TASK_READER_OPTIONS'];
          break;
        case taskStep['TASK_READER_OPTIONS']:
          this.setTaskDataForOverview();
          this.step = taskStep['TASK_OVERVIEW'];
          return;
      }
    } else {
      if (this.step === taskStep['TASK_STUDENTS']) {
        this.step = taskStep['TASK_BOOK'];
      } else if (this.step === taskStep['TASK_DATE']) {
        this.step = taskStep['TASK_OVERVIEW'];
        //function that renders elements for last step
        this.setTaskDataForOverview();
        return;
      } else {
        this.step++;
      }
    }

    // On task reader options step, if the task is time based, there is no need to validate, it is an optional step.
    if (
      this.step === taskStep['TASK_READER_OPTIONS'] &&
      this.studentsChooseBook
    ) {
      this.stepValidated$.next(true);
    } else {
      this.stepValidated$.next(false);
    }
  }

  previousStep() {
    const taskStep = {
      TASK_TYPE: 1,
      TASK_STUDENTS: 2,
      TASK_AUTOMODE: 3,
      TASK_BOOK: 4,
      TASK_READER_OPTIONS: 5,
      TASK_DATE: 6,
      TASK_OVERVIEW: 7,
    };

    if (this.taskType === 'auto') {
      switch (this.step) {
        case taskStep['TASK_AUTOMODE']:
          this.step = taskStep['TASK_STUDENTS'];
          break;
        case taskStep['TASK_READER_OPTIONS']:
          this.stepValidated$.next(true);
          this.step = taskStep['TASK_AUTOMODE'];
          break;
        case taskStep['TASK_OVERVIEW']:
          this.step = taskStep['TASK_READER_OPTIONS'];
          break;
        default:
          this.stepValidated$.next(true);
          this.step--;
      }
    } else {
      if (this.step === taskStep['TASK_STUDENTS']) {
        this.stepValidated$.next(true);
        this.step--;
      } else if (
        this.step === taskStep['TASK_READER_OPTIONS'] &&
        this.taskType === 'time'
      ) {
        this.stepValidated$.next(true);
        this.step--;
      } else if (this.step === taskStep['TASK_BOOK']) {
        this.step = taskStep['TASK_STUDENTS'];
      } else {
        this.step--;
        this.stepValidated$.next(false);
      }
    }
    //this.step--;
  }

  // open beta info and update teacher after close
  openBetaInfo(betaLeaveInfo): void {
    let dialogRef = this.dialog.open(BetaInfoComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'beta-info-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'helpdesk-panel-backdrop',
      data: {
        betaLeaveInfo: betaLeaveInfo,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  submitTask(formDirective?: FormGroupDirective) {
    this.isLoading = true;
    let isAutomode: boolean = false;
    if (this.studentsChooseBook && this.taskType === 'time') {
      this.taskType = 'time-choice';
    } else if (this.studentsChooseBook && this.taskType === 'auto') {
      isAutomode = true;
    }

    const reader_settings = {
      font_family: this.readerSetting.value.font_family,
      font_size: this.readerSetting.value.font_size,
      line_height: this.readerSetting.value.line_height,
      letter_spacing: this.readerSetting.value.letter_spacing,
      hyphenation_mode: this.readerSetting.value.hyphenation_mode,
      editable: this.readerSetting.value.editable,
      //
    };

    let weekdays = [];
    let disableInHolidays: boolean;
    if (this.taskType === 'auto') {
      this.taskType = 'time';
      let durationMonth = 1; //change this when automode is implemented for more months
      let durationDate = moment();
      durationDate.add(durationMonth, 'month');
      this.deadline = durationDate.endOf('day');
      weekdays = this.selectedWeekdays;
      disableInHolidays = this.disableInHolidays;
    } else {
      this.deadline = moment(
        this.readingTimeSelector.get('selectedDate').value,
      ).endOf('day');
    }

    let taskTypeVal = '';
    //let minumumValue = null;
    if (this.taskMode === 'several_steps') {
      taskTypeVal = 'multiple';
      // minumumValue = this.readingTimeSelector.value.minimum;
    } else if (isAutomode) {
      taskTypeVal = 'autonomous';
    } else {
      taskTypeVal = 'single';
    }
    let task = {
      teacherId: this.teacherId,
      description: this.description,
      status: 'active',
      wordcount: this.wordcount,
      type: this.taskType,
      deadline: this.deadline,
      book_isbn: this.bookData?.isbn,
      book_name: this.bookData?.title,
      mode: taskTypeVal,
      task_area: this.taskArea,
      reader_settings: reader_settings,
      auto_stop: !this.isAllowedReadMore,
      dsef_version: this.bookData?.dsef_version
        ? this.bookData?.dsef_version
        : 1.0,
      // minimum: minimumValue
    };

    if (this.taskType === 'time' || this.taskType === 'time-choice') {
      task['time'] = this.readingTimeSelector.value.minutes;
    }
    if (this.taskType === 'text') {
      task['range'] = this.bookDataService.getRangeForTask();
    }
    if (isAutomode) {
      task['weekdays'] = weekdays;
      task['disableInHolidays'] = this.disableInHolidays;
    }

    this.taskService
      .createMasterTask(
        task,
        this.teacherId,
        this.studentsArray,
        this.trialStatus.isActive,
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (res: Date) => {
          // Update local student trial phase
          this.groupOverview.forEach((go) => {
            const group = this.groups.find((g) =>
              go.id.map((g) => g._id).includes(g.group._id),
            );
            group.group._students.forEach((student) => {
              if (
                this.groupOverview
                  .map((go) => go.id[0].name)
                  .includes(student.name)
              ) {
                if (!student.trial) {
                  student.trial = { training: 1 };
                } else if (!student.trial.training) {
                  student.trial = { ...student.trial, training: 1 };
                } else {
                  student.trial.training += 1;
                }
              }
            });
          });
          setTimeout(() => {}, 3000);
          let message =
            this.translatedText?.training_task_create_success_message;
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          this.dialog.closeAll();
          // update task list in studentOverview
          //fix with next?
          if (this.isSingleStudent === true) {
            // this.taskService.getTasksToStudents(this.studentId);
          } else {
            // update list
            this.taskService.getMasterTasksFromTeacher();
          }
        },
        error: (error: any) => {
          console.log(error);
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
          this.dialog.closeAll();
        },
      });
  }

  getSelectedStudentsList(event: any) {
    if (event.students) {
      this.studentsArray = event.students;
    }
    if (event.students.length != 0) {
      this.stepValidated$.next(true);
    }
  }

  openUpgradeModal() {
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: 'readingMinutes' },
      },
    );
  }

  closeAll(event: boolean): void {
    this.dialog.closeAll();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  //not used fcuntions after redesign
  replaceHtmlContent(string) {
    return this.taskService.formatHTMLContent(string);
  }

  backToLibrary(event) {
    this.isBookList = event;
    this.isBookPreview = false;
    this.isBookView = false;
  }

  backToBookPreview(event) {
    this.isBookList = false;
    this.isBookPreview = true;
    this.isBookView = false;
  }
  openBookView(event) {
    if (this.taskType === 'text') {
      this.isBookList = false;
      this.isBookPreview = false;
      this.isBookView = event;
    } else if (this.taskType === 'time') {
      this.isBookList = false;
      this.isBookPreview = false;
      this.isBookView = false;
      // this.stepper.selectedIndex = 4;
    }
  }
  removeFromStudentsArray(index: number, id): void {
    // this.studentsArray.splice(index, 1);
    this.studentsArray = this.studentsArray.filter((item) => item._id !== id);
  }
  getStundentsByGroup(id) {
    return this.tasks.filter((task) => task._group === id);
  }
  checkForSelection(student, index) {
    if (
      this.isSingleGroup === true ||
      this.isSingleStudent === true ||
      this.checkSelection === true
    ) {
      return this.studentsArray.some((el) => {
        return el._id === student._id;
      });
    }
  }

  disableSelectAll(students) {
    let count = 0;
    students.forEach((student) => {
      student.licenses.forEach((license) => {
        if (license.type_key === 'fo' && license.active) {
          count++;
        }
      });
    });
    return count == students.length ? false : true;
  }

  isOptionDisabled(group: any, selectedOptions: any[], student: any): boolean {
    const foundStudent = group._students.find(
      (item) => item._user.uuid === student._user.uuid,
    );
    if (foundStudent) {
      const license = student.licenses.find(
        (license) => license.type_key === 'fo',
      );
      return license ? !license.active : true;
    } else {
      return true;
    }
  }

  selectedType(value?) {
    if (this.step === 1) {
      if (this.taskType === 'auto') {
        // remove validation from deadline for auto mode
        this.readingTimeSelector.get('selectedDate').setValidators(null);
        this.readingTimeSelector.get('selectedDate').updateValueAndValidity();
        if (this.showBetaInfo === true) {
          this.openBetaInfo(false);
        }
      } else if (this.taskType === 'text' || this.taskType === 'time') {
        this.readingTimeSelector.get('minutes').setValidators(null);
        this.readingTimeSelector.get('minutes').updateValueAndValidity();
        this.readingTimeSelector
          .get('selectedDate')
          .setValidators([Validators.required]);
        this.readingTimeSelector.get('selectedDate').updateValueAndValidity();
      } else {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText
            ?.training_task_create_error_snackbar_select_mode,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }

    if (value === 'area') {
      if (this.taskArea === 'reading_fluency' && this.taskType === 'auto') {
        this.step = 3;
        /* else if (this.taskType === 'manual') {
          this.step++;
        } */
      } else {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText
            ?.training_task_create_error_snackbar_select_test,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }
  }
  getEstimatedReadingTime() {
    this.wordcount = this.bookDataService.getWordCountForTask();
    this.taskService.getEstimatedReadingTime(this.wordcount).subscribe({
      next: (estimations: any) => {
        this.estimationByGrade = estimations.groups;
      },
      error: (error: any) => {
        console.log(error);
        let message =
          'Die erwarteten Minuten Lesezeit konnten nicht berechnet werden.';
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  getBookImageUrl(isbn) {
    this.bookService.getBookimage(isbn).subscribe((response) => {
      if (response) {
        // just url
        this.bookImgUrl = response.link;
      }
    });
  }
}
