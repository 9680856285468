<section class="container-fluid overlay_container">
  <div class="step_container">
    <div class="header-wrapper">
      <div class="newschool-year-cancel-btn">
        <button
          alt="Schließen"
          mat-flat-button
          class="close-button-with-text"
          type="button"
          matSuffix
          [mat-dialog-close]="true"
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
      </div>

      <div class="chip_wrapper">
        <div class="chip_outline">
          <div class="chip_content add-student-chip">
            {{ translatedText.admin_newyear_school_headline }}
          </div>
        </div>
      </div>
      <div class="buttons-wrapper">
        <button alt="Zurück" mat-button (click)="back()" class="back-btn" *ngIf="step !== 1">
          {{ translatedText.students_add_back }}
        </button>
        <button
          alt="Weiter"
          mat-flat-button
          color="primary"
          class="submit-button"
          (click)="next()"
          [disabled]="
            chooseClassForm.invalid || isFormInvalid || (step === 3 && selectedStudentList.length === 0) || isLoading
          "
        >
          <span *ngIf="!isLoading">{{
            step === 3 ? translatedText.admin_newyear_school_update_class : translatedText.students_add_next
          }}</span>

          <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
        </button>
      </div>
    </div>
    <div class="training-task_container">
      <div class="step_content">
        <div class="training-task_wrap">
          <!-- Choose Class Step 1 Start -->
          <div class="step_padding step_padding--scroll" *ngIf="step === 1">
            <div class="training-task_create_headline">
              <h3 class="h2 h2--bold">
                {{ translatedText.admin_newyear_school_choose_class }}
              </h3>
            </div>
            <form [formGroup]="chooseClassForm" class="group-create-form">
              <div class="paragraph paragraph--bold paragraph--l">
                <div class="selected-group-name-details">
                  <p class="paragraph paragraph--m mb-3 text-center">
                    {{ translatedText.admin_newyear_school_choose_class_info }}
                  </p>
                  <div class="selected-group-name radio-check">
                    <mat-radio-group formControlName="class" aria-label="Select an option">
                      <div class="selected-group-item" *ngFor="let item of groups">
                        <mat-radio-button
                          [value]="item._id"
                          (click)="selectOneCheckBox(item)"
                          [checked]="currentlyCheckedClass === item._id"
                        >
                          <p class="paragraph paragraph--bold paragraph--l">
                            {{ item?.grade + item?.stream + ' - ' + item?.school_year.slice(2) }}
                          </p>
                        </mat-radio-button>
                      </div>
                    </mat-radio-group>
                    <!-- <div class="selected-group-item round-checkbox" *ngFor="let item of groups">
                      <mat-checkbox formControlName="class" [value]="item._id"
                        [checked]="currentlyCheckedClass === item._id" (click)="selectOneCheckBox(item)"></mat-checkbox>
                      <p class="paragraph paragraph--bold paragraph--l">{{item?.grade + item?.stream + ' - '
                        + item?.school_year.slice(2) }}</p>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- Choose Class Step 1 End -->

          <!-- Class Details Step 2 Start -->
          <div class="step_padding step_padding--scroll" *ngIf="step === 2">
            <div class="training-task_create_headline">
              <h3 class="h2 h2--bold">{{ translatedText.admin_group_class_details }}</h3>
            </div>
            <app-class-details
              [selectedGroup]="selectedGroup"
              [isCurrentYearValidationRequired]="isCurrentYearValidationRequired"
              (formDataEvent)="classDetailsForm($event)"
            ></app-class-details>
          </div>
          <!-- Class Details Step 2 End -->

          <!-- Student List Step 3 Start -->
          <div class="step_padding step_padding--scroll w-471" *ngIf="step === 3">
            <div class="training-task_create_headline">
              <h3 class="h2 h2--bold">{{ translatedText.admin_newyear_school_student_list_headline }}</h3>
              <form [formGroup]="studentListForm" class="group-create-form">
                <p class="paragraph paragraph--m mt-3 text-center">
                  {{ translatedText.admin_newyear_school_select_student_description }}
                </p>

                <div class="student-list-group">
                  <div class="student-list-selected-item">
                    <div class="selected-class-info">
                      <p class="paragraph paragraph--bold paragraph--l">
                        {{ classDetailsStepData.grade }}{{ classDetailsStepData.stream }} -
                        {{ classDetailsStepData.school_year | slice: 2 }}
                      </p>
                      <div class="selected-class-count">
                        <mat-icon class="icon-16" svgIcon="streamline_filled:small-circle"></mat-icon>
                        {{ selectedStudentList.length }}
                      </div>
                    </div>
                    <button alt="Alle auswählen" mat-button (click)="selectAllStudents()">
                      {{ translatedText.students_add_select_all }}
                    </button>
                  </div>
                  <div class="student-available-group round-checkbox">
                    <div class="student-available-item" *ngFor="let item of studentList; let i = index">
                      <div class="student-available-info">
                        <img [src]="item.avatarUrl" alt="studend avatar" class="icon-32" />
                        <!-- <img src="assets/planet_package.svg" alt="" *ngIf="classDetailsStepData.avatar==='planet'" /> -->
                        <p class="paragraph">{{ item.username }}</p>
                      </div>
                      <mat-checkbox
                        formControlName="students"
                        [checked]="item.checked"
                        [value]="item._id"
                        (click)="chooseStudent(item, i)"
                      ></mat-checkbox>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Student List Step 3 End -->

          <!-- Success Message Step 4 Start -->
          <div class="step_padding step_padding--scroll" *ngIf="step === 4">
            <div class="training-task_create_headline">
              <h3 class="h2 h2--bold">{{ translatedText.admin_newyear_school_student_list_headline }}</h3>
            </div>
          </div>
          <!-- Success Message Step 4 End -->
        </div>
      </div>
    </div>
  </div>
</section>
