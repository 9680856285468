import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { StudentService } from 'src/app/providers/student.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';
import { GroupService } from 'src/app/providers/group.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-student-pdf-overlay-info',
  templateUrl: './student-pdf-overlay-info.component.html',
  styleUrls: ['./student-pdf-overlay-info.component.scss'],
})
export class StudentPdfOverlayInfoComponent implements OnInit, OnDestroy {
  environment = environment;
  IS_APP = environment.isApp;
  teacherId; // TODO: unused?
  groupId; // TODO: unused?
  groupName;
  isLoading: boolean = false;
  blobUrl;
  group;
  id; // TODO: unused?
  isDownload: boolean = true;
  studentId: string;
  studentUsername: string;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private authService: AuthService,
    private groupService: GroupService,
    private studentService: StudentService,
    private capacitorService: CapacitorService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['group'] !== 'undefined') {
        this.group = this.injectedData['group'];
      }
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
      if (typeof injectedData['studentUsername'] !== 'undefined') {
        this.studentUsername = this.injectedData['studentUsername'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  downloadPDF() {
    // create download object
    if (!this.IS_APP) {
      let pdfFilename = this.groupService.generateGroupFilename(
        this.group,
        this.studentUsername,
      );
      var element = document.createElement('a');
      element.setAttribute('href', this.blobUrl);
      element.setAttribute('download', pdfFilename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    this.closePdfOverlay();
  }

  closePdfOverlay() {
    // Reset the form
    this.dialog.closeAll();
  }

  regeneratePdfForStudent() {
    this.isLoading = true;
    this.studentService
      .regeneratePdfForStudent(
        this.group._id,
        this.studentId,
        false,
        this.translatedText,
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.isLoading = false;
            this.isDownload = false;
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            this.blobUrl = window.URL.createObjectURL(blob);
            if (this.IS_APP) {
              let pdfFilename = this.groupService.generateGroupFilename(
                this.group,
                this.studentUsername,
              );
              this.capacitorService.saveBlobToFilesystem(blob, pdfFilename);
            }
          }
        },
        error: (error: any) => {
          let message = this.translatedText?.technical_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }
}
