<div class="slr-popup-container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <mat-icon svgIcon="streamline_outlined:warning-info" class="icon-120"></mat-icon>

  <h3 class="h3 h3--bold">{{ translatedText.admin_group_class_student_limit_reach_title }}</h3>
  <p class="paragraph paragraph--m">
    {{ descriptionText }}
  </p>

  <button alt="ok" mat-flat-button class="mt-3" color="primary" type="button" (click)="closePopup()">Ok</button>
</div>
