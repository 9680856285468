<div class="for-parents-form-wrapper">
  <form [formGroup]="personalForm" #formDirective="ngForm">
    <div class="input-wrapper">
      <div class="">
        <div class="col-md-12 col-sm-12 input-flex">
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_firstname }}</mat-label>
            <input required matInput formControlName="firstname" type="text" />
            <mat-error *ngIf="personalForm.get('firstname')?.hasError('required')">
              {{ translatedText.parents_form_name_error }}
            </mat-error>
            <mat-error *ngIf="personalForm.get('firstname').hasError('hasDigits')">
              {{ translatedText.parents_form_hasDigits_error }}
            </mat-error>
          </mat-form-field>
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_lastname }}</mat-label>
            <input required matInput formControlName="lastname" type="text" />
            <mat-error *ngIf="personalForm.get('lastname')?.hasError('required')">
              {{ translatedText.parents_form_name_error }}
            </mat-error>
            <mat-error *ngIf="personalForm.get('lastname').hasError('hasDigits')">
              {{ translatedText.parents_form_hasDigits_error }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12 input-flex">
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_email }}</mat-label>
            <input required matInput formControlName="email" type="text" />
            <mat-error *ngIf="personalForm.get('email')?.hasError('required') && personalForm.get('email').touched">
              {{ translatedText.parents_form_email_error }}
            </mat-error>
            <mat-error *ngIf="personalForm.get('email').hasError('invalidEmail') && personalForm.get('email').touched">
              {{ translatedText.parents_form_email_error_valid }}
            </mat-error>
          </mat-form-field>
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_tel }}</mat-label>
            <input matInput formControlName="phoneNum" type="text" />
            <mat-error
              *ngIf="personalForm.get('phoneNum')?.hasError('required') && personalForm.get('phoneNum').touched"
            >
              {{ translatedText.parents_form_tel_error }}
            </mat-error>
            <mat-error
              *ngIf="personalForm.get('phoneNum').hasError('invalidPhone') && personalForm.get('phoneNum').touched"
            >
              {{ translatedText.parents_form_hasLetters_error }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12">
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_kids_age }}</mat-label>
            <input matInput formControlName="kidsAge" type="number" />
            <!-- <mat-error *ngIf="personalForm.get('kidsAge')"> </mat-error> -->
          </mat-form-field>
        </div>

        <div class="col-md-12 input-flex">
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_state }}</mat-label>
            <input matInput formControlName="state" type="text" placeholder="{{ translatedText.parents_form_state }}" />
            <mat-error *ngIf="personalForm.get('state')"></mat-error>
            <!-- <mat-select formControlName="country" type="country" disableOptionCentering panelClass="country-select">
              <mat-option value="Deutschland">Deutschland</mat-option>
              <mat-option value="Italien">Italien</mat-option>
              <mat-option value="Österreich">Österreich</mat-option>
            </mat-select> -->
          </mat-form-field>

          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_school }}</mat-label>
            <input
              matInput
              formControlName="school"
              type="text"
              placeholder="{{ translatedText.parents_form_school }}"
            />
            <mat-error *ngIf="personalForm.get('school')"></mat-error>
            <!-- <mat-select formControlName="school" type="school" disableOptionCentering panelClass="school-select">
              <mat-option value="Schule 1">Schule 1</mat-option>
              <mat-option value="Schule 2">Schule 2</mat-option>
              <mat-option value="Schule 3">Schule 3</mat-option>
            </mat-select> -->
          </mat-form-field>
        </div>

        <div class="col-md-12 col-sm-12">
          <mat-form-field floatLabel="auto">
            <mat-label>{{ translatedText.parents_form_reason_of_interest }}</mat-label>
            <input
              matInput
              formControlName="reasonOfInterest"
              type="text"
              placeholder="{{ translatedText.parents_form_reason_of_interest }}"
            />
            <mat-error *ngIf="personalForm.get('reasonOfInterest')"></mat-error>
          </mat-form-field>

          <mat-form-field class="mat-form-field--honey">
            <input matInput formControlName="honey" type="text" />
            <!-- <mat-error *ngIf="personalForm.get('honeypot')"></mat-error> -->
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="btn-wrapper">
      <button
        alt="Jetzt anmelden"
        mat-flat-button
        color="primary"
        class="home-primary_student for-parents-form-btn"
        type="submit"
        (click)="submitForm()"
        [disabled]="!privacyCheckbox.checked || personalForm.invalid"
      >
        <span class="inline_text">
          {{ translatedText.parents_form_button }}
          <mat-icon>navigate_next</mat-icon>
        </span>
      </button>
    </div>
    <mat-checkbox #privacyCheckbox>
      {{ translatedText.parents_form_consent_1 }}
      <a [routerLink]="['/datenschutz']" target="_blank">{{ translatedText.parents_form_consent_2 }}</a>
      {{ translatedText.parents_form_consent_3 }}
    </mat-checkbox>
  </form>
</div>
