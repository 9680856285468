<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <button alt="schließen" mat-icon-button type="button" [mat-dialog-close]="'close'" class="closeOverlay" matSuffix>
        <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
      </button>
      <div class="license-add-wrap" *ngIf="isFormView">
        <h3 class="h3 h3--bold">{{ translatedText.license_check_license_check }}</h3>
        <form [formGroup]="licenseForm" #licenseFormElement="ngForm">
          <div class="row">
            <div class="col-md-10">
              <div class="license_input">
                <textarea
                  matInput
                  #inputs
                  formControlName="license"
                  [class.ng-invalid]="licenseError"
                  placeholder="Lizenzcodes eingeben, um die Verfügbarkeit zu prüfen"
                  required
                ></textarea>
                <!-- <mat-icon svgIcon="streamline_outlined:tips" matTooltip="{{translatedText.license_check_tooltip}}"></mat-icon> -->
                <p class="paragraph paragraph--s error" *ngIf="licenseError">
                  {{ translatedText.license_check_error }}
                </p>
              </div>
              <p class="paragraph paragraph--s color-slate">{{ translatedText.license_check_content }}</p>
            </div>
            <div class="col-md-2">
              <button
                alt="Prüfen"
                mat-flat-button
                color="primary"
                (click)="submitForm(licenseFormElement)"
                type="submit"
                class="mat-flat-button--full"
                aria-label="Connect"
                [disabled]="licenseForm.invalid || isLoading || disableButton"
                #submitButton
              >
                <span *ngIf="!isLoading">{{ translatedText.license_check_check }}</span>
                <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="school_filter" *ngIf="licenseView">
        <div class="col-md-3">
          <mat-form-field class="book_list_select task_type_select">
            <div class="task-label-wrap">
              <mat-label>{{ translatedText.license_check_license_type }}</mat-label>
            </div>
            <mat-select
              [formControl]="filterFormGroup.get('license_type')"
              floatLabel="never"
              panelClass="dashboard-task-select"
              disableOptionCentering
              multiple
            >
              <mat-select-trigger>
                {{ generateSelectionText(license_type, filterFormGroup.get('license_type').value) }}
              </mat-select-trigger>
              <app-select-all-option
                [control]="filterFormGroup.get('license_type')"
                [values]="license_type"
                [valuetype]="'object'"
                (selectionChange)="selectlicenseType($event)"
                [translatedText]="translatedText"
              >
              </app-select-all-option>
              <mat-option *ngFor="let type of license_type" [value]="type?.value">
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field class="book_list_select task_type_select">
            <div class="task-label-wrap">
              <mat-label>{{ translatedText.license_check_status }}</mat-label>
            </div>
            <mat-select
              [formControl]="filterFormGroup.get('status')"
              floatLabel="never"
              panelClass="dashboard-task-select"
              disableOptionCentering
              multiple
            >
              <mat-select-trigger>
                {{ generateSelectionText(license_status, filterFormGroup.get('status').value) }}
              </mat-select-trigger>
              <app-select-all-option
                [control]="filterFormGroup.get('status')"
                [values]="license_status"
                [valuetype]="'object'"
                (selectionChange)="selectStatus($event)"
                [translatedText]="translatedText"
              >
              </app-select-all-option>
              <mat-option *ngFor="let type of license_status" [value]="type?.value">
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="license-add-wrap" *ngIf="licenseView">
        <mat-table [dataSource]="dataSource" matSort matSortDisableClear [matSortActive]="sortBy">
          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let element; columns: displayedColumns"></mat-row>

          <ng-container matColumnDef="master_license_key">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              translatedText.license_check_license_codes
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <p class="paragraph paragraph--s">
                {{ element[0]?.master_license_key }}
              </p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="school">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Schule</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <p class="paragraph paragraph--s">
                {{ element[0]?.school?.name }}
              </p>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="tag">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Lizenzart</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <!-- <p class="paragraph paragraph--s">
                          {{element[0]?.master_license_key}}
                        </p> -->
              <div *ngIf="element[0]?.master_license_key">
                <ng-container [ngSwitch]="element[0]?.master_license_key.substr(0, 2)">
                  <p class="paragraph paragraph--s" *ngSwitchCase="'FO'">
                    {{ translatedText.license_check_license_training }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'DI'">
                    {{ translatedText.license_check_license_diagnostic }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'DF'">
                    {{ translatedText.license_check_license_diagnostic_training }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'KI'">
                    {{ translatedText.license_check_license_school }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'MK'">
                    {{ translatedText.license_check_license_marketing }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'TG'">
                    {{ translatedText.license_check_license_help }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'TF'">
                    {{ translatedText.license_check_license_help }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'yy'">
                    {{ translatedText.license_check_license_internal }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchCase="'ZZ'">
                    {{ translatedText.license_check_license_dev }}
                  </p>
                  <p class="paragraph paragraph--s" *ngSwitchDefault>-</p>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="used">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              translatedText.license_check_license_status
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element[0]?.exists">
                <ng-container *ngIf="!element[0]?.used">
                  <p class="paragraph paragraph--s status-chip status-chip--active">
                    {{ translatedText.license_check_available }}
                  </p>
                </ng-container>
                <ng-container *ngIf="element[0]?.used">
                  <p class="paragraph paragraph--s status-chip status-chip--inactive">
                    {{ translatedText.license_check_license_assigned }}
                  </p>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!element[0]?.exists">
                <p class="paragraph paragraph--s status-chip status-chip--warn">
                  {{ translatedText.license_check_license_expired }}
                </p>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="diagnostic">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              translatedText.license_check_diagnostic
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element[0]?.exists">
                <p class="paragraph paragraph--s">
                  {{ element[0]?.amount }}
                </p>
              </ng-container>
              <ng-container *ngIf="!element[0]?.exists">
                <p class="paragraph paragraph--s">-</p>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="training">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
              translatedText.license_check_minutes
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element[0]?.exists">
                <p class="paragraph paragraph--s">
                  {{ element[1]?.amount / 60 }}
                </p>
              </ng-container>
              <ng-container *ngIf="!element[0]?.exists">
                <p class="paragraph paragraph--s">-</p>
              </ng-container>
            </mat-cell>
          </ng-container>
        </mat-table>
      </div>
    </div>
  </div>
</div>
