import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { DSReaderComponent } from 'digi-sapiens-reader';
import { DSTableOfContents } from 'table-of-contents';
import { AppRoutingModule } from '../app-routing.module';
import { AuthInterceptor } from '../auth/auth-interceptor';
import { ErrorInterceptor } from '../error/error-interceptor';
import { LoadingSpinnerModule } from '../helpers/loading-spinner/loading-spinner.module';
import { MaterialModule } from '../material.module';
import { BookReaderNavComponent } from './book-reader-nav/book-reader-nav.component';
import { BookReaderPaginationComponent } from './book-reader-pagination/book-reader-pagination.component';
import { BookReaderSettingsComponent } from './book-reader-settings/book-reader-settings.component';
import { BookReaderViewComponent } from './book-reader-view/book-reader-view.component';

@NgModule({
  declarations: [
    BookReaderViewComponent,
    BookReaderNavComponent,
    BookReaderPaginationComponent,
    BookReaderSettingsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    MatDialogModule,
    LoadingSpinnerModule,
    DSReaderComponent,
    DSTableOfContents,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [],
  exports: [
    BookReaderViewComponent,
    BookReaderNavComponent,
    BookReaderPaginationComponent,
    BookReaderSettingsComponent,
  ],
})
export class BookReaderModule {}
