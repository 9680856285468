import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { OauthErrorComponent } from './oauth-error.component';

@NgModule({
  declarations: [OauthErrorComponent],
  imports: [BrowserModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [OauthErrorComponent],
})
export class OauthErrorModule {}
