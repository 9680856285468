<div *ngIf="isCanceled">
  <div class="record_overlay_container">
    <button
      alt="Schließen"
      mat-mini-fab
      type="button"
      class="close-button close-button--right"
      [mat-dialog-close]="'continue'"
      matSuffix
    >
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button>
    <div class="record_overlay_wrap">
      <h3 class="h3 h3--bold">{{ translatedText.record_overlay_cancel_title }}</h3>
      <p class="paragraph paragraph--xl mt-3">
        {{ translatedText.record_overlay_cancel_text }}
        <span *ngIf="!isDiagnosticTest">{{ translatedText.record_overlay_cancel_text_2 }}</span>
      </p>
      <div class="record_button finish mt-4" *ngIf="!isDiagnosticTest">
        <mat-icon class="circle record" svgIcon="streamline_outlined:check"></mat-icon>
        {{ translatedText.record_overlay_finish }}
      </div>
      <div class="record_overlay_buttons mt-2">
        <button alt="Abbrechen" mat-flat-button color="secondary-student" [mat-dialog-close]="'cancel'" type="button">
          {{ translatedText.record_overlay_cancel }}
        </button>
        <button alt="Weiterlesen" mat-flat-button color="primary-student" [mat-dialog-close]="'continue'" type="button">
          {{ translatedText.record_overlay_continue }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isRestart">
  <div class="record_overlay_container">
    <button
      alt="Schließen"
      mat-mini-fab
      type="button"
      class="close-button close-button--right"
      [mat-dialog-close]="'continue'"
      matSuffix
    >
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button>
    <div class="record_overlay_wrap">
      <h3 class="h3 h3--bold">{{ translatedText.record_overlay_reset_title }}</h3>
      <p class="paragraph paragraph--xl">
        {{ translatedText.record_overlay_reset_text }}
      </p>
      <div class="record_overlay_buttons">
        <button alt="Von vorne" mat-flat-button color="secondary-student" [mat-dialog-close]="'restart'" type="button">
          {{ translatedText.record_overlay_reset }}
        </button>
        <button alt="Weiterlesen" mat-flat-button color="primary-student" [mat-dialog-close]="'continue'" type="button">
          {{ translatedText.record_overlay_continue }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isFinished">
  <div class="record_overlay_container">
    <button
      alt="Schließen"
      mat-mini-fab
      type="button"
      class="close-button close-button--right"
      [mat-dialog-close]="'continue'"
      matSuffix
    >
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button>
    <div class="record_overlay_wrap">
      <img class="record_overlay_img" src="../../../assets/end-task.svg" alt="" />
      <h3 class="h3 h3--bold">{{ translatedText.record_overlay_finish_title }}</h3>
      <p class="paragraph paragraph--xl">
        {{ translatedText.record_overlay_finish_text }}
      </p>
      <div class="record_overlay_buttons">
        <button
          alt="Weiterlesen"
          mat-flat-button
          color="secondary-student"
          [mat-dialog-close]="'continue'"
          type="button"
        >
          {{ translatedText.record_overlay_continue }}
        </button>
        <button alt="Fertig" mat-flat-button color="primary-student" [mat-dialog-close]="'finished'" type="button">
          {{ translatedText.record_overlay_finish }}
        </button>
      </div>
    </div>
  </div>
</div>
