<button alt="Schließen" mat-icon-button type="button" class="circle light" [mat-dialog-close]="'close'" matSuffix>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>

<div class="book-complexity-rating-overlay_container">
  <p class="paragraph paragraph--xl paragraph--bold rating-header">
    {{ translatedText.book_complexity_rating_success }}
  </p>
  <div class="rating-wrap">
    <h3 class="h3 h3--bold">{{ translatedText.book_complexity_rating_1 }}</h3>
    <div class="star-rating">
      <div class="star-wrap" (click)="setRating(1)">
        <mat-icon [ngClass]="rating >= 1 ? 'hidden' : ''" svgIcon="streamline_filled:book-rating"></mat-icon>
        <mat-icon [ngClass]="rating >= 1 ? 'active' : 'hidden'" svgIcon="streamline_filled:book-rating-active">
        </mat-icon>
        <p class="paragraph paragraph--bold">{{ translatedText.book_complexity_rating_1 }}</p>
      </div>
      <div class="star-wrap" (click)="setRating(2)">
        <mat-icon [ngClass]="rating >= 2 ? 'hidden' : ''" svgIcon="streamline_filled:book-rating"></mat-icon>
        <mat-icon [ngClass]="rating >= 2 ? 'active' : 'hidden'" svgIcon="streamline_filled:book-rating-active">
        </mat-icon>
        <p class="paragraph paragraph--bold">{{ translatedText.book_complexity_rating_2 }}</p>
      </div>
      <div class="star-wrap" (click)="setRating(3)">
        <mat-icon [ngClass]="rating >= 3 ? 'hidden' : ''" svgIcon="streamline_filled:book-rating"></mat-icon>
        <mat-icon [ngClass]="rating >= 3 ? 'active' : 'hidden'" svgIcon="streamline_filled:book-rating-active">
        </mat-icon>
        <p class="paragraph paragraph--bold">{{ translatedText.book_complexity_rating_3 }}</p>
      </div>
      <div class="star-wrap" (click)="setRating(4)">
        <mat-icon [ngClass]="rating >= 4 ? 'hidden' : ''" svgIcon="streamline_filled:book-rating"></mat-icon>
        <mat-icon [ngClass]="rating >= 4 ? 'active' : 'hidden'" svgIcon="streamline_filled:book-rating-active">
        </mat-icon>
        <p class="paragraph paragraph--bold">{{ translatedText.book_complexity_rating_4 }}</p>
      </div>
      <div class="star-wrap" (click)="setRating(5)">
        <mat-icon [ngClass]="rating >= 5 ? 'hidden' : ''" svgIcon="streamline_filled:book-rating"></mat-icon>
        <mat-icon [ngClass]="rating >= 5 ? 'active' : 'hidden'" svgIcon="streamline_filled:book-rating-active">
        </mat-icon>
        <p class="paragraph paragraph--bold">{{ translatedText.book_complexity_rating_5 }}</p>
      </div>
    </div>
  </div>
  <mat-form-field>
    <textarea matInput #commentInput placeholder="{{ translatedText.book_complexity_rating_comment }}"></textarea>
  </mat-form-field>
  <div class="view_button">
    <button alt="Später fragen" mat-flat-button color="secondary-student" type="button" [mat-dialog-close]="'close'">
      {{ translatedText.book_complexity_rating_ask_again }}
    </button>
    <button
      alt="Weiter"
      [disabled]="rating == 0"
      mat-flat-button
      color="primary-student"
      type="button"
      (click)="submitForm()"
    >
      {{ translatedText.book_complexity_rating_next }}
    </button>
  </div>
</div>
