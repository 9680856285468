<div class="license-accept-container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <ng-container *ngIf="isForm">
    <h3 class="h3 h3--bold">{{ translatedText.license_decline_title }}n</h3>
    <div class="license-info">
      <p class="paragraph paragraph--bold">{{ translatedText.license_decline_details }}</p>
      <div class="license-info-box">
        <div class="license-info-box-header">
          <div class="teacher_with_icon teacher_with_icon--blue">
            <button alt="Person" mat-mini-fab>
              <mat-icon svgIcon="streamline_filled:user"></mat-icon>
            </button>
            <p class="paragraph paragraph--bold">
              {{ license?._teacher }}
            </p>
          </div>
          <div class="group_headline">
            <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
            <p class="paragraph paragraph--bold paragraph--s">
              {{ license?._group?.name }} - {{ license?._group?.school_year | slice: 2 }}
            </p>
          </div>
        </div>
        <div class="license-info-box-content">
          <div class="license-content">
            <p class="paragraph paragraph--s" *ngIf="license.type_key == 'di'">
              {{ translatedText.license_decline_amount }}
            </p>
            <p class="paragraph paragraph--s" *ngIf="license.type_key == 'fo'">
              {{ translatedText.license_decline_minutes }}
            </p>
            <p class="paragraph paragraph--bold">{{ license?.amount }}</p>
          </div>
          <div class="license-content">
            <p class="paragraph paragraph--s">{{ translatedText.license_decline_license_type }}</p>
            <p class="paragraph paragraph--bold">
              <ng-container *ngIf="license.type_key == 'fo'">
                {{ translatedText.license_decline_minutes }}
              </ng-container>
              <ng-container *ngIf="license.type_key == 'di'">
                {{ translatedText.license_decline_diagnostic }}
              </ng-container>
            </p>
          </div>
          <div class="license-content">
            <p class="paragraph paragraph--s">{{ translatedText.license_decline_created }}</p>
            <p class="paragraph paragraph--bold">{{ license.created | date: 'd MMM y HH:mm' }}</p>
          </div>
        </div>
      </div>
      <div class="decline-license-buttons">
        <button
          alt="Abbrechen"
          mat-flat-button
          color="secondary"
          type="button"
          aria-label="cancel"
          [mat-dialog-close]="'close'"
        >
          {{ translatedText.license_decline_cancel }}
        </button>
        <button
          alt="Anfrage ablehnen"
          mat-flat-button
          color="warning"
          (click)="submitForm()"
          type="submit"
          aria-label="LOG IN"
        >
          {{ translatedText.license_decline_declined_text }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isInfo">
    <div class="info_headline decline">
      <mat-icon svgIcon="streamline_outlined:license-decline"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.license_decline_declined }}</h3>
    </div>
    <div class="license-info-box">
      <div class="license-info-box-header">
        <div class="license-teacher">
          <mat-icon color="slate" svgIcon="streamline_filled:user-circle"></mat-icon>
          <p class="paragraph paragraph--bold">
            {{ license?._teacher }}
          </p>
        </div>
        <div class="group_headline">
          <mat-icon svgIcon="streamline_outlined:class"></mat-icon>
          <p class="paragraph paragraph--bold paragraph--s">
            {{ license?._group?.name }} - {{ license?._group?.school_year | slice: 2 }}
          </p>
        </div>
      </div>
      <div class="license-info-box-content">
        <div class="license-content">
          <p class="paragraph paragraph--s" *ngIf="license.type_key == 'di'">
            {{ translatedText.license_decline_amount }}
          </p>
          <p class="paragraph paragraph--s" *ngIf="license.type_key == 'fo'">
            {{ translatedText.license_decline_minutes }}
          </p>
          <p class="paragraph paragraph--bold">{{ license?.amount }}</p>
        </div>
        <div class="license-content">
          <p class="paragraph paragraph--s">{{ translatedText.license_decline_license_type }}</p>
          <p class="paragraph paragraph--bold">
            <ng-container *ngIf="license.type_key == 'fo'">
              {{ translatedText.license_decline_minutes }}
            </ng-container>
            <ng-container *ngIf="license.type_key == 'di'">
              {{ translatedText.license_decline_diagnostic }}
            </ng-container>
          </p>
        </div>
        <div class="license-content">
          <p class="paragraph paragraph--s">{{ translatedText.license_decline_status }}</p>
          <p class="paragraph paragraph--bold warning">{{ translatedText.license_decline_declined_word }}</p>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <button
        alt="ok"
        mat-flat-button
        color="primary"
        type="button"
        class="mat-flat-button--full"
        aria-label="cancel"
        [mat-dialog-close]="'ok'"
      >
        {{ translatedText.license_decline_ok }}
      </button>
    </div>
  </ng-container>
</div>
