import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  DSEF,
  DSEFContentWrapper,
  DSEFContextClick,
  DSEFMarkupStyles,
  DSEFSelection,
  DSEFSyllablesStyles,
  DSEFTextSelectionStyles,
  DSEFTocEntry,
  DSReaderService,
  LinkClickedEvent,
  NewDSEFPageRequest,
} from 'digi-sapiens-reader';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BookDataService } from '../../providers/book-data.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { ActivatedRoute } from '@angular/router';
//import { ViewEncapsulation } from '@angular/core';
// import { BookReaderService } from '../book-reader-service';
// import { map,concatMap, takeUntil, repeatWhen } from 'rxjs/operators';
// import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { ReadingJsonService } from '../../providers/readingJson.service';

export const mockStyles: DSEFMarkupStyles = {
  del: [
    'background-color: #ffdbe3',
    'border-bottom: 2px solid #f23030',
    'padding-left: 0.25rem',
    'padding-right: 0.25rem',
    'cursor: pointer',
  ],
  sub: [
    'background-color: #ecdff9',
    'border-bottom: 2px solid #8654B9',
    'padding-left: 0.25rem',
    'padding-right: 0.25rem',
    'cursor: pointer',
  ],
  omit: ['color: #ccc'],
  ins: [
    'background-color: #d7f7f0',
    'border-bottom: 2px solid #03cc90',
    'padding-left: 0.25rem',
    'padding-right: 0.25rem',
    'cursor: pointer',
  ],
  pause: [
    'display: none',
    'background-color: none',
    'border-bottom: none',
    'padding-left: 0.25rem',
    'padding-right: 0.25rem',
  ],
};

export const hyphenationColors: DSEFSyllablesStyles[] = [
  {
    s1: '',
    s2: '',
  },
  {
    s1: '#3e61df',
    s2: '#DB1919',
  },
  {
    s1: '#14808F',
    s2: '#C45313',
  },
  {
    s1: '#6f8799',
    s2: '#000000',
  },
];

@Component({
  selector: 'app-book-reader-view',
  templateUrl: './book-reader-view.component.html',
  styleUrls: ['./book-reader-view.component.scss'],
  //encapsulation: ViewEncapsulation.ShadowDom,
  animations: [
    trigger('fadeAnimation', [
      state('fadeOut', style({ opacity: 0 })),
      state('fadeIn', style({ opacity: 1 })),
      transition('fadeOut => fadeIn', [animate('0.5s')]),
      transition('fadeIn => fadeOut', [animate('0.3s')]),
    ]),
  ],
})
export class BookReaderViewComponent implements OnInit, OnDestroy {
  restServerUrl: string;
  bookReaderApiUrl;
  translatedText: any;
  public apiDSEFs$!: Observable<DSEF[]>;
  private firstDataLoaded: boolean = false;
  public isLoading: boolean = true;
  public toogleContentChange: boolean = false;
  public customFontSize: string | undefined;
  public customFontFamily: string | undefined;
  public showCustomButtons: boolean = false;
  public useInfinityScroll: boolean = true;
  public showAIMarkup: boolean = false;
  public showHyphenation: boolean = false;
  public enableSelectionMode: boolean = false;
  public enableLinks: boolean = false;
  public customLetterSpacing: string | undefined;
  public customWordSpacing: string | undefined;
  public customLineHeight: string | undefined;
  public showTableOfContents: boolean = false;
  public clickedContext: DSEFContextClick;
  public showEditMenuItem: boolean = false;
  public showMenu: boolean = false;
  public styles: DSEFMarkupStyles = mockStyles;
  public hyphenationStyles: DSEFSyllablesStyles = hyphenationColors[0];
  private hyphenationData = [];
  private loadedHyphenationIds = [];
  public selectedIsbn: string = '';
  public data$!: Observable<DSEF | undefined>;
  public content$!: Observable<DSEFContentWrapper | undefined>;
  public rangeContent;
  public dataRange;
  private _currentApiPageId$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  public customTextSelectionStyles: DSEFTextSelectionStyles = {
    selectionColor: '#70c7d8',
    anchorWordStyles: ['background-color: #2596be', 'color: white'],
  };
  private _currentApiIsbn$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private _selectedApiData$!: Observable<DSEF | undefined>;
  private _selectedApiContentSource = new BehaviorSubject<
    DSEFContentWrapper | undefined
  >(undefined);
  private _selectedApiContent$ = this._selectedApiContentSource.asObservable();
  private _scrollToId = '';
  public scrollTo = '';

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Input() readMode: boolean = false;
  @Input('startWord') startWord: any = {};
  @Input('pickWord') pickWord: boolean = false;
  @Input('bookIsbn') isbn: string = '';
  @Input('bookReaderType') action: string = 'get';
  @Input('taskStartFrom') taskStartFrom: string = '';
  @Input('openFromBookmark') openFromBookmark: any = {};
  @Input('bookAnnotation') bookAnnotation: any;
  @Input('bookReaderSettings') bookReaderSettings: any;
  @Input('FilteredDSEF') FilteredDSEF: any;
  @Input('taskType') taskType: string;
  @Input('bookRange') bookRange: any;
  @Input('fromComponent') fromComponent: string;
  @Input('dsefVersion') dsef_version: number = 1.0;
  @Output() wordClickedEvent = new EventEmitter<any>();
  @Output('reportErrorData') informParentReportError = new EventEmitter<any>();
  constructor(
    private readerService: DSReaderService,
    private bookDataService: BookDataService,
    private translationService: TranslationService,
    private route: ActivatedRoute,
  ) {
    this.restServerUrl = environment.evolutionAPI;
    this.bookReaderApiUrl = environment.BOOKREADERAPI_SERVER;
  }

  public ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    console.log(this.isbn);
    if (this.dsef_version == undefined) {
      this.dsef_version = 1.0;
    }
    if (!this.isbn) {
      this.isbn = '9783732004591'; // Vulkanos
      // this.isbn = '9783864294815'; // Alle zwei Wochen
    }
    if (this.action == 'set') {
      this.enableSelectionMode = true;
    }
    if (this.bookAnnotation) {
      this.showAIMarkup = true;
      // this.taskStartFrom = '011_text';
      // console.log(this.bookAnnotation)
    }

    // Set bookmark start point
    if (Object.keys(this.openFromBookmark).length > 0) {
      this.taskStartFrom = this.openFromBookmark['pageId'];
      this.scrollTo = this.openFromBookmark['targetWordID'];
    }

    if (this._scrollToId !== '') {
      //           this.scrollService.setScrollToId(this._scrollToId); // Set the scroll ID using the service
    }
    if (this.bookReaderSettings) {
      this.bookDataService.clearHyphenContent();
      this.customFontFamily = this.bookDataService.getFontFamily(
        this.bookReaderSettings?.font_family,
      );
      this.customFontSize = this.bookDataService.getFontSize(
        this.bookReaderSettings?.font_size,
      );
      this.customLineHeight = this.bookDataService.getLineHeight(
        this.bookReaderSettings?.line_height,
      );
      this.customLetterSpacing = this.bookDataService.getLetterSpacing(
        this.bookReaderSettings?.letter_spacing,
      );
      let hyphenation_mode = this.bookReaderSettings?.hyphenation_mode;
      if (hyphenation_mode != undefined) {
        this.hyphenationStyles =
          hyphenationColors[hyphenation_mode.replace('option', '')];
      }
    }

    // Reader settings font family listener

    this.bookDataService.$FontFamilyUpdated.subscribe((font_family: string) => {
      if (font_family) {
        this.customFontFamily = font_family;
        this.bookDataService.setFontFamilyForReview(font_family);
      }
    });

    //  this.bookDataService
    //    .getFontFamilyUpdateListener()
    //    .pipe(takeUntil(this._unsubscribeAll))
    //    .subscribe({
    //      next: (font_family: string) => {
    //        console.log('font_family ', font_family);
    //        this.customFontFamily = font_family;
    //      },
    //      error: (error: any) => {
    //        console.error(error);
    //      },
    //    });

    // Reader settings font size listener
    this.bookDataService
      .getFontSizeUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (font_size: string) => {
          this.customFontSize = font_size;
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Reader settings line height listener
    this.bookDataService
      .getLineHeightUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (line_height: string) => {
          this.customLineHeight = line_height;
          this.bookDataService.setLineHeightForReview(line_height);
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Reader settings letter spacing listener
    this.bookDataService
      .getLetterSpacingUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (letter_spacing: string) => {
          this.customLetterSpacing = letter_spacing;
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Reader settings hyphenation mode listener
    this.bookDataService
      .getHyphenationModeUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (hyphenation_mode: string) => {
          this.hyphenationStyles =
            hyphenationColors[hyphenation_mode.replace('option', '')];
          this.showHyphenation = hyphenation_mode == 'option0' ? false : true;
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Reader table of contents listener
    this.bookDataService
      .getShowTableOfContentsUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (show_table: boolean) => {
          this.showTableOfContents = show_table;
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Reader table of contents listener
    this.bookDataService
      .getSyllablesUpdated()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (hyphenationData: any) => {
          this.hyphenationData = hyphenationData;
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Reader jump to content id from outside
    this.bookDataService
      .getJumpToContentIdUpdated()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (contentId: any) => {
          this.scrollTo = contentId.targetWordID;
          setTimeout(() => {
            this.readerService.scrollToElement(contentId.targetWordId);
          });
          this.jumpToChapter({ isbn: this.isbn, pageId: contentId.pageId });
        },
        error: (error: any) => {
          console.error(error);
        },
      });

    // Load specified book content
    if (
      this.action == 'get' &&
      (this.taskType == 'text' ||
        this.taskType == 'diagnostic-pre' ||
        this.taskType == 'diagnostic-mid' ||
        this.taskType == 'diagnostic-post' ||
        this.taskType == 'autonomous-placement')
    ) {
      // Load task with hyphenation
      this.showHyphenation = true;
      this.loadContentForRange();
    } else if (this.action == 'getan') {
      this.loadContentForRange();
    } else {
      this.loadContent();
    }
  }

  logWord($event) {
    console.log($event);
  }

  loadContentForRange() {
    // Load book meta
    this.bookDataService
      .fetchDsef(this.isbn, this.dsef_version)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((dsef) => {
        if (dsef) {
          //console.log(dsef)
          let contentLanguage = dsef.metadata.language;
          if (contentLanguage) {
            this.bookDataService.setContentLanguage(contentLanguage);
          }
        }
      });
    // Load book range
    this.bookDataService
      .fetchRangeContentOfDSEF(
        this.isbn,
        this.dsef_version,
        this.bookRange,
        this.showHyphenation,
        this.getMarkup(),
      )
      .subscribe((dsefApi) => {
        if (dsefApi) {
          // Filter images and prepend domain name
          dsefApi = this.bookDataService.addDomainNameToSrc(
            dsefApi,
            this.bookReaderApiUrl,
          );
          // Append text content for readalizer
          dsefApi = this.bookDataService.addTextContent(dsefApi);
          // Save current content id in service
          this.bookDataService.setCurrentPageAttributes(
            dsefApi?.content_detail?.content_id,
          );
          this.toogleContentChange = true;
          //console.log(dsefApi)
          this.rangeContent = dsefApi;

          // Necessary to update the cdk virtual scroll
          this.readerService.updatePages();

          // Feed empty data to prevent errors
          this.dataRange = {
            isbn: this.isbn,
            table_of_contents: [],
          };
        }
      });
  }

  loadContent() {
    this.apiDSEFs$ = this.bookDataService
      .fetchAllConvertedDSEFs([this.isbn], this.dsef_version)
      .pipe(shareReplay(1));
    this.setupDataObservables();
    if (this.taskType) {
      // Listen to bookmark change & save start and end marker
      this.readerService
        .getBookmark$()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(this.logBookmark.bind(this));
      // Listen to page change start and end marker
      // this.readerService.getStartAndEndFromPage$().pipe(
      //         takeUntil(this._unsubscribeAll)
      //     ).subscribe(this.logStartAndEndPage.bind(this));
    }
    // Load isbn content
    this.apiDSEFs$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      if (data.map((item) => item.isbn == this.isbn)) {
        setTimeout(() => {
          this.selectApiData(this.isbn);
        }, 0);
      }
    });
  }

  private setupDataObservables(): void {
    this.createApiDataObservables();

    this.data$ = combineLatest([this._selectedApiData$]).pipe(
      takeUntil(this._unsubscribeAll),
      switchMap(([dsefApi]: [DSEF]) => {
        // console.log('------DSEF-data-----')
        // console.log(dsefApi)
        dsefApi = this.bookDataService.addDomainNameToSrc(
          dsefApi,
          this.bookReaderApiUrl,
        );
        if (dsefApi && dsefApi.table_of_contents) {
          this.bookDataService.setTableOfContents(dsefApi.table_of_contents);
        }
        if (dsefApi && dsefApi.play_order) {
          this.bookDataService.setPlayOrder(dsefApi.play_order);
        }
        return of(dsefApi);
      }),
    );

    this.content$ = combineLatest([this._selectedApiContent$]).pipe(
      takeUntil(this._unsubscribeAll),
      switchMap(([dsefApi]: [DSEFContentWrapper]) => {
        //console.log('------DSEF-content----')
        // Filter images and prepend domain name
        dsefApi = this.bookDataService.addDomainNameToSrc(
          dsefApi,
          this.bookReaderApiUrl,
        );
        // Append text content for readalizer
        dsefApi = this.bookDataService.addTextContent(dsefApi);
        // Save content in array
        this.bookDataService.setLoadedDSEF(dsefApi);
        // Save current content id in service
        //this.bookDataService.setCurrentPageAttributes(dsefApi?.content_detail?.content_id);
        // Save hyphenation data
        // const hyphenationData = dsefApi['hyphenationData'] != undefined ? dsefApi['hyphenationData'] : [];
        // const contentId = dsefApi?.content_detail?.content_id;
        // // Push hyphens to array if they don't already exist
        // if (!this.loadedHyphenationIds.includes(contentId)) {
        //     this.hyphenationData.push(...hyphenationData);
        //     this.loadedHyphenationIds.push(contentId);
        // }
        this.toogleContentChange = true;
        this.readerService.updatePages();
        //console.log(dsefApi)
        return of(dsefApi);
      }),
    );
  }

  private createApiDataObservables(): void {
    this._selectedApiData$ = this._currentApiIsbn$.pipe(
      filter((isbn: string) => isbn !== ''),
      tap(() => (this.isLoading = true)),
      withLatestFrom(this.apiDSEFs$),
      map(([isbn, dsefs]) => {
        console.log(dsefs);
        return dsefs.find((dsef: DSEF) => dsef.isbn === isbn);
      }),
      tap((dsef) => {
        if (dsef) {
          //console.log(dsef)
          let contentId = dsef.play_order[0].content_id; // start page
          let contentLanguage = dsef.metadata.language;
          if (contentLanguage) {
            this.bookDataService.setContentLanguage(contentLanguage);
          }
          if (this.taskStartFrom != '') {
            console.log('taskStartFrom: ' + this.taskStartFrom);
            // Jump to specific page
            contentId = this.taskStartFrom;
            this.taskStartFrom = '';
          } /*else if (this.FilteredDSEF) {
                        console.log(this.FilteredDSEF);
                        console.log(this.FilteredDSEF.content[0].id)
                        this._selectedApiContentSource.next(this.FilteredDSEF);
                        contentId = '011_text';
                    } */
          if (!this.firstDataLoaded) {
            this.bookDataService.setFirstLoadedContent(contentId);
            this.firstDataLoaded = true;
          }
          // Jump to content
          this.jumpToChapter({ isbn: this.isbn, pageId: contentId });
          //this._currentApiPageId$.next(contentId);
        }
      }),
      startWith(undefined),
      shareReplay(1),
    );

    this._selectedApiContent$ = combineLatest([
      this._selectedApiData$,
      this._currentApiPageId$,
    ]).pipe(
      takeUntil(this._unsubscribeAll),
      // distinctUntilChanged(
      //     ([previousDsef, previousPageId], [dsef, pageId]) =>
      //     previousPageId === pageId && previousDsef?.isbn === dsef?.isbn
      // ),
      tap(() => (this.isLoading = true)),
      switchMap(([dsef, pageId]) => {
        if (
          !dsef ||
          dsef.isbn === '' ||
          !dsef.play_order.find(
            (toc: DSEFTocEntry) => toc.content_id === pageId,
          )
        ) {
          this.isLoading = false;
          return EMPTY;
        }
        return this.bookDataService
          .fetchContentOfDSEF(
            dsef.isbn,
            this.dsef_version,
            pageId || dsef.play_order[0].content_id,
            this.showHyphenation,
            this.fromComponent,
          )
          .pipe(
            tap(() => {
              this.isLoading = false;
              //                        this.scrollService.getScrollToId().subscribe((scrollToId) => {
              //                            if (scrollToId !== '') {
              //                              setTimeout(() => {
              //                                this.readerService.scrollToElement(scrollToId as string);
              //                              });
              //                            }
              //                          });
            }),
          );
      }),
    );
  }

  public selectApiData(isbn: string): void {
    this.selectedIsbn = isbn;
    this._currentApiIsbn$.next(isbn);
  }

  public newPage(newPageRequest: NewDSEFPageRequest): void {
    //console.info(newPageRequest, 'NEWPAGE');
    if (this.useInfinityScroll) {
      this._currentApiPageId$.next(newPageRequest.newPageId);
    } else {
      // Wait for animation change
      this.toogleContentChange = false;
      setTimeout(() => {
        this._currentApiPageId$.next(newPageRequest.newPageId);
      }, 400);
    }
  }

  public logSelection(event: DSEFSelection): void {
    // eslint-disable-next-line no-console, no-restricted-syntax
    console.info(event, 'SELECTION');

    this.wordClickedEvent.emit(event);

    if (this.readMode) return;

    this.bookDataService.setFixedRangeForTask(event.start, event.end);
    // Set wordcount
    this.bookDataService.setWordCount(event.wordCount);
  }

  public handleContextSelection(event: DSEFContextClick): void {
    // eslint-disable-next-line no-console, no-restricted-syntax
    console.info(event, 'CONTEXT_CLICK');
    this.clickedContext = event;
    if (
      this.clickedContext.markup &&
      this.clickedContext.markup.name == 'ins'
    ) {
      this.showEditMenuItem = true;
    } else {
      this.showEditMenuItem = false;
    }
  }

  public logLink(href: LinkClickedEvent): void {
    // eslint-disable-next-line no-console, no-restricted-syntax
    console.info(href, 'LINK_OBJECT');
    // this._currentApiPageId$.next(href.content_id);
    // this._scrollToId = href.anchor_id || '';
  }

  public logBookmark(bookmark: DSEFSelection): void {
    // eslint-disable-next-line no-console, no-restricted-syntax
    console.info(bookmark, 'BOOKMARK_OBJECT');
    console.info('THE START', this.bookDataService.getBookmarkStartObj());
    // Save range for task to be processes
    // this.bookDataService.setAutoRangeForTask(bookmark.start, bookmark.end);
    // Disable startobj saving in service
    //if (this.taskType) {
    //  if (
    //    this.taskType == 'time' ||
    //    this.taskType == 'time-choice' ||
    //    this.taskType == 'self'
    //  ) {
    //    this.bookDataService.setSkipStartObj(true);
    //    // Save current content id in service
    //    this.bookDataService.setCurrentPageAttributes(bookmark?.start?.pageId);
    //    // Save latest bookmark start obj in service
    //    this.bookDataService.setBookmarkStartObj(bookmark?.start);
    //  }
    //}
  }

  public logStartAndEndPage(pageSelection: DSEFSelection): void {
    // eslint-disable-next-line no-console, no-restricted-syntax
    console.info(pageSelection, 'START_END_SELECTION_PAGE');
    // this.bookDataService.setAutoRangeForTask(pageSelection.start, pageSelection.end);
    // // Disable startobj saving in service
    // if (this.taskType) {
    //     if (this.taskType == 'time' || this.taskType == 'time-choice' || this.taskType == 'self') {
    //         this.bookDataService.setSkipStartObj(true);
    //     }
    // }
  }

  public clearTextSelection(): void {
    this.readerService.clearTextSelection();
  }

  public clickedWordReportError(event: MouseEvent) {
    this.informParentReportError.emit(this.clickedContext?.markup);
    this.clickedContext = null;
    this.showMenu = false;
    const customEvent = new CustomEvent('hideContextMenu', {
      detail: event.detail,
    });
    document.dispatchEvent(customEvent);
  }

  public clickedWordEditError() {}

  public getMarkup() {
    if (this.bookAnnotation) {
      return this.bookAnnotation;
    } else {
      return [];
    }
  }

  public getHyphenation() {
    if (this.showHyphenation) {
      return this.hyphenationData;
    } else {
      return [];
    }
  }

  public onShowMenuChanged(event: boolean): void {
    this.showMenu = event;
  }

  public onChapterClick(event: { pageId: string; isbn: string }): void {
    this.jumpToChapter(event);
    this.bookDataService.setShowTableOfContentsUpdateListener(false);
  }

  public jumpToChapter(event: { isbn: string; pageId: string }): void {
    this._currentApiPageId$.next(event.pageId);
  }

  public ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
