<div class="group-delete_container">
  <button
    alt="Schließen"
    mat-mini-fab
    type="button"
    class="close-button close-button--right"
    [mat-dialog-close]="'close'"
    matSuffix
  >
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="group-delete_wrap">
    <p class="paragraph">
      <strong>{{ translatedText.class_pdf_print_attention }} </strong
      >{{ translatedText.class_pdf_print_attention_text }} <br />
      Bitte aktualisieren oder schließen Sie den Browser nicht.
    </p>
    <button
      alt="Klassenliste herunterladen"
      mat-flat-button
      color="primary"
      type="button"
      class="group-delete_button"
      (click)="regeneratePdfForGroup()"
      [disabled]="isLoading"
    >
      <span *ngIf="!isLoading">{{ translatedText.class_pdf_print_download }}</span>
      <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
    </button>
    <img class="footer_group-delete_img" [src]="'/assets/' + environment.whitelabel.generic_logo" alt="" />
  </div>
</div>
