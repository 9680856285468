import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { PinCodeComponent } from 'src/app/helpers/pin-code/pin-code.component';
import { StudentPdfOverlayInfoComponent } from 'src/app/helpers/student-pdf-overlay-info/student-pdf-overlay-info.component';
import { LicenseDistributionComponent } from 'src/app/license/license-distribution/license-distribution.component';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentEditComponent } from 'src/app/student/student-edit/student-edit.component';
import { StudentsAddComponent } from 'src/app/student/students-add/students-add.component';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { ClassDeleteComponent } from '../../../class/class-delete/class-delete.component';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { AdminService, GroupData } from '../../../providers/admin.service';
import { StudentService } from '../../../providers/student.service';
import { StudentDeleteComponent } from '../../../student/student-delete/student-delete.component';
import { AdminAddExistingStudentsComponent } from '../../admin-student/admin-add-existing-students/admin-add-existing-students.component';
import { AdminTeacherManagingComponent } from '../../admin-teacher/admin-teacher-managing/admin-teacher-managing.component';
import { AdminGroupCreateComponent } from '../admin-group-create/admin-group-create.component';
import { AdminGroupManagingComponent } from '../admin-group-managing/admin-group-managing.component';
import { TrialBannersComponent } from 'src/app/helpers/trial-banners/trial-banners.component';
import { UnassignedStudentToClassComponent } from 'src/app/helpers/unassigned-student-to-class/unassigned-student-to-class.component';
import { environment } from 'src/environments/environment';
import { AdminNewSchoolYearComponent } from '../../admin-new-school-year/admin-new-school-year/admin-new-school-year.component';
import { GroupService } from 'src/app/providers/group.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface Student {
  username?: string;
  avatarUrl?: string;
  _group?: any;
  language?: string;
  age?: string;
  reference_value?: number;
  status?: string;
  diagnostic?: any;
  licenses?: [];
}

export interface UnassignedStudent {
  student?: string /* data._students.username */;
  studentId?: string /* data._students._id */;
  avatarUrl?: string;
  _group?: any /* data._students._group.name */;
  schoolYear?: string;
}

@Component({
  selector: 'app-admin-group-list',
  templateUrl: './admin-group-list.component.html',
  styleUrls: [
    './admin-group-list.component.scss',
    '../../admin-teacher/admin-teacher-list/admin-teacher-list.component.scss',
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class AdminGroupListComponent implements OnInit, OnDestroy {
  environment = environment;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userAge = 0;
  teacher: any;
  teacherId;
  categories: any;
  filterCategories;
  page = 1;
  limit = 10;
  sortBy = 'name';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
  };
  dataSource: GroupData = null;
  pageEvent: PageEvent;
  isLoadingPdf: boolean = false;
  displayedColumns: string[] = [
    'name',
    'test_license',
    'teacher',
    /* 'training_license',
    'type3_license',*/
    'schoolUpdated',
    'action',
  ];
  nestedColumns: string[] = [
    'placeholder',
    'username',
    'test_license',
    'training_license',
    /*'type3_license',*/
    'action',
  ];

  student: Student;
  unassignedStudent: UnassignedStudent;
  totalUnassignedStudents: number;
  columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];
  userTypes: string[];
  userType: string;
  userAccountId: string;
  filterFormGroup: FormGroup;
  expandedElement: GroupData | null;
  translatedText: any;
  licensesToSchool: any = [];
  licensesToSchoolLoading: boolean = true;
  isUnlimitedDiagnosticLicense: boolean;
  totalCountDiagnostic: number = 0;
  totalCountTraining: number = 0;
  totalCountLeftDiagnostic: number = 0;
  totalCountLeftTraining: number = 0;
  totalCountLeftDiagnosticExp: number = 0;
  totalCountLeftTrainingExp: number = 0;
  totalCountPending: number = 0;
  status: any[] = [
    { value: 'functions', viewValue: 'Funktioniert' },
    { value: 'suspended', viewValue: 'Suspendiert' },
  ];

  license: any[] = [
    { value: 'test_license', viewValue: 'Diagnose License' },
    { value: 'training_license', viewValue: 'Training License' },
  ];
  selectedTeacherMenuIndex: number | null = null;
  selectedStudentMenu: string | null = null;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  studentCount: number = 0;
  STUDENT_LIMIT: number = 40;
  classNoLicenseStudentsNumber = {};
  hasExpiringLicenses: boolean = false;
  isSchoolYearUpdateRequired: boolean = false;
  currentSchoolYear: string | null = null;
  schoolYears = [];
  selectedSchoolYear: string | null = null;
  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  expandUnassigned: boolean = false;

  //dummydata for unassigned list of students
  unassignedData = [];
  unassignedColumns = ['placeholder', 'student', '_group', 'action'];
  evolutionApiUrl;
  updateRequiredGroupCount: number = 0;
  nextSchoolYear: string | null = null;
  totalUpdatedGroups: any;
  isOauth: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private studentService: StudentService,
    private licenseService: LicenseService,
    private snackBar: MatSnackBar,
    private groupService: GroupService,
    private capacitorService: CapacitorService,
    public dialog: MatDialog,
    private translationService: TranslationService,
  ) {
    this.evolutionApiUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.isOauth = this.authService.getIsOauth();
    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 0,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        //this.paginator.pageIndex = 0;
        // Check for search results
        this.getGroups();
      });

    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    // Init datasource
    this.getGroups();
    // Get user types for adding roles menu item
    this.userTypes = this.authService.getHierachicalRoles();
    this.userType = this.authService.getType();
    this.userAccountId = this.authService.getUserId();
    this.teacherId = this.authService.getTeacherId();
    this.filterFormGroup = new FormGroup({
      license: new FormControl(),
      status: new FormControl(),
    });
    this.getLicenses();
    this.trialStatus = this.authService.getUserTrialStatus();
    if (this.trialStatus.isActive) {
      this.STUDENT_LIMIT = environment.whitelabel.trial_number_students;
    }
    this.getSchoolYearUpdateInfo();
  }

  checkNumberCurrentClasses() {
    console.log('active classes', this.getGroups());
  }

  expandElement(event, element) {
    this.expandedElement =
      this.expandedElement === element._id ? null : element._id;
    event.stopPropagation();
  }

  showUpdateButton(group) {
    if (group && this.currentSchoolYear != null) {
      let currentYearNumber = Number(this.currentSchoolYear.slice(-2));
      let groupSchoolYearNumber = Number(group.school_year.slice(-2));

      return (
        (!group.archived || group.archived != undefined) &&
        ((groupSchoolYearNumber === currentYearNumber &&
          this.isSchoolYearUpdateRequired) ||
          groupSchoolYearNumber < currentYearNumber)
      );
    }
  }

  getGroupName(groups: any) {
    if (groups) {
      const recentGroup = this.adminService.sortGroups(groups);
      if (recentGroup) {
        return recentGroup;
      }
    }
  }

  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        let fourWeeksFromNow = new Date();
        fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
        let expireLicenseCount = 0;
        for (const item of licenses) {
          if (item.expiration_date <= fourWeeksFromNow) {
            expireLicenseCount++;
          }
        }
        if (expireLicenseCount > 0) {
          this.hasExpiringLicenses = true;
        }
        this.totalCountPending = this.licenseService.sumPendingLicenses(
          this.licensesToSchool,
        );
        this.totalCountDiagnostic = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'di',
          'amount',
          true,
        );
        this.isUnlimitedDiagnosticLicense =
          this.licenseService.checkForUnlimitedDiagnosticLicense(
            'di',
            this.licensesToSchool,
            true,
          );
        this.totalCountLeftDiagnostic =
          this.licenseService.sumLicensesByTypeKey(
            this.licensesToSchool,
            'di',
            'amount_left',
            true,
          );
        // this.totalCountLeftDiagnosticExp = 45;
        this.totalCountLeftDiagnosticExp =
          this.licenseService.filterLicensesByExpirationDate(
            this.licensesToSchool,
            'di',
            'amount_left',
          );
        this.totalCountTraining = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'fo',
          'amount',
          true,
        );
        this.totalCountLeftTraining = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'fo',
          'amount_left',
          true,
        );
        this.totalCountLeftTrainingExp =
          this.licenseService.filterLicensesByExpirationDate(
            this.licensesToSchool,
            'fo',
            'amount_left',
          );
        this.licensesToSchoolLoading = false;
      });

    this.getUnassignedStudents();
  }

  hasActiveLicense(licenses: any[], type: string): boolean {
    if (licenses) {
      for (const license of licenses) {
        // Check if the current license has the specified type_key and active is true
        if (license.type_key === type && license.active) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  checkLicenseExistence(licenses: any[], type: string): boolean {
    if (licenses) {
      let count = 0;
      for (const license of licenses) {
        // Check if the current license has the specified type_key and active is true
        if (license.type_key === type) {
          count++;
        }
      }
      return count > 0 ? true : false;
    } else {
      return false;
    }
  }

  checkLicenseAboutToExpire(
    licenses: any[],
    type: string,
    checkMaster: boolean,
  ): boolean {
    const usedMasterLicenses: number[] = [];
    if (licenses) {
      const fourWeeksFromNow = new Date();
      fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
      let aboutToExpire = false;
      licenses = licenses.filter((item) => {
        const now = new Date();
        const expiration = new Date(item.expiration_date);
        // console.log({
        //   now: now.toISOString(),
        //   expiration: expiration.toISOString(),
        //   withinFourWeeks: expiration <= fourWeeksFromNow,
        //   inFuture: expiration >= now,
        // });
        return (
          item.type_key == type &&
          expiration <= fourWeeksFromNow &&
          expiration >= now
        );
      });
      // Check if there are any licenses that are about to expire
      if (licenses.length > 0) {
        aboutToExpire = true;
      }
      licenses.map((license) => usedMasterLicenses.push(license._master_id));
      return checkMaster && aboutToExpire
        ? this.licensesToSchool.some(
            (master) =>
              master.status === 'active' &&
              master.type_key == type &&
              new Date(master.expiration_date) > new Date() &&
              !usedMasterLicenses.includes(master._id),
          )
        : aboutToExpire;
    } else {
      return false;
    }
  }

  getLatestLicenseExpirationDate(licenses: any[], type: string): Date {
    let farthestExpirationLicense = new Date(); // Initialize with today's date

    if (licenses.length > 0) {
      const licenseWithFarthestExpiration = licenses.reduce(
        (latest, current) => {
          const currentDate = new Date(current.expiration_date);
          return currentDate > new Date(latest.expiration_date)
            ? current
            : latest;
        },
      );

      farthestExpirationLicense = licenseWithFarthestExpiration.expiration_date;
    }
    return farthestExpirationLicense;
  }

  chooseStudent(element) {
    this.selectedStudentMenu = element._id;
  }

  checkForCurrentTeacher(teachers): boolean {
    return this.userType == 'teacher'
      ? teachers?.some((teacher) => teacher?._id == this.teacherId) || false
      : true;
  }

  /**
   * generate text for selected mat select
   */
  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value == selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.admin_group_list_more
            : this.translatedText?.admin_group_list_more_single;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.admin_group_all;
      }
    }
    return returnText;
  }

  /**
   * API call on adminService to retrieve requested teacher
   */
  getGroups() {
    this.studentCount = 0;
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .pipe(
        map((groupData: GroupData) => {
          this.dataSource = groupData;
          //console.log(groupData);
          for (const item of this.dataSource.data) {
            this.studentCount = this.studentCount + item._students.length;
            this.classNoLicenseStudentsNumber[item._id] = item._students.filter(
              (s) =>
                !('licenses' in s) ||
                ('licenses' in s && s['licenses'].length == 0),
            ).length;
          }

          const allGroups = groupData.data.filter((item: any) => {
            return !item.archived && item.school_year !== this.nextSchoolYear;
          });
          this.updateRequiredGroupCount = allGroups.length;
          this.totalUpdatedGroups = groupData.data.filter((group: any) => {
            return group.school_year != this.currentSchoolYear;
          });
        }),
      )

      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  /**
   * API call on adminService to retrieve requested teachers by pagination
   */
  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.getGroups();
  }

  /**
   * Send new input value to subscription
   */
  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  /**
   * API call on adminService to retrieve requested teachers with sorted direction
   */
  onSortChange(event: MatSort) {
    //console.log(event);
    this.sortBy = event.active;
    this.matSortDirection = event.direction;
    this.sortDirection = event.direction == 'asc' ? 1 : -1;
    this.getGroups();
  }

  impersonateUser(userId) {
    this.authService.impersonateUser(userId, '12345');
    //this.openPinCode(userId);
  }

  openGroupDeleteDialog(group): void {
    let dialogRef = this.dialog.open(ClassDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'license-add-backdrop',
      data: {
        groupId: group._id,
        groupName: group.name,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      // Refresh groups
      this.getGroups();
      // Refresh licenses
      this.licenseService.getLicensesToSchool();
    });
  }

  openPinCode(userId): void {
    let dialogRef = this.dialog.open(PinCodeComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'pin-code-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'pin-code-backdrop',
    });
    dialogRef.afterClosed().subscribe(() => {
      // TODO pass pin to authService
      //this.authService.impersonateUser(userId, '12345');
    });
  }

  openLicenseDistribution(element, template): void {
    let dialogRef = this.dialog.open(LicenseDistributionComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      disableClose: true,
      backdropClass: 'license-transfer-backdrop',
      data: {
        template: template,
        studentId: element?._id,
        username: element?.username,
        avatarUrl: element?.avatarUrl,
        studentLicenses: element?.licenses,
        availableLicenses: {
          totalCountDiagnostic: this.totalCountDiagnostic,
          totalCountTraining: this.totalCountTraining,
          totalCountLeftDiagnostic: this.totalCountLeftDiagnostic,
          totalCountLeftTraining: this.totalCountLeftTraining,
        },
        licensesToSchool: this.licensesToSchool,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      // Refresh groups
      this.getGroups();
      // Refresh licenses
      this.licenseService.getLicensesToSchool();
    });
  }

  handleCreateGroupTrial() {
    if (this.trialStatus.isActive && this.dataSource.meta.itemCount === 1) {
      this.openUpgradeModal('maxGroupsReached');
      return false;
    } else if (
      this.trialStatus.isActive &&
      this.trialStatus.expiryDate.getTime() < Date.now()
    ) {
      this.openTrialBanner();
      return false;
    } else {
      this.createGroupOverlay();
    }
  }

  createGroupOverlay() {
    if (this.trialStatus.isActive && this.studentCount > this.STUDENT_LIMIT) {
      this.openUpgradeModal('maxStudentsReached');
      return;
    }

    let dialogRef = this.dialog.open(AdminGroupCreateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'full-page-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        studentcount: this.studentCount,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      // Refresh groups
      this.getGroups();
      // Update licenses
      this.licenseService.getLicensesToSchool();
    });
  }

  openTrialBanner(): void {
    let dialogRef = this.dialog.open(TrialBannersComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'trial-banner-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        source: 'upgrade',
        trialStatus: this.trialStatus,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  newSchoolYearOverlay(group: any) {
    let dialogRef = this.dialog.open(AdminNewSchoolYearComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'full-page-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        group: group,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getGroups();
      this.getUnassignedStudents();
    });
  }

  addTeacherToGroup(group) {
    let dialogRef = this.dialog.open(AdminTeacherManagingComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'license-add-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        groupId: group?._id,
        groupName:
          group?.grade + group?.stream + ' - ' + group?.school_year.slice(2),
        managingType: 'add',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      // Refresh groups
      this.getGroups();
    });
  }

  managingGroupOverlay(group) {
    console.log(group);
    let dialogRef = this.dialog.open(AdminGroupManagingComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        group: group,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  addExistingStudentsToGroup(group) {
    let dialogRef = this.dialog.open(AdminAddExistingStudentsComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        groupName:
          group?.grade + group?.stream + ' - ' + group?.school_year.slice(2),
        groupId: group?._id,
        groupStudents: group._students,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      // Refresh groups
      this.getGroups();
    });
  }

  removeTeacherFromGroup(group) {
    let dialogRef = this.dialog.open(AdminTeacherManagingComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'create-training-task-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        groupId: group?._id,
        groupName:
          group?.grade + group?.stream + ' - ' + group?.school_year.slice(2),
        managingType: 'remove',
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(() => {
      // Refresh groups
      this.getGroups();
    });
  }

  editStudentOverlay(element, studentId, editMode) {
    let dialogRef = this.dialog.open(StudentEditComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'student_edit-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'student_edit-overlay-panel-backdrop',
      data: {
        id: studentId,
        editMode: editMode,
        studentLicenses: element?.licenses,
        availableLicenses: {
          totalCountDiagnostic: this.totalCountDiagnostic,
          totalCountTraining: this.totalCountTraining,
          totalCountLeftDiagnostic: this.totalCountLeftDiagnostic,
          totalCountLeftTraining: this.totalCountLeftTraining,
        },
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      // Refresh groups
      this.getGroups();
    });
  }

  updateStudentStatus(student) {
    let newStatus = student?.status == 'active' ? 'inactive' : 'active';
    let formData = {
      _id: student._id,
      status: newStatus,
    };

    this.studentService.updateStudent(formData).subscribe({
      next: () => {
        student.status = newStatus;
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  openStudentDeleteDialog(student): void {
    let deleteDialog = this.dialog.open(StudentDeleteComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'student-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'student-delete-overlay',
      data: {
        studentId: student._id,
        studentName: student.username,
      },
    });
    deleteDialog.afterClosed().subscribe((res) => {
      deleteDialog.close();
      // Refresh groups
      this.getGroups();
      // Refresh licenses
      this.licenseService.getLicensesToSchool();
    });
  }

  openPdfInfo(group, student): void {
    let pdfInfo = this.dialog.open(StudentPdfOverlayInfoComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'group-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        group: group,
        studentId: student._id,
        studentUsername: student.username,
      },
    });
    // dialogRef.disableClose = true;
    pdfInfo.afterClosed().subscribe((res) => {
      //console.log(res);
      if (res !== 'close') {
        pdfInfo.close();
      }
    });
  }

  /**
   * Route to teacher detail component
   */
  navigateToTeacherDetail(id) {
    //this.router.navigate(['teacher-view/' + id]);
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  addStudentsToGroup(
    groupId,
    groupGrade,
    groupName,
    groupYear,
    teachers,
    students,
  ) {
    if (this.trialStatus.isActive && students.length >= this.STUDENT_LIMIT) {
      this.openUpgradeModal('maxStudentsReached');
      return;
    }
    let dialogRef = this.dialog.open(StudentsAddComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'full-page-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        groupId: groupId,
        groupGrade: groupGrade,
        groupName: groupName,
        groupYear: groupYear,
        teachers: teachers,
        isUnlimitedDiagnosticLicense: this.isUnlimitedDiagnosticLicense,
        totalCountDiagnostic: this.totalCountDiagnostic,
        totalCountLeftDiagnostic: this.totalCountLeftDiagnostic,
        totalCountTraining: this.totalCountTraining,
        totalCountLeftTraining: this.totalCountLeftTraining,
        totalCountLeftDiagnosticExp: this.totalCountLeftDiagnosticExp,
        totalCountLeftTrainingExp: this.totalCountLeftTrainingExp,
        currentStudentAmount: students.length,
        currentYear: this.currentSchoolYear,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Refresh groups
        this.getGroups();
        this.licenseService.getLicensesToSchool();
      });
  }

  closeTeacherMenu() {
    this.selectedTeacherMenuIndex = null;
  }

  closeStudentMenu() {
    this.selectedStudentMenu = null;
  }

  openUpgradeModal(messageType: 'maxStudentsReached' | 'maxGroupsReached') {
    this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
      UpgradeDialogComponent,
      {
        width: '85vw',
        autoFocus: false,
        disableClose: true,
        panelClass: 'home-trial-dialog-panel',
        // hasBackdrop: false,
        backdropClass: 'license-add-backdrop',
        data: { upgradeModalText: messageType },
      },
    );
  }

  openAddToClassOverlay(student) {
    let dialogRef = this.dialog.open(UnassignedStudentToClassComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'password-changed-overlay-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
      data: {
        student: student,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Refresh groups
        this.getGroups();
        this.getUnassignedStudents();
        // Refresh licenses
        //this.licenseService.getLicensesToSchool();
      });
  }

  formatDateString(str) {
    const array = str.split('');
    array.splice(0, 2);
    return array.join('');
  }

  getUnassignedStudents() {
    this.studentService
      .getUnassignedStudents()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.totalUnassignedStudents = data.length;
        this.unassignedData = data;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      this.isSchoolYearUpdateRequired = data.yearChange;
      this.nextSchoolYear = data.nextYear;
      this.currentSchoolYear = data.year;
      this.schoolYears.push(
        {
          name:
            this.translatedText.admin_group_list_filter_school_year +
            ' ' +
            data.year.slice(2),
          value: data.year,
        },
        {
          name:
            this.translatedText.admin_group_list_filter_school_year +
            ' ' +
            data.nextYear.slice(2),
          value: data.nextYear,
        },
      );
    });
  }

  isSchoolLicense(type: string): boolean {
    if (type == 'di') {
      return this.licenseService.checkForUnlimitedDiagnosticLicense(
        type,
        this.licensesToSchool,
        true,
      );
    }
    return false;
  }

  chooseSchoolYear(event: any) {
    this.filterValues.search = event;
    this.getGroups();
  }
}
