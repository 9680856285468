<div class="admin-teacher-edit_wrap">
  <div class="row m-0">
    <div class="col-md-6">
      <div class="flex-col-center">
        <div class="teacher-profile-circle">{{ teacherShortName | shortName: 2 }}</div>
        <h3 class="h3 h3--bold mt-3">{{ firstName }} {{ lastName }}</h3>
        <p class="paragraph paragraph--s mt-2">{{ emailId }}</p>
      </div>
    </div>
    <div class="col-md-6">
      <form [formGroup]="rForm" #formDirective="ngForm">
        <div class="row">
          <div class="col-md-12">
            <div class="input-with-label">
              <div class="input-label-wrap">
                <p class="paragraph paragraph--s">
                  <mat-label>{{ translatedText.teacher_edit_salutation }}</mat-label>
                </p>
              </div>
              <mat-form-field>
                <input
                  matInput
                  placeholder="{{ translatedText.teacher_edit_salutation }}"
                  aria-label="Anrede"
                  [matAutocomplete]="auto"
                  formControlName="salutation"
                  required
                />
                <mat-icon
                  class="register-icon"
                  matTooltipClass="salutation-tooltip"
                  matTooltip="{{ translatedText.teacher_edit_salutation_info }}"
                  svgIcon="streamline_outlined:hint"
                >
                </mat-icon>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let item of filteredItems | async" [value]="item">
                    <span>{{ item }}</span>
                  </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="rForm.get('salutation').hasError('required')">
                  {{ translatedText.teacher_edit_salutation_error }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-md-12">
            <mat-label>{{ translatedText.teacher_edit_firstname }}</mat-label>
            <mat-form-field>
              <input
                matInput
                formControlName="firstname"
                type="text"
                placeholder="{{ translatedText.teacher_edit_firstname }}"
                required
              />
              <mat-error *ngIf="rForm.get('firstname').hasError('required')">
                {{ translatedText.teacher_edit_firstname_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('firstname').hasError('hasDigits')">
                {{ translatedText.teacher_edit_hasDigits_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-label>{{ translatedText.teacher_edit_lastname }}</mat-label>
            <mat-form-field>
              <input
                matInput
                formControlName="lastname"
                type="text"
                placeholder="{{ translatedText.teacher_edit_lastname }}"
                required
              />
              <mat-error *ngIf="rForm.get('lastname').hasError('required')">
                {{ translatedText.teacher_edit_lastname_error }}
              </mat-error>
              <mat-error *ngIf="rForm.get('lastname').hasError('hasDigits')">
                {{ translatedText.teacher_edit_hasDigits_error }}
              </mat-error>
            </mat-form-field>
          </div>
          <ng-container *ngIf="isExpert">
            <div class="col-12">
              <mat-label>{{ translatedText.expert_group_profile_title }}</mat-label>
              <mat-form-field>
                <mat-select formControlName="isExpert" type="role" disableOptionCentering panelClass="grade-select">
                  <mat-option [value]="true">{{ translatedText.expert_group_profile_option1 }}</mat-option>
                  <mat-option [value]="false">{{ translatedText.expert_group_profile_option2 }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
          <div class="col-12">
            <mat-label>{{ translatedText.teacher_edit_mfa }}</mat-label>
            <mat-form-field>
              <mat-select formControlName="otpEnabled" type="role" disableOptionCentering panelClass="grade-select">
                <mat-option [value]="false">{{ translatedText.teacher_edit_mfa_option1 }}</mat-option>
                <mat-option [value]="true">{{ translatedText.teacher_edit_mfa_option2 }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="instructionVideo_wrap">
          <button
            alt="Schließen"
            mat-flat-button
            color="secondary"
            type="button"
            class="mat-flat-button--full"
            aria-label="cancel"
            [mat-dialog-close]="'close'"
          >
            {{ translatedText.teacher_edit_cancel }}
          </button>
          <button
            alt="Speichern"
            mat-flat-button
            color="primary"
            (click)="submitForm(formDirective)"
            type="submit"
            class="mat-flat-button--full"
            aria-label="LOG IN"
            [disabled]="rForm.invalid"
          >
            {{ translatedText.teacher_edit_save }}
          </button>
        </div>
        <div>
          <button
            alt="Konto löschen"
            (click)="openDeleteAccountOverlay()"
            mat-flat-button
            color="unset"
            class="delete-btn"
          >
            <mat-icon svgIcon="streamline_outlined:trash"></mat-icon> {{ translatedText.account_deletion_open }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
