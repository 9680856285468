import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService, LoginStatus } from '../../auth/auth.service';
import { AdminService } from 'src/app/providers/admin.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

export interface QrCode {
  qrcode: string;
}

@Component({
  selector: 'app-authenticator-setup',
  templateUrl: './authenticator-setup.component.html',
  styleUrls: ['./authenticator-setup.component.scss'],
})
export class AuthenticatorSetupComponent implements OnInit {
  translatedText: any;
  qrCode: string;
  loginStatus: LoginStatus = 'credentials';
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private translationService: TranslationService,
    private authService: AuthService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translation) => (this.translatedText = translation),
      });
    this.authService
      .getLoginStatusListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (loginStatus) => {
          console.log(loginStatus);
          this.loginStatus = loginStatus;
        },
      });
    this.generateOTPTokenQR();
  }

  verifyQrCode(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.authService.verifyOTPToken(
      this.authService.getUsername(),
      form.value.otpToken,
      false,
    );
  }

  generateOTPTokenQR() {
    this.adminService
      .generateOTPTokenQR()
      .pipe(
        map((qr: QrCode) => {
          this.qrCode = qr.qrcode;
        }),
      )
      .subscribe();
  }
}
