<button
  alt="Schließen"
  mat-mini-fab
  type="button"
  [mat-dialog-close]="'close'"
  class="close-button close-button--right"
  matSuffix
>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button>
<div class="helpdesk_wrap">
  <mat-icon svgIcon="streamline_outlined:info-empty"></mat-icon>
  <ng-container *ngIf="request == 'school-taken'">
    <h3 class="h3 h3--bold">{{ translatedText.helpdesk_overlay_title }}</h3>
    <p class="paragraph--xl">{{ translatedText.helpdesk_overlay_desc }}</p>
  </ng-container>
  <ng-container *ngIf="request == 'school-missing'">
    <h3 class="h3 h3--bold">{{ translatedText.helpdesk_overlay_school }}</h3>
    <p class="paragraph--xl">{{ translatedText.helpdesk_overlay_contact }}</p>
  </ng-container>

  <form [formGroup]="rForm" #formDirective="ngForm">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_school_name }}</mat-label>
          <input matInput formControlName="school" type="text" required />
          <mat-error *ngIf="rForm.get('school').hasError('required')">
            {{ translatedText.school_create_school_name_error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_school_address }}</mat-label>
          <input matInput formControlName="address" type="text" required />
          <mat-error *ngIf="rForm.get('address').hasError('required')">
            {{ translatedText.school_create_school_address_error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_school_zip }}</mat-label>
          <input matInput formControlName="zipCode" type="text" required />
          <mat-error *ngIf="rForm.get('zipCode').hasError('required')">
            {{ translatedText.school_create_school_zip_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('zipCode').hasError('hasLetters')">
            {{ translatedText.helpdesk_overlay_only_numbers }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_school_city }}</mat-label>
          <input matInput formControlName="city" type="text" required />
          <mat-error *ngIf="rForm.get('city').hasError('required')">
            {{ translatedText.helpdesk_overlay_school_city_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('city').hasError('hasDigits')">
            {{ translatedText.helpdesk_overlay_school_city_valid }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_email }}</mat-label>
          <input matInput formControlName="email" type="text" required />
          <mat-error *ngIf="rForm.get('email').hasError('required')">
            <!-- Please enter email address. -->
            {{ translatedText.helpdesk_overlay_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('email').hasError('invalidEmail')">
            <!-- Please enter valid email address. -->
            {{ translatedText.helpdesk_overlay_valid_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('email').hasError('emailTaken')">
            <!-- Email is taken -->
            {{ translatedText.helpdesk_overlay_email_taken }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_full_name }}</mat-label>
          <input matInput formControlName="fullName" type="text" required />
          <mat-error *ngIf="rForm.get('fullName').hasError('required')">
            {{ translatedText.school_admin_create_firstname_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('fullName').hasError('hasDigits')">
            {{ translatedText.helpdesk_overlay_only_letters }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field>
          <mat-label>{{ translatedText.helpdesk_overlay_phone }}</mat-label>
          <input matInput formControlName="phoneNum" type="text" required />
          <mat-error *ngIf="rForm.get('phoneNum')?.hasError('required') && rForm.get('phoneNum').touched">
            {{ translatedText.parents_form_tel_error }}
          </mat-error>
          <mat-error *ngIf="rForm.get('phoneNum').hasError('hasLetters') && rForm.get('phoneNum').touched">
            {{ translatedText.parents_form_hasLetters_error }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="button_wrap">
      <button alt="Abbrechen" mat-flat-button color="secondary" [mat-dialog-close]="true">
        {{ translatedText.helpdesk_overlay_close }}
      </button>
      <button
        alt="Senden"
        mat-flat-button
        color="primary"
        class="teacher_button_primary"
        [disabled]="rForm.invalid"
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.helpdesk_overlay_send }}
      </button>
    </div>
  </form>
</div>
