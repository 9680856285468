import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { AuthService, LoginStatus } from '../../auth/auth.service';
import { environment } from 'src/environments/environment';
import { ForgotPasswordOverlayComponent } from '../../helpers/forgot-password-overlay/forgot-password-overlay.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  environment = environment;
  IS_APP = environment.isApp;
  loginForm: UntypedFormGroup;
  userRole;
  userIsAuthenticated: boolean = false;
  translatedText: any;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  loginStatus: LoginStatus = 'credentials';

  @ViewChild('otpTokenInput') set otpTokenInputRef(element: ElementRef) {
    if (element) {
      this.otpTokenInput = element;
      setTimeout(() => {
        this.otpTokenInput.nativeElement.focus();
      });
    }
  }
  otpTokenInput: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private authService: AuthService, // private translationService: TranslationService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translation) => (this.translatedText = translation),
      });

    this.userRole = this.route.snapshot.params.role;
    console.log(this.userRole);

    this.userIsAuthenticated = this.authService.getIsAuth();
    if (this.userIsAuthenticated) {
      const role = this.authService.getType();

      if (role === 'student') {
        this.router.navigate(['/dashboard-student']);
      } else if (
        role === 'teacher' &&
        environment.whitelabel.name === 'Lesecoach'
      ) {
        this.router.navigate(['/dashboard-teacher']);
      } else {
        this.router.navigate(['/admin-dashboard']);
      }
    }
    this.authService
      .getLoginStatusListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (loginStatus) => {
          console.log(loginStatus);
          this.loginStatus = loginStatus;
        },
      });
  }

  navigateHome(): void {
    this.router.navigate(['/home']);
  }

  /**
   *  login
   */
  login(form: NgForm) {
    if (form.invalid) {
      return;
    }
    if (this.loginStatus === 'credentials') {
      this.authService.login(
        form.value.username,
        form.value.password,
        form.value.rememberMe,
      );
    }
    if (this.loginStatus === 'otp' || this.loginStatus === 'otpError') {
      this.authService.verifyOTPToken(
        form.value.username,
        form.value.otpToken,
        form.value.rememberMe,
      );
    }
  }

  openForgotPasswordOverlay(): void {
    let dialogRef = this.dialog.open(ForgotPasswordOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'forgotpassword-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'forgotpassword-overlay-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
