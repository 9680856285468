<!-- <button mat-mini-fab type="button" [mat-dialog-close]="'close'" class="close-button close-button--right" matSuffix>
  <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
</button> -->

<div class="school_state_update_wrap">
  <form [formGroup]="oauthForm" #formDirective="ngForm">
    <div class="school_state_update_title">
      <h2 class="h2" *ngIf="!isSubmited">{{ translatedText.oauth_title }}</h2>
      <h2 class="h2" *ngIf="isSubmited">{{ translatedText.oauth_success }}</h2>
      <h2 class="h2" *ngIf="isError">{{ translatedText.technical_error }}</h2>
    </div>
    <div class="row" *ngIf="!isSubmited && !isError">
      <div class="col-md-12">
        <mat-label>{{ translatedText.school_admin_create_email }}</mat-label>
        <mat-form-field>
          <input
            matInput
            formControlName="email"
            type="text"
            required
            placeholder="{{ translatedText.school_admin_create_email }}"
            [disabled]="isEmailDisabled"
          />
          <mat-error *ngIf="oauthForm.get('email').hasError('required')">
            <!-- Please enter email address. -->
            {{ translatedText.school_admin_create_email_error }}
          </mat-error>
          <mat-error *ngIf="oauthForm.get('email').hasError('invalidEmail')">
            <!-- Please enter valid email address. -->
            {{ translatedText.school_admin_create_email_error_valid }}
          </mat-error>
          <mat-error *ngIf="oauthForm.get('email').hasError('emailTaken')">
            <!-- Email is taken -->
            {{ translatedText.school_admin_create_email_taken }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-label>{{ translatedText.school_admin_create_salutation }}</mat-label>
        <mat-form-field>
          <input
            matInput
            aria-label="Anrede"
            [matAutocomplete]="auto"
            formControlName="salutation"
            required
            placeholder="{{ translatedText.school_admin_create_salutation }}"
          />
          <mat-icon
            class="register-icon"
            matTooltipClass="salutation-tooltip"
            matTooltip="{{ translatedText.admin_teacher_create_salutation_tooltip }}"
            svgIcon="streamline_outlined:hint"
          ></mat-icon>
          <mat-icon matSuffix>expand_more</mat-icon>
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of filteredItems | async" [value]="item">
              <span>{{ item }}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="oauthForm.get('salutation').hasError('required')">
            {{ translatedText.school_admin_create_salutation_error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6">
        <mat-label>{{ translatedText.school_admin_create_firstname }}</mat-label>
        <mat-form-field floatLabel="always">
          <input
            matInput
            formControlName="firstname"
            type="text"
            required
            placeholder="{{ translatedText.school_admin_create_firstname }}"
          />
          <mat-error *ngIf="oauthForm.get('firstname').hasError('required')">
            {{ translatedText.school_admin_create_firstname_error }}
          </mat-error>
          <mat-error *ngIf="oauthForm.get('firstname').hasError('hasDigits')">
            {{ translatedText.school_admin_create_hasDigits_error }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-label>{{ translatedText.school_admin_create_lastname }}</mat-label>
        <mat-form-field>
          <input
            matInput
            formControlName="lastname"
            type="text"
            required
            placeholder="{{ translatedText.school_admin_create_lastname }}"
          />
          <mat-error *ngIf="oauthForm.get('lastname').hasError('required')">
            {{ translatedText.school_admin_create_lastname_error }}
          </mat-error>
          <mat-error *ngIf="oauthForm.get('lastname').hasError('hasDigits')">
            {{ translatedText.school_admin_create_hasDigits_error }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="school_state_update_buttons" *ngIf="!isError">
      <button
        alt="Speichern"
        mat-flat-button
        color="primary"
        *ngIf="!isSubmited"
        [disabled]="isLoading || oauthForm.invalid"
        mat-flat-button
        (click)="submitForm(formDirective)"
      >
        {{ translatedText.school_edit_save }}
      </button>
      <button
        alt="Schließen"
        mat-flat-button
        color="primary"
        *ngIf="isSubmited"
        mat-flat-button
        (click)="closeOverlay()"
      >
        {{ translatedText.admin_group_close_btn }}
      </button>
    </div>
  </form>
</div>
