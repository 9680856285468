import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { formatDate } from '@angular/common';

export interface Notification {
  notificationId?: string;
  message?: string;
  recipient?: any;
  _task?: any;
  type?: string;
  readAt?: any;
  createdAt?: any;
  sender?: any;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: Notification[] = [];
  notificationUpdated = new Subject<Notification[]>();
  unreadNotifications: Notification[] = [];
  unreadNotificationUpdated = new Subject<Notification[]>();
  taskNotification: Notification[] = [];
  taskNotificationUpdated = new Subject<Notification[]>();

  notificationBadgeCount = new BehaviorSubject<number>(0);
  currentnotificationBadgeCount = this.notificationBadgeCount.asObservable();
  userId;

  private restServerUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  getNotificationUpdateListener() {
    return this.notificationUpdated.asObservable();
  }

  getUnreadNotificationUpdateListener() {
    return this.unreadNotificationUpdated.asObservable();
  }

  getTaskNotificationUpdateListener() {
    return this.taskNotificationUpdated.asObservable();
  }

  createNotification(notification) {
    return this.http.post<any>(
      this.restServerUrl + '/api/notification/createNotification',
      notification,
      { params: { readalizer_customer: environment.readalizer_customer } },
    );
  }

  getNotificationList() {
    this.http
      .get<
        Notification[]
      >(this.restServerUrl + '/api/notification/listNotification', { params: { readalizer_customer: environment.readalizer_customer } })
      .subscribe({
        next: (data) => {
          this.notifications = data;
          this.notificationUpdated.next([...this.notifications]);
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  getUnreadNotificationList() {
    this.http
      .get<
        Notification[]
      >(this.restServerUrl + '/api/notification/listUnreadNotification', { params: { readalizer_customer: environment.readalizer_customer } })
      .subscribe({
        next: (data) => {
          this.unreadNotifications = data;
          this.unreadNotificationUpdated.next([...this.unreadNotifications]);
          this.checkUnreadNotifications();
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }

  getNotificationToTask(taskId, type) {
    let notificationParams = {
      readalizer_customer: environment.readalizer_customer,
    };
    this.http
      .get<
        Notification[]
      >(`${this.restServerUrl}/api/notification/getNotificationToTask/${taskId}/${type}`, { params: notificationParams })
      .subscribe({
        next: (data) => {
          console.log(data);
          this.taskNotification = data;
          this.taskNotificationUpdated.next([...this.taskNotification]);
        },
        error: (error) => {
          //   this.authenticationService.showToastOnError("Keine Verbindung zum Server")
        },
      });
  }
  // note?.readAt === null
  checkUnreadNotifications() {
    // filter unread messages
    this.userId = this.authService.getUserId();
    const count = this.unreadNotifications.filter((item) => {
      return item.recipient.some((recipient) => {
        return recipient.user === this.userId && recipient.readAt === null;
      });
    }).length;

    console.log(count);
    this.notificationBadgeCount.next(count);
  }

  updateNotification(notificationId) {
    const notificationData: Notification = {
      notificationId: notificationId,
    };
    return this.http
      .post<Notification>(
        this.restServerUrl + '/api/notification/updateNotification',
        notificationData,
        { params: { readalizer_customer: environment.readalizer_customer } },
      )
      .pipe(
        map((res) => {
          console.log(res);
          // const readNotification = this.unreadNotifications.findIndex((obj) => obj["_id"] === notificationId);
          // if (readNotification > -1) {
          //   this.unreadNotifications.splice(readNotification, 1);
          // }
          let index = this.unreadNotifications.findIndex((item) => {
            return item['_id'] === notificationId;
          });
          console.log(index);
          if (index >= 0) {
            //this.unreadNotifications[index].readAt = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
            this.unreadNotifications[index].recipient.filter((value) => {
              if (value.user === this.userId) {
                value.readAt = formatDate(new Date(), 'yyyyMMddhhmmss', 'en');
              }
            });
          }
          //this.notificationUpdated.next([...this.notifications]);
          this.unreadNotificationUpdated.next(this.unreadNotifications);
          //this.decreaseNotificationBadgeCount();
          this.checkUnreadNotifications();
          return res;
        }),
      );
  }

  updateNotificationMessage(data) {
    const notificationData: Notification = {
      notificationId: data.notificationId,
      message: data.message,
    };
    return this.http.post<Notification>(
      this.restServerUrl + '/api/notification/updateNotificationMessage',
      notificationData,
      { params: { readalizer_customer: environment.readalizer_customer } },
    );
  }

  increaseNotificationBadgeCount() {
    var newcount: number = this.notificationBadgeCount.value;
    this.notificationBadgeCount.next(newcount + 1);
  }

  decreaseNotificationBadgeCount() {
    var newcount: number = this.notificationBadgeCount.value;
    if (this.notificationBadgeCount.value > 0) {
      this.notificationBadgeCount.next(newcount - 1);
    }
  }

  resetNotificationBadgeCount() {
    this.notificationBadgeCount.next(0);
  }
}
