<div class="container-fluid login-container">
  <img class="img-left-top" src="../../../../../assets/sign_up-left.svg" alt="" />
  <img class="img-right-bottom" src="../../../../../assets/sign-up-right.svg" alt="" />
  <div class="login row">
    <div class="col-md-12">
      <div class="login_wrap">
        <img class="content-img" src="../../../assets/new_laletu-logo.svg" alt="" />
        <!-- <img class="footer-img" [src]="'/assets/' + environment.whitelabel.logo" alt="" /> -->
      </div>
    </div>
  </div>
</div>
