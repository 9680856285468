<div>
  <div class="task-visibility-banner" *ngIf="!clickedCloseBtn">
    <span>
      <mat-icon svgIcon="streamline_outlined:calendar"></mat-icon>
      <p class="paragraph paragraph--l paragraph--bold">
        {{ translatedText.training_task_create_task_visibility_title }}
      </p>
    </span>
    <p class="paragraph">
      <span *ngIf="selectedDeadline === null">
        {{ translatedText.training_task_create_task_visibility_desc_4_weeks }}</span
      >
      <span *ngIf="taskVisibleFrom === null && selectedDeadline != undefined">
        {{ translatedText.training_task_create_task_visibility_desc_immmediately }}</span
      >
      <span *ngIf="taskVisibleFrom && selectedDeadline != undefined">{{
        translatedText.t('training_task_create_task_visibility_desc', {
          taskVisibleFrom: viewValueTaskVisibily,
        })
      }}</span>
    </p>
    <button alt="Schließen" mat-mini-fab type="button" class="close-button" (click)="clickedCloseBtn = true">
      <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
    </button>
  </div>
  <mat-card [formControl]="control" [(ngModel)]="selectedDeadline" ngDefaultControl>
    <mat-calendar
      [selected]="control.value"
      [dateClass]="dateClass"
      (selectedChange)="onDateSelected($event)"
      [minDate]="minDate"
      [maxDate]="trialStatus.isActive ? trialStatus.expiryDate : ''"
    >
    </mat-calendar>
    <mat-error *ngIf="control.errors?.['required']">
      {{ translatedText.training_task_create_deadline_past_date_error }}
    </mat-error>
    <mat-error *ngIf="control.errors?.['pastDate']">
      {{ translatedText.training_task_create_deadline_error }}
    </mat-error>
  </mat-card>
</div>
