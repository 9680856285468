import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';
import { GroupService } from 'src/app/providers/group.service';
import { SchoolStateUpdateComponent } from 'src/app/helpers/school-state-update/school-state-update.component';
import { OauthGroupsUpdateUpdateComponent } from 'src/app/helpers/oauth-groups-update/oauth-groups-update.component';
import { OauthUserUpdateUpdateComponent } from 'src/app/helpers/oauth-user-update/oauth-user-update.component';
import { MatDialog } from '@angular/material/dialog';
import { TeacherService } from 'src/app/providers/teacher.service';

@Component({
  selector: 'app-open-id-connect',
  templateUrl: './open-id-connect.component.html',
  styleUrls: ['./open-id-connect.component.scss'],
})
export class OpenIdConnectComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  environment = environment;
  translatedText: any;
  teacherId: any;
  teacherEmail: string;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private groupService: GroupService,
    private location: Location,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    private teacherService: TeacherService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translation) => (this.translatedText = translation),
      });

    this.authService
      .getOAuthStatusListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((isOAuthenticated) => {
        console.log(isOAuthenticated);
        if (isOAuthenticated) {
          this.teacherId = this.authService.getTeacherId();
          setTimeout(() => {
            if (
              this.authService.getSchoolState() !== '' &&
              this.authService.getSchoolState() !== undefined
            ) {
              this.getTeacherInfo();
            } else {
              this.openSchoolStateUpdateOverlay();
            }
          }, 3000);
        }
      });
  }

  getOauthGroups() {
    this.groupService
      .getOauthGroups()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data.length > 0) {
          console.log(data);
          this.openOauthGroupsUpdateOverlay(data);
        } else {
          this.authService.setOauthAuthenticatedStatus(true);
          this.router.navigate(['/admin-dashboard']);
        }
      });
  }

  getTeacherInfo() {
    this.teacherService.readSingleTeacher(this.teacherId).subscribe((res) => {
      if (
        !res['email'] ||
        !res['firstname'] ||
        !res['lastname'] ||
        !res['salutation']
      ) {
        console.log(res);
        this.openOauthUserUpdateOverlay(res['email']);
      } else {
        this.getOauthGroups();
      }
    });
  }

  openSchoolStateUpdateOverlay() {
    let dialogRef = this.dialog.open(SchoolStateUpdateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'expert-group-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'reading-level-overlay-panel_backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.getTeacherInfo();
    });
  }

  openOauthGroupsUpdateOverlay(groups: any) {
    let dialogRef = this.dialog.open(OauthGroupsUpdateUpdateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'oauth-groups-update-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'oauth-groups-update-panel-backdrop',
      data: {
        groups: groups,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.authService.setOauthAuthenticatedStatus(true);
      this.router.navigate(['/admin-dashboard']);
    });
  }

  openOauthUserUpdateOverlay(teacherEmail) {
    console.log(teacherEmail);
    let dialogRef = this.dialog.open(OauthUserUpdateUpdateComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'expert-group-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'reading-level-overlay-panel_backdrop',
      data: {
        teacherEmail: teacherEmail,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {
      this.getOauthGroups();
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
