import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
// import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
// import { Subject} from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  // private _unsubscribeAll: Subject<any> = new Subject<any>();
  //translatedText;
  constructor(
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {
    // this.translationService.getTranslation().pipe(
    //   takeUntil(this._unsubscribeAll)
    // ).subscribe((translatedText: any[]) => {
    //   this.translatedText = translatedText;
    // });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        //console.log(error)
        let errorMessage = this.translationService.getTranslatedText(
          'errorInterceptor_500',
        ); // Error 500
        let showErrorSnackBar = false;
        if (error?.status && this.authService.getToken() != undefined) {
          switch (error?.status) {
            case 401: // Unauthorized
              errorMessage = this.translationService.getTranslatedText(
                'errorInterceptor_401',
              );
              showErrorSnackBar = true;
              // fire logout and redirect to login
              this.authService.logout('/login');
              break;
            case 422: // Unprocessable Entity
              errorMessage = this.translationService.getTranslatedText(
                'errorInterceptor_422',
              );
              showErrorSnackBar = true;
              // fire logout and redirect to login
              //this.authService.logout('/login');
              break;
            case 503: // Service unavailable
              errorMessage = this.translationService.getTranslatedText(
                'errorInterceptor_503',
              );
              showErrorSnackBar = true;
              break;
            default:
              break;
          }
        }
        //errorMessage = error?.error?.message;
        if (showErrorSnackBar) {
          // Fire snack bar
          this.snackBar.open(errorMessage, '', {
            duration: 4000,
            panelClass: 'snack-error',
            horizontalPosition: 'center',
          });
        }
        // this.errorService.throwError(errorMessage);
        return throwError(error);
      }),
    );
  }
}
