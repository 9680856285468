<app-students-add-stepper #stepper>
  <section class="container-fluid overlay_container">
    <!-- first step -->
    <cdk-step [stepControl]="howToAddStudents">
      <div class="header-wrapper">
        <button
          alt="Abbrechen"
          mat-flat-button
          class="close-button-with-text"
          type="button"
          matSuffix
          [mat-dialog-close]="true"
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button
            alt="Weiter"
            mat-flat-button
            color="primary"
            class="submit-button"
            [disabled]="howToAddStudents.get('form')?.value === ''"
            cdkStepperNext
          >
            {{ translatedText.students_add_next }}
          </button>
        </div>
      </div>
      <div class="step_padding flex-center">
        <!-- remove if condition below when this section should be shown also in Lesecoach -->
        <ng-container *ngIf="environment.whitelabel.name != 'Lesecoach'">
          <div class="training-task_create_headline">
            <h3 class="h2 h2--bold">{{ translatedText.students_add_how_to_add }}</h3>
          </div>
          <ul class="csv-box-list">
            <li
              [ngClass]="{
                selected: selectedAddStudentMode === 'new',
              }"
              (click)="selectHowtoAddStudents('new')"
            >
              <mat-icon class="icon-32" svgIcon="streamline_outlined:edit-blue-pencil"></mat-icon>
              <p class="paragraph paragraph--bold paragraph--m">{{ translatedText.students_add_new_students }}</p>
              <p class="paragraph paragraph--s">{{ translatedText.students_add_new_students_to_class }}</p>
            </li>
            <li
              [ngClass]="{ selected: selectedAddStudentMode === 'existing', disabled: trialStatus.isActive }"
              (click)="selectHowtoAddStudents('existing')"
              [matTooltip]="trialStatus.isActive ? translatedText.students_add_existing_not_available_trial : ''"
            >
              <mat-icon class="icon-32" svgIcon="streamline_outlined:class-small"></mat-icon>
              <p class="paragraph paragraph--bold paragraph--m">{{ translatedText.students_add_existing_student }}</p>
              <p class="paragraph paragraph--s">{{ translatedText.students_add_existing_student_desc }}</p>
            </li>
          </ul>
        </ng-container>
        <!--  manual or csv import selection -->
        <div *ngIf="selectedAddStudentMode === 'new'" class="csv-import-section">
          <div class="training-task_create_headline">
            <h3 class="h2 h2--bold">{{ translatedText.admin_group_class_add_student_info }}</h3>
          </div>
          <ul class="csv-box-list">
            <li
              [ngClass]="{
                selected: selectedStudentCreationMode === studentCreationMode.NORMAL,
                disabled: selectedAddStudentMode != 'new',
              }"
              (click)="chooseStudentCreationMode(studentCreationMode.NORMAL)"
            >
              <mat-icon class="icon-32" svgIcon="streamline_outlined:edit-blue-pencil"></mat-icon>
              <p class="paragraph paragraph--bold paragraph--m">
                {{ translatedText.admin_group_class_add_student_manual_input }}
              </p>
              <p class="paragraph paragraph--s">{{ translatedText.admin_group_class_add_student_manual_input_desc }}</p>
            </li>
            <li
              [ngClass]="{
                selected: selectedStudentCreationMode === studentCreationMode.CSV,
                disabled: selectedAddStudentMode != 'new',
              }"
              (click)="chooseStudentCreationMode(studentCreationMode.CSV)"
            >
              <mat-icon class="icon-32" svgIcon="streamline_outlined:csv-upload"></mat-icon>
              <p class="paragraph paragraph--bold paragraph--m">
                {{ translatedText.admin_group_class_add_student_import_csv }}
              </p>
              <p class="paragraph paragraph--s">{{ translatedText.admin_group_class_add_student_import_csv_desc }}</p>
            </li>
          </ul>
          <p
            *ngIf="enableCSVUpload"
            class="paragraph paragraph--bold paragraph--m mt-5 text-align-center"
            [innerHTML]="templateDownloadMessage"
          ></p>
        </div>
      </div>
    </cdk-step>
    <!-- second step : select student amount, CSV IMPORT or select existing student-->
    <cdk-step [stepControl]="studentAmountForm">
      <div class="header-wrapper">
        <button
          alt="Abbrechen"
          mat-flat-button
          class="close-button-with-text"
          type="button"
          matSuffix
          [mat-dialog-close]="true"
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button alt="Zurück" mat-flat-button color="unset" cdkStepperPrevious (click)="eraseStudentData()">
            {{ translatedText.students_add_back }}
          </button>
          <button
            alt="Weiter"
            *ngIf="selectedAddStudentMode === 'existing'"
            mat-flat-button
            color="primary"
            class="submit-button"
            (click)="prepareNextStep()"
            [disabled]="this.studentsArray.length === 0"
            cdkStepperNext
          >
            {{ translatedText.students_add_next }}
          </button>
          <button
            alt="Weiter"
            *ngIf="selectedAddStudentMode === 'new'"
            mat-flat-button
            color="primary"
            class="submit-button"
            (click)="prepareNextStep()"
            [disabled]="
              selectedStudentCreationMode === studentCreationMode.NORMAL
                ? studentAmountForm.get('studentAmount')?.value < 1 ||
                  studentAmountForm.get('studentAmount')?.value > getMaxStudentCount()
                : csvRecords.length === 0 && !isCSVError
            "
            cdkStepperNext
          >
            {{ translatedText.students_add_next }}
          </button>
        </div>
      </div>
      <ng-container *ngIf="selectedAddStudentMode === 'new'">
        <!-- NEW STUDENTS: add manually -->
        <div class="student-add-container" *ngIf="selectedStudentCreationMode === studentCreationMode.NORMAL">
          <h1>{{ translatedText.students_add_students_count }}</h1>

          <div class="content-wrapper">
            <div class="rectangle-container rectangle-container--gray">
              <h3 class="h3 h3--bold">{{ translatedText.students_add_how_many_students }}</h3>
              <form [formGroup]="studentAmountForm" class="student-amount-form">
                <div class="second_step select_students_amount">
                  <div class="col-12">
                    <mat-form-field>
                      <div class="number-wrap">
                        <button alt="Wert verringern" (click)="decreaseValue()">
                          <mat-icon svgIcon="streamline_filled:license-minus"></mat-icon>
                        </button>
                        <input
                          matInput
                          type="number"
                          formControlName="studentAmount"
                          [min]="1"
                          [max]="getMaxStudentCount()"
                          (input)="onStudentAmountInput()"
                        />
                        <button alt="Wert erhöhen" (click)="increaseValue()">
                          <mat-icon svgIcon="streamline_filled:license-plus"></mat-icon>
                        </button>
                      </div>
                    </mat-form-field>
                    <mat-error *ngIf="studentAmountForm.hasError('min')">
                      {{ translatedText.students_add_students_count_min }}
                    </mat-error>
                    <mat-error *ngIf="studentAmountForm.hasError('max')">
                      {{ translatedText.students_add_students_count_max }}
                    </mat-error>
                  </div>
                </div>
              </form>
            </div>
            <div class="admin-class-list-licenses-count">
              <div class="licenses-wrap diagnosis">
                <p>
                  <mat-icon class="pink-icon" svgIcon="streamline_outlined:target"></mat-icon>
                  {{ translatedText.students_add_total_diagnostic }}
                </p>
                <p>
                  <strong>
                    <span [countUp]="totalCountLeftDiagnostic" *ngIf="!hasUnlimitedDiagnosticLicense">{{
                      totalCountLeftDiagnostic
                    }}</span>
                    <span *ngIf="hasUnlimitedDiagnosticLicense"> ∞ </span>
                  </strong>
                </p>
              </div>

              <div class="licenses-wrap training">
                <p>
                  <mat-icon class="green-icon" svgIcon="streamline_outlined:lightning"></mat-icon>
                  {{ translatedText.students_add_total_training }}
                </p>
                <p>
                  <strong>
                    <span [countUp]="totalCountLeftTraining">{{ totalCountLeftTraining }}</span>
                  </strong>
                </p>
              </div>
            </div>
            <div class="rectangle-container rectangle-container--blue" *ngIf="displayEnoughLicensesNote()">
              <span>
                <mat-icon class="blue-icon" svgIcon="streamline_outlined:info-empty"></mat-icon>
                <h3>{{ translatedText.students_add_not_enough_licenses }}</h3>
              </span>

              <p>
                {{ translatedText.students_add_not_enough_licenses_text }}
              </p>
            </div>
          </div>
        </div>
        <!-- NEW STUDENTS: csv import section -->
        <div class="step_padding" *ngIf="selectedStudentCreationMode === studentCreationMode.CSV">
          <app-csv-import
            [studentAmount]="studentAmountForm.get('studentAmount').value"
            [enoughLicenses]="displayEnoughLicensesNote()"
            [hasUnlimitedDiagnosticLicense]="hasUnlimitedDiagnosticLicense"
            [totalCountLeftDiagnosticExp]="totalCountLeftDiagnosticExp"
            [totalCountLeftDiagnostic]="totalCountLeftDiagnostic"
            [totalCountLeftTraining]="totalCountLeftTraining"
            [totalCountLeftTrainingExp]="totalCountLeftTrainingExp"
            (csvData)="getCSVStudentData($event)"
            (hasCsvError)="(isCSVError)"
            [erasedCSVRecords]="this.csvRecords.length === 0 ? true : false"
          >
          </app-csv-import>
        </div>
      </ng-container>
      <!-- EXISTING STUDENT -->
      <div class="student-add-container" *ngIf="selectedAddStudentMode === 'existing'">
        <div class="training-task_create_headline">
          <h3 class="h2 h2--bold">{{ translatedText.students_add_choose_students }}</h3>
          <p class="paragraph paragraph--xl">
            {{ translatedText.t('students_add_choose_students_desc', { groupName: groupName }) }}
          </p>
        </div>
        <app-individual-learners-selection
          (selectedStudents)="getSelectedStudentsList($event)"
          [source]="'add-student'"
          [autoMode]="false"
          [selectedTypeDetails]="'add-student'"
          [selectedGroupId]="groupId"
        >
        </app-individual-learners-selection>
      </div>
    </cdk-step>

    <!-- third step: add student data or confirm existing students -->
    <cdk-step [stepControl]="addStudentDataForm">
      <div class="header-wrapper">
        <button
          alt="Abbrechen"
          mat-flat-button
          class="close-button-with-text"
          type="button"
          (click)="openCancelDialog()"
          matSuffix
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button alt="Zurück" mat-flat-button color="unset" cdkStepperPrevious (click)="clearStudentsSelected()">
            {{ translatedText.students_add_back }}
          </button>
          <button
            alt="Weiter"
            *ngIf="selectedAddStudentMode === 'new'"
            mat-flat-button
            color="primary"
            class="submit-button"
            [disabled]="isFormInvalid"
            cdkStepperNext
            (click)="finishedSecondStep()"
          >
            {{ translatedText.students_add_next }}
          </button>
          <button
            alt="Lernende hinzufügen"
            *ngIf="selectedAddStudentMode === 'existing'"
            mat-flat-button
            color="primary"
            class="add-student-final-button"
            cdkStepperNext
            (click)="addExistingStudentsToClass()"
          >
            {{ translatedText.students_add_button_final }}
          </button>
        </div>
      </div>

      <div class="student-add-container wider" *ngIf="selectedAddStudentMode === 'new'">
        <app-create-learners
          [totalStudents]="totalStudents"
          [title]="translatedText.students_add_class_profile_info"
          [diagnosticTotalCountLicenses]="hasUnlimitedDiagnosticLicense"
          [diagnosticTotalCount]="totalCountDiagnostic"
          [trainingTotalCount]="totalCountTraining"
          [diagnosticLeftCount]="totalCountLeftDiagnostic"
          [trainingLeftCount]="totalCountLeftTraining"
          [studentLimit]="getMaxStudentCount()"
          [grade]="groupGrade"
          [studentList]="csvRecords"
          [csvLanguageCode]="csvLanguageCode"
          [isCSVImport]="selectedStudentCreationMode === 1 ? true : false"
        ></app-create-learners>
      </div>
      <div *ngIf="selectedAddStudentMode === 'existing'">
        <ng-container *ngIf="selectedAddStudentMode === 'existing'">
          <div class="training-task_create_headline">
            <h3 class="h2 h2--bold">{{ translatedText.students_add_confirm_existing_students }}</h3>
            <p class="paragraph paragraph--xl">{{ translatedText.students_add_confirm_existing_students_desc }}</p>
          </div>
          <div class="flex-center">
            <div class="overview-box">
              <div class="existing-students-add">
                <p class="paragraph paragraph--s paragraph--bold custom-t-color">
                  {{ translatedText?.admin_dashboard_students }}
                </p>
                <span class="class_chip">
                  <mat-icon svgIcon="streamline_filled:class-hat-small"></mat-icon>{{ groupName }} -
                  {{ groupYear | slice: 2 }}
                </span>
              </div>
              <div class="accordion_wrap selected-students-chips">
                <mat-accordion multi>
                  <mat-expansion-panel
                    [togglePosition]="'before'"
                    *ngFor="let groupId of Object.keys(studentsArrangedByGroup || {})"
                    [expanded]="true"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title class="round-checkbox">
                        <div class="group-select">
                          <p class="paragraph paragraph--bold paragraph--xl">
                            {{ studentsArrangedByGroup[groupId].groupName }} -
                            {{ studentsArrangedByGroup[groupId].schoolYear | slice: 2 }}
                          </p>
                        </div>
                      </mat-panel-title>
                      <mat-panel-description>
                        <span class="selected-students-count">
                          <mat-icon svgIcon="streamline_filled:small-circle"></mat-icon>
                          {{ studentsArrangedByGroup[groupId].students?.length }}
                        </span>
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-selection-list>
                      <div class="task_overview-chip-wrap">
                        <div
                          *ngFor="let student of studentsArrangedByGroup[groupId]['students']; let i = index"
                          class="task_overview-chip"
                        >
                          <div class="user-img">
                            <img *ngIf="student.avatarUrl" [src]="student.avatarUrl" alt="" />
                            <p class="paragraph paragraph--s paragraph--bold">{{ student.username }}</p>
                          </div>
                        </div>
                      </div>
                    </mat-selection-list>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </cdk-step>

    <!-- assign license step -->
    <!-- <cdk-step [stepControl]="licenseStep">
      <div class="header-wrapper">
        <button mat-flat-button class="close-button-with-text" type="button" (click)="openCancelDialog()" matSuffix>
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon> {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">{{ translatedText.students_add_title_chip }}</div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button mat-flat-button color="unset" cdkStepperPrevious>
            {{ translatedText.students_add_back }}
          </button>
          <button mat-flat-button color="primary" class="submit-button" (click)="openPDFGenerate()">
            {{ translatedText.students_add_next }}
          </button>
        </div>
      </div>
      <div class="student-add-container">
        <div class="license-step">
          <h1>{{ translatedText.students_add_assign_licenses }}</h1>
          <p>
            {{ translatedText.students_add_assign_licenses_text }}
          </p>
          <button mat-flat-button color="unset" class="add-another-teacher student" (click)="openPDFGenerate()">
            <mat-icon svgIcon="streamline_outlined:skip-next"></mat-icon>{{ translatedText.students_add_skip }}
          </button>
          <app-assign-license
          [totalCountLeftDiagnostic]="totalCountLeftDiagnostic"
          [totalCountLeftTraining]="totalCountLeftTraining"
          [totalCountTraining]="totalCountTraining"
          [totalCountDiagnostic]="totalCountDiagnostic"
          [studentsData]="thirdFormStep"
          (assignLicense)="getAssignedLicense($event)"
          >
          </app-assign-license>
        </div>
      </div>
    </cdk-step> -->

    <!-- last step -->
    <cdk-step>
      <div class="header-wrapper">
        <button
          alt="Abbrechen"
          mat-flat-button
          class="close-button-with-text"
          type="button"
          (click)="openCancelDialog()"
          matSuffix
          [ngClass]="{ 'hidden-btn': blobUrl }"
          [disabled]="blobUrl"
        >
          <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
          {{ translatedText.admin_group_create_cancel }}
        </button>
        <div class="chip_wrapper">
          <div class="chip_outline">
            <div class="chip_content add-student-chip">
              {{ translatedText.students_add_title_chip }}
            </div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <button
            alt="Zurück"
            mat-flat-button
            color="unset"
            cdkStepperPrevious
            [ngClass]="{ 'hidden-btn': isLoading || blobUrl }"
            [disabled]="isLoading || blobUrl"
          >
            {{ translatedText.students_add_back }}
          </button>
          <button
            alt="Schließen"
            mat-flat-button
            color="primary"
            class="submit-button"
            cdkStepperNext
            [disabled]="blobUrl === null"
            [mat-dialog-close]="true"
          >
            {{ translatedText.admin_group_close_btn }}
          </button>
        </div>
      </div>
      <div class="step_padding">
        <div class="training-task_create_headline">
          <h3 class="h3 h3--bold">{{ translatedText.admin_group_class_pdf_generate }}</h3>
        </div>
      </div>
      <div class="generate-pdf-section">
        <div class="pdf-notification">
          <div class="notification-box open-mode">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="tn-heading">
                  <mat-icon svgIcon="streamline_outlined:shield-check-v2"></mat-icon>
                  <div class="tn-heading">{{ translatedText.generate_pdf_data_protection }}</div>
                </div>
                <p class="paragraph paragraph--s">
                  {{ translatedText.generate_pdf_data_protection_text }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="pdf-container">
          <mat-icon svgIcon="streamline_filled:pdf-doc" class="icon-56" *ngIf="blobUrl === null"></mat-icon>
          <mat-icon svgIcon="streamline_filled:download-boxed" class="icon-56" *ngIf="blobUrl"></mat-icon>
          <p class="paragraph paragraph--bold paragraph--xl">
            {{ blobUrl === null ? translatedText.generate_class_pdf : translatedText.generate_class_pdf_ready }}
          </p>
          <p class="paragraph paragraph--m">
            {{ blobUrl === null ? translatedText.generate_pdf_attention_text : translatedText.generate_pdf_ready_text }}
          </p>
          <button
            alt="PDF generieren und herunterladen"
            mat-flat-button
            color="primary"
            class="mt-4"
            *ngIf="!isLoading && blobUrl === null"
            (click)="addNewStudentsToClass()"
          >
            <mat-icon svgIcon="streamline_outlined:pdf-loader" class="mr-2"></mat-icon>
            {{ translatedText.generate_pdf_generate_PDF }}
          </button>
          <button alt="PDF-Datei wird generiert" mat-flat-button class="mt-4 button-light-green" *ngIf="isLoading">
            <mat-icon svgIcon="streamline_outlined:pdf-loader" class="mr-2"></mat-icon>
            {{ translatedText.generate_pdf_PDF_being_generated }}
          </button>
          <button
            alt="PDF herunterladen"
            mat-flat-button
            color="primary"
            class="mt-4"
            *ngIf="blobUrl"
            (click)="downloadPDF()"
          >
            <mat-icon svgIcon="streamline_outlined:download-pdf" class="mr-2"></mat-icon>
            {{ translatedText.admin_group_list_download_pdf }}
          </button>
        </div>

        <div class="pdf-notification" *ngIf="!clickedDownload">
          <div class="notification-box warn-mode">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="tn-heading">
                  <mat-icon svgIcon="streamline_outlined:warning-triangle"></mat-icon>

                  <div class="tn-heading">{{ translatedText.generate_pdf_save_where }}</div>
                </div>
                <p class="paragraph paragraph--s">
                  {{ translatedText.generate_pdf_save_where_text }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="pdf-notification" *ngIf="clickedDownload">
          <div class="notification-box success-mode">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="tn-heading">
                  <mat-icon svgIcon="streamline_outlined:checkmark"></mat-icon>

                  <div class="tn-heading">{{ translatedText.admin_group_class_generate_pdf_successfully }}</div>
                </div>
                <p class="paragraph paragraph--s">
                  {{ translatedText.admin_group_class_generate_pdf_successfully_desc }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </cdk-step>
  </section>
</app-students-add-stepper>
