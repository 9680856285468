import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-qr-login',
  templateUrl: './qr-login.component.html',
  styleUrls: ['./qr-login.component.scss'],
})
export class QrLoginComponent implements OnInit {
  private token;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.token = this.route.snapshot.params.token;
    console.log(this.token);
    this.authService.logout();
    this.authService.qrLogin(this.token);
  }
}
