import { CdkStep, CdkStepper } from '@angular/cdk/stepper';
import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { CsvImportStudentComponent } from 'src/app/csv-import/csv-import-students/csv-import-student.component';
import {
  AdminService,
  TeacherData,
  SchoolYears,
} from 'src/app/providers/admin.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { GroupService } from '../../../providers/group.service';
import { StudentService } from '../../../providers/student.service';
import { TrialBannersComponent } from 'src/app/helpers/trial-banners/trial-banners.component';
import { find } from 'lodash';
import { environment } from 'src/environments/environment';
import { CapacitorService } from 'src/app/providers/capacitor.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface Languages {
  name: string;
  code: string;
}
export interface DialogData {
  studentcount: number;
}

enum STUDENT_IMPORT_MODE {
  NORMAL,
  CSV,
}

@Component({
  selector: 'app-admin-group-create',
  templateUrl: './admin-group-create.component.html',
  styleUrls: ['./admin-group-create.component.scss'],
})
export class AdminGroupCreateComponent implements OnInit, OnDestroy {
  environment = environment;
  IS_APP = environment.isApp;
  isLoading: boolean = false;
  teacherId;
  userType;
  firstFormStep: FormGroup;
  secondFormStep: FormGroup;
  thirdFormStep: FormGroup;
  studentAmountForm: FormGroup;
  numberOfChildren: number;
  selectedIndex: number;
  color: string = 'default';
  date = new FormControl(moment());
  maxBirthday;
  templateDownloadMessage;
  licensesToSchool: any = [];
  hasUnlimitedDiagnosticLicense: boolean = false;
  totalCountDiagnostic: number = 0;
  totalCountTraining: number = 0;
  totalCountLeftDiagnostic: number = 0;
  totalCountLeftTraining: number = 0;
  totalCountLeftDiagnosticExp: number = 0;
  totalCountLeftTrainingExp: number = 0;
  totalCountPending: number = 0;
  hasExpiringLicenses: boolean = false;
  languages: any = [];
  pdfPrintLayout = '1';
  filteredOptions: Observable<Languages[]>[] = new Array();
  isClosedDialog: boolean = false;
  page: number = 1;
  limit: number = -1;
  sortBy: string = 'lastname';
  sortDirection: number = 1;
  matSortDirection: string = 'asc';
  filterValue: string = '';
  teachers;
  selectedTeacher;
  selectedText;
  diasbledTeacher;
  groupTeachers: any = [];
  generatePDF;
  femaleCount: number = 0;
  maleCount: number = 0;
  diversCount: number = 0;
  @ViewChild('cdkStepper', { static: false }) stepper: CdkStepper;
  blobUrl: string | null = null;
  translatedText;
  studentsArray = [];
  filteredItems: Observable<any[]>;
  newStreamItem: FormControl;
  stream: string[] = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  prompt =
    'Drücken Sie die <enter> Taste um einen neuen Klassenzug hinzuzufügen.';
  showAddButton: boolean = false;
  recommendedAvatar = null;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  step: number = 1;
  isSuccess: boolean = false;
  isMultipleSelection: boolean = true;
  disabledTeacher: number = 0;
  studentCount: number = 0;
  groupName: string = '';
  STUDENT_LIMIT: number = 40;
  downloadSuccess: boolean = false;
  currentYearName: string = '';
  $currentYearUpdate = null;
  schoolYears: SchoolYears[] = [];
  studentCreationMode: typeof STUDENT_IMPORT_MODE = STUDENT_IMPORT_MODE;
  selectedStudentCreationMode: any = this.studentCreationMode.NORMAL;
  csvRecords: any[] = [];
  csvHeader: boolean = true;
  isCSVError: boolean = false;
  csvErrorMessage: string | null = null;
  csvFileName: string | null = null;
  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef;
  currentSystemLanguage: string = 'en';
  csvLanguageCode: string = 'en';
  enableCSVUpload: boolean = environment.whitelabel.enable_csv_learns_upload;
  $clearFormStatusTimeout: any = null;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private authService: AuthService,
    private adminService: AdminService,
    private groupService: GroupService,
    private studentService: StudentService,
    private capacitorService: CapacitorService,
    private translationService: TranslationService,
    private licenseService: LicenseService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    private ngxCsvParser: NgxCsvParser,
  ) {
    this.studentCount = injectedData['studentcount'];
  }

  ngOnInit(): void {
    const currentYear = moment().year();
    this.maxBirthday = moment([currentYear - 5, 11, 31]);
    this.teacherId = this.authService.getTeacherId();
    this.userType = this.authService.getType();
    this.studentService.getLanguages().subscribe((res) => {
      this.languages = res;
      for (const la of this.languages) {
        la.translate =
          this.translatedText[
            this.languages.filter((obj) => obj.code == la.code)[0]?.code
          ];
      }
    });
    this.currentSystemLanguage = localStorage.getItem('language');
    if (this.currentSystemLanguage === null) {
      this.currentSystemLanguage = environment.language;
    }

    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        let currentLanguage = this.currentSystemLanguage;
        if (currentLanguage === null) {
          currentLanguage = environment.language;
        }
        const templateFileName =
          currentLanguage.trim() === 'en'
            ? 'assets/file/students_template.csv'
            : 'assets/file/profildaten_lernende.csv';
        this.templateDownloadMessage = this.sanitizer.bypassSecurityTrustHtml(
          this.translatedText.t('admin_group_class_add_student_download_desc', {
            csvtemplate: `<a href="${templateFileName}" target="_blank">${this.translatedText.admin_group_class_add_student_download_link}</a>`,
          }),
        );
      });

    this.newStreamItem = new FormControl();
    this.filteredItems = this.newStreamItem.valueChanges.pipe(
      startWith(''),
      map((item) => (item ? this.filterItems(item) : this.stream?.slice())),
    );

    this.firstFormStep = this._formBuilder.group({
      // className   : ['', [Validators.required, Validators.maxLength(10)]],
      grade: ['', Validators.required],
      stream: ['', [Validators.required, this.onlyLettersValidator()]],
      school_year: ['', Validators.required],
      avatar: ['', Validators.required],
      teachers: [
        this.userType == 'teacher' ? [this.teacherId] : '',
        Validators.required,
      ],
    });

    this.secondFormStep = this._formBuilder.group(
      {
        genderMale: ['', Validators.required],
        genderFemale: ['', Validators.required],
        genderDivers: ['', Validators.required],
      },
      {
        // Set custom student count validator
        validators: this.studentCountValidator(),
      },
    );

    this.studentAmountForm = this._formBuilder.group({
      studentAmount: [0, Validators.required],
    });

    this.getTeachers();
    this.getLicenses();
    this.trialStatus = this.authService.getUserTrialStatus();
    if (this.trialStatus.isActive) {
      this.STUDENT_LIMIT = environment.whitelabel.trial_number_students;
    }
    this.firstFormStep.statusChanges.subscribe((val) => {
      if (val) {
        this.adminService.updateClassFormStatus({
          form: 'firstFormStep',
          status: val,
        });
      }
    });

    this.studentAmountForm.statusChanges.subscribe((val) => {
      if (val) {
        if (
          this.studentAmountForm.get('studentAmount')?.value < 1 ||
          this.studentAmountForm.get('studentAmount')?.value > 40
        ) {
          val = 'INVALID';
        }
        this.adminService.updateClassFormStatus({
          form: 'studentAmountForm',
          status: val,
        });
      }
    });

    this.adminService.$addClassNextBtn.subscribe((data: any) => {
      if (data && data === 'next') {
        this.next();
      } else if (data && data === 'back') {
        this.back();
      }
    });

    this.adminService.$addClassFormStatus.subscribe((data: any) => {
      if (data && data.form === 'studentFormStep') {
        this.thirdFormStep = data.formData;
      }
    });
    this.firstFormStep.get('teachers')?.setValue([this.teacherId]);
    this.getSchoolYearUpdateInfo();
  }

  getLicenses() {
    this.licenseService.getLicensesToSchool();
    this.licenseService
      .getLicensesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((licenses: any[]) => {
        this.licensesToSchool = licenses;
        let fourWeeksFromNow = new Date();
        fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
        let expireLicenseCount = 0;
        for (const item of licenses) {
          if (item.expiration_date <= fourWeeksFromNow) {
            expireLicenseCount++;
          }
        }
        if (expireLicenseCount > 0) {
          this.hasExpiringLicenses = true;
        }
        this.totalCountPending = this.licenseService.sumPendingLicenses(
          this.licensesToSchool,
        );
        this.totalCountDiagnostic = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'di',
          'amount',
          true,
        );
        this.hasUnlimitedDiagnosticLicense =
          this.licenseService.checkForUnlimitedDiagnosticLicense(
            'di',
            this.licensesToSchool,
            true,
          );
        this.totalCountLeftDiagnostic =
          this.licenseService.sumLicensesByTypeKey(
            this.licensesToSchool,
            'di',
            'amount_left',
            true,
          );
        // this.totalCountLeftDiagnosticExp = 45;
        this.totalCountLeftDiagnosticExp =
          this.licenseService.filterLicensesByExpirationDate(
            this.licensesToSchool,
            'di',
            'amount_left',
          );
        this.totalCountTraining = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'fo',
          'amount',
          true,
        );
        this.totalCountLeftTraining = this.licenseService.sumLicensesByTypeKey(
          this.licensesToSchool,
          'fo',
          'amount_left',
          true,
        );
        this.totalCountLeftTrainingExp =
          this.licenseService.filterLicensesByExpirationDate(
            this.licensesToSchool,
            'fo',
            'amount_left',
          );
      });
  }

  onlyLettersValidator() {
    return (control) => {
      const value = control.value;
      if (!/^[a-zA-ZäöüÄÖÜ]+$/.test(value)) {
        return { onlyLetters: true };
      }
      return null;
    };
  }

  filterItems(name: string) {
    let results = this.stream.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  validatorRequireMatch(control: AbstractControl) {
    const selection: any = control.value;
    if (
      this.languages &&
      this.languages.find((obj) => obj.code === selection)
    ) {
      return null;
    }
    return { incorrect: true };
  }

  gradeSelection(event) {
    if (event.value >= 1 && event.value <= 4) {
      this.firstFormStep.get('avatar').setValue('animal');
      this.recommendedAvatar = 'animal';
    } else if (event.value >= 5 && event.value <= 10) {
      this.firstFormStep.get('avatar').setValue('planet');
      this.recommendedAvatar = 'planet';
    }
  }

  private _filter(value: string): Languages[] {
    const filterValue = value.toLowerCase();
    return this.languages.filter(
      (option) =>
        option.name.toLowerCase().startsWith(filterValue) ||
        option.translate.toLowerCase().startsWith(filterValue),
    );
  }

  displayFn(code) {
    return this.getLanguageByCode(code);
  }

  getLanguageByCode(code) {
    return this.languages !== undefined
      ? this.translatedText[
          this.languages.filter((obj) => obj.code === code)[0]?.code
        ]
      : '';
  }

  setLanguages(index) {
    this.filteredOptions[index] = (
      (this.thirdFormStep.get('group') as FormArray).at(index) as FormGroup
    )
      .get('language')
      .valueChanges.pipe(
        startWith(''),
        map((value) => {
          const name = typeof value === 'string' ? value : '';
          return name
            ? this._filter(name as string)
            : this.languages
              ? this.languages.slice()
              : [];
        }),
      );
  }

  setLanguageOption(value, index) {
    ((this.thirdFormStep.get('group') as FormArray).at(index) as FormGroup)
      .get('language')
      .patchValue(value);
  }

  closeClassOverlay() {
    this.dialog.closeAll();
  }

  // Custom validator for the teachers selection
  validateTeachersSelection(control) {
    const selectedTeachers = control.value;
    if (selectedTeachers && selectedTeachers.length > 3) {
      return { maxTeachersSelected: true };
    }
    return null;
  }

  selectedTeacherValue(value) {
    this.selectedTeacher = value.length;
    this.groupTeachers = value;
  }

  filteredTeachers(): string[] {
    return this.teachers?.filter((teacher) =>
      this.firstFormStep.get('teachers').value?.includes(teacher._id),
    );
  }

  checkForCurrentTeacher(teacher): boolean {
    return teacher?._id == this.teacherId && teacher?._user?.role == 'teacher';
  }

  removeSelectedTeacher(index: number, event): void {
    event.stopPropagation();
    this.firstFormStep.get('teachers').value?.splice(index, 1);
    this.firstFormStep
      .get('teachers')
      .setValue(this.firstFormStep.get('teachers')?.value);
  }

  /**
   * API call on adminService to retrieve requested teacher
   */
  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .pipe(
        map((teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data;
        }),
      )
      .subscribe();
  }

  checkGrouplength(groupTeacher) {
    if (groupTeacher?.length === 3) {
      return true;
    }
  }

  increaseValue() {
    let studentAmount = this.studentAmountForm.get('studentAmount').value || 0;
    // Angular digest cycle will take few seconds to update the form value, that's why adding 1
    if (
      this.trialStatus.isActive &&
      studentAmount + this.studentCount + 1 > this.STUDENT_LIMIT
    ) {
      this.openTrialBanner();
      return;
    }
    this.studentAmountForm.get('studentAmount').setValue(studentAmount + 1);
  }

  decreaseValue() {
    const studentAmount =
      this.studentAmountForm.get('studentAmount').value || 0;
    if (studentAmount > 0) {
      this.studentAmountForm.get('studentAmount').setValue(studentAmount - 1);
    }
  }

  onStudentAmountInput() {
    let studentAmount = this.studentAmountForm.get('studentAmount').value || 0;
    const maxStudentsDuringTrialPhase = this.STUDENT_LIMIT;
    studentAmount = this.studentCount + studentAmount;
    if (
      this.trialStatus.isActive &&
      studentAmount > maxStudentsDuringTrialPhase
    ) {
      this.openTrialBanner();
      this.studentAmountForm
        .get('studentAmount')
        .setValue(maxStudentsDuringTrialPhase);
    }
  }

  /*   checkTrialMax() {
    if (
      this.trialStatus.isActive &&
      this.studentAmountForm.get('studentAmount').value > 9
    ) {
      this.openTrialBanner();
      this.studentAmountForm.get('studentAmount').setValue(10);
    }
  } */

  // openUpgradeModal() {
  //   this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
  //     UpgradeDialogComponent,
  //     {
  //       width: '85vw',
  //       autoFocus: false,
  //       disableClose: true,
  //       panelClass: 'home-dialog-panel',
  //       // hasBackdrop: false,
  //       backdropClass: 'license-add-backdrop',
  //       data: { upgradeModalText: 'maxStudentsReached' },
  //     },
  //   );
  // }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  studentCountValidator(): ValidatorFn {
    return (formGroup: FormGroup) => {
      const maleControl = formGroup.get('genderMale');
      const femaleControl = formGroup.get('genderFemale');
      const diversControl = formGroup.get('genderDivers');

      // if (!maleControl || !femaleControl || !diversControl) {
      //   return null;
      // }

      const maleValue = maleControl.value;
      const femaleValue = femaleControl.value;
      const diversValue = diversControl.value;
      const studentCountValidator =
        maleValue + femaleValue + diversValue < 1 ? true : false;
      const studentMaxCountValidator =
        maleValue + femaleValue + diversValue > 40 ? true : false;
      if (studentCountValidator) {
        return studentCountValidator ? { studentCountValidator: true } : null;
      } else if (studentMaxCountValidator) {
        return studentMaxCountValidator
          ? { studentMaxCountValidator: true }
          : null;
      }
    };
  }

  closeAll(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  downloadPDF() {
    if (!this.IS_APP) {
      let pdfFilename = this.groupService.generateGroupFilenameDate(
        this.groupName,
      );
      var element = document.createElement('a');
      element.setAttribute('href', this.blobUrl);
      element.setAttribute('download', pdfFilename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    this.downloadSuccess = true;
  }

  closeOverlay() {
    this.dialog.closeAll();
  }

  next() {
    //skip assign license step
    if (this.step === 4) {
      this.step = 6;
    }
    //skip csv step
    /* else if (this.step === 1) {
      //this.step = 2;
      this.step = 3;
    } */
    else {
      this.step++;
    }
  }

  back() {
    if (this.step === 3) {
      this.csvFileName = null;
      this.selectedStudentCreationMode = this.selectedStudentCreationMode;
      if (this.fileInput && this.fileInput.nativeElement) {
        this.fileInput.nativeElement.value = '';
      }
      this.csvRecords = [];
      this.step--;
    } else if (this.step === 2 && this.selectedStudentCreationMode === null) {
      this.$clearFormStatusTimeout = setTimeout(() => {
        this.adminService.updateClassFormStatus({
          form: 'studentCreationMode',
          status: 'INVALID',
        });
      }, 100);
      this.step--;
    } else if (this.step === 6) {
      /* skip csv step
     if (this.step === 3) {
      this.step = 1;
    } else  */
      this.step = 4;
    } else {
      this.step--;
    }
  }
  // Get Data from Assign License Child Component and map to main student forms
  getAssignedLicense(event) {
    for (var group of this.thirdFormStep.value.group) {
      const exactStudent = find(event, { fullname: group.name });
      if (exactStudent) {
        group.licenses = {};
        Object.assign(group, {
          licenses: { di: exactStudent.diLicense, fo: exactStudent.foLicense },
        });
      }
    }
  }

  submitForm() {
    this.isLoading = true;
    if (this.firstFormStep.invalid || this.thirdFormStep.invalid) {
      this.thirdFormStep.markAllAsTouched();
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        panelClass: 'snack-error',
        data: this.translatedText.admin_group_class_creation_warning,
        duration: 3000,
        horizontalPosition: 'left',
      });
      return;
    }
    let groupArray = {
      teacherId: this.firstFormStep.value.teachers,
      stream: this.firstFormStep.value.stream,
      grade: this.firstFormStep.value.grade,
      school_year: this.firstFormStep.value.school_year,
      pdfPrintLayout: this.pdfPrintLayout,
      generatePDF: this.generatePDF,
      translatedText: this.translatedText,
      namesPackageType: this.firstFormStep.value.avatar,
    };
    // If Skip is clicked we need to set license default value false

    for (var group of this.thirdFormStep.value.group) {
      if (!group.hasOwnProperty('licenses')) {
        group.licenses = {};
        Object.assign(group, {
          // licenses: { di: false, fo: false }, // UNCOMMENT AS SOON AS LICENSE ASSIGN STEP IS AVAILABLE
          licenses: { di: true, fo: true }, // PERMANANTLY ON LICENSE ASSIGN HIDDEN
        });
      }
    }
    this.isLoading = true;
    this.groupName =
      groupArray.grade + groupArray.stream + '-' + groupArray.school_year;
    this.groupService
      .createGroupWithPDF(
        groupArray,
        this.thirdFormStep.value.group,
        this.translatedText,
        this.firstFormStep.value.avatar,
      )
      .subscribe({
        next: (response: any) => {
          if (response) {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);
            let blob = new Blob(binaryData, { type: 'application/pdf' });
            let blobUrl = window.URL.createObjectURL(blob);
            this.blobUrl = blobUrl;
            if (this.IS_APP) {
              let pdfFilename =
                this.groupService.generateGroupFilename(groupArray);
              this.capacitorService.saveBlobToFilesystem(blob, pdfFilename);
            }
            //this.groupService.openPdfOverlay(blobUrl, 'multiple');
            this.isLoading = false;
            this.downloadPDF();
          }
        },
        error: (error: any) => {
          let message = this.translatedText.admin_group_class_error;
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
      });
  }
  formatDate(inputDate: string): string {
    const parts = inputDate.split('/');
    if (parts.length !== 2) {
      return '';
    }

    const year = parts[1];
    const month = parts[0];
    const day = '01';

    const formattedDate = `${year}-${month}-${day}`;
    const parsedDate = new Date(formattedDate);

    if (isNaN(parsedDate.getTime())) {
      return '';
    }

    const formattedOutput = this.datePipe.transform(
      parsedDate,
      "EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ (zzzz)",
      'en-US',
    );
    return formattedOutput || '';
  }

  checkTeacherArray(teacher, groupTeacher) {
    // get disabled teacher length
    if (groupTeacher) {
      this.disabledTeacher = groupTeacher.length;
      return groupTeacher.some((item) => item._id === teacher);
    }
  }

  /*   openStudentsCsvImport(): void {
    let dialogRef = this.dialog.open(CsvImportStudentComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'csv-import-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'csv-import-panel-backdrop',
      data: {
        students: this.thirdFormStep.get('group').value.length,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.length > 0) {
        if (res.length === this.thirdFormStep.get('group').value.length) {
          // Durchlaufen Sie beide Arrays und setzen Sie die Werte mit setValue
          for (let i = 0; i < res.length; i++) {
            this.thirdFormStep
              .get('group')
              .get(i.toString())
              .setValue({
                name: res[i].name,
                gender: res[i].gender,
                language: res[i].language,
                age: moment(this.formatDate(res[i].age)),
              });
          }
        }
      }
    });
  }
 */
  openTrialBanner(): void {
    let dialogRef = this.dialog.open(TrialBannersComponent, {
      width: '100%',
      autoFocus: false,
      disableClose: true,
      panelClass: 'trial-banner-panel',
      backdropClass: 'license-transfer-backdrop',
      data: {
        source: 'upgrade',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {});
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      if (data) {
        this.currentYearName = data.year;
        if (data.yearChange) {
          this.schoolYears.push({
            name: data.nextYear.slice(2),
            value: data.nextYear,
          });
        } else {
          this.schoolYears.push({
            name: this.currentYearName.slice(2),
            value: this.currentYearName,
          });
        }

        this.$currentYearUpdate = setTimeout(() => {
          if (data.yearChange)
            this.firstFormStep.patchValue({ school_year: data.nextYear });
          else this.firstFormStep.patchValue({ school_year: data.year });
        }, 500);
      }
    });
  }

  chooseStudentCreationMode(type: any) {
    this.studentAmountForm.patchValue({ studentAmount: 0 });
    this.selectedStudentCreationMode = type;
    this.adminService.updateClassFormStatus({
      form: 'studentCreationMode',
      status: 'VALID',
    });
  }

  displayEnoughLicensesNote() {
    let currentAmount = this.studentAmountForm.get('studentAmount').value;
    return (currentAmount > this.totalCountLeftDiagnostic &&
      !this.totalCountDiagnostic) ||
      currentAmount > this.totalCountLeftTraining
      ? true
      : false;
  }
  getCSVStudentData(csvData) {
    this.csvRecords = csvData;
  }

  ngOnDestroy(): void {
    if (this.$currentYearUpdate) {
      clearTimeout(this.$currentYearUpdate);
    }
    if (this.$clearFormStatusTimeout) {
      clearTimeout(this.$clearFormStatusTimeout);
    }
    this.csvRecords = [];
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
